import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { Info, ThumbsUp } from "react-feather";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Input } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import { API_URL_V1 } from "../../../api";
import { dataURLtoFile } from "../../../Services/Api.Service";
import { authHeader } from "../../../Services/AuthVerify";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import { canvasPreview } from "../BecomeTutor/canvasPreview";
import { useDebounceEffect } from "../BecomeTutor/useDebounceEffect";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 30,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const StudentPhotoContain = () => {
  const {
    handleSkipOrNext,
    profilePhoto,
    setProfilePhoto,
    isEmailVerified,
    handleEmailVerified,
  } = useContext(BecomeStudentContext);

  const navigate = useNavigate();

  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(4 / 4);
  const [img64, setImg64] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const imageInputRef = useRef();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
      const base64Image = previewCanvasRef.current?.toDataURL("image/jpeg", 1);
      setImg64(base64Image);
    },
    100,
    [completedCrop, scale, rotate]
  );

  const handleSavePhoto = async () => {
    setIsLoading(true);
    const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

    if (_tuteair?._id && imgSrc) {
      var file = dataURLtoFile(img64, _tuteair?.name + ".jpeg");

      const formData = new FormData();
      formData.append("document", file);
      formData.append("prev_photo", _tuteair?.prev_photo);
      formData.append("userId", _tuteair?._id);

      const res2 = await axios.post(
        `${API_URL_V1}/public/user-photo-save`,
        formData,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (res2?.data) {
        if (res2.data?.message === "success") {
          alert("successfully updated photo");

          localStorage.setItem("_userPhoto", img64);
          localStorage.setItem("picture2", img64);
          setProfilePhoto(img64);

          localStorage.setItem(
            "_tuteair_newTutor",
            JSON.stringify({
              ..._tuteair,
              prev_photo: res2?.data?.prev_photo,
            })
          );

          setImgSrc("");
          imageInputRef.current.value = "";
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      alert("Select image again");
      setIsLoading(false);
    }
  };

  const handleFinish = async (e) => {
    await handleEmailVerified(e, 8, navigate);
  };

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <>
      <Row className="bt_title">
        <Col md={12} className={"text-center"}>
          <h1 className="text-left">Photo</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  03 <br /> Photo
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Your Picture</h6>
                    <p>
                      <ul className="list-group">
                        <li>
                          <i className="icofont icofont-minus"></i> Upload a
                          photo of yourself so the student can recognize you.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <hr />

                <div className="media bt_help">
                  <Info />
                  <div className="media-body">
                    <p>
                      Uploading any image that cannot be used to identify you is
                      prohibited.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card>
            <CardHeader className="pb-0">
              <H5>Upload your photo</H5>
            </CardHeader>
            <CardBody>
              <div className="input-cropper">
                <Input
                  type="file"
                  onChange={onSelectFile}
                  className="form-control"
                  ref={imageInputRef}
                  accept="image/*"
                  title="Choose a Profile Picture"
                />
              </div>
              {Boolean(imgSrc) && (
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              )}
              <div className="py-2 row">
                <Col md={6} sm={12}>
                  Your Photo:
                </Col>
                <Col md={6} sm={12}>
                  {Boolean(completedCrop) && (
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: completedCrop.height,
                      }}
                    />
                  )}

                  {!completedCrop && profilePhoto && (
                    <img
                      src={profilePhoto}
                      alt=""
                      style={{ width: "200px", height: "200px" }}
                    />
                  )}
                </Col>
              </div>
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-1"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn btn-lg btn-back btn-info  mx-2"
                onClick={(e) => handleSkipOrNext("back")}
              >
                Back
              </button>

              <button
                className="btn  btn-lg btn-danger  mx-2"
                disabled={imgSrc === "" || isLoading}
                onClick={(e) => handleSavePhoto()}
              >
                Upload
              </button>
              <button
                className="btn  btn-lg btn-success btn_finish"
                disabled={profilePhoto === "" || isLoading}
                onClick={(e) => handleFinish(e)}
              >
                Finish
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default StudentPhotoContain;
