import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Clock } from "react-feather";
import { Link } from "react-router-dom";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";

import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import { authHeader } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import TutorLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";
import RequestTuitionModal from "../../RequestTuition/Student/RequestTuitionAddEdit";
import ReactPaginate from "react-paginate";

// import BasicFilter from "./BasicFilter";
// import SubjectsFilter from "./SubjectsFilter";
// import TutorLocationFilter from "./TutorLocationFilter";

// import EducationFilter from "./EducationFilter";
// import ScheduleFilter from "./ScheduleFilter";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";

export default function ListView() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const { accountType } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredStudents = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "tutor" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );
  const showStudents = allFilteredStudents.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredStudents.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const filterByLanguage = async (tutors, languageArray) => {
    let temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      if (languageArray.every((lan) => tutors[i].languages?.includes(lan))) {
        temp_tutors.push(tutors[i]);
      }
    }
    return temp_tutors;
  };

  const filterByTuitionType = async (tutors, tutionType) => {
    return tutors.filter((tutor) =>
      tutionType.some((item) =>
        tutor.interestToLearn.filter((i) => i.tutionTypes?.indexOf(item) >= 0)
          .length
          ? true
          : false
      )
    );
  };

  const filterBySubject = async (tutors, subjectsArray) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestToLearn.length; j++) {
        for (var k = 0; k < subjectsArray.length; k++) {
          for (
            var l = 0;
            l < tutors[i].interestToLearn[j].subjects.length;
            l++
          ) {
            if (
              tutors[i].interestToLearn[j].subjects[l].id == subjectsArray[k].id
            ) {
              tutor_taken = true;
              temp_tutors.push(tutors[i]);
              break;
            }
          }
          if (tutor_taken) break;
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const filterBySubjectCategory = async (tutors, categoryObject) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestToLearn.length; j++) {
        for (var l = 0; l < tutors[i].interestToLearn[j].subjects.length; l++) {
          if (
            tutors[i].interestToLearn[j].subjects[l].tag.indexOf(
              categoryObject.value
            ) !== -1
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.media.picture.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
              filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
              filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
              filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
              filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    // filtering ends here
    setTutorsToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    GetAllTutors();
    setLoading(false);
  }, []);

  const updateFilter = () => {
    const addedFilter = {};

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }

    //console.log(addedFilter);

    setFilters(addedFilter);
  };

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8}>
            {loading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {allFilteredStudents?.length} matched student's based on your
                search criteria
              </CardTitle>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>
                {showStudents?.map((tutor, x) => (
                  <Card className="mb-2" key={x}>
                    <CardBody className="justify-content-between">
                      <div className="row">
                        <div className="d-flex align-items-center col-md-9">
                          <CardImg
                            style={{
                              width: "120px",
                              height: "120px",
                              cursor: "pointer",
                            }}
                            className="rounded-circle me-4"
                            src={
                              tutor.media.picture
                                ? ` ${ProfilePictureRootURL}/${tutor.media.picture}`
                                : man
                            }
                            alt={tutor.name?.firstName}
                          />
                          <div>
                            <CardTitle
                              tag="h3"
                              className="cardBox-2"
                              onClick={() =>
                                window.open(
                                  `/student-profile/${tutor.userId}`,
                                  "_blank"
                                )
                              }
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  color: "blueviolet",
                                }}
                              >
                                {!tutor.name.firstName && (
                                  <span>Name not given</span>
                                )}
                                <span className="darkModeTutorName">
                                  {tutor.name?.firstName} {tutor.name?.lastName}
                                </span>
                              </div>
                            </CardTitle>

                            <CardText className="mb-0 color-info">
                              {tutor.interestToLearn?.map((item, i) =>
                                [
                                  ...new Set(
                                    item.subjects?.map((sub) => sub.tag)
                                  ),
                                ]?.map((subject, k) => (
                                  <span
                                    key={k}
                                    className="badge badge-light-primary py-1"
                                  >
                                    {subject}
                                  </span>
                                ))
                              )}
                            </CardText>

                            <CardText>
                              {" "}
                              {tutor.about?.studentBio?.length > 200 ? (
                                <span>
                                  {tutor.about?.studentBio.substring(0, 195)}
                                  ...
                                </span>
                              ) : (
                                <span>{tutor.about?.studentBio}</span>
                              )}
                            </CardText>

                            <CardText tag="h5" className="mt-1">
                              {""} {tutor.address?.postalCode}{" "}
                              {tutor.address?.city} {tutor.address?.country}
                            </CardText>
                          </div>
                        </div>
                        <div
                          className="col-md-3"
                          style={{ textAlignLast: "right" }}
                        >
                          <span className="text-primary">
                            <Clock
                              style={{
                                height: "17px",
                                verticalAlign: "text-top",
                              }}
                            />
                            <span style={{ verticalAlign: "top" }}>
                              Joined {moment(tutor.createdAt).fromNow()}
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </span>
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            <div>
              <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
                <AccordionItem>
                  <AccordionHeader targetId="1">Basic</AccordionHeader>
                  <AccordionBody accordionId="1">
                    <BasicFilter setFilters={setFilters} />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Subjects</AccordionHeader>
                  <AccordionBody accordionId="2">
                    <SubjectsFilter setFilters={setFilters} />
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">
                    Tutor's Location
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <TutorLocationFilter setFilters={setFilters} />
                  </AccordionBody>
                </AccordionItem>

                {/* <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Schedule</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <ScheduleFilter />
                  </AccordionBody>
                </AccordionItem> */}
              </UncontrolledAccordion>
              <div style={{ textAlign: "right", marginTop: "10px" }}>
                <Button
                  color="primary"
                  className="mb-2 "
                  onClick={updateFilter}
                >
                  Apply
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        {allFilteredStudents?.length !== 0 && (
          <div className="mt-5 mb-5 pagination">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              //disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </Col>
    </Fragment>
  );
}
