import React, { useEffect, useState } from "react";
import Rating from "react-rating";

const TutorRating = (props) => {
  const [tutorBehaviour, setTutorBehaviour] = useState(0);
  const [capability, setCapability] = useState(0);
  const [proffesionalism, setProffesionalism] = useState(0);

  useEffect(() => {
    setTutorBehaviour(props.rating.behaviour);
    setCapability(props.rating.capability);
    setProffesionalism(props.rating.proffesionalism);
  }, []);

  return (
    <table className="table table-borderless" style={{ width: "280px" }}>
      <tr>
        <td className="py-0">
          <span>Behaviour </span>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={tutorBehaviour}
            emptySymbol="fa fa-star-o fa-1x"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setTutorBehaviour(rate)}
          ></Rating>
          <span>{tutorBehaviour}</span>
        </td>
      </tr>
      <tr>
        <td className="py-0">
          <span>Proffesionalism </span>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={proffesionalism}
            emptySymbol="fa fa-star-o fa-1x text-default"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setProffesionalism(rate)}
          ></Rating>
          <span>{proffesionalism}</span>
        </td>
      </tr>
      <tr>
        <td className="py-0">
          <span>Capability </span>
        </td>
        <td className="py-0">
          <Rating
            readonly
            initialRating={capability}
            emptySymbol="fa fa-star-o fa-1x text-default"
            fullSymbol="fa fa-star fa-1x text-warning"
            onChange={(rate) => setCapability(rate)}
          ></Rating>
          <span>{capability}</span>
        </td>
      </tr>
    </table>
  );
};

export default TutorRating;
