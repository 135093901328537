import React, { useContext, useEffect, useState } from "react";
import { Row, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Info, Plus, PlusSquare, ThumbsUp } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  Label,
  Media,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import { saveNewSchedule } from "./fnBecomeTutor";

const BecomeTutorSchedule = () => {
  const {
    schedule,
    setSchedule,
    scheduleList,
    setScheduleList,
    handleSkipOrNext,
  } = useContext(BecomeTutorContext);

  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";

    setSchedule({
      ...schedule,
      from: new Date("01-Nov-2022 08:00 AM"),
      to: new Date("01-Nov-2022 11:00 AM"),
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    });

    if (scheduleList?.length === 0) {
      setIsAdded(false);
    }
  }, []);

  const handleSaveSchedule = async (e) => {
    e.preventDefault();
    if (isAdded) {
      var res = await saveNewSchedule(schedule, 2);
      if (res) {
        var newList = [...scheduleList, schedule];
        setScheduleList(newList);
        setIsAdded(!isAdded);
      }
    } else {
      alert("disabled");
    }
  };

  const handleAddNew = (e) => {
    e.preventDefault();
    setIsAdded(!isAdded);
  };

  const getLocalTime = (utcDate) => {
    const date = new Date(utcDate);
    const date_string =
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0"); //+ " " + date.toLocaleString().split(',')[1].slice(-2)
    return date_string; //date.toLocaleString().split(',')[1];
  };
  return (
    <>
      <Row className="bt_title">
        <Col md={4}></Col>
        <Col md={4}>
          <h1 className="text-center">Schedules</h1>
        </Col>
        <Col md={4} className="self-end">
          <Media className="float-end">
            <div className={` ${isAdded ? "d-none" : ""}`}>
              <a
                href="#education"
                onClick={(e) => handleAddNew(e)}
                className=""
              >
                <PlusSquare height={35} width={35} />
              </a>
            </div>
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  02 <br /> Schedule
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Your schedule</h6>
                    <p>
                      Choose from time and to time, when you are available to
                      teach
                    </p>
                    <p>
                      After that, choose the days you will teach in your
                      available time
                    </p>
                  </div>
                </div>

                <hr />
                <div className="media bt_help  align-items-center">
                  <Info />
                  <div className="media-body">
                    <p>
                      By clicking,
                      <span onClick={(e) => handleAddNew(e)} className="icon">
                        <Plus />
                      </span>
                      you may add several schedules.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card className="mb-0">
            <CardHeader className="d-none">
              <Media className="float-end">
                <div className={` ${isAdded ? "d-none" : ""}`}>
                  <a
                    href="#education"
                    onClick={(e) => handleAddNew(e)}
                    className=""
                  >
                    <PlusSquare height={30} width={30} />
                  </a>
                </div>
              </Media>
            </CardHeader>
            <CardBody className="megaoptions-border-space-sm">
              {!isAdded && (
                <div className="table-responsive-sm">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr style={{ background: "#dbdbdb2b" }}>
                        <th>Time</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                        <th>Sun</th>
                      </tr>
                    </thead>

                    <tbody>
                      {scheduleList?.length === 0 && (
                        <tr className="text-center text-red">
                          <td colSpan={9}>No Schedule Info Found</td>
                        </tr>
                      )}
                      {scheduleList?.map((item, k) => (
                        <tr key={k}>
                          <th scope="row">
                            {/* <span className="d-flex scheduleTime">
                          {JSON.stringify(item.from).slice(12, 17)}-
                          {JSON.stringify(item.to).slice(12, 17)}{" "}
                        </span> */}
                            <span className="d-flex scheduleTime">
                              {getLocalTime(item.from)} -{getLocalTime(item.to)}{" "}
                            </span>
                          </th>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.mon}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.tue}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.wed}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.thu}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.fri}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.sat}
                              readOnly
                            />
                          </td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={item.sun}
                              readOnly
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {isAdded && (
                <Row>
                  <Col sm="12" xl="12 box-col-12">
                    <Card className="mb-0">
                      <CardBody className="megaoptions-border-space-sm">
                        <Row>
                          <Col sm="12" xl="12">
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                From
                              </Label>
                              <Col sm="4">
                                <InputGroup
                                  className="date"
                                  id="dt-minimum"
                                  data-target-input="nearest"
                                >
                                  <DatePicker
                                    className="form-control datetimepicker-input digits"
                                    selected={schedule?.from}
                                    onChange={(event) => {
                                      setSchedule({
                                        ...schedule,
                                        from: event,
                                      });
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="From"
                                    timeFormat="HH:mm"
                                    dateFormat="H:mm"
                                  />
                                </InputGroup>
                              </Col>
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                To
                              </Label>
                              <Col sm="4">
                                <InputGroup
                                  className="date"
                                  id="dt-minimum"
                                  data-target-input="nearest"
                                >
                                  <DatePicker
                                    className="form-control datetimepicker-input digits"
                                    selected={schedule?.to}
                                    onChange={(event) => {
                                      setSchedule({
                                        ...schedule,
                                        to: event,
                                      });
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="To"
                                    timeFormat="HH:mm"
                                    dateFormat="H:mm"
                                  />
                                </InputGroup>
                              </Col>
                            </div>
                            <Col sm="12" xl="12">
                              <div className="row mb-2"></div>
                            </Col>
                            <div className="row mb-2">
                              <div className="table-responsive-sm">
                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th>Mon</th>
                                      <th>Tue</th>
                                      <th>Wed</th>
                                      <th>Thu</th>
                                      <th>Fri</th>
                                      <th>Sat</th>
                                      <th>Sun</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          //checked={mon}
                                          // onChange={() => setMon(!mon)}
                                          checked={schedule?.mon === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              mon: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.tue === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              tue: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.wed === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              wed: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.thu === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              thu: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.fri === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              fri: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.sat === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              sat: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="checkbox"
                                          checked={schedule?.sun === true}
                                          onChange={(event) => {
                                            setSchedule({
                                              ...schedule,
                                              sun: event.target.checked,
                                            });
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                          <Col sm="12" xl="12">
                            <div className="row mb-2">
                              <Col md={12} className="btn_acc">
                                <button
                                  className="btn  btn-sm btn-warning"
                                  onClick={(e) => {
                                    setIsAdded(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn mx-2 btn-sm btn-success"
                                  onClick={(e) => handleSaveSchedule(e)}
                                >
                                  Save
                                </button>
                              </Col>
                            </div>
                            <div className="row mb-2"></div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-4"
          >
            <Col md={12} className="btn_acc">
              <>
                <button
                  className="btn btn-lg btn-back btn-info  mx-2"
                  onClick={(e) => handleSkipOrNext("back")}
                >
                  Back
                </button>
              </>

              <button
                className="btn  btn-lg btn-warning btn-skip "
                disabled={isAdded}
                onClick={(e) => handleSkipOrNext("skip")}
              >
                {scheduleList?.length > 0 ? "Next" : "Skip"}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BecomeTutorSchedule;
