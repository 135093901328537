import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";

export default function TuitionFeesFilter({ isReset }) {
  const [currency, setCurrency] = useState("");
  const [minValue, set_minValue] = useState("");
  const [maxValue, set_maxValue] = useState("");
  const { priceFilter, setPriceFilter } = useContext(FilterContext);
  const { getCurrencies, currencies, setCurrencies } =
    useContext(TutorsContext);

  useEffect(() => {
    if (currencies.length === 0) {
      getCurrencies();
    }
    return () => {
      // setCurrencies(currencies);
    };
  }, []);

  const updateCurrencyFilter = (item) => {
    if (!item || item.length == 0) {
      setPriceFilter({});
      set_maxValue("");
      set_minValue("");
      return;
    }
    setPriceFilter((prev) => ({ ...prev, currency: item }));
  };

  useEffect(() => {
    if (maxValue == "" && minValue == "") {
      setPriceFilter({});
    } else if (maxValue >= minValue) {
      setPriceFilter((prev) => ({ ...prev, min: minValue, max: maxValue }));
    }
  }, [minValue, maxValue]);

  const initializeFeesFilter = () => {
    setCurrency("");
    setPriceFilter({});
    set_maxValue("");
    set_minValue("");
  };

  useEffect(() => {
    if (isReset) {
      initializeFeesFilter();
    }
  }, [isReset]);

  return (
    <div className="filter_card">
      <h4 className="f_title">
        <span>Price/hour</span>
      </h4>
      <br></br>
      <Row>
        <Col md={5}>
          <Select
            options={currencies}
            placeholder="Select Currency"
            className="js-example-basic-single col-sm-12 darkMode"
            isClearable={true}
            onChange={(item) => {
              setCurrency(item);
              updateCurrencyFilter(item);
            }}
            value={currency}
          />
        </Col>

        <Col md="7">
          <Row>
            <Col sm="5">
              <Input
                type="number"
                placeholder="Minimum"
                value={minValue}
                disabled={!currency}
                onChange={(e) => set_minValue(e.target.value)}
              ></Input>
            </Col>
            -
            <Col sm="5">
              <Input
                type="number"
                placeholder="Maximum"
                value={maxValue}
                disabled={!currency}
                onChange={(e) => set_maxValue(e.target.value)}
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>{" "}
    </div>
  );
}
