import axios from "axios";
import React, { useEffect, useState } from "react";
import { MemberAllInfosGetApi, ProfilePictureRootURL } from "../../api";
import { axiosRequest } from "../../redux/utils/axios-utils";
import { authHeader } from "../../Services/AuthVerify";
import Context from "./index";

const MemberInformationProvider = (props) => {
  const [about, setAbout] = useState({
    tutorBio: " ",
    studentBio: " ",
  });
  const [isActive, setisActive] = useState(true);
  const [media, setmedia] = useState({});
  const [dateOfBirth, setdateOfBirth] = useState(new Date("01/01/2000"));
  const [fullname, setFullName] = useState({});
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [languages, setLanguages] = useState([]);
  const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);
  const [memberType, setMemberType] = useState("");
  const [address, setAddress] = useState({});
  const [educations, setEducations] = useState([]);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [interestAndPricing, setInterestAndPricing] = useState([]);
  const [interestToLearn, setInterestToLearn] = useState([]);

  const [profileUrl, setProfileUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [documentID, setDocumentID] = useState("");
  const [degreeList, setDegreeList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [tutorViewCount, setTutorViewCount] = useState(0);
  const [studentViewCount, setStudentViewCount] = useState(0);

  const setMembersAllInfo = (data) => {
    //console.log(data.educations)
    setAbout(data.about);
    setmedia(data.media);
    setdateOfBirth(new Date(data.doB == "" ? "01/01/2000" : data.doB));
    setFullName(data.name?.firstName + " " + data.name?.lastName);
    setGender(data.gender);
    setEmail(data.email);
    setPhone(data.contactNo.number);
    setLanguages(data.languages);
    setMemberType(data.memberType);
    setAddress(data.address);
    setEducations(data.educations);
    setWorkExperiences(data.workExperiences);
    setSchedules(data.schedules);
    setInterestAndPricing(data.interestAndPricing);
    setInterestToLearn(data.interestToLearn);
    setStudentViewCount(data.studentViewCount ? data.studentViewCount : 0);
    setTutorViewCount(data.tutorViewCount ? data.tutorViewCount : 0);

    const selectedlanguages = [];
    for (var i = 0; i < data.languages.length; i++) {
      selectedlanguages.push(data.languages[i]);
    }
    setLanguages(selectedlanguages?.map((value) => value));
    setLanguageSelectedOptions(selectedlanguages);
    if (data.media.picture) {
      const profUrl = ProfilePictureRootURL + data.media.picture;
      setProfileUrl(profUrl);
      setSelectedFile([]);
      setDocumentID(data.media.picture);
    }
  };

  const getMemberInformation = async () => {
    await axios
      .get(MemberAllInfosGetApi, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        if (res.data) setMembersAllInfo(res.data);
      });
  };

  const getDegreeAndCoursesList = async () => {
    var res = await axiosRequest({
      url: "/member/educational-info/getAllDegreeCourseAndInstitutes",
      method: "get",
    });
    if (res.data) {
      setCourseList(res.data.courses);
      setDegreeList(res.data.degrees);
    }
  };

  useEffect(() => {
    var user = JSON.parse(localStorage.getItem("tuteAirUser"));
    if (user !== null && (courseList?.length === 0 || degreeList?.length === 0))
      getDegreeAndCoursesList();
  }, []);

  return (
    <Context.Provider
      value={{
        ...props,
        address,
        setAddress,
        about,
        setAbout,
        media,
        setmedia,
        dateOfBirth,
        setdateOfBirth,
        fullname,
        setFullName,
        gender,
        setGender,
        email,
        setEmail,
        phone,
        setPhone,
        languages,
        setLanguages,
        memberType,
        setMemberType,
        educations,
        setEducations,
        workExperiences,
        setWorkExperiences,
        schedules,
        setSchedules,
        interestAndPricing,
        setInterestAndPricing,
        interestToLearn,
        setInterestToLearn,
        getMemberInformation,
        selectedLanguageOptions,
        setLanguageSelectedOptions,
        profileUrl,
        selectedFile,
        documentID,
        setProfileUrl,
        setSelectedFile,
        setDocumentID,
        courseList,
        degreeList,
        setCourseList,
        setDegreeList,
        getDegreeAndCoursesList,
        tutorViewCount,
        studentViewCount,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default MemberInformationProvider;
