import React, { Fragment, useEffect, useState } from "react";
import { LogIn, Mail, Settings, User } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input, Label, Media } from "reactstrap";
import { H6, Image, LI, UL } from "../../AbstractElements";
import man from "../../assets/images/avtar/man.png";
// import man from "../../assets/images/avtar/man.png";
import { Inbox, LogOut } from "../../Constant";
import { FAQSvg } from "../../Data/svgIcons";
import { userLoggedOut } from "../../redux/features/auth/authSlice";
import {
  clearMemberMenus,
  memberMenus,
} from "../../redux/features/menus/menuSlice";
import Language from "./Langauge";
import MaxMiniSize from "./MaxMiniSize";
import MoonLight from "./MoonLight";
import Notification from "./Notification";

import { useContext } from "react";
import { axiosRequest } from "../../redux/utils/axios-utils";
import "../../style/custom-css/PersonalInfo.css";
import BecomeTutorContext from "../../_helper/BecomeTutor";
import MemberInformationContext from "../../_helper/MemberInformation";
import PersonalAddressContext from "../../_helper/PersonalAddress";
import ChatIcon from "./ChatIcon";

const HeaderContain = () => {
  const [profile, setProfile] = useState(man);
  const [name, setName] = useState("");
  const [memberTypeText, setMemberType] = useState("Tutor");
  const [isTutor, setIsTutor] = useState(false);
  const [isAccountHide, setIsAccountHide] = useState("");
  const { accountType } = useSelector((state) => state.auth) || {};

  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
  let picture2 = localStorage.getItem("picture2");

  const { clearBecomeTutorContext } = useContext(BecomeTutorContext);
  const {
    setAddress1,
    setAddress2,
    setArea,
    setCity,
    setState,
    setPostalCode,
    setCountry,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);

  const {
    setAbout,
    setmedia,
    setdateOfBirth,
    setFullName,
    setGender,
    setEmail,
    setPhone,
    setLanguages,
    setMemberType: setMemberType2,
    setAddress,
    setEducations,
    setWorkExperiences,
    setSchedules,
    setInterestAndPricing,
    setInterestToLearn,
    setLanguageSelectedOptions,
    setProfileUrl,
    setDocumentID,
  } = useContext(MemberInformationContext);

  const clearProvider = () => {
    setAddress1("");
    setAddress2("");
    setArea("");
    setCity("");
    setState("");
    setPostalCode("");
    setCountry("");
    setLatitude("");
    setLongitude("");
    clearMemberInfoContext();
  };

  const clearMemberInfoContext = () => {
    setAbout({});
    setmedia({});
    setdateOfBirth(new Date("01/01/2000"));
    setFullName("");
    setGender("");
    setEmail("");
    setPhone("");
    setLanguages([]);
    setMemberType2("");
    setAddress({});
    setEducations([]);
    setWorkExperiences([]);
    setSchedules([]);
    setInterestAndPricing([]);
    setInterestToLearn([]);
    setLanguageSelectedOptions([]);
    setProfileUrl("");
    setDocumentID("");
  };

  const navigate = useNavigate();
  const Logout = () => {
    dispatch(userLoggedOut());
    dispatch(clearMemberMenus());
    clearProvider();
    localStorage.clear();
    clearBecomeTutorContext();
    navigate(`${process.env.PUBLIC_URL}/login-account`);
  };

  const authenticated = auth0_profile?.authenticated;
  const dispatch = useDispatch();
  var swMember = localStorage.getItem("swMember");

  if (swMember === "undefined") Logout();

  useEffect(() => {
    setMemberType(swMember);
    if (swMember === "turor") setIsTutor(false);
    else if (swMember === "student") setIsTutor(true);
  }, [swMember]);

  useEffect(() => {
    setProfile(picture2);

    setName(auth0_profile?.name);
  }, [picture2, auth0_profile]);

  const switchMemberToggle = (isTutor) => {
    setIsTutor(!isTutor);
    if (isTutor) {
      setMemberType("Tutor");
      // dispatch(userLoggedIn({ accountType: "tutor"}));
      localStorage.setItem("swMember", "tutor");
      dispatch(memberMenus({ memberType: "tutor" }));
    } else {
      localStorage.setItem("swMember", "student");
      // dispatch(userLoggedIn({ accountType: "student"}));
      setMemberType("Student");
      dispatch(memberMenus({ memberType: "student" }));
    }
  };
  useEffect(() => {
    const getMemberInfo = async () => {
      var res = await axiosRequest({
        url: "/newMember/membersInfo",

        method: "get",
      });

      if (res) {
        setIsAccountHide(res?.data?.isAccountHide);
      }
    };

    getMemberInfo();
  }, [isAccountHide]);

  return (
    <Fragment>
      <div className="nav-right col-10 col-sm-6 pull-right right-header p-0 dash-76">
        <UL
          attrUL={{ className: `simple-list flex-row nav-menus` }}
          className="d-flex justify-content-left align-items-center"
        >
          <LI attrLI={{ className: "onhover-dropdown d-none" }}>
            <H6 attrH6={{ className: "txt-dark mb-0 mt-1" }}>EN</H6>
            <Language />
          </LI>
          {isAccountHide && (
            <span className="hideAccount">
              <Link to={`${process.env.PUBLIC_URL}/settings`}>
                <li>
                  <i class="fa fa-eye-slash"></i>
                </li>
              </Link>
            </span>
          )}

          {!isAccountHide && (
            <span className="visibleAccount">
              <Link to={`${process.env.PUBLIC_URL}/settings`}>
                <li>
                  <i class="fa fa-eye"></i>
                </li>
              </Link>
            </span>
          )}
          {(accountType === "tutor" || accountType === "both") && (
            <li className="list-group-item">
              <H6 attrH6={{ className: "txt-dark mb-0 mt-1" }}>
                <span>TUTOR</span>
              </H6>
            </li>
          )}

          {accountType === "both" && (
            <li className="mt-1">
              <Media body className={"text-end switch-outline media-body"}>
                <Label className="switch p-0 m-0">
                  <Input
                    type="checkbox"
                    checked={isTutor}
                    onClick={(e) => switchMemberToggle(isTutor)}
                    onChange={(e) => switchMemberToggle(isTutor)}
                  />
                  <span
                    className="switch-state bg-primary"
                    style={{ background: "#6b6b91" }}
                  ></span>
                </Label>
              </Media>
            </li>
          )}

          {(accountType === "student" || accountType === "both") && (
            <li className="list-group-item">
              <H6 attrH6={{ className: "txt-dark mb-0 mt-2" }}>
                <span>STUDENT</span>
              </H6>
            </li>
          )}

          <MoonLight />
          {/* <ItemCart /> */}
          {/* <Bookmark /> */}
          <ChatIcon />
          <Notification />
          <MaxMiniSize />

          <LI
            attrLI={{
              className: "profile-nav onhover-dropdown pe-0 pt-0 me-0",
            }}
          >
            <Media className="profile-media">
              <Image
                attrImage={{
                  className: "rounded-circle",
                  src: `${profile}`,
                  alt: "",
                }}
              />
              <Media body>
                <span>{authenticated ? auth0_profile?.name : name}</span>
              </Media>
            </Media>
            <UL
              attrUL={{
                className: `simple-list profile-dropdown onhover-show-div`,
              }}
            >
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/profile/me`}>
                  <i>
                    <User />
                  </i>
                  <span>{"My Profile"} </span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/settings#javascript`}>
                  <i>
                    <Settings />
                  </i>
                  <span>{"Account Settings"}</span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/inbox`}>
                  <i>
                    <Mail />
                  </i>
                  <span>{Inbox}</span>
                </Link>
              </LI>
              <LI>
                <Link to={`${process.env.PUBLIC_URL}/FAQ`}>
                  <FAQSvg />
                  <span>{"FAQs"}</span>
                </Link>
              </LI>
              <LI attrLI={{ onClick: Logout }}>
                <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                  <LogIn />
                  <span>{LogOut}</span>
                </Link>
              </LI>
            </UL>
          </LI>
        </UL>
      </div>
    </Fragment>
  );
};
export default HeaderContain;
