import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { userLoggedOut } from "../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import "./become-tutor.css";
import NavbarContain from "./ChildComponent/Navbar";
import { getResendEmailResponse } from "./fnBecomeTutor";
import BecomeTutorCreateAccount from "./S1.CreateAccount";
import BecomeTutorSubjects from "./S2.SubjectsPricing";
import BecomeTutorSchedule from "./S3.Schedule";
import BecomeTutorPersonal from "./S4.Personal";
import BecomeTutorAddress from "./S5.Address";
import BecomeTutorEducation from "./S6.Education";
import BecomeTutorExperience from "./S7.Experience";
import PhotoUpload from "./S8.Photo";

const BecomeTutorContain = () => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  const [seconds, setSeconds] = useState(59);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const [minutes, setMinutes] = useState(0);

  const {
    step,
    setStep,
    getLoggedTutorInfo,
    profilePhoto,
    setProfilePhoto,
    clearBecomeTutorContext,
    isMemberExists,
    handleEmailVerified,
    isEmailVerified,
    setIsEmailVerified,
  } = useContext(BecomeTutorContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";
    if (_tuteair?.accessToken && _tuteair?._id) {
      if (_tuteair.accountType === "student") {
        navigate(`${process.env.PUBLIC_URL}/become-student`);
      } else {
        getLoggedTutorInfo(_tuteair?._id);
        setStep(_tuteair.currentStep);
      }
    } else {
      dispatch(userLoggedOut());
      dispatch(clearMemberMenus());
      clearBecomeTutorContext();
      localStorage.clear();
      navigate(`${process.env.PUBLIC_URL}/become-tutor`);
    }
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
        if (_tuteair?.currentStep > 0 && !_tuteair?.emailVerified) {
          setResendEnabled(true);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleResendEmail = async () => {
    var response = await getResendEmailResponse(_tuteair?._id);
    setResendEnabled(false);
    if (response?.message === "verified") {
      setIsEmailVerified(true);
      SweetAlert.fire({
        title: `Your email are already verified.`,
        text: "Thank you for being with TuteAir. Complete your profile to find your desired tuitions.",
      });
    } else if (response?.message === "success") {
      SweetAlert.fire({
        title: `Email sent to <u>${_tuteair?.email}<u/>`,
        text: "N.B: If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder",
      });
    }
  };

  const handleNotReceivedEmail = async (e) => {
    e.preventDefault();
    var htmlText = "";
    htmlText +=
      "<p>If you do not see the email in your inbox in a few minutes, please check your junk mail folder and add  <q>support@tuteair.com</q> to your White List or Safe Sender List.</p>";
    htmlText +=
      "<p>We make every effort to ensure that these emails are delivered.</p>";
    htmlText += `<p>If you still don’t receive an email, then please click on <b>Resend Email</b></p>`;

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Didn't receive an Email?",
      html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Resend Email",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        handleResendEmail();
      }
    });
  };

  return (
    <>
      <div className="" style={{ background: "#f0f8ffd9" }}>
        <NavbarContain />
        <Container className={`py-5 ${step !== 0 ? "container_padding" : ""}`}>
          {step > 0 && (
            <Row className="mt-4" style={{ placeContent: "center" }}>
              <Col md={7} className="email_confirm_title text-center">
                {!isEmailVerified && (
                  <>
                    <Mail width={17} height={17} />
                    <h5 className="px-2 ">
                      An Email has been sent to your email. Please confirm your
                      email.
                    </h5>

                    <a
                      href="#resentEmail"
                      onClick={(e) => handleNotReceivedEmail(e)}
                      className="text-underline px-3"
                    >
                      Didn't receive an Email?
                    </a>
                    {/* <button
                      className="btn btn-sm py-1 text-dark btn-light-gradien mx-3"
                      // disabled={!resendEnabled}
                      disabled={false}
                      onClick={(e) => handleResendEmail(e)}
                    >
                      Resend
                      {minutes === 0 && seconds === 0 ? null : (
                        <span>
                          {" "}
                          in {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      )}
                    </button> */}
                  </>
                )}
              </Col>
            </Row>
          )}

          {step === 0 ? (
            <BecomeTutorCreateAccount />
          ) : step === 1 ? (
            <BecomeTutorSubjects />
          ) : step === 2 ? (
            <BecomeTutorSchedule />
          ) : step === 3 ? (
            <BecomeTutorPersonal step={3} />
          ) : step === 4 ? (
            <BecomeTutorAddress step={4} />
          ) : step === 5 ? (
            <BecomeTutorEducation step={5} />
          ) : step === 6 ? (
            <BecomeTutorExperience />
          ) : step === 7 ? (
            <PhotoUpload step={7} />
          ) : (
            <BecomeTutorSubjects />
          )}
        </Container>
      </div>
    </>
  );
};

export default BecomeTutorContain;
