import React, { Fragment } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import OnDemandTuitionRequestContainer from "../../Component/OnDemandTuitionRequest";

const OnDemandTuitionRequest = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Bookmarks" />
      <OnDemandTuitionRequestContainer />
    </Fragment>
  );
};
export default OnDemandTuitionRequest;
