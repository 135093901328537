import React, { Fragment, useContext, useState } from "react";
import { MapPin } from "react-feather";
import { Card, Col, Row } from "reactstrap";
import { H4, Image, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";

const TutorCardHome = ({ tutors }) => {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");

  const isValidateForm = () => {
    if (subject?.length === 0) {
      toastNotifiy("Please Fillup the Subject Field!", "warn");
      return false;
    } else {
      return true;
    }
  };

  const openRequestTuitionModal = () => {
    if (isValidateForm()) {
      setTitle("Add Request for Tuition");
      setListViewData({
        gender: gender,
        profilePicture: profilePicExist,
        language: languages,
        location: location,
        tutionType: tutionType,
        subject: subject,
        subjectCategory: subject_category_filter,
        subjectSubCategory: subject_subcategory_filter,
      });
      toggle();
      setModal(true);
    }
  };
  return (
    <Fragment>
      <Col xl="12">
        <div className="product-wrapper">
          <div className="product-grid">
            <div className="product-wrapper-grid">
              <Row className="gridRow">
                {tutors ? (
                  tutors.map((item, k) => {
                    return (
                      <div
                        id="gridId"
                        className={`${
                          true
                            ? "col-xl-4 col-lg-6 col-sm-6 xl-4"
                            : "col-xl-" + 2
                        }`}
                        key={k}
                      >
                        <Card>
                          <div className="product-box tutor_box">
                            <div className="product-img text-center">
                              {false && (
                                <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
                                  <i className="icon-heart">{item.status}</i>
                                </span>
                              )}

                              <Image
                                attrImage={{
                                  className: "img-fluid",
                                  src: `${
                                    item.media.picture
                                      ? ` ${ProfilePictureRootURL}/${item.media.picture}`
                                      : man
                                  }`,
                                  alt: "",
                                }}
                              />

                              <div className="product-hover">
                                <UL attrUL={{ className: "simple-list" }}>
                                  {/* <LI>
                                    <Btn
                                      attrBtn={{
                                        color: "transprant",
                                        onClick: () => alert(1),
                                        type: "button",
                                        datatoggle: "modal",
                                        datatarget: "#exampleModalCenter",
                                      }}
                                    >
                                      <i className="icon-eye"></i>
                                    </Btn>{" "}
                                  </LI> */}
                                </UL>
                              </div>
                            </div>
                            <div className="product-details">
                              {/* <Link
                                to={`${process.env.PUBLIC_URL}/`}
                              > */}
                              <H4>
                                {" "}
                                {item.name.firstName + " " + item.name.lastName}
                              </H4>
                              {/* </Link> */}

                              <p className="mb-0">
                                {item.interestAndPricing &&
                                item.interestAndPricing.length > 0 ? (
                                  item.interestAndPricing.map((item, i) =>
                                    [
                                      ...new Set(
                                        item.subjects?.map((sub) => sub.tag)
                                      ),
                                    ]?.map((subject, k) => (
                                      <span
                                        key={k}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {subject}
                                      </span>
                                    ))
                                  )
                                ) : (
                                  <span
                                    key={k}
                                    className="badge badge-light-info py-1"
                                  >
                                    No Subjects Added
                                  </span>
                                )}
                              </p>
                              <p
                                className="pt-2"
                                style={{ textAlign: "justify" }}
                              >
                                {item.about?.tutorBio?.length > 165 ? (
                                  <span>
                                    {item.about?.tutorBio
                                      .substring(0, 164)
                                      .trim()}
                                    ...
                                  </span>
                                ) : (
                                  <span>{item.about?.tutorBio}</span>
                                )}
                              </p>
                              <div className="product-price tutor_price">
                                {item.interestAndPricing[0]?.amount && (
                                  <>
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title={
                                        item.interestAndPricing[0]?.currency
                                          .code
                                      }
                                    >
                                      {
                                        item.interestAndPricing[0]?.amount
                                          .initialPrice
                                      }{" "}
                                      {
                                        item.interestAndPricing[0]?.currency
                                          .name
                                      }{" "}
                                      / Hourly
                                    </span>
                                  </>
                                )}

                                <span
                                  style={{
                                    float: "right",
                                    margin: "-1px",
                                  }}
                                >
                                  <span>
                                    <MapPin height={"12px"} />
                                  </span>
                                  <span style={{ fontSize: "13px" }}>
                                    {item.address?.city},{" "}
                                    {item.address?.country}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    );
                  })
                ) : (
                  <Image
                    attrImage={{
                      className: "img-fluid m-auto",
                      src: "",
                      alt: "",
                    }}
                  />
                )}
                {false}
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};
export default TutorCardHome;
