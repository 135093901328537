
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useContext } from 'react'
import PersonalAddressContext from '../../_helper/PersonalAddress';

const FindTutorsGeocoder = () => {

    const {
        setLatitude,
        setLongitude
    } = useContext(PersonalAddressContext);

    const ctrl = new MapBoxGeocoder({
        accessToken: "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ",
        marker: false,
        collapsed: false,
        placeholder: "Search Location"
    });

    useControl(() => ctrl);
    ctrl.on('result', (e) => {
        //const coords = e.result.geometry.coordinates;
        //setLatitude(coords[1])
        //setLongitude(coords[0])
    });
    return null;
};

export default FindTutorsGeocoder;