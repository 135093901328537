import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userLoggedOut } from "../../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../../redux/features/menus/menuSlice";
import BecomeStudentContext from "../../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../../_helper/BecomeTutor";
import tuteair from "../../assets/img/tuteair-v2.png";

const NavbarContain = () => {
  const { clearBecomeTutorContext } = useContext(BecomeTutorContext);
  const { clearBecomeStudentContext } = useContext(BecomeStudentContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  const Logout = () => {
    dispatch(userLoggedOut());
    dispatch(clearMemberMenus());
    clearBecomeTutorContext();
    clearBecomeStudentContext();
    localStorage.clear();
    navigate(`${process.env.PUBLIC_URL}/login-account`);
  };

  useEffect(() => {
    if (_tuteair?._id && _tuteair?.accessToken) {
      setVisible(true);
      setName(_tuteair.name);
    } else {
      setVisible(false);
    }
  }, []);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className=" mb-5"
        variant="light"
        fixed="top"
        // style={{ background: "#faebd7" }}
      >
        <Container>
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <Navbar.Brand className="tuteairBrand px-3">
              <img src={tuteair} height="35" className="" alt="TuteAir" />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="tuteair_navbar"
          >
            <Nav className="me-auto"></Nav>
            <Nav className="px-0 nav-link align-items-center">
              {visible && (
                <>
                  <Nav>
                    {/* <Media className="profile-media">
                      <Image
                        attrImage={{
                          className: "rounded-circle",
                          src: profilePhoto,
                          alt: "",
                        }}
                      />
                    </Media> */}
                    <span className="be_tutor px-0">{name}</span>
                  </Nav>
                  <Nav className="px-0 nav-link" onClick={Logout}>
                    <Link to={""}>
                      <span className="be_tutor">Logout</span>
                    </Link>
                  </Nav>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarContain;
