import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Btn, H2 } from "../../../AbstractElements";
import "./prices.css";

const Prices = () => {
  return (
    <Fragment>
      <Container className="" id="pricing" style={{ paddingTop: "80px" }}>
        <CardBody className="pricing-content pricing-col">
          <Row>
            <h1 className="text-center pb-4">Pricing</h1>
            <Col
              sm="4"
              xl="4"
              className="box-col-4 offset-md-4"
              key={"item.id"}
            >
              <Card className="pricing-block text-center">
                <div className="pricing-header">
                  <H2>{""}</H2>
                  <div
                    className="price-box"
                    style={{ height: "110px", width: "110px" }}
                  >
                    <div style={{ color: "white" }}>
                      <h3>{"FREE"}</h3>
                      <small>Until</small>
                      <p style={{}}>
                        31<sup>TH</sup> DECEMBER 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pricing-list">
                  <u>
                    <i>'Then the amount will be only USD $5/Monthly'</i>
                  </u>
                  <p className="pt-2 mt-2">
                    We are in promotion of free registration now. Take advantage
                    of becoming a free member and find your suitable tutors or
                    students. No credit card or payment details are required
                    with free membership promotion. <br /> <br /> After end of
                    the promotional period, the membership will be USD$5 per
                    month. No obligation or contract. You can cancel at any
                    time. With USD$5 per month, you can find unlimited number of
                    students/tutors.
                  </p>
                  <div className="d-none">
                    <Btn attrBtn={{ color: "primary", size: "lg" }}>
                      {"Register"}
                    </Btn>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Container>
    </Fragment>
  );
};

export default Prices;
