//worked till now
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { TutorInterestAndPricingGetApi } from "../../../../api/index";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";

import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  CardText,
} from "reactstrap";
import { useEditConversationMutation } from "../../../../redux/features/chat/conversations/conversationsApi";

import { Btn } from "../../../../AbstractElements";
import { apiSlice } from "../../../../redux/features/api/apiSlice";
import { useEditTuitionMutation } from "../../../../redux/features/chat/tuitions/tuitionsApi";
import { tutorTuitions } from "../../../../redux/features/tuition/tuitionSlice";
import { confirmedTutorTuitions } from "../../../../redux/features/tuition/confirmedTuitionSlice";
import { toastMessage } from "../../../../redux/utils/tuteairCommon";
import { AddNotification } from "../../../../Services/Notification.Service";

const TuitionStatusModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [myTuitions, setMyTuitions] = useState([]);
  const [myTuitionRequest, setMyTuitionRequest] = useState([]);
  const [selectedTuition, setSelectedTuition] = useState("");
  const [confirmTuitionFee, setConfirmTuitionFee] = useState("");
  const [status, setStatus] = useState("OPEN");
  const [startDate, setStartDate] = useState(new Date());
  const [currencyList, setCurrencyList] = useState(null);
  const [currency, setCurrency] = useState("");
  const [IsConfirmedByTutor, setIsConfirmedByTutor] = useState(false);
  const [IsConfirmedByStudent, setIsConfirmedByStudent] = useState(false);
  const [IsDisableConfirmedByTutor, setIsDisableConfirmedByTutor] = useState(true);
  const [IsDisableConfirmedByStudent, setIsDisableConfirmedByStudent] = useState(true);
  const [IsShowTuitionRequest, setIsShowTuitionRequest] = useState(false);
  const [IsStudentConfirmation, setIsStudentConfirmation] = useState(props.IsStudentConfirmation);
  const [budget, setBudget] = useState("");
  const [editConversation, { isSuccess }] = useEditConversationMutation();
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [editTuition, { isSuccess: isEditTuition }] = useEditTuitionMutation();
  const { tutorTuitionList } =  useSelector((state) => state.tuition) || {};
  
  const { confirmedTutorTuitionList } = useSelector((state) => state.confirmedTuition) || {};

  const handleStartDate = (date) => {
    setStartDate(new Date(date));
  };

  const validateUpdatingTuition = () => {
    if (selectedTuition === "" || selectedTuition.value === "") {
      toastMessage("Select Tuition is required", "warn");
      return false;
    } else {
      return true;
    }
  };

   // get all inital values
   const fetchInitialValue = async () => {
    const res = await axios.get(TutorInterestAndPricingGetApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    var currList = [];
    if (res.data.currencies) {
      res.data.currencies.map((item, index) => {
        currList.push({
          value: item.name,
          label: item.code + " - " + item.name,
          code: item.code,
          name: item.name,
          countryId: item.countryId,
        });
      });
    }
    currList = currList.slice().sort((a, b) => a.label.localeCompare(b.label));
    setCurrencyList(currList);
    
  };
  
  useEffect(() => {
    if(props.IsStudentConfirmation)
    {
      const myTuition = confirmedTutorTuitionList?.filter(
        (t) => t.tuitionId === selectedTuition.value
      )[0];

      if(myTuition)
      {
        if (Object.keys(myTuition?.tuitionRequest)?.length === 0) 
        {
          setIsShowTuitionRequest(false);
        }
        else{
          setIsShowTuitionRequest(true);
        }
      }
      else
      {
        setIsShowTuitionRequest(false);
      }
      setMyTuitionRequest(myTuition);
      setConfirmTuitionFee(myTuition?.confirmedTuitionFee)
      
      if(myTuition?.startDate !== undefined)
      {
        setStartDate(new Date(myTuition?.startDate));
      }
      setBudget(myTuition?.studentBudget)
      fetchInitialValue();
      const myCurrency = currencyList?.filter(
        (t) => t.name === myTuition?.currency
      )[0];

      setCurrency(myCurrency);
      setIsConfirmedByTutor(myTuition?.IsConfirmedByTutor);
      setIsConfirmedByStudent(myTuition?.IsConfirmedByStudent);
      if(!myTuition?.IsConfirmedByTutor)
      {
        setIsDisableConfirmedByTutor(false);
        setIsDisableConfirmedByStudent(true);
      }
      if(myTuition?.IsConfirmedByTutor && !myTuition?.IsConfirmedByStudent)
      {
        setIsDisableConfirmedByTutor(true);
        setIsDisableConfirmedByStudent(false);
      }
    }
    else
    {
      const myTuition = tutorTuitionList?.filter(
        (t) => t.tuitionId === selectedTuition.value
      )[0];
      if(myTuition)
      {
        if (Object.keys(myTuition?.tuitionRequest)?.length === 0) 
        {
          setIsShowTuitionRequest(false);
        }
        else{
          setIsShowTuitionRequest(true);
        }
      }
      else
      {
        setIsShowTuitionRequest(false);
      }
      setMyTuitionRequest(myTuition);
      setConfirmTuitionFee(myTuition?.confirmedTuitionFee)
      setBudget(myTuition?.studentBudget)
      if(myTuition?.startDate !== undefined)
      {
        setStartDate(new Date(myTuition?.startDate));
      }
      fetchInitialValue();
      
      const myCurrency = currencyList?.filter(
        (t) => t.name === myTuition?.currency
      )[0];

      setCurrency(myCurrency);
      setIsConfirmedByTutor(myTuition?.IsConfirmedByTutor);
      setIsConfirmedByStudent(myTuition?.IsConfirmedByStudent);

      if(!myTuition?.IsConfirmedByTutor)
      {
        setIsDisableConfirmedByTutor(false);
        setIsDisableConfirmedByStudent(true);
      }
      if(myTuition?.IsConfirmedByTutor && !myTuition?.IsConfirmedByStudent)
      {
        setIsDisableConfirmedByTutor(true);
        setIsDisableConfirmedByStudent(false);
      }
    }
  
  }, [selectedTuition]);

  useEffect(() => {
    if(status === "CONFIRMEDByTutor")
    {
       setIsConfirmedByTutor(true);
       setIsConfirmedByStudent(false);
    }
    if(status === "CONFIRMED")
    {
       setIsConfirmedByStudent(true);
       setIsConfirmedByTutor(true);
    }

  }, [status]);



  useEffect(() => {
    if (isEditTuition) {
      props.toggler();
      
      const updatedData = props.IsStudentConfirmation ? tutorTuitionList.map((x) =>
        x.tuitionId === selectedTuition.value ? { ...x, status: status } : x
      ) :  confirmedTutorTuitionList.map((x) =>
      x.tuitionId === selectedTuition.value ? { ...x, status: status } : x
      );

      if(props.IsStudentConfirmation)
      {
        dispatch(
          tutorTuitions({
            tutorTuitionList: updatedData,
          })
        );
      }
      else
      {
        dispatch(
          confirmedTutorTuitions({
            confirmedTutorTuitionList: updatedData,
          })
        );
      }
      dispatch(
        apiSlice.endpoints.getMyTuitions.initiate(loggedInUser, {
          subscribe: false,
          forceRefetch: true,
        })
      );
      const myTuition = props.tuitions?.filter(
        (t) => t.tuitionId === selectedTuition.value
      )[0];
      let charge = "";
      if (status === "OPEN") {
        charge =
          "My expected charge is [" +
          confirmTuitionFee +
          " " +
          myTuition?.currency +
          "] per hour.";
      }else if (status === "CONFIRMED") {
        charge =
          "Your charged is [" +
          confirmTuitionFee +
          " " +
          myTuition?.currency +
          "] per hour.";
      }
      else if (status === "CONFIRMEDByTutor") {
        charge =
          "My charged is [" +
          confirmTuitionFee +
          " " +
          myTuition?.currency +
          "] per hour.Please Confirm the tuition";
      }
       else {
        charge =
          "My charged is [" +
          confirmTuitionFee +
          " " +
          myTuition?.currency +
          "] per hour.";
      }
      const tuitionStatus = IsConfirmedByTutor && !IsConfirmedByStudent? "Confirmed By Tutor" : status;
      // send message
      editConversation({
        sender: loggedInUser,
        id: props.conversationId,
        data: {
          message: `The Requested tuition '${selectedTuition.label}' has been ${tuitionStatus}. ${charge}`,
        },
      });
    }
  }, [isEditTuition]);

  const handleUpdateTuitionStatus = (e) => {
    e.preventDefault(); //
    if (validateUpdatingTuition()) {
      if (
        window.confirm(`Confirm to Update '${selectedTuition.label} ' Tuition?`)
      ) {
        editTuition({
          tuitionId: selectedTuition.value,
          data: {
            confirmedTuitionFee: confirmTuitionFee,
            currency: currency.name,
            startDate: startDate,
            status: status,
            endDate: status === "CLOSED" || status === "CANCELED" ? new Date() : "",
            IsConfirmedByTutor: IsConfirmedByTutor,
            IsConfirmedByStudent: IsConfirmedByStudent,
          },
        });
        const myTuition = props.tuitions?.filter(
          (t) => t.tuitionId === selectedTuition.value
        )[0];
        const notifyObj = {
          receiver: myTuition.studentId,
          title:
            "Your tuition status updated from " +
            myTuition.status +
            " to " +
            status,
          description: "tuition status changed",
          context: process.env.PUBLIC_URL + "/student-tuitions",
          isPublic: false,
        };

        AddNotification(notifyObj);
      } else {
        // setSelectedTuition(selectedTuition.value);
      }
    }
  };

  const intitalFetch = () => {
    var arrList = [];
    var myTuitions = [];
    if(props.IsStudentConfirmation)
    {
       myTuitions = confirmedTutorTuitionList.filter(
        (c, i) => c.tutorId === props?.receiverId && c.IsConfirmedByTutor == true
      );
    }
    else
    {
       myTuitions = tutorTuitionList.filter(
        (c, i) => c.studentId === props?.receiverId
      );
    }
    myTuitions?.map((item, index) => {
      var subjects = "";
      if(item.tuitionRequest?.subjects)
      {
        item.tuitionRequest?.subjects?.map((item2, index2) => {
          var comma =
            item.tuitionRequest?.subjects?.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.label?.toString().trim() + comma;
        });
      }
      else
      {
        item.interestedSubjects?.subjects?.map((item2, index2) => {
              var comma =
                item.interestedSubjects?.subjects?.length - 1 === index2 ? "" : " - ";
              subjects = subjects + item2.name?.toString().trim() + comma;
            });
      }
      const tuitionStatus = item.IsConfirmedByTutor && !item.IsConfirmedByStudent ? "Confirmed By Tutor" : item.status;
      arrList.push({
        value: item.tuitionId,
        label: subjects + " [" + tuitionStatus + "]",
        isDisabled: false,
      });
    });

    setMyTuitions(arrList);
  };

  useEffect(() => {
    intitalFetch();
  }, []);

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader style={{ placeContent: "center" }} toggle={props.toggler}>
          <div>{props.title}</div>
        </ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm py-1">
                <Row>
                  <Col sm="12" xl="12">
                    <div>
                      <Row className="">
                        <Col md={4}>
                          <Label className="col-form-label" htmlFor="">
                            Tuitions
                          </Label>
                        </Col>
                        <Col md={8}>
                          <Select
                            options={myTuitions}
                            placeholder="Select Tuitions"
                            className="js-example-basic-single col-sm-12 darkMode"
                            onChange={(item) => setSelectedTuition(item)}
                            value={selectedTuition}
                          />
                        </Col>
                      </Row>
                      {IsShowTuitionRequest ?
                      <Row className="">
                        <Col md={4}>
                          <Label className="col-form-label" htmlFor="">
                            Tuitions Request 
                          </Label>
                        </Col>
                        <Col md={8}>
                        <CardText className="mb-0">
                          {
                                  myTuitionRequest?.tuitionRequest?.languages?.length > 0 ? ( <span  style={{
                                    margin: "0px 3px 2px 3px",
                                    fontSize: "12.5px",
                                  }}>Preferred Language: {myTuitionRequest?.tuitionRequest?.languages?.map((item, index) => (
                                    <span
                                      className="badge badge-light-primary py-1"
                                      style={{
                                        margin: "0px 3px 2px 0px",
                                        fontSize: "12.5px",
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  ))}</span> ) : ("") 
                                }
                          </CardText>
                
                          <CardText className="mb-0">
                          { myTuitionRequest?.tuitionRequest?.tuitionTypes?.length > 0 ? ( <span  style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}>Tuition Place: {myTuitionRequest?.tuitionRequest?.tuitionTypes?.map((item, index) => (
                                    <span
                                      className="badge badge-light-primary py-1"
                                      style={{
                                        margin: "0px 3px 2px 0px",
                                        fontSize: "12.5px",
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </span>
                                  ))}
                                  </span> ) : ("") 
                              }
                              </CardText>
                         
                       
                          <CardText className="mb-0">
                              {
                                  myTuitionRequest?.tuitionRequest?.gender && myTuitionRequest?.tuitionRequest?.gender !== '0' ? ( <span   style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}>Gender: <span style={{
                                    margin: "0px 3px 2px 0px",
                                    fontSize: "12.5px",
                                  }} className="badge badge-light-primary py-1">{myTuitionRequest?.tuitionRequest?.gender}</span>
                                  </span> ) : ("") 
                              }
                              </CardText>
                          
                              <CardText>
                              {myTuitionRequest?.tuitionRequest?.location ? ( <span   style={{
                                    margin: "0px 3px 0px 3px",
                                    fontSize: "12.5px",
                                  }}>Preferred Location: <span style={{
                                    margin: "0px 3px 2px 0px",
                                    fontSize: "12.5px",
                                  }}  className="badge badge-light-primary py-1">
                                    {myTuitionRequest?.tuitionRequest.location?.country} {myTuitionRequest?.tuitionRequest.location?.city} {myTuitionRequest?.tuitionRequest.location?.postalCode}</span>
                                  </span> ) : ("") 
                              }
                              </CardText>
                          <span></span>
                        </Col>
                      </Row>
                      : ""
                    }
                    </div>
                    <Row className="py-1">
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Confirmed Tuition Fee
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="number"
                          className="form-control"
                          placeholder="Enter Confirmed Tuition Fee"
                          style={{ fontSize: "13px" }}
                          value={confirmTuitionFee}
                          onChange={(e) => setConfirmTuitionFee(e.target.value)}
                          disabled={IsStudentConfirmation}
                        />
                      </Col>
                    </Row>
                    {IsShowTuitionRequest ?
                      ""
                      : <Row className="py-1">
                      <Col md={4}>
                          <Label className="col-form-label" htmlFor="inputEmail3">
                          Student Budget
                          </Label>
                        </Col>
                        <Col md={8}>
                          <div style={{ display: "flex", placeItems: "center" }}>
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Enter Your Budget"
                              style={{ fontSize: "13px" }}
                              value={budget}
                              onChange={(e) => setBudget(e.target.value)}
                            />
                            <div>
                              <span className="px-2">/Hourly</span>
                            </div>
                          </div>
                         </Col>
                      </Row>
                    }
                    
                    <Row className="py-1">
                    <Col md={4}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                        Select Currency
                        </Label>
                      </Col>
                      <Col md={8}>
                      <Select
                          options={currencyList}
                          placeholder="Select Currency"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setCurrency(item)}
                          value={currency}
                          isDisabled={IsStudentConfirmation}
                        />
                       </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="inputEmail3">
                          Status
                        </Label>
                      </Col>
                      <Col md={8}>
                        <Input
                          type="select"
                          name="select"
                          className="js-example-basic-single col-sm-12 darkMode"
                          style={{ fontSize: "13px" }}
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="OPEN">OPEN</option>
                          <option value="CANCELED">CANCELED</option>
                          <option value="CLOSED">CLOSED</option>
                          {
                               IsDisableConfirmedByTutor? <option value="CONFIRMEDByTutor" hidden>CONFIRMED</option> :
                               <option value="CONFIRMEDByTutor">CONFIRMED</option>
                          }
                          {
                               IsDisableConfirmedByStudent? <option value="CONFIRMED" hidden>CONFIRMED</option> :
                               <option value="CONFIRMED">CONFIRMED</option>
                          }
                        </Input>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col md={4}>
                        <Label className="col-form-label" htmlFor="">
                          Start Date
                        </Label>
                      </Col>
                      <Col md={4}>
                        <ReactDatePicker
                          className="form-control digits"
                          selected={startDate}
                          onChange={handleStartDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy"
                          minDate={new Date()}
                          disabled={IsStudentConfirmation}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "primary",
              disabled: loading,
              onClick: (e) => handleUpdateTuitionStatus(e),
            }}
          >
            {/* {loading ? "LOADING..." : "Save changes"} */}
            {"Update Tuition"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TuitionStatusModal;
