import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Lock, Mail, Type, User } from "react-feather";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormGroup, Input, InputGroup } from "reactstrap";

import { Btn, H4, H6, P } from "../../../AbstractElements";
import { RegisterApi, ResendEmailUrl } from "../../../api";
import man from "../../../assets/images/dashboard/1.png";
import {
  ConfirmPassword,
  CreateAccount,
  EmailAddress,
  Password,
  PrivacyPolicy,
  SignIn,
  YourName,
} from "../../../Constant";

const RegisterFrom = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [emailVerify, setEmailVerify] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState(
    "minimum 8 characters, at least 1 letter, 1 number and 1 special character"
  );
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [bgColor1, setBgColor1] = useState("");
  const [bgColor2, setBgColor2] = useState("");
  const [isTutorChecked, setIsTutorChecked] = useState(true);
  const [isStudentChecked, setIsStudentChecked] = useState(true);

  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});

  const fnIsValidPassword = (string) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(
      string
    );
  //^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/.test(string);

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (password === confirmPassword) {
        setIsCPasswordDirty(false);
        if (agreeCheckbox) {
          setButtonDisabled(false);
        }
      } else if (!fnIsValidPassword(password)) {
        setIsValidPassword(false);
      } else {
        setShowErrorMessage(true);
        if (agreeCheckbox) {
          setButtonDisabled(true);
          setShowErrorMessage(fnIsValidPassword(password));
        }
      }
    }
  }, [password, confirmPassword]);

  const handleConfirmPasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };
  const handlePasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };
  const fnValidatePwd = () => {
    if (password !== confirmPassword) {
      setButtonDisabled(true);
      setShowErrorMessage(true);
      setIsCPasswordDirty(true);
    } else {
      setIsCPasswordDirty(false);
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const checkboxHandler = (isChk) => {
    setAgreeCheckbox(isChk);
    if (isCPasswordDirty === false && isChk === true) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (resendEnabled) {
      const response = axios.post(ResendEmailUrl, emailObject);
      if ((await response).status == 201) {
        setLoading(false);
        setResendEnabled(false);
        setEmailVerify(true);
        setButtonDisabled(true);
        setInterval(() => {
          setEmailVerify(false);
          setButtonDisabled(false);
          setResendEnabled(true);
        }, 60000);
      }
    } else {
      var accountType = fnGetAccountType();
      if (!accountType) {
        toast.error("Please select account type!!");
        return;
      }
      // var disposeURL = "https://disposable.debounce.io/?email=" + email;
      // const response = await axios.get(disposeURL);
      // // if email is disposable
      // if (response.data.disposable === "true") {
      //   setLoading(false);
      //   toast.error("Temporary email not accepted. Please provide valid email!");
      //   return;
      // }

      try {
        let result = await fetch(RegisterApi, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            firstName,
            lastName,
            email,
            password,
            accountType,
          }),
        });

        if (result.status !== 201 || result.status === 400) {
          let res = await result.json();
          setTimeout(() => {
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
          }, 300);
          setLoading(false);
          return;
        }
        let jsonResult = await result.json();
        setEmailVerify(true);
        setLoading(false);
        setButtonDisabled(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setIsStudentChecked(false);
        setIsTutorChecked(false);
        setEmailObject(jsonResult?.emailObject);
        setInterval(() => {
          setResendEnabled(true);
          setButtonDisabled(false);
        }, 60000);
      } catch (error) {
        setTimeout(() => {
          toast.error("Oppss.. Something went wrong!!.");
        }, 200);
      }
    }
  };

  const handleTutorChecked = (e) => {
    const checked = e.target.checked;
    // setButtonDisabled(!checked);
    setIsTutorChecked(checked);
    if (checked) setBgColor1("bg-primary");
    else setBgColor1("");
  };

  const handleStudentChecked = (e) => {
    const checked = e.target.checked;
    //const checkedValue = e.target.value;
    // setButtonDisabled(!checked);
    setIsStudentChecked(checked);
    if (checked) setBgColor2("bg-primary");
    else setBgColor2("");
  };

  const fnGetAccountType = () => {
    if (isTutorChecked && isStudentChecked) {
      return "both";
    } else if (isTutorChecked) {
      return "tutor";
    } else if (isStudentChecked) {
      return "student";
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <ToastContainer />
        <div className="login-header text-center">
          <H4>Create your account</H4>
          <H6>Enter your personal details to create account</H6>
        </div>
        <FormGroup className="form-group">
          <label className="form-label">{YourName}</label>
          <div className="small-group">
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </InputGroup>
          </div>
        </FormGroup>
        <FormGroup>
          <label className="form-label">{EmailAddress}</label>
          <InputGroup>
            <span className="input-group-text">
              <Mail />
            </span>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="user@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-label">{Password}</label>
          <InputGroup>
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              required=""
              placeholder="*********"
              value={password}
              onChange={handlePassword}
              onKeyUp={handlePasswordKeyUp}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
          {showErrorMessage && !isValidPassword ? (
            <div className="text-danger">{pwdErrorMessage}</div>
          ) : (
            ""
          )}
        </FormGroup>
        <FormGroup>
          <label className="form-label">{ConfirmPassword}</label>
          <InputGroup>
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              required=""
              placeholder="*********"
              value={confirmPassword}
              onChange={handleConfirmPassword}
              onKeyUp={handleConfirmPasswordKeyUp}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
          {showErrorMessage && isCPasswordDirty ? (
            <div className="text-danger"> Passwords did not match </div>
          ) : (
            ""
          )}
        </FormGroup>
        <FormGroup className="">
          <label className="form-label">
            {"Account Type"}{" "}
            <small
              className="px-1"
              style={{ fontWeight: "300", color: "#9a6b4c" }}
            >
              You can change the type later!
            </small>
          </label>
          <InputGroup className="form-control p-0 bg-light-info text-dark">
            <span className="input-group-text">
              <Type />
            </span>
            <div
              style={{
                paddingLeft: "35px",
                paddingBottom: "5px",
                FontSize: "13px",
              }}
            >
              <div
                className="m-checkbox-inline custom-radio-ml"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                <div className="checkbox checkbox-dark">
                  <Input
                    id="inline-student"
                    type="checkbox"
                    value="student"
                    checked={isStudentChecked}
                    onChange={handleStudentChecked}
                  />
                  <label
                    htmlFor="inline-student"
                    className="mb-0"
                    style={{ fontWeight: "400" }}
                  >
                    {Option}
                    <span className="digits"> {"Student"}</span>
                  </label>
                </div>
                <div className="checkbox checkbox-dark">
                  <Input
                    id="inline-tutor"
                    type="checkbox"
                    value="tutor"
                    checked={isTutorChecked}
                    onChange={handleTutorChecked}
                  />
                  <label
                    htmlFor="inline-tutor"
                    className="mb-0"
                    style={{ fontWeight: "400" }}
                  >
                    {Option}
                    <span className="digits"> {"Tutor"}</span>
                  </label>
                </div>
              </div>
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="form-group">
          <div
            className="m-t-15 m-checkbox-inline custom-radio-ml"
            style={{ fontSize: "13px" }}
          >
            <div className="checkbox checkbox-dark">
              <Input
                id="inline-1"
                type="checkbox"
                onChange={(event) => {
                  checkboxHandler(event.target.checked);
                }}
              />
              <label
                htmlFor="inline-1"
                style={{ position: "absolute", FontSize: "13px" }}
                className="mb-0"
              >
                <div>
                  {Option}
                  <span className="digits">
                    Agree with{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/privacy`}
                      target="_blank"
                    >
                      {PrivacyPolicy}
                    </Link>
                    <span className="px-1">and</span>
                    <Link
                      to={`${process.env.PUBLIC_URL}/terms-condition`}
                      target="_blank"
                    >
                      Terms and Condition
                    </Link>
                  </span>
                </div>
              </label>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="topSpace">
          <Btn
            attrBtn={{
              className: "btn-block",
              color: "primary",
              type: "submit",
              disabled: isButtonDisabled, //loading ? loading : loading,
              onClick: (e) => handleRegisterUser(e),
            }}
          >
            {loading
              ? "LOADING..."
              : resendEnabled
              ? "Resend Email"
              : emailVerify
              ? "An Email has been sent to your account. Please verify"
              : CreateAccount}
          </Btn>
        </FormGroup>
        <P>
          Already have an account?
          <Link to={`${process.env.PUBLIC_URL}/signin`} className="ms-2">
            {SignIn}
          </Link>
        </P>
      </Form>
    </Fragment>
  );
};

export default RegisterFrom;
