import axios from "axios";
import React, { useState } from "react";
import { MemberAllInfosGetApi } from "../../api";
import { authHeader } from "../../Services/AuthVerify";
import Context from "./index";

const PersonalAddressProvider = (props) => {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const setAddressData = (address) => {
    if (address) {
      setAddress1(address.address1);
      setAddress2(address.address2);
      setArea(address.area);
      setCity(address.city);
      setPostalCode(address.postalCode);
      setState(address.state);
      setCountry(address.country);
      setLatitude(address.latitude);
      setLongitude(address.longitude);
    }
  };

  const getPreviousLocation = async () => {
    await axios
      .get(MemberAllInfosGetApi, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => {
        setAddressData(res.data.address);
      });
  };

  // useEffect(() => {
  //   var isMemberExists = localStorage.getItem("isMemberExists");
  //   console.log(isMemberExists)
  //   if (isMemberExists === "undefined") {
  //     localStorage.clear();
  //   } else {
  //     var isMember = JSON.parse(isMemberExists);
  //     if (isMember) getPreviousLocation();
  //   }
  // }, []);

  return (
    <Context.Provider
      value={{
        ...props,
        address1,
        setAddress1,
        address2,
        setAddress2,
        area,
        setArea,
        city,
        setCity,
        postalCode,
        setPostalCode,
        state,
        setState,
        country,
        setCountry,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        getPreviousLocation,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default PersonalAddressProvider;
