import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import "../chat.css";
import ChatBody from "./inbox/ChatBody";
import MyChatStatus from "./inbox/ChatStatus";
const Inbox = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    setWidth(windowSize.innerWidth);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const { showConversation, showMessage } =
    useSelector((state) => state.conversations) || {};

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col className={`call-chat-sidebar ${showConversation}`}>
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="people-list" id="people-list">
                      {/* <SearchChatList /> */}
                      <MyChatStatus /> {/* Sidebar */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className={`call-chat-body ${showMessage}`}>
            <Card>
              <CardBody className="p-0">
                <ChatBody />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Inbox;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
