import moment from "moment";
import React from "react";
import { Card, CardText, CardTitle } from "reactstrap";

export default function Experience(props) {
  return (
    <div>
      <Card body>
        <CardTitle tag="h4" className="">
          Experience
        </CardTitle>
        {props.data?.map((item, index) => (
          <div key={index}>
            <CardText
              className="text-primary mb-0"
              style={{ fontSize: "13px" }}
            >
              {" "}
              <i className="fa fa-briefcase"></i>
              <span className="ms-1">{item.position}</span>
            </CardText>
            <CardText className="mb-0" style={{ fontSize: "13px" }}>
              {item.organization}
            </CardText>
            <CardText style={{ fontSize: "13px" }}>
              {moment(item.startDate).format("MMMM D, yyyy")} -{" "}
              {item.running ? (
                <span>Running</span>
              ) : (
                <span>{moment(item.endDate).format("MMMM D, yyyy")}</span>
              )}
            </CardText>
          </div>
        ))}
      </Card>
    </div>
  );
}
