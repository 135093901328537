import React, { Fragment } from "react";
import BasicFilter from "./BasicFilter";
import SubjectsFilter from "./SubjectsFilter";
import TutorLocation from "./TutorLocationFilter";

const FilterOptionsContain = ({ setFilters, isReset }) => {
  return (
    <Fragment>
      <SubjectsFilter setFilters={setFilters} isReset={isReset} />
      <BasicFilter setFilters={setFilters} isReset={isReset} />
      <TutorLocation setFilters={setFilters} isReset={isReset} />
    </Fragment>
  );
};

export default FilterOptionsContain;
