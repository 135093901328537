import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Col, Input, Label } from "reactstrap";
import { CountriesApi } from "../../../api/index";
import { authHeader } from "../../../Services/AuthVerify";
import FilterContext from "../../../_helper/Filter";

export default function TutorLocation({ isReset }) {
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countries, setCountries] = useState([]);
  // const [countryStates, setCountryStates] = useState([]);
  // const [cities, setCities] = useState([]);

  const { location, setLocation } = useContext(FilterContext);

  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isPostCodeDisabled, setIsPostCodeDisabled] = useState(true);

  const fetchCountries = async () => {
    const res = await axios.get(CountriesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });

    var countryList = [];
    if (res.data) {
      res.data.map((item, index) => {
        countryList.push({
          value: item._id + "_" + item.name,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    countryList = countryList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    setCountries(countryList);
  };

  useEffect(() => {
    if (isReset) {
      setLocation({});
      setCountry("");
      setCity("");
      setPostalCode("");
      setIsCityDisabled(true);
      setIsPostCodeDisabled(true);
    }
  }, [isReset]);

  const handleCountryChange = async (item) => {
    if (!item) {
      setCountry();
      setLocation({});
      setPostalCode("");
      setIsPostCodeDisabled(true);
      return;
    }
    const [value, name] = item.value.split("_");
    if (!value) {
      setLocation({});
      return;
    }
    if (value) {
      setCountry(item);
      setLocation({ country: name });
      setCity("");
      setState("");
      setPostalCode("");
      setIsStateDisabled(true);
      setIsCityDisabled(false);
      setIsPostCodeDisabled(false);
      return;
    }
  };

  const handleStateChange = async (e) => {
    setState(e.target.value);

    if (e.target.value == "") {
      setCity("");
      setPostalCode("");
      setIsCityDisabled(true);
      setIsPostCodeDisabled(true);
      setLocation({ country: location.country });
    } else {
      setLocation((prev) => ({ ...prev, state: e.target.value }));
      setIsCityDisabled(false);
    }
  };

  const updateCity = (e) => {
    setCity(e.target.value);
    setLocation((prev) => ({ ...prev, city: e.target.value }));
  };

  const updatePostalCode = (e) => {
    setPostalCode(e.target.value);
    setLocation((prev) => ({ ...prev, postalCode: e.target.value }));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <Fragment>
      <div className="filter_card">
        <h4 className="f_title">
          <span>Location</span>
        </h4>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Country
          </Label>
          <Col sm="9">
            <Select
              options={countries}
              placeholder="Select Country"
              className="js-example-basic-single col-sm-12 darkMode"
              onChange={(item) => handleCountryChange(item)}
              value={country}
              isClearable={true}
            />
          </Col>
        </div>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            City
          </Label>
          <Col sm="9">
            <Input
              type="text"
              name="city"
              className="form-control darkMode"
              placeholder="City"
              value={city}
              disabled={isCityDisabled}
              onChange={(e) => updateCity(e)}
            />
          </Col>
        </div>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Post Code
          </Label>
          <Col sm="9">
            <Input
              className="form-control"
              type="text"
              placeholder="Post Code"
              disabled={isPostCodeDisabled}
              value={postalCode}
              onChange={(e) => updatePostalCode(e)}
            />
          </Col>
        </div>
        <hr
          color="#337AB7"
          size="5"
          width="92%"
          style={{ position: "absolute", marginTop: "15px" }}
        />
      </div>
    </Fragment>
  );
}
