import React from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { LI, UL } from "../../../AbstractElements";
import "./features.css";

const Features = () => {
  return (
    <section>
      <Container id="features" style={{ paddingTop: "20px" }}>
        <Row className="features_1">
          <h1 className="text-center pb-4">Features</h1>
          <Col lg="12" sm="12" className="">
            <Card className="total-sale" style={{ placeContent: "center" }}>
              <CardHeader className="card-no-border p-4 text-center">
                <h5>Unlimited Access With No Hidden Fees</h5>
                <span className="pt-2">
                  All registered users have unlimited access to TuteAir. You can
                  contact multiple tutors or students. You can also teach/learn
                  multiple subjects. Once you registered, there are no
                  additional costs, commissions or any other form of fees.
                </span>
                <div className="animat-block">
                  <UL>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                  </UL>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row className="">
          <Col xl="6" lg="6" sm="6" className=" xl-50">
            <Card className="total-sale" style={{ placeContent: "center" }}>
              <CardHeader className="card-no-border p-4">
                <h5>Learn And Teach Globally</h5>
                <span className="pt-2">
                  A global platform where you can teach anything to anyone in
                  anywhere of world. You can also learn anything from any of
                  your preferred tutors.
                </span>
                <div className="animat-block">
                  <UL>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                  </UL>
                </div>
              </CardHeader>
            </Card>
          </Col>
          <Col xl="6" lg="6" sm="6" className=" xl-50">
            <Card className="total-sale" style={{ placeContent: "center" }}>
              <CardHeader className="card-no-border p-4">
                <h5>Find Suitable Tutors Using Smart Filtering</h5>
                <span className="pt-2">
                  You can find your preferred tutors using smart filtering.
                  TuteAir will convert tutors’ availability based on your time
                  zone. You can also find tutors based on location, gender,
                  education, experience, rating, offering and so on.
                </span>
                <div className="animat-block">
                  <UL>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                    <LI></LI>
                  </UL>
                </div>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
