import React, { Fragment, useEffect } from "react";
import CompanySection from "./Company-section/Company";
import Header from "./Header/Header";
import HeroSection from "./Hero-Section/HeroSection";

import AboutUs from "./About-us/AboutUs";
import Features from "./Feature-section/Features";
import Prices from "./Prices-section/Prices";

import Footer from "./Footer/Footer";
import MinFaqs from "./FrequnetlyAskQustions/MinFAQ";
import Newsletter from "./Newsletter/Newsletter";

const LandingPageContain = () => {
  useEffect(() => {
    document.title = "Home | TuteAir - Learn & Teach Gloabally";
  }, []);
  return (
    <Fragment>
      <div className="" style={{ background: "#f0f8ffd9" }}>
        {/* #f174171f  #f0f8ffd9*/}
        <Header />
        <HeroSection />
        <CompanySection />
        {/* <AboutUs /> */}
        <AboutUs />
        <Features />
        <MinFaqs />
        <Prices />
        {/* <Testimonials /> */}
        <Newsletter />
        <Footer />
      </div>
    </Fragment>
  );
};

export default LandingPageContain;
