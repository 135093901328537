import { apiSlice } from "../api/apiSlice";

export const usersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (email) => `/chat/findUser/${email}`,
        }),
        getUsers: builder.query({
            query: (type) => `/chat/users/${type}`,
        }),
    }),
});

export const { useGetUserQuery, useGetUsersQuery } = usersApi;
