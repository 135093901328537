import React, { Fragment } from "react";
import AllUsersContain from "../../Component/Chat/NewChat/AllUsers";

const AllUsers = () => {
  return (
    <Fragment>
      <AllUsersContain />
    </Fragment>
  );
};

export default AllUsers;
