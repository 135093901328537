import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row, InputGroup, Table } from "reactstrap";
import DatePicker from "react-datepicker";
import { useContext } from "react";
import FilterContext from "../../../_helper/Filter";

export default function ScheduleFilter({ isReset }) {
  const [from, setfrom] = useState(new Date("01-Nov-2022 00:00"));
  const [to, setTo] = useState(new Date("01-Nov-2022 23:59"));
  const [mon, setMon] = useState(true);
  const [tue, setTue] = useState(true);
  const [wed, setWed] = useState(true);
  const [thu, setThu] = useState(true);
  const [fri, setFri] = useState(true);
  const [sat, setSat] = useState(true);
  const [sun, setSun] = useState(true);

  const initializeScheduleFilter = () => {
    setfrom(new Date("01-Nov-2022 00:00 AM"));
    setTo(new Date("01-Nov-2022 11:59 AM"));
    setMon(true)
    setTue(true)
    setWed(true)
    setThu(true)
    setFri(true)
    setSat(true)
    setSun(true)
    setScheduleFilter({})
  }

  const {
    scheduleFilter,
    setScheduleFilter
  } = useContext(FilterContext)

  useEffect(() => {
    if (isReset) {
      initializeScheduleFilter();
    }
  }, [isReset]);


  // useEffect(() => {
  //   setMon(singleSchedule.mon);
  //   setTue(singleSchedule.tue);
  //   setWed(singleSchedule.wed);
  //   setThu(singleSchedule.thu);
  //   setFri(singleSchedule.fri);
  //   setSat(singleSchedule.sat);
  //   setSun(singleSchedule.sun);
  //   singleSchedule.from
  //     ? setfrom(parseTime(JSON.stringify(singleSchedule.from).slice(12, 17)))
  //     : new Date();
  //   singleSchedule.to
  //     ? setTo(parseTime(JSON.stringify(singleSchedule.to)?.slice(12, 17)))
  //     : new Date();
  // }, [singleSchedule]);

  const handleFromChange = (from) => {
    setfrom(from);
  };
  const handleToChange = (to) => {
    setTo(to);
  };

  useEffect(() => {
    if (sat && sun && mon && tue && wed && thu && fri) {
      setScheduleFilter({})
    }
    else {
      setScheduleFilter({
        from: from,
        to: to,
        sat: sat,
        sun: sun,
        mon: mon,
        tue: tue,
        wed: wed,
        thu: thu,
        fri: fri
      })
    }
  }, [sat, sun, mon, tue, wed, thu, fri, from, to])

  // const parseTime = (userInput) => {
  //   const hours = userInput.slice(0, 2); // userInput => 12:34
  //   const minutes = userInput.slice(3);

  //   const date = new Date();
  //   date.setHours(hours, minutes);

  //   return date;
  // };
  //console.log(scheduleFilter)

  return (
    <div className="filter_card">
      <h4 className="f_title">
        <span>Schedule</span>
      </h4>
      <sup>*Tutors time will be converted to your local time</sup>
      <Row>
        <Col sm="12" xl="12">
          <div className="row mb-2">
            <Label className="col-sm-2 col-form-label" htmlFor="inputEmail3" style={{ textAlign: "right" }}>
              From
            </Label>
            <Col sm="4">
              <InputGroup
                className="date"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits"
                  selected={from}
                  onChange={handleFromChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="From"
                  timeFormat="HH:mm"
                  dateFormat="H:mm"
                />
              </InputGroup>
            </Col>
            <Label className="col-sm-2 col-form-label" htmlFor="inputEmail3" style={{ textAlign: "right" }}>
              To
            </Label>
            <Col sm="4">
              <InputGroup
                className="date"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits"
                  selected={to}
                  onChange={handleToChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="To"
                  timeFormat="HH:mm"
                  dateFormat="H:mm"
                />
              </InputGroup>
            </Col>
          </div>
          <Col sm="12" xl="12">
            <div className="row mb-2"></div>
          </Col>
          <div className="row mb-2">
            <div className="table-responsive">
              <Table className="table-border-horizontal">
                <thead>
                  <tr>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                    <th>Sun</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Input
                        type="checkbox"
                        checked={mon}
                        onChange={() => setMon(!mon)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={tue}
                        onChange={() => setTue(!tue)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={wed}
                        onChange={() => setWed(!wed)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={thu}
                        onChange={() => setThu(!thu)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={fri}
                        onChange={() => setFri(!fri)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={sat}
                        onChange={() => setSat(!sat)}
                      />
                    </td>
                    <td>
                      <Input
                        type="checkbox"
                        checked={sun}
                        onChange={() => setSun(!sun)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col sm="12" xl="12">
          <div className="row mb-2"></div>
        </Col>
      </Row>
    </div>
  );
}
