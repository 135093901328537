import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import feature2 from "../assets/img/feature2.png";
import Header from "../Header/Header";
import AllTutorList from "./AllTutorCard";
import "./search-tutor.css";

const SearchTutorContain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Find Tutor | TuteAir - Learn & Teach Gloabally";
  }, []);
  return (
    <>
      <div className="" style={{ background: "#f0f8ffd9" }}>
        {/* #f174171f */}
        <Header />
        <section className="searchPage container px-0">
          <Row className="px-0 search_heading">
            <Col md={8} className="offset-md-2">
              <h1 className="text-center my-0 mb-2 ta-student">
                Find Suitable Tutors Using Smart Filtering
              </h1>
              <p className="text-center">
                You can find your preferred tutors using smart filtering.
                TuteAir will convert tutors’ availability based on your time
                zone. You can also find tutors based on location, gender,
                education, experience, rating, offering and so on.
              </p>
            </Col>
            <img src={feature2} alt="" className="search_img_1" />
          </Row>

          <AllTutorList />
        </section>
      </div>
    </>
  );
};

export default SearchTutorContain;
