import SweetAlert from "sweetalert2";
import { axiosRequest } from "../../../redux/utils/axios-utils";

export const updateGeneralProfile = async (firstName, lastName, accType) => {
  SweetAlert.fire({
    title: "Are you sure?",
    text: "It will update your name and account type!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Ok",
    cancelButtonText: "cancel",
    reverseButtons: true,
  }).then(async (result) => {
    if (result.value) {
      if (firstName !== "" && accType !== "") {
        var response = await axiosRequest({
          url: "/newMember/settings/general-info",
          method: "put",
          data: {
            firstName,
            lastName,
            accountType: accType,
          },
        });
        if (response && response.status === 200) {
          SweetAlert.fire({
            title: "Successfully updated!",
            // text: "You clicked the button!",
            icon: "success",
          });
        } else {
          SweetAlert.fire({
            title: response.data,
            icon: "error",
          });
        }
      } else {
        SweetAlert.fire({
          title: "Account Type & First Name is required!",
          icon: "error",
        });
      }
    }
  });
};
export const updateChangedPassword = async (
  prevPassword,
  password,
  confirmPassword
) => {
  SweetAlert.fire({
    title: "Are you sure?",
    text: "Once changed, you need to login again!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Ok",
    cancelButtonText: "cancel",
    reverseButtons: true,
  }).then(async (result) => {
    if (result.value) {
      if (password === confirmPassword) {
        var response = await axiosRequest({
          url: "/newMember/settings/changed-password",
          method: "put",
          data: {
            prevPassword,
            newPassword: confirmPassword,
          },
        });
        if (response?.status === 200) {
          SweetAlert.fire({
            title: "Successfully changed password!",
            // text: "You clicked the button!",
            icon: "success",
          });
        } else {
          SweetAlert.fire({
            title: response.data,
            icon: "error",
          });
        }
      } else {
        SweetAlert.fire("Password didn't matched!");
      }
    } else {
      SweetAlert.fire("Nothing is changed!");
    }
  });
};
