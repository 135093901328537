import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { userLoggedOut } from "../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import "../BecomeTutor/become-tutor.css";
import NavbarContain from "../BecomeTutor/ChildComponent/Navbar";
import { getResendEmailResponse } from "../BecomeTutor/fnBecomeTutor";
import StudentInterestToLearn from "./S1.InterestToLearn";
import StudentPersonalInfo from "./S2.Personal";
import StudentPhotoContain from "./S3.Photo";

const BecomeStudentContain = () => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  const [seconds, setSeconds] = useState(59);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const [minutes, setMinutes] = useState(0);

  const {
    stepStudent,
    setStepStudent,
    getLoggedStudentInfo,
    profilePhoto,
    setProfilePhoto,
    clearBecomeStudentContext,
    handleEmailVerified,
    setIsEmailVerified,
    isEmailVerified,
    isMemberExists,
  } = useContext(BecomeStudentContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "Become Student | TuteAir - Learn & Teach Gloabally";
    if (_tuteair?.accessToken && _tuteair?._id) {
      getLoggedStudentInfo(_tuteair?._id);
      setStepStudent(_tuteair.currentStep);
    } else {
      dispatch(userLoggedOut());
      dispatch(clearMemberMenus());
      clearBecomeStudentContext();
      localStorage.clear();
      navigate(`${process.env.PUBLIC_URL}/login-account`);
    }

    if (!isMemberExists) {
      console.log(isMemberExists);
      // Logout();
    }
  }, []);

  const handleResendEmail = async () => {
    var response = await getResendEmailResponse(_tuteair?._id);
    setResendEnabled(false);
    if (response?.message === "verified") {
      setIsEmailVerified(true);
      SweetAlert.fire({
        title: `Your email are already verified.`,
        text: "Thank you for being with TuteAir. Complete your profile to find your desired tuitions.",
      });
    } else if (response?.message === "success") {
      SweetAlert.fire({
        title: `Email sent to <u>${_tuteair?.email}<u/>`,
        text: "N.B: If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder",
      });
    }
  };

  const handleNotReceivedEmail = async (e) => {
    e.preventDefault();
    var htmlText = "";
    htmlText +=
      "<p>If you do not see the email in your inbox in a few minutes, please check your junk mail folder and add  <q>support@tuteair.com</q> to your White List or Safe Sender List.</p>";
    htmlText +=
      "<p>We make every effort to ensure that these emails are delivered.</p>";
    htmlText += `<p>If you still don’t receive an email, then please click on <b>Resend Email</b></p>`;

    SweetAlert.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      backdrop: true,
      title: "Didn't receive an Email?",
      html: htmlText,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Resend Email",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        handleResendEmail();
      }
    });
  };

  return (
    <>
      <div className="" style={{ background: "#f0f8ffd9" }}>
        <NavbarContain />
        <Container
          className={`py-5 ${stepStudent !== 0 ? "container_padding" : ""}`}
        >
          {stepStudent > 0 && (
            <Row className="mt-4" style={{ placeContent: "center" }}>
              <Col md={7} className="email_confirm_title text-center">
                {!isEmailVerified && (
                  <>
                    <Mail width={17} height={17} />
                    <h5 className="px-2 ">
                      An Email has been sent to your email. Please confirm your
                      email.
                    </h5>
                    <a
                      href="#resentEmail"
                      onClick={(e) => handleNotReceivedEmail(e)}
                      className="text-underline px-3"
                    >
                      Didn't receive an Email?
                    </a>
                    {/* 
                    <button
                      className="btn btn-sm py-1 text-dark btn-light-gradien mx-3"
                      // disabled={!resendEnabled}
                      disabled={false}
                      onClick={(e) => handleResendEmail(e)}
                    >
                    
                      Resend
                      {minutes === 0 && seconds === 0 ? null : (
                        <span>
                          {" "}
                          in {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      )}
                    </button> */}
                  </>
                )}
              </Col>
            </Row>
          )}

          {stepStudent === 1 ? (
            <StudentInterestToLearn />
          ) : stepStudent === 2 ? (
            <StudentPersonalInfo />
          ) : stepStudent === 3 ? (
            <StudentPhotoContain />
          ) : (
            <StudentInterestToLearn />
          )}
        </Container>
      </div>
    </>
  );
};

export default BecomeStudentContain;
