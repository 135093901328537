import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Button,
  CardFooter,
} from "reactstrap";
import { UpcomingClassListView } from "../../../../../api";
import axios from "axios";
import { authHeader, toastNotifiy } from "../../../../../Services/AuthVerify";
import { useSelector } from "react-redux";
import { axiosRequest } from "../../../../../redux/utils/axios-utils";

export default function UpComingClass(props) {
  const [allUpcomingClassList, setAllUpcomingClassList] = useState([]);
  const { user } = useSelector((state) => state.auth) || {};

  const fetchAllUpcomingClass = async () => {
    const res = await axios.get(UpcomingClassListView, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.data) {
      const participant = res.data?.map((item) => {
        item?.participants.map((item2) => {
          if (item2?.studentId != user) {
            res.data?.map((item, index) => {
              item.start = new Date(item.start);
              item.end = new Date(item.end);
              item.subjects = "";
              item.studentName = "";
              item.confirmedTuitionFee = 0;
              item.currency = "";
              var mySelectedTuition = "";
              const subjects = "";

              const myTuitions = props?.tutorTuitions?.filter(
                (t) => t.tuitionId == item.tuition
              );

              const myTuition = myTuitions[0];
              item.studentName = myTuition?.studentName;
              item.confirmedTuitionFee = myTuition?.confirmedTuitionFee;
              item.currency = myTuition?.currency;

              if (myTuition?.tuitionRequest?.subjects) {
                myTuition?.tuitionRequest?.subjects?.map((item2, index2) => {
                  var comma =
                    item2.tuitionRequest?.subjects?.length - 1 === index2
                      ? ""
                      : " - ";
                  item.subjects =
                    subjects + item2.label?.toString().trim() + comma;
                });
              } else {
                myTuition?.interestedSubjects?.subjects?.map(
                  (item2, index2) => {
                    var comma =
                      myTuition.interestedSubjects?.subjects?.length - 1 ===
                      index2
                        ? ""
                        : " - ";
                    item.subjects =
                      item.subjects + item2.name?.toString().trim() + comma;
                  }
                );
              }
            });
            setAllUpcomingClassList(res.data);
          }
        });
      });
    }
  };

  useEffect(() => {
    fetchAllUpcomingClass();
    return () => {
      setAllUpcomingClassList();
    };
  }, [props]);

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <td className="h4" colSpan={6}>
                  Upcoming Classes
                </td>
              </tr>
              <tr style={{ background: "#dbdbdb2b" }}>
                <th style={{ width: "4%" }} scope="col"></th>
                <th>Title</th>
                <th>Subject</th>
                <th>Student Name</th>
                <th>Amount</th>
                <th>Time</th>
                <th>Payment Status</th>
              </tr>
            </thead>

            <tbody>
              {allUpcomingClassList?.map((item, index) => (
                <tr className="px-0" key={index}>
                  <td scope="row">{index + 1}</td>
                  <td>{item?.title}</td>
                  <td>{item?.subjects}</td>
                  <td>{item?.studentName}</td>
                  <td>
                    <span>{item?.confirmedTuitionFee}</span> /{" "}
                    <span>{item?.currency}</span>
                  </td>
                  <td>
                    <span>
                      {new Date(item?.start).toDateString()} :{" "}
                      {new Date(item?.start).toLocaleTimeString()}
                    </span>
                    {" - "}
                    <span>{new Date(item?.end).toLocaleTimeString()}</span>
                  </td>
                  <td>Unpaid</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
