import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Info, ThumbsUp } from "react-feather";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import TutorsContext from "../../../_helper/Tutors";
import { saveNewBasicInfo } from "./fnBecomeTutor";

const BecomeTutorPersonal = () => {
  const {
    step,
    basicInfo,
    setBasicInfo,
    handleSkipOrNext,
    saveCurrentStepToSession,
  } = useContext(BecomeTutorContext);

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const { languages, getLangugaes } = useContext(TutorsContext);

  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";
    console.log(basicInfo);
    if (languages.length === 0) getLangugaes();
  }, []);

  const handleBasicInfoSave = async (e) => {
    setBasicInfo({
      ...basicInfo,
      tutorBio: basicInfo?.about.tutorBio ? basicInfo?.about?.tutorBio : "",
      studentBio: basicInfo?.about?.studentBio
        ? basicInfo?.about.studentBio
        : "",
    });

    var result = await saveNewBasicInfo(basicInfo, 3);
    if (result) {
      saveCurrentStepToSession(4);
    }
  };

  return (
    <>
      <Row className="bt_title">
        <Col md={12}>
          <h1 className="text-center">Yourself</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  03 <br /> Yourself
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Fillup all [*] info</h6>
                    <p>
                      <ul>
                        <li>Gender </li>
                        <li>Your birth of date </li>
                        <li>Your phone </li>
                        <li>Your language </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Tutor Bio</h6>
                    <p>
                      Give a brief overview of yourself & your tutoring
                      experience, so that the student may learn about you and
                      your background.
                    </p>
                  </div>
                </div>

                <hr />
                <div className="media bt_help">
                  <Info />
                  <div className="media-body">
                    <p>
                      You can skip this for the moment, but you must fill it out
                      later to ensure a proper response from the student's side.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card className="mb-0 py-4">
            <CardBody className="megaoptions-border-space-sm">
              <Row>
                <Col sm="12" xl="12">
                  <Form
                    className="needs-validation"
                    // onChange={markFormChange}
                  >
                    <div className="form-icon d-none">
                      <i className="icofont icofont-envelope-open"></i>
                    </div>
                    <div className="row mb-1">
                      <Label
                        className="col-sm-3 col-form-label pt-0 asterisk "
                        htmlFor=""
                      >
                        Gender
                      </Label>
                      <Col sm="9">
                        <div
                          className="m-checkbox-inline"
                          style={{ paddingLeft: "6px" }}
                        >
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline1"
                              type="radio"
                              name="radio1"
                              value="male"
                              checked={basicInfo?.gender === "male"}
                              onChange={(event) => {
                                setBasicInfo({
                                  ...basicInfo,
                                  gender: "male",
                                });
                              }}
                              // checked={gender === "male"}
                              // onChange={handleGenderChange}
                            />
                            <Label className="mb-0" htmlFor="radioinline1">
                              <span className="digits"> {"Male"}</span>
                            </Label>
                          </div>
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline2"
                              type="radio"
                              name="radio1"
                              value="female"
                              checked={basicInfo?.gender === "female"}
                              onChange={(event) => {
                                setBasicInfo({
                                  ...basicInfo,
                                  gender: "female",
                                });
                              }}
                            />
                            <Label className="mb-0" htmlFor="radioinline2">
                              <span className="digits"> {"Female"}</span>
                            </Label>
                          </div>
                          <div className="radio radio-primary">
                            <Input
                              id="radioinline3"
                              type="radio"
                              name="radio1"
                              value="other"
                              checked={basicInfo?.gender === "other"}
                              onChange={(event) => {
                                setBasicInfo({
                                  ...basicInfo,
                                  gender: "other",
                                });
                              }}
                            />
                            <Label className="mb-0" htmlFor="radioinline3">
                              <span className="digits"> {"Other"}</span>
                            </Label>
                          </div>
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-1">
                      <Label
                        className="col-sm-3 col-form-label asterisk "
                        htmlFor=""
                      >
                        Date Of Birth
                      </Label>
                      <Col sm="9">
                        <DatePicker
                          className="form-control digits"
                          selected={basicInfo?.doB}
                          onChange={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              doB: new Date(event),
                            });
                          }}
                          peekNextMonth
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MMM-yyyy"
                        />
                      </Col>
                    </div>
                    <div className="row mb-1">
                      <Label className="col-sm-3 col-form-label asterisk ">
                        Phone
                      </Label>
                      <Col sm="9">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Phone No"
                          value={basicInfo?.contactNo?.number}
                          onChange={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              contactNo: {
                                code: basicInfo?.contactNo?.code,
                                number: event.target.value,
                              },
                            });
                          }}
                        />
                      </Col>
                    </div>
                    <div className="row mb-1">
                      <Label className="col-sm-3 col-form-label asterisk">
                        Language
                      </Label>
                      <Col sm="9">
                        <Multiselect
                          className="darkMode"
                          isObject={false}
                          options={languages}
                          showCheckbox
                          selectedValues={basicInfo?.language}
                          onSelect={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              language: event,
                            });
                          }}
                          onRemove={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              language: event,
                            });
                          }}
                        />
                      </Col>
                    </div>
                    <FormGroup>
                      <Label
                        className="col-form-label asterisk"
                        htmlFor="exampleInputEmail1"
                      >
                        {url === "/become-student"
                          ? "Student Bio"
                          : "Tutor Bio"}
                      </Label>
                      {url === "/become-student" ? (
                        <Input
                          className="form-control"
                          type="textarea"
                          rows="5"
                          cols="50"
                          placeholder="Your Message"
                          value={basicInfo?.about?.studentBio}
                          onChange={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              about: {
                                tutorBio: basicInfo?.about?.tutorBio,
                                studentBio: event.target.value,
                              },
                            });
                          }}
                        />
                      ) : (
                        <Input
                          className="form-control"
                          type="textarea"
                          rows="5"
                          cols="50"
                          placeholder="Your Message"
                          value={basicInfo?.about?.tutorBio}
                          onChange={(event) => {
                            setBasicInfo({
                              ...basicInfo,
                              about: {
                                tutorBio: event.target.value,
                                studentBio: basicInfo?.about?.studentBio,
                              },
                            });
                          }}
                        />
                      )}
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-4"
          >
            <Col md={12} className="btn_acc">
              {step !== 1 && (
                <button
                  className="btn btn-lg btn-back btn-info  mx-2"
                  onClick={(e) => handleSkipOrNext("back")}
                >
                  Back
                </button>
              )}

              {basicInfo && (
                <button
                  className="btn  btn-lg btn-warning btn-skip "
                  // disabled={!isSkip}
                  onClick={(e) => handleSkipOrNext("skip")}
                >
                  Skip
                </button>
              )}

              <button
                className="btn  btn-lg btn-primary  mx-2"
                disabled={false}
                onClick={(e) => handleBasicInfoSave()}
              >
                Save & Continue
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BecomeTutorPersonal;
