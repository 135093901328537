import React, {
  Component,
  Fragment,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import { BackgroundColor, BasicCalendars } from "../../../../Constant";
import { H5 } from "../../../../AbstractElements";
import { toast } from "react-toastify";
import {
  ClassScheduleGetAllApi,
  ClassScheduleDeleteApi,
  ClassScheduleUpdateApi,
} from "../../../../api";
import PopUp from "./PopUp";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import axios from "axios";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/sass/styles.scss";
import EventView from "./EventView";
import "./_calendar.css";
import { AddNotification } from "../../../../Services/Notification.Service";
import { useSelector } from "react-redux";

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

const CalenderContain = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState({});
  const [tutorTuitions, setTutorTuitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myEventsList, setEventsData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isdelete, setisDelete] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [view, setView] = useState(false);
  const viewToggle = () => setView(!view);
  const [title, setTitle] = useState("");
  const [IsStudent, setIsStudent] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const { user } = useSelector((state) => state.auth) || {};

  const fetchAllEvents = async () => {
    const res = await axios.get(ClassScheduleGetAllApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.data) {
      const participant = res.data?.map((item) => {
        item?.participants.map((item2) => {
          if (item2?.studentId === user) {
            res.data?.map((item, index) => {
              item.start = new Date(item.start);
              item.end = new Date(item.end);
            });
            setEventsData(res.data);
          }
        });
      });
    }
  };

  useEffect(() => {
    getTutorTuitions();
    fetchAllEvents();
    // eventPropGetter()
    return () => {
      setTutorTuitions([]);
    };
  }, []);

  const getTutorTuitions = async () => {
    setIsLoading(true);
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/student",
      method: "get",
    });
    if (res.data) {
      setTutorTuitions(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleSelectSlot = (e) => {
    if (e.start > new Date()) {
      if (!IsStudent) {
        setTitle("Add New Event");

        setData(e);

        toggle();

        setEdit(false);

        setisDelete(false);
      }
    }
  };

  function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }

  function isToday(date) {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  const handleSelectEvent = (item) => {
    const participant = item?.participants?.map((item) => item.payment.ispaid);
    const isPaid = participant[0];

    if (item.start < new Date()) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
    if (new Date() < item.end && isToday(item.end)) {
      const times = getMinutesBetweenDates(new Date(), item.start);
      const classDuration = getMinutesBetweenDates(item.start, item.end);
      if (times < 0) {
        const duration = getMinutesBetweenDates(item.start, new Date());
        if (duration > 0 && isPaid) {
          setIsJoin(true);
        }
      }
      if (times > 0 && times < 16 && isPaid) {
        setIsJoin(true);
      }
    } else {
      setIsJoin(false);
    }
    setTitle("View Event");
    viewToggle();
    setData(item);
    setEdit(true);
    setisDelete(true);
  };

  const handleUpdate = async (id, start, end) => {
    var req = {
      start: start,
      end: end,
    };
    const res = await axios.put(ClassScheduleUpdateApi + "/" + id, req, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.status === 200) {
      if (res.data) {
        const msg = "Schedule Updated Succefully";
        toast.success(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        //props.toggle();
      } else {
        toast.warn("Failed to add", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      //setLoading(false);
    } else {
      toastNotifiy("Failed to insert!!", "warn");
      // setLoading(false);
    }
    return;
  };

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      if (start >= new Date()) {
        const { allDay } = event;
        if (!allDay && droppedOnAllDaySlot) {
          event.allDay = true;
        }
        const participant = event?.participants?.map(
          (item) => item.payment.ispaid
        );

        const isPaid = participant[0];

        if (!isPaid && event.eventCreator === user) {
          if (window.confirm("Are you sure to rescheduled this class?")) {
            handleUpdate(event._id, start, end);
            const notifyObj = {
              receiver: participant[0].studentId,
              title: event.title + " class has been rescheduled",
              description: "Class rescheduled by Tutor",
              context: process.env.PUBLIC_URL + "/schedule",
              isPublic: false,
            };
            AddNotification(notifyObj);
            setEventsData((prev) => {
              const existing = prev.find((ev) => ev._id === event._id) ?? {};
              const filtered = prev.filter((ev) => ev._id !== event._id);
              return [...filtered, { ...existing, start, end, allDay }];
            });
          }
        }
      }
    },
    [setEventsData]
  );
  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event?.status?.includes("New") && {
        className: "default_class",
      }),
      ...(event?.status?.includes("Complete") && {
        className: "complete_class",
      }),
      ...(event?.status?.includes("Paid") && {
        className: "paid_class",
      }),
    }),
    []
  );
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {view && (
              <EventView
                title={title}
                isOpen={view}
                isJoin={isJoin}
                isConfirm={isConfirm}
                toggle={viewToggle}
                data={data}
                tutorTuitions={tutorTuitions}
                myEventsList={myEventsList}
              />
            )}
            <Card>
              <CardHeader className="pb-0">
                <H5>{BasicCalendars}</H5>
              </CardHeader>
              <CardBody>
                <DragAndDropCalendar
                  localizer={localizer}
                  events={myEventsList}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500, margin: "50px" }}
                  onSelectEvent={(item) => handleSelectEvent(item)}
                  onSelectSlot={(e) => handleSelectSlot(e)}
                  selectable
                  onEventDrop={moveEvent}
                  // eventPropGetter={(myEventsList) => {
                  //   const backgroundColor = myEventsList.allDay ? "yellow" : 'green';
                  //   const color = myEventsList.allDay ? "green": 'black';
                  //   return { style: { color } }
                  // }}
                  eventPropGetter={eventPropGetter}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CalenderContain;
