import React from "react";
import { useState, useEffect } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import Select, { createFilter } from "react-select";
import { useContext } from "react";
import FilterContext from "../../../_helper/Filter";

export default function WorkExperienceFilter({ isReset }) {
  const [org, setOrg] = useState("")
  const [workExpYear, setWorkExpYear] = useState(0)
  const {
    workExperiencesFilter, setWorkExperiencesFilter
  } = useContext(FilterContext)

  const workExpYearList = [
    {
      value: "<1",
      label: "<1 years",
      name: "<1",
    },
    {
      value: "1-2",
      label: "1-2 years",
      name: "1-2",
    },
    {
      value: "2-4",
      label: "2-4 years",
      name: "2-4",
    },
    {
      value: "4+",
      label: "4+ years",
      name: "4+",
    }
  ]

  const fnsetWorkExpYear = (item) => {
    if (!item || item.length == 0) {
      setWorkExpYear(0);
      setWorkExperiencesFilter({ org: org, expYear: "<1" })
      return
    }
    setWorkExpYear(item);
    setWorkExperiencesFilter(prev => ({ ...prev, expYear: item.value }))
  }

  const fnsetOrg = (value) => {
    setOrg(value)
    if (value == "") {
      setWorkExperiencesFilter({ expYear: workExpYear.value })
    } else {
      setWorkExperiencesFilter(prev => ({ ...prev, org: value }))
    }
  }

  const initializeWorkExpFilter = () => {
    setWorkExperiencesFilter({})
    setOrg("")
    setWorkExpYear(0)
  }

  useEffect(() => {
    if (isReset) {
      initializeWorkExpFilter();
    }
  }, [isReset]);

  return (
    <div className="filter_card">
      <h4 className="f_title">
        <span>Work Experience</span>
      </h4>
      <Row>
        <Col>
          <Label className="col-form-label" htmlFor="">
            Duration in years
          </Label>
          <Select
            options={workExpYearList}
            placeholder="Select Years"
            className="js-example-basic-single col-sm-12 darkMode"
            isClearable={true}
            onChange={(item) => fnsetWorkExpYear(item)}
            value={workExpYear}
          />
        </Col>

        <Col md={7}>
          <Label className="col-form-label" htmlFor="">
            Prefered Organisation
          </Label>

          <Input
            type="text"
            name="text"
            className="form-control px-2 py-7"
            placeholder="Organisation Name"
            value={org}
            onChange={(e) => fnsetOrg(e.target.value)}
          ></Input>
        </Col>
      </Row>
    </div>
  );
}
