//worked till now
import React, { Fragment } from "react";
import { Modal } from "reactstrap";
import AddReviewContainer from "./ReviewModalContent/AddReviewContainer";
import AddReviewStudentContainer from "./ReviewModalContent/AddReviewStudentContainer";

const ReviewModal = (props) => {
  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        style={{
          top: "0vh",
          right: "0vw",
        }}
        className="modal-xl"
        backdrop={"static"}
      >
        {props.isTutor && (
          <AddReviewContainer
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            data={props.data}
          />
        )}
        {props.isStudent && (
          <AddReviewStudentContainer
            title={props.title}
            isOpen={props.isOpen}
            toggler={props.toggler}
            data={props.data}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default ReviewModal;
