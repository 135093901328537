import React from "react";
import Slider from "react-slick";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import logo01 from "../assets/img/TuteAirLogo.png";
import logo01 from "../assets/img/logo01.jpg";
import "./about-us.css";

// import ReactPlayer from "react-player";

const AboutUs = () => {
  const settings = {
    infinite: true,
    dots: false,
    speed: 1500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
  };
  return (
    <section>
      <Container className="pt-5" id="about">
        <Row className="border__1">
          <h1 className="text-center pb-4 ">About TuteAir</h1>
          <Col lg="6" md="6" style={{ alignSelf: "center" }}>
            <div className="choose__content">
              <p className="" style={{ textAlign: "justify" }}>
                <span>
                  TutrAir is the only global online platform that allows users
                  from all over the world to find tutors or students from
                  anywhere in world. TuteAir also makes it easier to match
                  tutors and students locally.
                </span>
                <br />
                <br />
                <span>
                  Anyone can teach any subjects of their expertise. Parents or
                  students can find tutors of any one or multiple subjects
                  experts. The goal of TuteAir is to help communities to find
                  their preferred tutors/students and establish a learning
                  platform.
                </span>
              </p>
            </div>
          </Col>
          <Col
            xl="6"
            md="6"
            className="dash-lgorder-1 dash-xl-50 dash-39 box-col-6"
          >
            <Card className="special-discount">
              <CardBody>
                <Slider {...settings}>
                  <div>
                    <div className="item">
                      <div className="discount-block row">
                        <div className="col-md-6 dash-xl-100 box-col-12">
                          <div className="discount-detail">
                            <div>
                              <h3 className="m-auto">
                                Unlimited Access With No Hidden Fees
                              </h3>
                              <p className="py-4 mt-3">
                                All registered users have unlimited access to
                                TuteAir. You can contact multiple tutors or
                                students. You can also teach/learn multiple
                                subjects. Once you registered, there are no
                                additional costs, commissions or any other form
                                of fees.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 dash-xl-100 box-col-12">
                          <div className="img-wrraper">
                            <img src={logo01} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="discount-block row">
                        <div className="col-md-6 dash-xl-100 box-col-12">
                          <div className="discount-detail">
                            <div>
                              <h3 className="m-auto">
                                Learn And Teach Globally
                              </h3>
                              <p className="py-4 mt-3 f-14">
                                A global platform where you can teach anything
                                to anyone in anywhere of world. You can also
                                learn anything from any of your preferred
                                tutors.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 dash-xl-100 box-col-12">
                          <div className="img-wrraper">
                            <img src={logo01} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="item">
                      <div className="discount-block row">
                        <div className="col-md-6 dash-xl-100 box-col-12">
                          <div className="discount-detail">
                            <div>
                              <h3 className="m-auto">
                                Find Suitable Tutors Using Smart Filtering
                              </h3>
                              <p className="py-4 mt-3 f-14">
                                You can find your preferred tutors using smart
                                filtering. TuteAir will convert tutors’
                                availability based on your time zone. You can
                                also find tutors based on location, gender,
                                education, experience, rating, offering and so
                                on.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 dash-xl-100 box-col-12">
                          <div className="img-wrraper">
                            <img src={logo01} className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
