import React, { useContext, useState } from "react";
import { Image } from "../../AbstractElements";
// import logo from "../../assets/images/logo/logo_v2.png";
// import logo1 from "../../assets/images/logo/logo_v2_dark.png";
import { Link } from "react-router-dom";
import logo1 from "../../assets/img/tuteair-logo-dark.svg";
import logo from "../../assets/img/tuteair-logo.svg";
import CheckContext from "../../_helper/customizer/index";

const SidebarIcon = () => {
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  return (
    <div className="logo-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/home`}>
        <Image
          attrImage={{
            className: "img-fluid for-light h-100",
            src: `${logo}`,
            alt: "",
          }}
        />
        <Image
          attrImage={{
            className: "img-fluid for-dark h-100",
            src: `${logo1}`,
            alt: "",
          }}
        />
      </Link>
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
    </div>
  );
};
export default SidebarIcon;
