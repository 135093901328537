import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import StudentClassScheduleContain from "../../../Component/Scheduler/Student";

const StudentClassSchedule = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Class Schedule" title="Class Schedule" />
      <StudentClassScheduleContain />
    </Fragment>
  );
};

export default StudentClassSchedule;
