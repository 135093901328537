import React from "react";
import { Facebook, Instagram, Linkedin, Twitter } from "react-feather";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { Image } from "../../../AbstractElements";
import sidebar from "../../../assets/images/side-bar.png";
import tuteair from "../assets/img/tuteair-v2.png";

import "./footer.css";

const footerQuickLinks = [
  {
    display: "Home",
    url: "#",
  },
  {
    display: "About US",
    url: "#about",
  },

  {
    display: "Features",
    url: "#features",
  },

  {
    display: "FAQs",
    url: "#faqs",
  },
];

const footerInfoLinks = [
  {
    display: "Privacy Policy",
    url: "privacy",
  },
  {
    display: "Terms & Condition",
    url: "terms-condition",
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="pt-5 pb-2">
          <Col lg="3" md="6" className="mb-4 footer__1">
            <h2 className="d-flex align-items-center py-0 my-0 tuteairBrand">
              <span>
                <img src={tuteair} height="30" className="" alt="" />
              </span>
            </h2>
            <small>A Tutor and Tuition Finding Platform.</small>
            <div className="follows mt-3">
              <p className="mb-2">Follow us on social media</p>
              <span>
                <a
                  target="_blank"
                  href="https://m.facebook.com/profile.php?id=100085190424250"
                  rel="noreferrer"
                >
                  <Facebook className="icon" />
                </a>
              </span>

              <span>
                <a
                  target="_blank"
                  href="https://www.instagram.com/tuteair/"
                  rel="noreferrer"
                >
                  <Instagram className="icon" />
                </a>
              </span>

              <span>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/tuteair/about/"
                  rel="noreferrer"
                >
                  <Linkedin className="icon" />
                </a>
              </span>

              <span>
                <a
                  href="https://twitter.com/tuteair"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter className="icon" />
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  <a href={item.url}>
                    <span>{item.display}</span>
                  </a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Information</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.display}
                  </a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <div className="sidebar-img-section">
              <div className="sidebar-img-content">
                <Image
                  attrImage={{
                    className: "img-fluid",
                    src: `${sidebar}`,
                    alt: "",
                  }}
                />
                <h4>Need Help ?</h4>
                <a href={"#support@tuteair.com"} className="txt">
                  Send Email at "support@tuteair.com"
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
