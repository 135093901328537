import React from "react";
import "./company-section.css";
const Company = () => {
  return (
    <section>
      {/* <Container className="pt-5 border__2"></Container> */}
    </section>
  );
};

export default Company;
