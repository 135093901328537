import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updatedReviews: undefined,
    updatedReplys: undefined,};

const reviewsSlice = createSlice({
    name: "reviews",
    initialState,
    reducers: {
        stUpdatedReplys: (state, action) => {
            state.updatedReplys = action.payload.updatedReplys;
            state.updatedReviews = action.payload.updatedReviews;
        },
    },
});

export const {stUpdatedReplys} = reviewsSlice.actions;
export default reviewsSlice.reducer;
