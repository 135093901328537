import React, { Fragment, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { H6, P } from "../../../AbstractElements";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { userAcconuntVisible } from "../../../redux/features/auth/authSlice";
import { useEffect } from "react";

export default function HideAccount() {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoadin, setIsLoading] = useState(false);
  const [isContinued, setIsContinued] = useState(false);
  const [passwordNull, setPasswordNull] = useState(false);
  const [password, setPassword] = useState("");
  const [showAcc, setShowAcc] = useState(false);
  const [hideAcc, setHideAcc] = useState(false);
  const [accountHideFinal, setAccountHideFinal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { isAccountHide } = useSelector((state) => state.auth) || {};
  const localStorageHide = localStorage.getItem("isAccountHide");

  useEffect(() => {
    if (isAccountHide !== undefined) setAccountHideFinal(isAccountHide);
    else setAccountHideFinal(localStorageHide);
  }, [isAccountHide, localStorageHide]);

  const handleEditIcon = () => setIsEdit(!isEdit);
  const handleCheckBox = (event) => {
    if (event.target.checked) {
      setIsChecked(event.target.checked);
      setPassword("passwordNull");
    } else {
      setIsChecked(!isChecked);
      setPassword("");
    }
  };
  const handleContinueShowHide = async () => {
    setIsContinued(true);
    var response = await axiosRequest({
      url: "/newMember/settings/password-null-check",
      method: "post",
      data: {
        call: true,
      },
    });
    if (response?.status === 200) {
      setPasswordNull(response?.data?.passwordNull);
    }
  };
  const handleShowAcc = () => {
    setHideAcc(false);
    setShowAcc(true);
  };
  const handleHideAcc = () => {
    setHideAcc(true);
    setShowAcc(false);
  };

  const handleAccountShowHide = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        var response = await axiosRequest({
          url: "/newMember/settings/update-account",
          method: "post",
          data: {
            password,
            showAcc,
            hideAcc,
          },
        });
        if (response?.status === 200 && response?.data?.message) {
          SweetAlert.fire({
            title: response.data.message,
            icon: "success",
          });

          localStorage.setItem("isAccountHide", response?.data?.isAccountHide);
          dispatch(
            userAcconuntVisible({
              isAccountHide: response?.data?.isAccountHide,
            })
          );
          setIsContinued(!isContinued);
        } else {
          SweetAlert.fire({
            title: response.data.message,
            icon: "error",
          });
        }
      } else {
        SweetAlert.fire("You are safe! No changes");
      }
    });
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row>
            <Col sm="9">
              <h3 className="tab_title">Hide & Visualize</h3>
            </Col>
            <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
          </Row>
          <div className={`row mb-1 pt-3 ${isEdit ? "d-none" : ""}`}>
            <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={handleEditIcon}
              >
                Hide & Visualization
              </button>
            </Label>
            <Col sm="7">
              <p>Temporarily hide and visualize your TuteAir account.</p>
            </Col>
            <Col sm="2" style={{ textAlign: "-webkit-right" }}>
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={handleEditIcon}
              >
                View
              </button>
            </Col>
          </div>
          <Row className={`${!isEdit ? "d-none" : ""}`}>
            <Col sm="12" xl="12">
              <Card className="height-equal">
                <CardHeader className="pb-0">
                  <h4>{"Hide or Visualize your TuteAir account"}</h4>
                  <p>
                    If you feel bothered with new requests you can hide your
                    account.
                  </p>
                </CardHeader>
                <CardBody>
                  <Form
                    className={`mega-horizontal ${isContinued ? "d-none" : ""}`}
                  >
                    <Row>
                      {accountHideFinal && (
                        <Col sm="12">
                          <Card className="mb-3">
                            <Media
                              className={`p-20 ${
                                showAcc ? "bg_deactivated" : ""
                              }`}
                            >
                              <div className="radio radio-primary me-3">
                                <Input
                                  id="radio40"
                                  type="radio"
                                  name="radio1"
                                  value="option1"
                                  onChange={(e) => handleShowAcc()}
                                />
                                <Label for="radio40"></Label>
                              </div>
                              <Media body>
                                <H6
                                  attrH6={{
                                    className: "mt-0 mega-title-badge",
                                  }}
                                >
                                  Visualize account
                                </H6>
                                <p className="mb-0">This can be Temporary</p>
                                <P>
                                  Your account will be visible to TuteAir
                                  community. New members will be able to contact
                                  with you now.
                                </P>
                              </Media>
                            </Media>
                          </Card>
                        </Col>
                      )}
                      {!accountHideFinal && (
                        <Col sm="12">
                          <Card className="mb-0">
                            <Media
                              className={`p-20  ${hideAcc ? "bg_delete" : ""}`}
                            >
                              <div className="radio radio-primary me-3">
                                <Input
                                  id="radio41"
                                  type="radio"
                                  name="radio1"
                                  value="option1"
                                  onChange={(e) => handleHideAcc()}
                                />
                                <Label for="radio41"></Label>
                              </div>
                              <Media body>
                                <H6
                                  attrH6={{
                                    className: "mt-0 mega-title-badge",
                                  }}
                                >
                                  {"Hide Account"}
                                </H6>
                                <p className="mb-0">This is permanent</p>
                                <P>
                                  No new member can find you and contact with
                                  you. Only those who already communicated with
                                  you will be able to chat.
                                </P>
                              </Media>
                            </Media>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Form>
                  <Row className={`${!isContinued ? "d-none" : ""}`}>
                    {passwordNull && (
                      <Col sm="8" xl="8" className="offset-md-2">
                        <FormGroup>
                          <label className="form-label">
                            {
                              "The page you are trying to visit requires that you re-enter your password."
                            }
                          </label>
                          <InputGroup>
                            <span className="input-group-text">
                              <Lock />
                            </span>
                            <Input
                              className="form-control"
                              type={false ? "text" : "password"}
                              required=""
                              placeholder="*********"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                    {!passwordNull && (
                      <Col sm="8" xl="8" className="offset-md-2">
                        <FormGroup>
                          <label className="form-label">
                            {
                              "The page you are trying to visit requires you to re-enter your password. since you log in with goggle, you didn't set any password.Do you want to continue without password??"
                            }
                          </label>
                          <Col
                            md={4}
                            className="mt-2 ms-2 d-flex align-items-center"
                          >
                            <Input
                              className="form-control"
                              type="checkbox"
                              checked={isChecked === true}
                              onChange={(e) => {
                                handleCheckBox(e);
                              }}
                            />
                            <Label className="col-form-label" htmlFor="">
                              &nbsp;&nbsp;Yes
                            </Label>
                          </Col>
                        </FormGroup>
                      </Col>
                    )}
                    <Col sm="8" xl="8" className="offset-md-2">
                      <button
                        className="btn btn-warning"
                        onClick={(e) => {
                          setIsContinued(!isContinued);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-info mx-3"
                        disabled={password === "" ? true : false}
                        onClick={handleAccountShowHide}
                      >
                        Continue to {accountHideFinal ? "visualize" : "hide"}
                      </button>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter
                  className={`text-end ${isContinued ? "d-none" : ""}`}
                >
                  <button
                    className="btn btn-warning mx-2"
                    onClick={(e) => setIsEdit(!isEdit)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary w-30"
                    disabled={!showAcc && !hideAcc}
                    onClick={(e) => handleContinueShowHide(e)}
                  >
                    Continute to {accountHideFinal ? "visualize" : "hide"}{" "}
                    account
                  </button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
}
