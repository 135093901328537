import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  CardTitle,
  CardBody,
  CardImg,
  CardText,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { FilteredTutorsApi, ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import allowLocation from "../../../assets/images/avtar/allowLocation.png";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import FindTutorsGeocoder from "../../FindTutors/FindTutorsGeocoder";
// import BasicFilter from "./ListView/BasicFilter";
// import SubjectsFilter from "./ListView/SubjectsFilter";
// import TutorLocationFilter from "./ListView/TutorLocationFilter";
import ProfilePopup from "../../FindTutors/ProfilePopup";
import PulseLoader from "react-spinners/PulseLoader";
import { P } from "../../../AbstractElements";
import TutorsContext from "../../../_helper/Tutors";
import BasicFilter from "../../FindTutors/ListView/BasicFilter";
import SubjectsFilter from "../../FindTutors/ListView/SubjectsFilter";
import StudentLocationFilter from "../../FindTutors/ListView/TutorLocationFilter";

// import EducationFilter from "./ListView/EducationFilter";
// import ScheduleFilter from "./ListView/ScheduleFilter";
// import TuitionFeesFilter from "./ListView/TuitionFeesFilter";
// import WorkExperienceFilter from "./ListView/WorkExperienceFilter";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function ShowStudentsOnMap() {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};

  const { latitude, longitude, setLatitude, setLongitude } = useContext(
    PersonalAddressContext
  );

  const [filters, setFilters] = useState({});
  //const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [tutorList, setTutorList] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);

  useEffect(() => {
    const setInitialLocation = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    };
    setInitialLocation();
  }, []);

  const filterByLanguage = async (tutors, languageArray) => {
    let temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      if (languageArray.every((lan) => tutors[i].languages?.includes(lan))) {
        temp_tutors.push(tutors[i]);
      }
    }
    return temp_tutors;
  };

  const filterByTuitionType = async (tutors, tutionType) => {
    return tutors.filter((tutor) =>
      tutionType.some((item) =>
        tutor.interestAndPricing.filter(
          (i) => i.tutionTypes?.indexOf(item) >= 0
        ).length
          ? true
          : false
      )
    );
  };

  const filterBySubject = async (tutors, subjectsArray) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
        for (var k = 0; k < subjectsArray.length; k++) {
          for (
            var l = 0;
            l < tutors[i].interestAndPricing[j].subjects.length;
            l++
          ) {
            if (
              tutors[i].interestAndPricing[j].subjects[l].id ==
              subjectsArray[k].id
            ) {
              tutor_taken = true;
              temp_tutors.push(tutors[i]);
              break;
            }
          }
          if (tutor_taken) break;
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const filterBySubjectCategory = async (tutors, categoryObject) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
        for (
          var l = 0;
          l < tutors[i].interestAndPricing[j].subjects.length;
          l++
        ) {
          if (
            tutors[i].interestAndPricing[j].subjects[l].tag.indexOf(
              categoryObject.value
            ) !== -1
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.media.picture.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }
    // filtering ends here

    setTutorsToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  const updateFilter = () => {
    const addedFilter = {};

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }

    setFilters(addedFilter);
  };

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8}>
            {loading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {
                  tutorsToShow?.filter(
                    (x) =>
                      x.userId &&
                      x.userId !== userId &&
                      x.memberType !== "tutor" &&
                      !x.isAccountDeactive &&
                      !x.isAccountDelete &&
                      !x.isAccountHide
                  ).length
                }{" "}
                matched student's based on your search criteria
              </CardTitle>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>
                {latitude != 0 && longitude != 0 && (
                  <Map
                    mapboxAccessToken={
                      "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ"
                    }
                    style={{
                      width: "100%",
                      height: "80vh",
                      borderRadius: "15px",
                      border: "2px solid red",
                    }}
                    initialViewState={{
                      longitude: longitude,
                      latitude: latitude,
                      zoom: 7,
                    }}
                    mapStyle="mapbox://styles/moniruzzaman-18/cl7zwo94p000014qsaoizctns"
                  >
                    {tutorsToShow
                      ?.filter(
                        (x) =>
                          x.userId &&
                          x.userId !== userId &&
                          x.memberType !== "tutor" &&
                          !x.isAccountDeactive &&
                          !x.isAccountDelete &&
                          !x.isAccountHide
                      )
                      .map((item, key) =>
                        item.address?.latitude && item.address?.longitude ? (
                          <Marker
                            key={key}
                            longitude={item.address.longitude}
                            latitude={item.address.latitude}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedTutor(item);
                              }}
                            >
                              {" "}
                              <img
                                src={
                                  item.media.picture
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.media.picture
                                      }`
                                    : man
                                }
                                height={32}
                                width={32}
                                alt={"tuteair-member"}
                                style={{
                                  borderRadius: "50%",
                                  border: "1.5px solid red",
                                }}
                              />
                            </span>
                          </Marker>
                        ) : null
                      )}

                    {selectedTutor ? (
                      <Popup
                        latitude={selectedTutor.address.latitude}
                        longitude={selectedTutor.address.longitude}
                        onClose={() => {
                          setSelectedTutor(null);
                        }}
                      >
                        <ProfilePopup tutorInfo={selectedTutor} />
                      </Popup>
                    ) : null}

                    <NavigationControl position="bottom-right" />
                    <FindTutorsGeocoder />
                    <FullscreenControl />
                    <GeolocateControl />
                  </Map>
                )}
                {latitude == 0 && longitude == 0 && (
                  <>
                    <P>
                      Please allow your location to see tutor's location on map
                      view.
                    </P>
                    <P>
                      You can allow location by clicking on location icon in
                      your browser's address bar. <br></br>
                      <img src={allowLocation}></img>
                    </P>
                  </>
                )}
              </span>
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">Basic</AccordionHeader>
                <AccordionBody accordionId="1">
                  <BasicFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">Subjects</AccordionHeader>
                <AccordionBody accordionId="2">
                  <SubjectsFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">Tutor's Location</AccordionHeader>
                <AccordionBody accordionId="3">
                  <StudentLocationFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>

              {/* <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="7">Schedule</AccordionHeader>
                  <AccordionBody accordionId="7">
                    <ScheduleFilter />
                  </AccordionBody>
                </AccordionItem> */}
            </UncontrolledAccordion>
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="primary"
                className="mb-2 me-2"
                onClick={updateFilter}
              >
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}

export default ShowStudentsOnMap;
