import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NewTutorProfileContain from "../Profile/Tutor";

const TutorProfileContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (memberType === "student" && params.id === "me") {
      navigate(`${process.env.PUBLIC_URL}/student-profile/me`);
    }
  }, [memberType]);
  return (
    <Fragment>
      {/* <Container fluid={true}>
        <Row>
          <TutorProfile />
        </Row>
      </Container> */}
      <NewTutorProfileContain />
    </Fragment>
  );
};

export default TutorProfileContain;
