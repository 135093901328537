import React, { Fragment, useEffect, useState } from "react";
import { User } from "react-feather";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { Btn } from "../../../AbstractElements";
import { useUpgradeMemberAccountMutation } from "../../../redux/features/settings/settingsApi";
import "./AccountSettings.css";
import { updateGeneralProfile } from "./accountSettingsApi";

const GeneralProfile = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  const [upgradeAccount, { data, isLoading, isSuccess, error: responseError }] =
    useUpgradeMemberAccountMutation();

  const [modal, setModal] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [togglePassword, setTogglePassword] = useState(false);

  const [firstName, setFirstName] = useState(fName);
  const [lastName, setLastName] = useState(lName);
  const [accType, setAccType] = useState(accountType);

  const [prevPassword, setPrevPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [bgColor1, setBgColor1] = useState("");
  const [bgColor2, setBgColor2] = useState("");
  const [isTutorChecked, setIsTutorChecked] = useState(false);
  const [isStudentChecked, setIsStudentChecked] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      SweetAlert.fire({
        title: "Successfully Saved!",
        // text: "You clicked the button!",
        icon: "success",
      });
    }
    if (responseError) {
      SweetAlert.fire({
        title: "Something went wrong!",
        // text: "You clicked the button!",
        icon: "danger",
      });
    }
  }, [isSuccess, responseError]);

  useEffect(() => {
    if (accountType === "tutor") {
      setIsTutorChecked(true);
      setBgColor1("bg-primary");
    } else if (accountType === "student") {
      setIsStudentChecked(true);
      setBgColor2("bg-primary");
    } else {
      setIsTutorChecked(true);
      setBgColor1("bg-primary");
      setIsStudentChecked(true);
      setBgColor2("bg-primary");
    }
  }, [accountType]);

  const handleTutorChecked = (e) => {
    const checked = e.target.checked;
    setButtonDisabled(!checked);
    setIsTutorChecked(checked);

    if (checked) setBgColor1("bg-primary");
    else setBgColor1("");
  };

  const handleStudentChecked = (e) => {
    const checked = e.target.checked;
    setButtonDisabled(!checked);
    setIsStudentChecked(checked);
    if (checked) setBgColor2("bg-primary");
    else setBgColor2("");
  };

  useEffect(() => {
    if (!isTutorChecked && !isStudentChecked) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
      if (isTutorChecked && isStudentChecked) {
        setAccType("both");
      } else if (isTutorChecked) {
        setAccType("tutor");
      } else {
        setAccType("student");
      }
    }
  }, [isTutorChecked, isStudentChecked]);

  const handleAccountSetup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    await updateGeneralProfile(firstName, lastName, accType);
    setButtonDisabled(false);
  };
  const [isEdit, setIsEdit] = useState(false);

  const handleEditIcon = () => setIsEdit(!isEdit);

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row className="">
            <Col sm="9">
              <h3 className="tab_title">General profile settings</h3>
            </Col>
            <Col sm="3" style={{ textAlign: "-webkit-right" }}>
              <button
                onClick={handleEditIcon}
                className={`${
                  isEdit ? "d-none" : "btn btn-default btn-sm text-primary"
                }`}
              >
                Edit
              </button>
            </Col>

            <FormGroup className={`form-group ${isEdit ? "d-none" : ""}`}>
              <div className="row mb-1 pt-3">
                <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                  Name
                </Label>
                <Col sm="6">
                  {firstName} {lastName}
                </Col>
                <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
              </div>
              <div className="row mb-1 py-3">
                <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
                  Member Type
                </Label>
                <Col sm="6">{accountType.toUpperCase()}</Col>
                <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
              </div>
            </FormGroup>
            <FormGroup className={`form-group ${isEdit ? "" : "d-none"}`}>
              <div className={`mega-inline row border_bottom`}>
                <div className="row pb-3">
                  <label className="form-label">First Name</label>
                  <InputGroup>
                    <span className="input-group-text">
                      <User />
                    </span>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      placeholder="Your First Name"
                      value={firstName}
                      autoComplete="off"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <div className="row pb-3">
                  <label className="form-label">Last Name</label>
                  <InputGroup>
                    <span className="input-group-text">
                      <User />
                    </span>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      placeholder="Your Last Name"
                      autoComplete="off"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <Row>
                  <Col sm="6">
                    <Card className={`mb-0 ${bgColor1}`}>
                      <Media className="p-3">
                        <div className="checkbox checkbox-dark">
                          <input
                            id="inline-1"
                            type="checkbox"
                            checked={isTutorChecked}
                            value="tutor"
                            onChange={handleTutorChecked}
                          />
                          <label htmlFor="inline-1" className="mt-0 text-white">
                            Tutor Account
                          </label>
                        </div>
                      </Media>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card className={`mb-0 ${bgColor2}`}>
                      <Media className="p-3">
                        <div className="checkbox checkbox-dark">
                          <Input
                            id="inline-2"
                            type="checkbox"
                            checked={isStudentChecked}
                            value="student"
                            onChange={handleStudentChecked}
                          />
                          <label htmlFor="inline-2" className="mt-0 text-white">
                            Student Account
                          </label>
                        </div>
                      </Media>
                    </Card>
                  </Col>
                </Row>
                <div className="row pt-2">
                  <Label
                    className="col-sm-3 col-form-label pt-0"
                    htmlFor=""
                  ></Label>
                  <div class="col-md-9">
                    <div class="d-flex" style={{ float: "right" }}>
                      <button
                        className="btn btn-sm btn-warning mx-2"
                        onClick={(e) => setIsEdit(!isEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-sm btn-info w-30"
                        disabled={isButtonDisabled || isLoading}
                        onClick={(e) => handleAccountSetup(e)}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end d-none">
          <Btn
            attrBtn={{
              color: "warning",
              className: "m-r-15",
              disabled: isLoading,
              type: "submit",
              onClick: (e) => handleAccountSetup(e),
            }}
          >
            Update Changes
          </Btn>
          {/* <Btn attrBtn={{ color: "light", type: "submit" }}>{Cancel}</Btn> */}
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default GeneralProfile;
