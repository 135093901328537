import React, { Fragment } from "react";
import Inbox from "../../../Component/Chat/NewChat/Inbox";

const InboxContain = () => {
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Chat" title=" Chat App" /> */}
      <div className="pt-2">
        <Inbox />
      </div>
    </Fragment>
  );
};
export default InboxContain;
