import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ContactMemberModal from "../../Chat/NewChat/ContactMemberModal";
import About from "./About";
import Experience from "./Experience";
import Feedback from "./Feedback";
import PreferredLanguage from "./PreferredLanguage";
import Qualification from "./Qualification";
import Rating from "./Rating";
import Schedule from "./Schedule";
import SubjectAndPricing from "./SubjectAndPricing";

const NewTutorProfileContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const [basicInfo, setBasicInfo] = useState({});
  const [educationInfo, setEducationInfo] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [interestPricingData, setInterestPricingData] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const [fullname, setFullName] = useState("");
  const [profile, setProfile] = useState(man);
  const [profile2, setProfile2] = useState("");
  const [isModal, setIsModal] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const initilindex = { index: 0, isOpen: false, toggler: toggle };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const navigate = useNavigate();

  const params = useParams();
  const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
  const id = params.id === "me" ? userData.id : params.id;

  //tutorInterestPricing
  useEffect(() => {
    const getMemberInfo = async () => {
      var res = await axiosRequest({
        url: "/newMember/singleMemberInfo/" + id,
        method: "post",
        data: {
          type: "student"
        }
      });
      if (res) {
        if (res.data) {
          setEducationInfo(res.data.educations);
          setWorkExperience(res.data.workExperiences);
          setSchedules(res.data.schedules);
          setInterestPricingData(res.data.interestAndPricing);
          var obj = {
            name: res.data.name,
            gender: res.data.gender,
            address: res.data.address,
            email: res.data.email,
            media: res.data.media,
            about: res.data.about,
            languages: res.data.languages,
            contactNo: res.data.contactNo,
            createdAt: res.data.createdAt,
          };
          setBasicInfo(obj);

          if (res.data.name) {
            setFullName(res.data.name.firstName + " " + res.data.name.lastName);
          }

          const pro_pic = localStorage.getItem("picture2");

          var profUrl =
            res.data.media.picture === "" || res.data.media.picture === null
              ? pro_pic.includes("googleusercontent")
                ? JSON.parse(pro_pic)
                : ""
              : res.data.media.picture.includes("googleusercontent")
                ? res.data.media.picture
                : ProfilePictureRootURL + res.data.media.picture;
          setProfile2(profUrl);
        } else {
          const pro_pic = localStorage.getItem("picture2");
          if (pro_pic.includes("googleusercontent")) {
            setProfile2(JSON.parse(pro_pic));
          } else {
            setProfile2("");
          }
        }
      }
    };
    getMemberInfo();
  }, []);

  const handleStartConversationModal = (e) => {
    e.preventDefault();
    setPhotoIndex({
      ...photoIndex,
      isOpen: true,
      userId: id,
      name: fullname,
      tuitions: interestPricingData,
    });
    setIsModal(true);
  };
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });

  useEffect(() => {
    if (memberType === "student" && params.id === "me") {
      navigate(`${process.env.PUBLIC_URL}/student-profile/me`);
    }
  }, [memberType]);
  const tutorInstitueName = educationInfo;
  const tutorlastInstitueName = tutorInstitueName?.slice(-1);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <div>
            <Card>
              <CardBody className="d-flex align-items-center">
                <img
                  style={{ width: "150px", height: "150px" }}
                  className="rounded-circle me-2"
                  alt="tutor profile"
                  src={profile2 !== "" ? profile2 : profile}
                />

                <div className="" style={{ flex: "auto" }}>
                  <CardTitle tag="h3" className="cardBox-2">
                    {fullname}
                  </CardTitle>
                  {tutorlastInstitueName?.map((item, index) => (
                    <CardText className="mb-0" key={index}>
                      Studied at {item.institute}
                    </CardText>
                  ))}

                  <CardText>
                    <span className="mb-0">
                      Lives in {basicInfo?.address?.city}
                      {","} {basicInfo?.address?.country}
                    </span>
                  </CardText>
                </div>
                {params.id !== "me" && (
                  <div style={{ placeSelf: "flex-end" }}>
                    <button
                      type="submit"
                      className="btn btn-warning text-white ms-1 p-2"
                      disabled={false}
                      onClick={(e) => handleStartConversationModal(e)}
                    >
                      Contact Tutor
                    </button>
                  </div>
                )}
              </CardBody>
              {isModal && (
                <ContactMemberModal
                  photoIndex={photoIndex}
                  setPhotoIndex={callback}
                />
              )}
            </Card>
            <Row className="d-flex">
              <Col sm="4">
                <About data={basicInfo} fullname={fullname} />
                <PreferredLanguage data={basicInfo.languages} />
                <Qualification data={educationInfo} title="Qualifications" />
                <Experience data={workExperience} />
                <Rating />
              </Col>
              <Col sm="8">
                <SubjectAndPricing data={interestPricingData} />
                <Schedule data={schedules} />
                <Feedback fullname={fullname} tutorPhoto={profile2} />
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewTutorProfileContain;
