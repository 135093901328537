import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "reactstrap";
import { H4, P, Image } from "../../../AbstractElements";
import axios from "axios";
import { RegisterApi } from "../../../api";
import { SignIn } from "../../../Constant";

const VarifyRegisterAgain = () => {
  const [validURL, setvalidURL] = useState(false);
  const [message, setMessage] = useState("Reactivating Your Account...");
  const params = useParams();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        const url = `${RegisterApi}/email-verify/${params.id}/verifyAgain/${params.token}`;
        console.log(url);
        const response = await axios.get(url);
        if (response.data == "updated") {
          setvalidURL(true);
        }
      } catch (error) {
        setvalidURL(false);
        setMessage("404 NOT FOUND!");
      }
    };

    verifyEmailUrl();
  }, []);

  return (
    <Fragment>
      <section>
        <Container fluid={true} className="p-0">
          <Row className="m-0">
            <Col xl="12 p-0">
              <div className="login-card1">
                <Form className="theme-form login-form">
                  <div style={{ "text-align": "center" }}>
                    <Image
                      attrImage={{
                        // className: "w-50",
                        alt: "img",
                        src: "https://i.ibb.co/9T0CHzt/tute-logo-1.png",
                      }}
                    />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  {validURL ? (
                    <div style={{ "text-align": "center" }}>
                      <H4 attrH4={{ className: "mb-3" }}>
                        ACCOUNT ACTIVATED SUCCESSFULLY
                      </H4>

                      <P>
                        Click here to
                        <Link to={`${process.env.PUBLIC_URL}/signin`}>
                          <a className="ms-2" href="#">
                            {SignIn}
                          </a>
                        </Link>
                      </P>
                    </div>
                  ) : (
                    <div style={{ "text-align": "center" }}>
                      <H4 attrH4={{ className: "mb-3" }}>{message}</H4>
                    </div>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default VarifyRegisterAgain;
