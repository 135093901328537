import React, { Fragment, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ConfigDB from "../Config/Theme-Config";
import AnimationThemeContext from "../_helper/AnimationTheme";
import CheckContext from "../_helper/customizer";
import ProductContext from "../_helper/ecommerce/product";
import Footer from "./Footer/index";
import Header from "./Header";
import Loader from "./Loader";
import SideBarLayout from "./SideBar-Layout";
import TapTop from "./TapTop/index";
toast.configure();
const Layout = ({ children, classNames, ...rest }) => {
  const {
    sidebar_types,
    settings,
    toggleIcon,
    setToggleIcon,
    defaultClass,
    setDefaultClass,
  } = useContext(CheckContext);
  const { setIsVertical } = useContext(ProductContext);
  const settings1 = localStorage.getItem("sidebar_Settings") || settings;
  const sidebar_types1 = localStorage.getItem("sidebar_types") || sidebar_types;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;
  window.addEventListener("resize", () => {
    if (window.innerWidth - 440 <= 575) {
      setToggleIcon(true);
    } else {
      setToggleIcon(false);
    }
    if (window.innerWidth <= 1200) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
    if (window.innerWidth <= 992) {
      setDefaultClass(true);
    } else setDefaultClass(false);
  });
  const navigate = useNavigate();

  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));

  if (!auth0_profile) {
    // toastMessage("Your session has been timout!", "warn");
    navigate(`${process.env.PUBLIC_URL}/signin`);
  }

  return (
    <Fragment>
      <Loader />
      <TapTop />
      <div
        className={`page-wrapper ${
          !defaultClass ? sidebar_types1 : "compact-wrapper"
        } ${settings1}`}
        id="pageWrapper"
      >
        <div className={`page-header ${toggleIcon ? "close_icon" : ""}`}>
          <Header />
        </div>
        <div className="page-body-wrapper">
          <div className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`}>
            <div>
              <SideBarLayout />
            </div>
          </div>
          <div className="page-body">
            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                unmountOnExit
              >
                <div>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      {/* <Themecustomizer /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default Layout;
