import React, { Fragment, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Media,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { H6, P } from "../../../AbstractElements";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import { axiosRequest } from "../../../redux/utils/axios-utils";

export default function SecurityAndPrivacySettings() {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [isEdit, setIsEdit] = useState(false);
  const [isLoadin, setIsLoading] = useState(false);
  const [isContinued, setIsContinued] = useState(false);
  const [passwordNull, setPasswordNull] = useState(false);
  const [password, setPassword] = useState("");
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  //const handleEditIcon = () => setIsEdit(!isEdit);
  const handleCheckBox = (event) => {
    if (event.target.checked) {
      setIsChecked(event.target.checked);
      setPassword("passwordNull");
    } else {
      setIsChecked(!isChecked);
      setPassword("");
    }
  };
  const handleDeleteAccountButton = async () => {
    setIsEdit(!isEdit);
    var response = await axiosRequest({
      url: "/newMember/settings/password-null-check",
      method: "post",
      data: {
        call: true,
      },
    });
    if (response?.status === 200) {
      setPasswordNull(response?.data?.passwordNull);
    }
  };

  const handleContinueDeactivation = async () => {
    setIsContinued(true);
  };
  const handleIsDeactivate = () => {
    setIsDelete(false);
    setIsDeactivate(true);
  };
  const handleIsDelete = () => {
    setIsDelete(true);
    setIsDeactivate(false);
  };

  const handleAccountDeactivation = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once changed, you will redirect to sign in page!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        var response = await axiosRequest({
          url: "/newMember/settings/update-account",
          method: "post",
          data: {
            password,
            isDeactivate,
            isDelete,
          },
        });
        if (response?.status === 200 && response?.data?.message) {
          SweetAlert.fire({
            title: response.data.message,
            icon: "success",
          });
          dispatch(clearMemberMenus());
          localStorage.clear();
          navigate(`${process.env.PUBLIC_URL}/signin`);
        } else {
          SweetAlert.fire({
            title: response.data.message,
            icon: "error",
          });
        }
      } else {
        SweetAlert.fire("You are safe! No changes");
      }
    });
  };

  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row>
            <Col sm="9">
              <h3 className="tab_title">Security & Privacy</h3>
            </Col>
            <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
          </Row>
          <div className={`row mb-1 pt-3 ${isEdit ? "d-none" : ""}`}>
            <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={(e) => {
                  handleDeleteAccountButton(e);
                }}
              >
                Deactivation & Deletion
              </button>
            </Label>
            <Col sm="7">
              <p>Temporarily deactivate or permanently delete your account.</p>
            </Col>
            <Col sm="2" style={{ textAlign: "-webkit-right" }}>
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={(e) => {
                  handleDeleteAccountButton(e);
                }}
              >
                View
              </button>
            </Col>
          </div>
          <Row className={`${!isEdit ? "d-none" : ""}`}>
            <Col sm="12" xl="12">
              <Card className="height-equal">
                <CardHeader className="pb-0">
                  <h4>{"Deactivating or deleting your TuteAir account"}</h4>
                  <p>
                    If you want to take a break from TuteAir, you can deactivate
                    your account. If you want to permanently delete your TuteAir
                    account, let us know.
                  </p>
                </CardHeader>
                <CardBody>
                  <Form
                    className={`mega-horizontal ${isContinued ? "d-none" : ""}`}
                  >
                    <Row>
                      <Col sm="12">
                        <Card className="mb-3">
                          <Media
                            className={`p-20 ${
                              isDeactivate ? "bg_deactivated" : ""
                            }`}
                          >
                            <div className="radio radio-primary me-3">
                              <Input
                                id="radio30"
                                type="radio"
                                name="radio1"
                                value="option1"
                                onChange={(e) => handleIsDeactivate()}
                              />
                              <Label for="radio30"></Label>
                            </div>
                            <Media body>
                              <H6
                                attrH6={{ className: "mt-0 mega-title-badge" }}
                              >
                                Deactivate account
                              </H6>
                              <p className="mb-0">This can be Temporary</p>
                              <P>
                                Your account will be disabled and your name and
                                others informations will be removed from most
                                things you've shared. Account will be active if
                                you log in again.
                              </P>
                            </Media>
                          </Media>
                        </Card>
                      </Col>
                      {passwordNull && (
                        <Col sm="12">
                          <Card className="mb-0">
                            <Media
                              className={`p-20  ${isDelete ? "bg_delete" : ""}`}
                            >
                              <div className="radio radio-primary me-3">
                                <Input
                                  id="radio31"
                                  type="radio"
                                  name="radio1"
                                  value="option1"
                                  onChange={(e) => handleIsDelete()}
                                />
                                <Label for="radio31"></Label>
                              </div>
                              <Media body>
                                <H6
                                  attrH6={{
                                    className: "mt-0 mega-title-badge",
                                  }}
                                >
                                  {"Delete Account"}
                                </H6>
                                <p className="mb-0">This is permanent</p>
                                <P>
                                  When you delete your TuteAir account, you
                                  won't be able to retrieve the content or
                                  information you've shared on TuteAir.
                                </P>
                              </Media>
                            </Media>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Form>
                  <Row className={`${!isContinued ? "d-none" : ""}`}>
                    {passwordNull && (
                      <Col sm="8" xl="8" className="offset-md-2">
                        <FormGroup>
                          <label className="form-label">
                            {
                              "The page you are trying to visit requires that you re-enter your password."
                            }
                          </label>
                          <InputGroup>
                            <span className="input-group-text">
                              <Lock />
                            </span>
                            <Input
                              className="form-control"
                              type={false ? "text" : "password"}
                              required=""
                              placeholder="*********"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                    {!passwordNull && (
                      <Col sm="8" xl="8" className="offset-md-2">
                        <FormGroup>
                          <label className="form-label">
                            {
                              "The page you are trying to visit requires you to re-enter your password. since you log in with goggle, you didn't set any password.Do you want to continue without password??"
                            }
                          </label>
                          <Col
                            md={4}
                            className="mt-2 ms-2 d-flex align-items-center"
                          >
                            <Input
                              className="form-control"
                              type="checkbox"
                              checked={isChecked === true}
                              onChange={(e) => {
                                handleCheckBox(e);
                              }}
                            />
                            <Label className="col-form-label" htmlFor="">
                              &nbsp;&nbsp;Yes
                            </Label>
                          </Col>
                        </FormGroup>
                      </Col>
                    )}
                    <Col sm="8" xl="8" className="offset-md-2">
                      <button
                        className="btn btn-warning"
                        onClick={(e) => {
                          setIsContinued(!isContinued);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-info mx-3"
                        disabled={password === "" ? true : false}
                        onClick={handleAccountDeactivation}
                      >
                        Continue {isDelete ? "deletion" : "deactivation"}
                      </button>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter
                  className={`text-end ${isContinued ? "d-none" : ""}`}
                >
                  <button
                    className="btn btn-warning mx-2"
                    onClick={(e) => setIsEdit(!isEdit)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary w-30"
                    disabled={!isDeactivate && !isDelete}
                    onClick={(e) => handleContinueDeactivation(e)}
                  >
                    Continute to account{" "}
                    {isDelete ? "deletion" : "deactivation"}
                  </button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
}
