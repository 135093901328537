import React from "react";
import { Card, CardTitle } from "reactstrap";

export default function PreferredLanguage(props) {
  return (
    <div>
      <Card body className="mb-3">
        <CardTitle tag="h4">Preferred Language</CardTitle>
        <div>
          <CardTitle className="">
            <span>
              {props.data?.map((item, index) => (
                <span
                  className="badge badge-light-primary"
                  style={{ margin: "0px 3px 2px 0px" }}
                  key={index}
                >
                  {item}
                </span>
              ))}
            </span>
          </CardTitle>
        </div>
      </Card>
    </div>
  );
}
