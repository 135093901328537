import React, { Fragment } from "react";
import { MoreHorizontal } from "react-feather";
import { Card, CardBody, CardHeader, Col, Media, Table } from "reactstrap";
import { H5, LI, UL } from "../../../AbstractElements";

const RecentActivitys = () => {
  return (
    <Fragment>
      <Col xl="6" md="6" className="dash-31 dash-xl-50 box-col-12">
        <Card className="recent-activity">
          <CardHeader className="card-no-border">
            <Media className="media-dashboard">
              <Media body>
                <H5 attrH5={{ className: "mb-0" }}>Recent Activity</H5>
              </Media>
              <div className="icon-box onhover-dropdown">
                <MoreHorizontal />
                <div className="icon-box-show onhover-show-div">
                  <UL>
                    <LI>
                      <a href="#javascript">Latest </a>
                    </LI>
                    <LI>
                      <a href="#javascript">Earlist</a>
                    </LI>
                  </UL>
                </div>
              </div>
            </Media>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="table-responsive-sm">
              <div className="table-border-horizontal">
                <Table className="table-bordernone">
                  <tbody>
                    <tr>
                      <td className="text-center py-5">
                        No Recent Activities are found
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default RecentActivitys;
