import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";

import { useSelector } from "react-redux";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import FilterOptionsContain from "../../FindTutors/ListView/FilterOptions";
import MapViewContain from "../../FindTutors/MapView";
// import EducationFilter from "./EducationFilter";
import { Filter, List, MapPin, RefreshCcw, X } from "react-feather";
import ReactPaginate from "react-paginate";
import {
  filterByEducation,
  filterByLanguage,
  filterByPrice,
  filterBySchedule,
  filterBySubject,
  filterBySubjectCategory,
  filterByTuitionType,
  filterByWorkExperience,
} from "../../../Services/TutorFilter.Service";
import QuickSearchPublic from "../Hero-Section/QuickSearch";
import TutorListView from "./TutorListView";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";
export default function AllTutorList() {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    scheduleFilter,
    setScheduleFilter,
    educationFilter,
    setEducationFilter,
    workExperiencesFilter,
    setWorkExperiencesFilter,
    priceFilter,
    setPriceFilter,
    // myFilter: filters,
    // setMyFilter: setFilters,
  } = useContext(FilterContext);

  const {
    tutorList: tutors2,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
    countries,
    publicTutors: tutors,
    setPublicTutors,
    showPublicTutors,
    setShowPublicTutors,
    getPublicTutors,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredTutors = showPublicTutors?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "student" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );

  const showTutors = allFilteredTutors.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredTutors?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const fetchFilteredTutors = async () => {
    setLoading(true);

    var filteredTutors = [...tutors];
    // filtering starts here
    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.media?.picture?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }

        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    if (filters.scheduleFilter) {
      filteredTutors = await filterBySchedule(
        filteredTutors,
        filters.scheduleFilter
      );
    }
    if (filters.educationFilter) {
      filteredTutors = await filterByEducation(
        filteredTutors,
        filters.educationFilter
      );
    }
    if (filters.workExperiencesFilter) {
      filteredTutors = await filterByWorkExperience(
        filteredTutors,
        filters.workExperiencesFilter
      );
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter);
    }
    // filtering ends here
    setShowPublicTutors(filteredTutors);
    // setTutorsToShow(filteredTutors);
    setLoading(false);
  };

  // useEffect(() => {
  //   fetchFilteredTutors();
  // }, [filters]);

  useEffect(() => {
    setLoading(true);

    if (tutors?.length === 0) {
      getPublicTutors();
    }

    if (location?.country) filters.location = location;

    if (subject?.length > 0) filters.subject = subject;

    fetchFilteredTutors();

    // if (tutors.length === 0 && !filters) GetAllTutors();
    setLoading(false);

    return () => {
      //setTutorList(tutors);
    };
  }, [filters]);

  const updateFilter = (a) => {
    if (a === 1) setIsSearched(!isSearched);
    setIsReset(false);
    console.log(location);

    const addedFilter = {};

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages?.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location)?.length != 0) {
      addedFilter.location = location;
    }
    if (tutionType?.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject?.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter)?.length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter)?.length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(scheduleFilter)?.length) {
      addedFilter.scheduleFilter = scheduleFilter;
    }
    if (Object.keys(educationFilter)?.length) {
      addedFilter.educationFilter = educationFilter;
    }
    if (Object.keys(workExperiencesFilter)?.length) {
      addedFilter.workExperiencesFilter = workExperiencesFilter;
    }
    if (Object.keys(priceFilter)?.length) {
      addedFilter.priceFilter = priceFilter;
    }
    setFilters(addedFilter);
  };

  const handleSearch = () => {
    setIsSearched(!isSearched);
  };

  const handleRefresh = () => {
    getPublicTutors();
  };

  const [isListView, setIsListView] = useState(true);
  const [isMapViewView, setIsMapView] = useState(false);

  const handleListView = (e) => {
    e.preventDefault();
    setIsListView(true);
    setIsMapView(false);
  };
  const handleMapView = (e) => {
    e.preventDefault();
    setIsMapView(true);
    setIsListView(false);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    getPublicTutors();
  };

  return (
    <Fragment>
      <Container fluid={true} className="pt-2">
        <div
          className={`row form-inline my-3 mx-0 search_tutor pt-4 ${
            isSearched ? "d-none" : ""
          }`}
        >
          <QuickSearchPublic isReset={isReset} countries={countries} />
          <div className="col-xxl-1 d-flex mb-3">
            <button
              type="reset"
              className="btn btn-outline-info"
              onClick={(e) => updateFilter(1)}
            >
              Search
            </button>
            <button
              className="btn btn-sm btn-outline-warning px-2 d-none"
              style={{
                marginLeft: "50px",
                width: "120px",
                textAlign: "-webkit-match-parent",
                fontSize: "14px",
              }}
            >
              <Filter height={17} />
              <span style={{ position: "absolute", marginTop: "0px" }}>
                Filter More
              </span>
            </button>
          </div>
        </div>

        <Row className="mb-0 py-2">
          <Col sm="6" className="quick_search">
            <div
              className={`square-product-setting d-inline-block ${
                !isSearched ? "d-none" : ""
              }`}
              style={{ paddingRight: "20px" }}
            >
              <button
                className="btn btn-sm btn-outline-warning"
                onClick={handleSearch}
              >
                Show Quick Search
              </button>
            </div>
          </Col>

          <Col
            sm="6"
            className="px-3 view_list"
            style={{ textAlign: "-webkit-right" }}
          >
            <div className={`square-product-setting d-inline-block`}>
              <a
                className={`icon-grid m-0 list-layout-view`}
                onClick={(e) => handleRefresh(e)}
                href="#javascript"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Refresh List"
              >
                <RefreshCcw />
              </a>
            </div>
            <div className={`mx-2 square-product-setting d-inline-block`}>
              <a
                className={`icon-grid m-0 list-layout-view  ${
                  isListView ? "icon_visited" : ""
                }`}
                onClick={(e) => handleListView(e)}
                href="#javascript"
                data-toggle="tooltip"
                data-placement="bottom"
                title="List View"
              >
                <List />
              </a>
            </div>
            <div className={`square-product-setting d-inline-block`}>
              <a
                className={`icon-grid grid-layout-view  ${
                  isMapViewView ? "icon_visited" : ""
                }`}
                onClick={(e) => handleMapView(e)}
                href="#javascript"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Map View"
              >
                <MapPin />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="d-none">
          <Col sm="12">
            Filtered By:{" "}
            <span className="px-2">
              <span className="text-primary">
                Bangladesh
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Dhaka
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Physics
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">Clear All</span>
            </span>
          </Col>
        </Row>
        <Row className="mb-5 tutorViewResponsive">
          {isListView && <TutorListView tutors={showTutors} />}
          {isMapViewView && <MapViewContain />}

          <Col xl="4" className="filterListResponsive mt-0">
            <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              <Button
                color="primary"
                className="mb-2 "
                onClick={(e) => updateFilter(0)}
              >
                Apply Filter
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {isListView && allFilteredTutors?.length !== 0 && (
              <div className="mt-5 mb-5 pagination" style={{ float: "left" }}>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  //disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
