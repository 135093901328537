import React, { Fragment, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Lock } from "react-feather";
import {
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Row,
} from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import { CreateAccount, Done } from "../../../Constant";
import axios from "axios";
import { CreatePasswordURL } from "../../../api";

const CreatePwd = () => {
  const [togglePassword, setTogglePassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState(
    "minimum 8 characters, at least 1 letter, 1 number and 1 special character"
  );

  const fnIsValidPassword = (string) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(
      string
    );
  const handleConfirmPasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };
  const handlePasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };

  const fnValidatePwd = () => {
    if (password !== confirmPassword) {
      setShowErrorMessage(true);
      setIsCPasswordDirty(true);
    } else {
      setIsCPasswordDirty(false);
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const params = useParams();
  const handlePassSubmission = async (e) => {
    e.preventDefault();
    setLoading(true);
    const password_object = {
      id: params.id,
      token: params.token,
      password: password,
    };

    const response = await axios.post(CreatePasswordURL, password_object);

    if (response.data) {
      setLoading(false);
      window.location.href = `${process.env.PUBLIC_URL}/signin`;
    }
  };

  return (
    <Fragment>
      <section>
        <Container fluid={true} className="p-0">
          <Row className="m-0">
            <Col xl="12 p-0">
              <div className="login-card1">
                <Form className="theme-form login-form">
                  <H4 attrH4={{ className: "mb-3" }}>Create Your Password</H4>
                  <FormGroup>
                    <label className="form-label">{"New Password"}</label>
                    <InputGroup>
                      <span className="input-group-text">
                        <Lock />
                      </span>
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        required=""
                        placeholder="*********"
                        value={password}
                        onChange={handlePassword}
                        onKeyUp={handlePasswordKeyUp}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </InputGroup>
                    {showErrorMessage && !isValidPassword ? (
                      <div className="text-danger">{pwdErrorMessage}</div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <FormGroup>
                    <label className="form-label">
                      {"Confirm New Password"}
                    </label>
                    <InputGroup>
                      <span className="input-group-text">
                        <Lock />
                      </span>
                      <Input
                        className="form-control"
                        type={togglePassword ? "text" : "password"}
                        required=""
                        placeholder="*********"
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                        onKeyUp={handleConfirmPasswordKeyUp}
                      />
                      <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </InputGroup>
                    {showErrorMessage && isCPasswordDirty ? (
                      <div className="text-danger">
                        {" "}
                        Passwords did not match{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Btn
                      attrBtn={{
                        className: "btn-block",
                        color: "primary",
                        type: "submit",
                        onClick: handlePassSubmission,
                      }}
                    >
                      {loading ? "Updating..." : Done}
                    </Btn>
                  </FormGroup>
                  <P>
                    Don't have account?
                    <Link to={`${process.env.PUBLIC_URL}/signup`}>
                      <a className="ps-2" href="#javascritp">
                        {CreateAccount}
                      </a>
                    </Link>
                  </P>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default CreatePwd;
