// export const getTopTutors = async () => {
//   var res = await axiosRequest({
//     url: "/users/public/topTutors",
//     method: "get",
//   });
//   if (res.data) {
//     return res.data;
//   }
// };

import { axiosRequest } from "../redux/utils/axios-utils";

export const getPublicTutorsResponse = async () => {
  var res = await axiosRequest({
    url: "/users/public/publicTutors",
    method: "get",
  });
  if (res.data) {
    return res.data;
  } else {
    return [];
  }
};

export const getCurrenciesResponse = async () => {
  var res = await axiosRequest({
    url: "/users/public/currencies",
    method: "get",
  });
  if (res.data) {
    var currList = [];

    res.data.map((item, index) => {
      currList.push({
        value: item._id,
        label: item.code + " - " + item.name,
        code: item.code,
        name: item.name,
        countryId: item.countryId,
      });
    });

    currList = currList.slice().sort((a, b) => a.label.localeCompare(b.label));

    return currList;
  }
};

export const getDegreesResponse = async () => {
  var res = await axiosRequest({
    url: "/users/public/degrees",
    method: "get",
  });
  if (res.data) {
    var result = res.data?.slice().sort((x, y) => {
      return x.sorting - y.sorting;
    });
    var degreeList = [];
    if (result) {
      result.map((item, index) => {
        degreeList.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    return degreeList;
  } else {
    return [];
  }
};

export const getCoursesResponse = async () => {
  var res = await axiosRequest({
    url: "/users/public/courses",
    method: "get",
  });
  if (res.data) {
    var result = res.data?.slice().sort((a, b) => a.name.localeCompare(b.name));
    var courseList = [];
    if (result) {
      result.map((item, index) => {
        courseList.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    return courseList;
  } else {
    return [];
  }
};

export const getLangugaesResponse = async () => {
  var res = await axiosRequest({
    url: "/users/public/languages",
    method: "get",
  });
  if (res.data) {
    var newList = [];
    var len = res.data.length;
    for (var i = 0; i < len; i++) {
      newList.push(res.data[i].name);
    }
    newList.sort();
    return newList;
  } else {
    return [];
  }
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
