import Multiselect from "multiselect-react-dropdown";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col, Input, Label } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";

export default function BasicFilter({ isReset }) {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
  } = useContext(FilterContext);
  const {
    getLangugaes,
    languages: languageList,
    setLanguages: setLanguageList,
  } = useContext(TutorsContext);

  const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);

  useEffect(() => {
    if (isReset) {
      setGender("0");
      setLanguages([]);
      setLanguageSelectedOptions([]);
      setProfilePicExist(false);
    }
  }, [isReset]);

  useEffect(() => {
    if (languageList.length === 0) {
      getLangugaes();
    }
    return () => {
      // setLanguageList(languageList);
    };
  }, []);

  return (
    <Fragment>
      <div className="filter_card_t">
        <div className="row mb-1">
          <h4 className="f_title">
            <span>Basic</span>
          </h4>
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Gender
          </Label>
          <Col sm="9">
            <div className="m-checkbox-inline" style={{ paddingLeft: "6px" }}>
              <div className="radio radio-primary">
                <Input
                  id="radioinline4"
                  type="radio"
                  name="radio1"
                  value="0"
                  onChange={(e) => setGender("0")}
                />
                <Label className="mb-0" for="radioinline4">
                  <span className="digits"> {"All"}</span>
                </Label>
              </div>
              <div className="radio radio-primary">
                <Input
                  id="radioinline1"
                  type="radio"
                  name="radio1"
                  value="male"
                  onChange={(e) => setGender("male")}
                />
                <Label className="mb-0" for="radioinline1">
                  <span className="digits"> {"Male"}</span>
                </Label>
              </div>
              <div className="radio radio-primary">
                <Input
                  id="radioinline2"
                  type="radio"
                  name="radio1"
                  value="female"
                  onChange={(e) => setGender("female")}
                />
                <Label className="mb-0" for="radioinline2">
                  <span className="digits"> {"Female"}</span>
                </Label>
              </div>
              <div className="radio radio-primary">
                <Input
                  id="radioinline3"
                  type="radio"
                  name="radio1"
                  value="other"
                  onChange={(e) => setGender("other")}
                />
                <Label className="mb-0" for="radioinline3">
                  <span className="digits"> {"Other"}</span>
                </Label>
              </div>
            </div>
          </Col>
        </div>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Profile Picture?
          </Label>
          <Col md={9}>
            <Input
              checked={profilePicExist}
              type="checkbox"
              onChange={(e) => setProfilePicExist(e.target.checked)}
            />
          </Col>
        </div>
        <div className="row mb-1">
          <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
            Language
          </Label>
          <Col sm="9">
            <Multiselect
              className="darkMode bg-white"
              isObject={false}
              options={languageList}
              showCheckbox
              selectedValues={selectedLanguageOptions}
              onSelect={(e) => {
                setLanguages(e);
              }}
              onRemove={(e) => {
                setLanguages(e);
              }}
            />
          </Col>
        </div>
        <hr
          color="#337AB7"
          size="5"
          width="92%"
          style={{ position: "absolute", marginTop: "15px" }}
        />
      </div>
    </Fragment>
  );
}
