import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Container, Input, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { SubscribeEmailPostApi } from "../../../api/index";
import "./newsletter.css";
const Newsletter = () => {
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const [style, setStyle] = useState({});
  useEffect(() => {
    setTimeout(function () {
      setStyle({ style: { display: "none" } });
    }, 1000);
  }, []);

  const handleSubscribeEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (subscribeEmail) {
      // var disposeURL = "https://disposable.debounce.io/?email=" + subscribeEmail;
      // const response = await axios.get(disposeURL);
      // // if email is disposable
      // if (response.data.disposable === "true") {
      //   setLoading(false);
      //   toast.error("Temporary email not accepted. Please provide valid email!");
      //   return;
      // }
      try {
        const res = await axios.post(SubscribeEmailPostApi, {
          email: subscribeEmail,
        });
        if (res.status === 201) {
          toast.success(subscribeEmail + " has been subscribed successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setSubscribeEmail("");
          setLoading(false);
        } else if (res.status === 200) {
          toast(subscribeEmail + " already exists", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setLoading(false);
        } else {
          toast.success("Something went wrong!!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setTimeout(() => {
          toast.error("Oppss.. Something went wrong!!.");
        }, 200);
      }
    } else {
      toast("Email can't be empty", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      setLoading(false);
    }
  };
  return (
    <section>
      <Container className="newsletter my-3 pt-5">
        <Row>
          <Col className="col-md-8 offset-md-2 text-center">
            <h2 className="mb-4">Subscribe to Our Newsletter</h2>
            <div className="row">
              <Col sm="9">
                <Input
                  className="form-control"
                  type="email"
                  placeholder="Enter Your Email"
                  value={subscribeEmail}
                  onChange={(e) => setSubscribeEmail(e.target.value)}
                />
              </Col>
              <Col
                sm="3"
                className="btn_subscribe"
                style={{ textAlign: "-webkit-left" }}
              >
                <Btn
                  attrBtn={{
                    className: "btn",
                    type: "button",
                    color: "success",
                    onClick: (e) => handleSubscribeEmail(e),
                  }}
                >
                  {loading ? "LOADING..." : "Subscribe"}
                </Btn>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
