import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import "../../../../style/custom-css/PersonalInfo.css";

import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Btn, Image } from "../../../../AbstractElements";
import {
  LanguagesApi,
  MemberBasicInfoSave,
  ProfilePictureRootURL,
} from "../../../../api";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import MemberInformationContext from "../../../../_helper/MemberInformation";
import PersonalAddressContext from "../../../../_helper/PersonalAddress";
import SetUsersLocation from "../../../FindTutors/SetUsersLocation";

const TutorPersonalInfo = () => {
  const ref = useRef();
  const { accountType } = useSelector((state) => state.auth) || {};
  const [objective, setObjective] = useState("");

  const [loading, setLoading] = useState(false);

  const [languageList, setLanguageList] = useState([]);
  // const [selectedLanguageOptions, setLanguageSelectedOptions] = useState([]);
  const [change, setChange] = useState(false);

  const {
    address1,
    setAddress1,
    address2,
    setAddress2,
    area,
    setArea,
    city,
    setCity,
    state,
    setState,
    postalCode,
    setPostalCode,
    country,
    setCountry,
    latitude,
    longitude,
    getPreviousLocation,
  } = useContext(PersonalAddressContext);

  const {
    address,
    setAddress,
    about,
    setAbout,
    media,
    setmedia,
    dateOfBirth,
    setdateOfBirth,
    fullname,
    setFullName,
    gender,
    setGender,
    email,
    setEmail,
    phone,
    setPhone,
    languages,
    setLanguages,
    memberType,
    setMemberType,
    getMemberInformation,
    selectedLanguageOptions,
    setLanguageSelectedOptions,
    profileUrl,
    selectedFile,
    documentID,
    setProfileUrl,
    setSelectedFile,
    setDocumentID,
    getDegreeAndCoursesList,
  } = useContext(MemberInformationContext);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const { memberType: membertypeCurrent } =
    useSelector((state) => state.menu) || {};
  useEffect(() => {
    if (membertypeCurrent) {
      if (membertypeCurrent === "student") {
        setObjective(about.studentBio);
      } else {
        setObjective(about.tutorBio);
      }
    }
  }, [membertypeCurrent, about]);

  useEffect(() => {
    fetchLanguages();
    const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
    var memberType = localStorage.getItem("swMember");
    setFullName(userData.name);
    setEmail(userData.email);
    setMemberType(memberType);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data !== "") {
      alert("You submitted the form and stuff!");
    } else {
      errors.showMessages();
    }
  };

  const markFormChange = () => setChange(true);

  const fetchLanguages = async () => {
    const res = await axios.get(LanguagesApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    var newList = [];
    var len = res.data.length;
    for (var i = 0; i < len; i++) {
      newList.push(res.data[i].name);
    }
    newList.sort();
    setLanguageList(newList);
  };

  function deleteFile() {
    setSelectedFile([]);
    setProfileUrl("");
    setDocumentID("");
    ref.current.value = "";
  }

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const onFilesChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    if (isValidFileUploaded(e.target.files[0])) {
      setSelectedFile(e.target.files[0]);
      setDocumentID("");
      setProfileUrl(window.URL.createObjectURL(e.target.files[0]));
    } else {
      toastNotifiy("File type is not accepted", "warn");
      e.target.value = null;
    }
  };

  const handledateOfBirthChange = (date) => {
    var dateOfBirth = new Date(date);
    setdateOfBirth(dateOfBirth);
  };

  const isValidateForm = () => {
    if (gender === "") {
      toastNotifiy("Gender Field Required!", "warn");
      return false;
    } else if (dateOfBirth === "") {
      toastNotifiy("Birthdate Field Required!", "warn");
      return false;
    } else if (phone === "") {
      toastNotifiy("Phone Field Required!", "warn");
      return false;
    } else if (objective === "") {
      toastNotifiy("Objective Field Required!", "warn");
      return false;
    } else if (!languages || languages.length === 0) {
      toastNotifiy("Language Field Required", "warn");
      return false;
    } else if (memberType === "") {
      toastNotifiy("Account Type Field Required!", "warn");
      return false;
    } else if (address1 === "") {
      toastNotifiy("Address Line 1 is required!", "warn");
      return false;
    } else if (city === "") {
      toastNotifiy("Select City is required!", "warn");
      return false;
    } else if (postalCode === "") {
      toastNotifiy("Post Code Type Field Required!", "warn");
      return false;
    } else if (country === "") {
      toastNotifiy("Country is required!", "warn");
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const pro_pic = localStorage.getItem("picture2");
    if (pro_pic.includes("googleusercontent")) {
      setDocumentID("89233275832");
    }
    getDegreeAndCoursesList();
    var isMember = JSON.parse(localStorage.getItem("isMemberExists"));

    if (isMember) {
      getMemberInformation();
      let selectedlanguages = [];
      for (var i = 0; i < languages.length; i++) {
        selectedlanguages.push(languages[i]);
      }
      setLanguages(selectedlanguages?.map((value) => value));
      setLanguageSelectedOptions(selectedlanguages);
      getPreviousLocation();
    }
  }, []);

  useEffect(() => {}, [languages]);

  const handleUpdateTutorPersonalInfo = async (e) => {
    e.preventDefault();
    if (isValidateForm()) {
      if (window.confirm("Are you sure to save this information?")) {
        setLoading(true);
        const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
        var memberType = userData?.accountType;
        const addressObject = {
          address1: address1,
          address2: address2,
          area: area,
          city: city,
          postalCode: postalCode,
          state: state,
          country: country,
          latitude: latitude,
          longitude: longitude,
        };

        var currentMemberType = localStorage.getItem("swMember");
        const aboutObject = {
          tutorBio: currentMemberType == "tutor" ? objective : about.tutorBio,
          studentBio: currentMemberType == "student" ? objective : about.studentBio,
        };

        const contactNoObject = {
          code: "+880",
          number: phone,
        };

        var len = languages.length;
        const formData = new FormData();
        formData.append("gender", gender);
        formData.append("doB", dateOfBirth);
        formData.append("contactNo", JSON.stringify(contactNoObject));
        formData.append("about", JSON.stringify(aboutObject));
        formData.append("memberType", memberType);
        formData.append("document", selectedFile);
        formData.append("documentID", documentID);
        formData.append("address", JSON.stringify(addressObject));

        for (var i = 0; i < len; i++) {
          formData.append("languages", languages[i]);
        }

        // https://dev-tuteair-backend-app-service.azurewebsites.net/document/document//6316e3b1b82d83ced7c47fd9
        /*
      1. document = [1] && documentID = "" => First Time Save [Save]
      // Already exists
      2. document = [] && documentID = "6316e3b1b82d83ced7c47fd9" => No Update [Update]
      3. document = [] && documentID = "" => Update with blank [Delete]
      */

        const res = await axios.post(MemberBasicInfoSave, formData, {
          headers: {
            Authorization: authHeader(),
          },
        });
        if (res.status === 200) {
          if (res.data && res.data._id) {
            toastNotifiy("Successfully Updated Personal Info", "success");
            setChange(false);
            if (media.picture !== "89233275832") {
              localStorage.setItem(
                "picture2",
                JSON.stringify(ProfilePictureRootURL + res.data.media.picture)
              );
            }
            getMemberInformation();
          } else {
            toastNotifiy("Phone number duplicate, please change", "warn");
          }
          setLoading(false);
        } else if (res.status === 201) {
          if (res.data && res.data._id) {
            toastNotifiy("Successfully Created Personal Info", "success");
            setChange(false);
            localStorage.setItem("isMemberExists", true);
            if (media.picture !== "" && documentID !== "89233275832") {
              localStorage.setItem(
                "picture2",
                JSON.stringify(ProfilePictureRootURL + res.data.media.picture)
              );
            }
            getMemberInformation();
          } else {
            toastNotifiy(res.data.message, "warn");
          }
          setLoading(false);
        } else {
          toastNotifiy("Something went wrong!!", "danger");
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    markFormChange();
  }, [country, city]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="py-1"></CardHeader>
        <CardBody className="rounded py-3 mb-2">
          <Form
            className="needs-validation"
            onSubmit={handleSubmit(onSubmit)}
            onChange={markFormChange}
          >
            <Row>
              <Col sm="12" xl="6">
                <div className="row mb-1">
                  <Label
                    className="col-sm-3 col-form-label asterisk "
                    htmlFor=""
                  >
                    Account Type
                  </Label>
                  <Col sm="9">
                    <Input
                      type="text"
                      // name="select"
                      disabled
                      className="form-control"
                      // onChange={(e) => handleChangeMemberType(e.target.value)}
                      value={
                        accountType === "both"
                          ? "BOTH"
                          : memberType.toUpperCase()
                      }
                    ></Input>
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label
                    className="col-sm-3 col-form-label asterisk "
                    htmlFor="inputEmail3"
                  >
                    Fullname
                  </Label>
                  <Col sm="9">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Full Name"
                      disabled
                      value={fullname === null ? "" : fullname}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label
                    className="col-sm-3 col-form-label pt-0 asterisk "
                    htmlFor=""
                  >
                    Gender
                  </Label>
                  <Col sm="9">
                    <div
                      className="m-checkbox-inline"
                      style={{ paddingLeft: "6px" }}
                    >
                      <div className="radio radio-primary">
                        <Input
                          id="radioinline1"
                          type="radio"
                          name="radio1"
                          value="male"
                          checked={gender === "male"}
                          onChange={handleGenderChange}
                        />
                        <Label className="mb-0" for="radioinline1">
                          <span className="digits"> {"Male"}</span>
                        </Label>
                      </div>
                      <div className="radio radio-primary">
                        <Input
                          id="radioinline2"
                          type="radio"
                          name="radio1"
                          value="female"
                          checked={gender === "female"}
                          onChange={handleGenderChange}
                        />
                        <Label className="mb-0" for="radioinline2">
                          <span className="digits"> {"Female"}</span>
                        </Label>
                      </div>
                      <div className="radio radio-primary">
                        <Input
                          id="radioinline3"
                          type="radio"
                          name="radio1"
                          value="other"
                          checked={gender === "other"}
                          onChange={handleGenderChange}
                        />
                        <Label className="mb-0" for="radioinline3">
                          <span className="digits"> {"Other"}</span>
                        </Label>
                      </div>
                    </div>
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label
                    className="col-sm-3 col-form-label asterisk "
                    htmlFor=""
                  >
                    Date Of Birth
                  </Label>
                  <Col sm="9">
                    <DatePicker
                      className="form-control digits"
                      selected={dateOfBirth}
                      onChange={handledateOfBirthChange}
                      peekNextMonth
                      maxDate={new Date()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      dateFormat="dd-MMM-yyyy"
                    />
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label asterisk ">
                    Email
                  </Label>
                  <Col sm="9">
                    <Input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      disabled
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label asterisk ">
                    Phone
                  </Label>
                  <Col sm="9">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Phone No"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label asterisk">
                    Objective
                  </Label>
                  <Col sm="9">
                    <Input
                      type="textarea"
                      className="form-control"
                      value={objective}
                      onChange={(e) => setObjective(e.target.value)}
                      rows="3"
                      maxLength={1000}
                    />
                  </Col>
                </div>

                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label asterisk">
                    Language
                  </Label>
                  <Col sm="9">
                    <Multiselect
                      className="darkMode"
                      isObject={false}
                      options={languageList}
                      showCheckbox
                      selectedValues={selectedLanguageOptions}
                      onSelect={(e) => {
                        setLanguages(e);
                      }}
                      onRemove={(e) => {
                        setLanguages(e);
                      }}
                    />
                  </Col>
                </div>

                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label" htmlFor="">
                    Profile Picture
                  </Label>
                  <Col sm="6">
                    <input
                      type="file"
                      className="form-control"
                      onChange={onFilesChange}
                      ref={ref}
                      accept="image/*"
                      title="Choose a Profile Picture"
                    />
                  </Col>
                  <Col sm="3 files-dropzone fileContainer">
                    {profileUrl && (
                      <div>
                        <Btn
                          attrBtn={{
                            className: "btn delete-button me-2",
                            color: "danger",
                            type: "button",
                            onClick: (e) => deleteFile(e),
                          }}
                        >
                          Delete
                        </Btn>
                        <Image
                          attrImage={{
                            className: "w-50",
                            alt: "img",
                            src: `${profileUrl}`,
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </div>

                <div className="row mb-1 ">
                  <Label className="col-sm-2 col-form-label"></Label>
                </div>
                <div className="row mb-1 ">
                  <Label className="col-sm-2 col-form-label"></Label>
                </div>
                <div className="row mb-1 ">
                  <Label className="col-sm-2 col-form-label"></Label>
                </div>
              </Col>

              <Col sm="12" xl="6">
                <div className="row mb-1">
                  <Label
                    style={{ color: "red" }}
                    className="col-sm-12 col-form-label"
                    htmlFor=""
                  >
                    <b>
                      {" "}
                      {latitude == 0 && longitude == 0 ? (
                        <i>
                          Please allow your location from browser settings to
                          visualize the map here and set your location on map.
                        </i>
                      ) : (
                        <i>
                          Search your location on map to auto-complete address
                          fields.
                        </i>
                      )}
                    </b>
                  </Label>
                </div>

                <div className="row mb-1">
                  <SetUsersLocation />
                </div>

                <div className="row mb-1">
                  <Label
                    className="col-sm-3 col-form-label asterisk "
                    htmlFor=""
                  >
                    Address Line 1
                  </Label>

                  <Col sm="9">
                    <Input
                      type="text"
                      name="select"
                      value={address1 === null || undefined ? "" : address1}
                      className="form-control"
                      onChange={(e) => setAddress1(e.target.value)}
                      placeholder=""
                    ></Input>
                  </Col>
                </div>

                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label" htmlFor="">
                    Address Line 2
                  </Label>

                  <Col sm="9">
                    <Input
                      type="text"
                      name="select"
                      value={address2 === null || undefined ? "" : address2}
                      className="form-control"
                      onChange={(e) => setAddress2(e.target.value)}
                      placeholder=""
                    ></Input>
                  </Col>
                </div>

                <div className="row mb-1">
                  <Label className="col-sm-3 col-form-label" htmlFor="">
                    Area
                  </Label>

                  <Col sm="9">
                    <Input
                      type="text"
                      name="select"
                      value={area === null || undefined ? "" : area}
                      className="form-control"
                      onChange={(e) => setArea(e.target.value)}
                      placeholder="[Optional]"
                    ></Input>
                  </Col>
                </div>

                <div className="row mb-1">
                  <Label
                    className="col-sm-2 col-form-label asterisk "
                    htmlFor=""
                  >
                    City
                  </Label>
                  <Col sm="4">
                    <Input
                      type="text"
                      name="select"
                      className="form-control"
                      value={city === null || undefined ? "" : city}
                      onChange={(e) => setCity(e.target.value)}
                    ></Input>
                  </Col>
                  <Label
                    className="col-sm-2 col-form-label asterisk "
                    htmlFor=""
                  >
                    Post Code
                  </Label>
                  <Col sm="4">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder=""
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    />
                  </Col>
                </div>
                <div className="row mb-1">
                  <Label className="col-sm-2 col-form-label" htmlFor="">
                    State
                  </Label>

                  <Col sm="4">
                    <Input
                      type="text"
                      name="select"
                      value={state === null || undefined ? "" : state}
                      className="form-control"
                      onChange={(e) => setState(e.target.value)}
                    ></Input>
                  </Col>

                  <Label
                    className="col-sm-2 col-form-label asterisk "
                    htmlFor=""
                  >
                    Country
                  </Label>

                  <Col sm="4">
                    <Input
                      type="text"
                      name="select"
                      value={country === null || undefined ? "" : country}
                      className="form-control"
                      onChange={(e) => setCountry(e.target.value)}
                    ></Input>
                  </Col>
                </div>
                <div className="row mb-1 mt-4 align-right">
                  <Label className="col-sm-8 col-form-label"></Label>
                  <Col sm="4">
                    <Btn
                      attrBtn={{
                        className: "btn-block",
                        color: "success",
                        type: "submit",
                        disabled: change ? (loading ? loading : loading) : true,
                        // disabled: true,
                        onClick: (e) => handleUpdateTutorPersonalInfo(e),
                      }}
                    >
                      {loading ? "LOADING..." : "Save changes"}
                    </Btn>
                  </Col>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default TutorPersonalInfo;
