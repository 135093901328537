import React, { useContext, useEffect, useState } from "react";
import { getTrackBackground, Range } from "react-range";
import Select from "react-select";
import { Col, Input, Label, Row } from "reactstrap";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";

export default function EducationFilter({ isReset }) {
  const { getDegrees, degrees, setDegrees, getCourses, courses, setCourses } =
    useContext(TutorsContext);

  const { educationFilter, setEducationFilter } = useContext(FilterContext);

  const [institute, setInstitute] = useState("");
  const [grade, setGrade] = useState([]);
  const [passingYear, setPassingYear] = useState({});
  const [passingYearList, setPassingYears] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  // new
  const [selectedDegree, setSelectedDegree] = useState();
  const [selectedCourse, setSelectedCourse] = useState();

  useEffect(() => {
    if (degrees.length === 0) getDegrees();
    if (courses.length === 0) getCourses();
    fnPassingYearList(83, "prev");
    return () => {
      // setDegrees(degrees);
      // setCourses(courses);
    };
  }, []);

  const fnPassingYearList = (count, type) => {
    const year = new Date().getFullYear();
    let years = [];
    if (type === "next") {
      years = Array.from(new Array(count), (val, index) => year + index);
    } else {
      years = Array.from(new Array(count), (val, index) => year - index);
    }

    var yearList = [];
    if (years) {
      years?.map((item, index) => {
        yearList.push({
          value: item,
          label: item,
          isDisabled: false,
        });
      });
    }
    setPassingYears(yearList);
  };

  const fnSetDegree = (item) => {
    setSelectedDegree(item);
    setEducationFilter((prev) => ({ ...prev, degree: item.value }));
  };

  const fnSetCourse = (item) => {
    setSelectedCourse(item);
    setEducationFilter((prev) => ({ ...prev, course: item.value }));
  };

  const fnSetInstitute = (value) => {
    setInstitute(value);
    setEducationFilter((prev) => ({ ...prev, institute: value }));
  };

  const fnSetGrade = (value) => {
    setGrade(value);
    setEducationFilter((prev) => ({ ...prev, grade: value }));
  };

  const fnSetPassingYear = (item) => {
    setPassingYear(item);
    setEducationFilter((prev) => ({ ...prev, passingYear: item.value }));
  };

  const MIN = 0;
  const MAX = 100;
  const STEP = (MAX - MIN) / 20;
  const [values, setValues] = useState([MIN + 10, MAX]);

  const MINYEAR = 1940;
  const MAXYEAR = 2027;
  const STEPYEAR = 1;
  const [valueYear, setValueYear] = useState([MINYEAR + 10, MAXYEAR - 2]);

  useEffect(() => {
    if (values[0] == 10 && values[1] == 100) {
      // do nothing
    } else {
      fnSetGrade(values);
    }
  }, values);

  const initializeEducationFilter = () => {
    setEducationFilter({});
    setSelectedCourse({});
    setSelectedDegree({});
    setInstitute("");
    setValues([MIN + 10, MAX]);
  };

  useEffect(() => {
    if (isReset) {
      initializeEducationFilter();
    }
  }, [isReset]);

  //console.log(educationFilter)

  return (
    <div className="filter_card">
      <h4 className="f_title">
        <span>Education</span>
      </h4>
      <Row>
        <Col sm="12" xl="12">
          <div className="row mb-2">
            <Label className="col-sm-2 col-form-label " htmlFor="inputEmail3">
              Degree
            </Label>
            <Col sm="10">
              <Select
                options={degrees}
                placeholder="Select Degree"
                className="js-example-basic-single col-sm-12 darkMode"
                selecte
                onChange={(item) => fnSetDegree(item)}
                value={selectedDegree}
              />
            </Col>
          </div>
          <div className="row mb-2">
            <Label className="col-sm-2 col-form-label " htmlFor="inputEmail3">
              Course
            </Label>
            <Col sm="10">
              <Select
                options={courses}
                placeholder="Select Course"
                className="js-example-basic-single col-sm-12 darkMode"
                onChange={(item) => fnSetCourse(item)}
                value={selectedCourse}
              />
            </Col>{" "}
          </div>
          <div className="row mb-2">
            <Label className="col-sm-2 col-form-label pt-0 " htmlFor="">
              Prefered Institute
            </Label>
            <Col sm="10">
              <Input
                type="text"
                name=""
                placeholder="Enter Your Institute"
                className="form-control"
                value={institute}
                onChange={(e) => fnSetInstitute(e.target.value)}
              />
            </Col>
          </div>
          <div className="row mb-2">
            <Label className="col-sm-2 col-form-label " htmlFor="inputEmail3">
              Result (%)
            </Label>
            <Col md="10">
              <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(values) => {
                  setValues(values);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: "36px",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <output style={{ marginTop: "10px" }}>{values[0]}</output>
                    <div
                      ref={props.ref}
                      style={{
                        height: "5px",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values,
                          colors: ["#ccc", "#4d8aff", "#ccc"],
                          min: MIN,
                          max: MAX,
                        }),
                        alignSelf: "center",
                      }}
                    >
                      {children}
                    </div>
                    <output style={{ marginTop: "10px" }}>{values[1]}</output>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "25px",
                      width: "25px",
                      borderRadius: "30px",
                      backgroundColor: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 2px 6px #AAA",
                    }}
                  >
                    <div
                      style={{
                        height: "16px",
                        width: "5px",
                        backgroundColor: isDragged ? "#4d8aff" : "#CCC",
                      }}
                    />
                  </div>
                )}
              />
            </Col>
          </div>
          {/* <div className="row mb-2">
            <Label
              className="col-sm-2 col-form-label "
              htmlFor="inputEmail3"
            >
              Completed Year
            </Label>
            <Col md='6'>
              <Range
                values={valueYear}
                step={STEPYEAR}
                min={MINYEAR}
                max={MAXYEAR}
                onChange={values => {
                  setValueYear(values);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                      ...props.style,
                      height: '36px',
                      display: 'flex',
                      width: '100%'
                    }}
                  >
                    <output style={{ marginTop: '10px' }}>
                      {valueYear[0]}
                    </output>
                    <div
                      ref={props.ref}
                      style={{
                        height: '5px',
                        width: '100%',
                        borderRadius: '4px',
                        background: getTrackBackground({
                          values, colors: ['#ccc', '#4d8aff', '#ccc'],
                          min: MINYEAR, max: MAXYEAR
                        }),
                        alignSelf: 'center'
                      }}>{children}</div>
                    <output style={{ marginTop: '10px' }}>{valueYear[1]}</output>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div {...props} style={{
                    ...props.style, height: '25px',
                    width: '25px', borderRadius: '30px',
                    backgroundColor: '#FFF', display: 'flex',
                    justifyContent: 'center', alignItems: 'center',
                    boxShadow: '0px 2px 6px #AAA'
                  }} >
                    <div style={{
                      height: '16px', width: '5px',
                      backgroundColor: isDragged ? '#4d8aff' : '#CCC'
                    }} />
                  </div>
                )}
              />
            </Col>
            <Col sm="4">
              <div className="row" style={{ placeItems: "center" }}>
                <Col sm="2">
                  <Input
                    id=""
                    type="checkbox"
                    checked={inProgress === true}
                    onChange={(event) => {
                      checkboxHandler(event.target.checked);
                    }}
                  />
                </Col>
                <Label
                  className="col-sm-10 col-form-label"
                  htmlFor="inputEmail3"
                >
                  In Progress
                </Label>
              </div>
            </Col>
          </div> */}
        </Col>
      </Row>
    </div>
  );
}
