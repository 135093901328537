import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import NewTutorTuitionsContain from "../../../Component/NewMyTuitions/Tutor";

const NewTutorTuitions = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="MyTuitions" title="" />
      <NewTutorTuitionsContain />
    </Fragment>
  );
};

export default NewTutorTuitions;
