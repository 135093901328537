import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepZilla from "react-stepzilla";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import TutorPersonalInfo from "./BasicInfo/TutorPersonalInfo";
import TutorEduInfo from "./Education/TutorEduInfo";
import TutorPricing from "./InterestPricing/TutorPricing";
import TutorSchedule from "./Schedule/TutorSchedule";
import TutorExperience from "./WorkExperience/TutorExperience";

const TutorFormWizardContain = () => {
  const steps = [
    { name: "Personal Info", component: <TutorPersonalInfo /> },
    { name: "Educational Info", component: <TutorEduInfo /> },
    { name: "Working Experience", component: <TutorExperience /> },
    { name: "Schedule", component: <TutorSchedule /> },
    { name: "Interest & Pricing", component: <TutorPricing /> },
  ];
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "student") {
      navigate(`${process.env.PUBLIC_URL}/student-account`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader className="pb-0">
                <H5>{FormWizardWithIcon}</H5>
              </CardHeader> */}
              <CardBody className="pt-1">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                  preventEnterSubmission={true}
                  nextButtonText="Next"
                  nextButtonCls="btn btn-prev btn-primary btn-lg pull-right"
                  backButtonText="Previous"
                  backButtonCls="btn btn-next btn-primary btn-lg pull-left"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TutorFormWizardContain;
