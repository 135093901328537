import React, { useState } from "react";
import SweetAlert from "sweetalert2";
import { getEmailVerifiedOrNot } from "../../Component/LandingPage/BecomeTutor/fnBecomeTutor";
import { axiosRequest } from "../../redux/utils/axios-utils";
import Context from "./index";

const BecomeTutorProvider = (props) => {
  const [step, setStep] = useState(0);
  const [isMemberExists, setIsMemberExists] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [interestPricing, setInterestPricing] = useState({});
  const [interestPricingList, setInterestPricingList] = useState([]);

  const [schedule, setSchedule] = useState({});
  const [scheduleList, setScheduleList] = useState([]);

  const [education, setEducation] = useState({});
  const [educationList, setEducationList] = useState([]);

  const [experience, setExperience] = useState({});
  const [experienceList, setExperienceList] = useState([]);

  const [basicInfo, setBasicInfo] = useState({
    gender: "",
    doB: new Date("01-01-2000"),
    contactNo: {
      code: "+880",
      number: "",
    },
    language: [],
    about: {
      tutorBio: "",
      studentBio: "",
    },
  });
  const [addresses, setAddresses] = useState({});

  const [profilePhoto, setProfilePhoto] = useState("");

  var _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  const getTopTutors = async () => {
    var res = await axiosRequest({
      url: "/users/public/topTutors",
      method: "get",
    });
    if (res.data) {
    }
  };

  const getLoggedTutorInfo = async (userId) => {
    if (profilePhoto === "") {
      const _photo = localStorage.getItem("_userPhoto");
      if (_photo) setProfilePhoto(_photo);
    }

    var res = await axiosRequest({
      url: "/public/singleTutor/" + userId,
      method: "get",
    });

    if (res.data) {
      var tutor = res.data;
      if (res.data.email) {
        setBasicInfo({
          ...basicInfo,
          gender: tutor.gender ? tutor.gender : "",
          doB: tutor.doB ? new Date(tutor.doB) : new Date("01-01-2000"),
          contactNo: {
            code: tutor.contactNo?.code ? tutor.contactNo?.code : "",
            number: tutor?.contactNo?.number ? tutor?.contactNo?.number : "",
          },
          language: tutor?.languages ? tutor?.languages : [],
          about: {
            tutorBio: tutor.about?.tutorBio ? tutor.about?.tutorBio : "",
            studentBio: tutor.about?.studentBio ? tutor.about?.studentBio : "",
          },
        });
      } else {
        console.warn("nothing found");
      }
      setIsEmailVerified(tutor?.emailVerified);

      if (tutor?.interestAndPricing?.length > 0) {
        setInterestPricingList(tutor?.interestAndPricing);

        saveCurrentStepToSession(2);
      }

      if (tutor?.schedules?.length > 0) {
        setScheduleList(tutor?.schedules);
        saveCurrentStepToSession(3);
      }

      if (res.data?.address) {
        setAddresses(res.data.address);
        //saveCurrentStepToSession(5);
      }

      if (tutor?.educations?.length > 0) {
        setEducationList(tutor?.educations);
        saveCurrentStepToSession(6);
      }

      if (tutor?.workExperiences?.length > 0) {
        setExperienceList(tutor?.workExperiences);
        saveCurrentStepToSession(7);
      }
    } else {
      clearBecomeTutorContext();
      // console.log(step);
      // setIsMemberExists(false);
    }
  };

  const saveCurrentStepToSession = (value) => {
    setStep(value);
    if (_tuteair) {
      localStorage.setItem(
        "_tuteair_newTutor",
        JSON.stringify({
          ..._tuteair,
          currentStep: value,
        })
      );
    }
  };

  const handleSkipOrNext = (e) => {
    if (step > 6) setStep(1);

    if (e === "next") setStep(step + 1);
    if (e === "skip") setStep(step + 1);
    if (e === "back") setStep(step - 1);
  };

  const handleEmailVerified = async (e, step, navigate) => {
    e.preventDefault();
    var result = await getEmailVerifiedOrNot();

    if (result?.emailVerified) {
      if (profilePhoto !== "") {
        SweetAlert.fire({
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: true,
          title: "Welcome to TuteAir",
          text: "Thank you for joining us. Visit TuteAir frequently to explore and interact. We'll be adding a lot more features daily.",
          icon: "success",
          confirmButtonText: "Go Dashboard",
        }).then((result) => {
          if (result.value) {
            setIsEmailVerified(true);
            localStorage.setItem(
              "_tuteair_newTutor",
              JSON.stringify({
                ..._tuteair,
                emailVerified: true,
              })
            );
            navigate(`${process.env.PUBLIC_URL}/dashboard`);
          }
        });
      } else {
        SweetAlert.fire({
          title: "Please upload your photo",
          icon: "warning",
        });
      }
    } else {
      SweetAlert.fire({
        title: "Email not verified yet!",
        icon: "error",
      });
      setIsEmailVerified(false);
    }
  };
  const clearBecomeTutorContext = () => {
    setStep(0);
    setInterestPricing({});
    setInterestPricingList([]);
    setSchedule({});
    setScheduleList([]);
    setEducation({});
    setEducationList([]);
    setExperience({});
    setExperienceList([]);
    setBasicInfo({
      gender: "",
      doB: new Date("01-01-2000"),
      contactNo: {
        code: "+880",
        number: "",
      },
      language: [],
      about: {
        tutorBio: "",
        studentBio: "",
      },
    });
    setAddresses({});
    setProfilePhoto("");
  };
  return (
    <Context.Provider
      value={{
        ...props,
        step,
        setStep,
        getLoggedTutorInfo,
        interestPricingList,
        setInterestPricingList,
        interestPricing,
        setInterestPricing,
        schedule,
        setSchedule,
        scheduleList,
        setScheduleList,
        basicInfo,
        setBasicInfo,
        handleSkipOrNext,
        addresses,
        setAddresses,
        saveCurrentStepToSession,
        education,
        setEducation,
        educationList,
        setEducationList,
        experience,
        setExperience,
        experienceList,
        setExperienceList,
        profilePhoto,
        setProfilePhoto,
        clearBecomeTutorContext,
        isMemberExists,
        setIsMemberExists,
        handleEmailVerified,
        isEmailVerified,
        setIsEmailVerified,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
export default BecomeTutorProvider;
