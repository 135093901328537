import React from "react";
import { Card } from "reactstrap";

export default function InterestToLearn(props) {
  return (
    <Card body>
      <div className="table-responsive-sm">
        <table className="table table-border-horizontal">
          <thead>
            <tr className="h4">
              <td colSpan={2}>Interest To Learn</td>
            </tr>
            <tr style={{ background: "#dbdbdb2b" }}>
              <th>Subjects</th>
              <th>Degree</th>
            </tr>
          </thead>
          <tbody>
            {props?.data?.length === 0 && (
              <tr>
                <td colSpan={2} className="text-center">
                  No Info Found
                </td>
              </tr>
            )}
            {props.data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <span>
                    {item.subjects?.map((item, index) => (
                      <span
                        className="badge badge-light-primary"
                        style={{ margin: "0px 3px 2px 0px" }}
                        key={index}
                      >
                        {item.name}
                      </span>
                    ))}
                  </span>
                </td>
                <td>{item.classes?.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
