import React, { Fragment, useState } from "react";
import { Lock } from "react-feather";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import "./AccountSettings.css";
import { updateChangedPassword } from "./accountSettingsApi";

const ChangePassword = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);

  const [prevPassword, setPrevPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState(
    "minimum 8 characters, at least 1 letter, 1 number and 1 special character"
  );

  const fnIsValidPassword = (string) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(
      string
    );
  const handleAccountSetup = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    await updateChangedPassword(prevPassword, password, confirmPassword);
    setButtonDisabled(false);
  };

  const handleConfirmPasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };
  const handlePasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
    fnValidatePwd();
  };

  const fnValidatePwd = () => {
    if (password !== confirmPassword) {
      setButtonDisabled(true);
      setShowErrorMessage(true);
      setIsCPasswordDirty(true);
    } else {
      setIsCPasswordDirty(false);
      setButtonDisabled(false);
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row className="">
            <h3 className="tab_title">Change Password</h3>
            <FormGroup>
              <label className="form-label">{"Current Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type="password"
                  required=""
                  autoComplete="off"
                  placeholder="Enter Current Password"
                  value={prevPassword}
                  onChange={(e) => setPrevPassword(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <label className="form-label">{"New Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type={togglePassword ? "text" : "password"}
                  required=""
                  placeholder="*********"
                  value={password}
                  onChange={handlePassword}
                  onKeyUp={handlePasswordKeyUp}
                />
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  <span className={togglePassword ? "" : "show"}></span>
                </div>
              </InputGroup>
              {showErrorMessage && !isValidPassword ? (
                <div className="text-danger">{pwdErrorMessage}</div>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-label">{"Confirm New Password"}</label>
              <InputGroup>
                <span className="input-group-text">
                  <Lock />
                </span>
                <Input
                  className="form-control"
                  type={togglePassword ? "text" : "password"}
                  required=""
                  placeholder="*********"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  onKeyUp={handleConfirmPasswordKeyUp}
                />
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  <span className={togglePassword ? "" : "show"}></span>
                </div>
              </InputGroup>
              {showErrorMessage && isCPasswordDirty ? (
                <div className="text-danger"> Passwords did not match </div>
              ) : (
                ""
              )}
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Btn
            attrBtn={{
              color: "warning",
              className: "m-r-15",
              disabled: isButtonDisabled,
              type: "submit",
              onClick: (e) => handleAccountSetup(e),
            }}
          >
            Update Changes
          </Btn>
          {/* <Btn attrBtn={{ color: "light", type: "submit" }}>{Cancel}</Btn> */}
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default ChangePassword;
