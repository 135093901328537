import { ProfilePictureRootURL } from "../../../api";
import { apiSlice } from "../api/apiSlice";
import { memberMenus } from "../menus/menuSlice";
import { userAcconuntVisible, userLoggedIn } from "./authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "/register",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          localStorage.setItem(
            "tuteAirUser",
            JSON.stringify({
              accessToken: result.data.accessToken,
              user: result.data.user,
            })
          );

          dispatch(
            userLoggedIn({
              accessToken: result.data.accessToken,
              user: result.data.user,
            })
          );
        } catch (err) {
          // do nothing
        }
      },
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/users/login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          if (result.data.accountType === "both") {
            localStorage.setItem("swMember", "tutor");
            dispatch(memberMenus({ memberType: "tutor" }));
          } else {
            localStorage.setItem("swMember", result.data.accountType);
            dispatch(memberMenus({ memberType: result.data.accountType }));
          }

          const pro_pic_url =
            ProfilePictureRootURL + result.data.profilePicture;

          localStorage.setItem(
            "tuteAirUser",
            JSON.stringify({
              name: result.data.firstName + " " + result.data.lastName,
              accessToken: result.data.token,
              id: result.data._id,
              picture: pro_pic_url,
              email: result.data.email,
              autenticated: true,
              memberType: result.data.memberType,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              accountType: result.data.accountType,
            })
          );

          localStorage.setItem("picture2", JSON.stringify(pro_pic_url));
          localStorage.setItem(
            "isMemberExists",
            JSON.stringify(result.data.isMember)
          );

          dispatch(
            userLoggedIn({
              accessToken: result.data.token,
              user: result.data._id,
              photo: result.data.profilePicture,
              accountType: result.data.accountType,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              isAccountHide: result.data.isAccountHide,
            })
          );
          dispatch(
            userAcconuntVisible({
              isAccountHide: result.data.isAccountHide,
            })
          );
          localStorage.setItem("isAccountHide", result.data.isAccountHide);
        } catch (err) {
          // do nothing
        }
      },
    }),
    loginNew: builder.mutation({
      query: (data) => ({
        url: "/public/login-v2",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          if (result.data.accountType === "both") {
            localStorage.setItem("swMember", "tutor");
            dispatch(memberMenus({ memberType: "tutor" }));
          } else {
            localStorage.setItem("swMember", result.data.accountType);
            dispatch(memberMenus({ memberType: result.data.accountType }));
          }

          const pro_pic_url =
            ProfilePictureRootURL + result.data.profilePicture;

          localStorage.setItem(
            "tuteAirUser",
            JSON.stringify({
              name: result.data.firstName + " " + result.data.lastName,
              accessToken: result.data.token,
              id: result.data._id,
              picture: pro_pic_url,
              email: result.data.email,
              autenticated: true,
              memberType: result.data.memberType,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              accountType: result.data.accountType,
            })
          );

          dispatch(
            userLoggedIn({
              accessToken: result.data.token,
              user: result.data._id,
              photo: result.data.profilePicture,
              accountType: result.data.accountType,
              firstName: result.data.firstName,
              lastName: result.data.lastName,
              isAccountHide: result.data.isAccountHide,
            })
          );
          dispatch(
            userAcconuntVisible({
              isAccountHide: result.data.isAccountHide,
            })
          );

          localStorage.setItem("isAccountHide", result.data.isAccountHide);

          // var img64 = ""; // await getBase64FromUrl(ProfilePictureRootURL + result.data?.profilePicture);

          // if (
          //   result.data?.profilePicture &&
          //   result.data?.profilePicture !== " "
          // ) {
          //   img64 = await getBase64FromUrl(
          //     ProfilePictureRootURL + result.data?.profilePicture
          //   );
          // } else {
          //   img64 = await getBase64FromUrl(man);
          // }

          // localStorage.setItem("_userPhoto", img64);

          localStorage.setItem(
            "_tuteair_newTutor",
            JSON.stringify({
              currentStep: 1,
              name: result.data?.firstName + " " + result.data?.lastName,
              accountType: result.data.accountType,
              accessToken: result?.data?.token,
              email: result.data.email,
              _id: result.data._id,
              prev_photo: result.data?.profilePicture,
            })
          );
        } catch (err) {
          // do nothing
        }
      },
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLoginNewMutation } =
  authApi;
