// import Blank from "./Blank";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { unseenConversations } from "../../../../redux/features/chat/conversations/conversationsSlice";
import { useGetMessagesQuery } from "../../../../redux/features/chat/messages/messagesApi";
import Error from "../Error";
import ChatHead from "./ChatHead";
import Messages from "./Messages";
import SendChat from "./SendChat";

export default function ChatBody(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth) || {};
  const { unseensData } = useSelector((state) => state.conversations) || {};
  const {
    data: result,
    isLoading,
    isError,
    error,
  } = useGetMessagesQuery(id, user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && unseensData?.length > 0) {
      var updatedUnseens = unseensData?.filter(
        (x) =>
          x.isSeen === false && x.conversationId !== id && x.updatedBy !== user
      );
      dispatch(
        unseenConversations({
          type: "ALL",
          unseensData: updatedUnseens,
        })
      );
    }
  }, []);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (!isLoading && isError) {
    content = (
      <div>
        <Error message={error?.data} />
      </div>
    );
  } else if (!isLoading && !isError && result.messages?.length === 0) {
    content = <div>No messages found!</div>;
  } else if (!isLoading && !isError && result.messages?.length > 0) {
    content = (
      <>
        <ChatHead conversationId={id} />
        <Messages messages={result.messages} />
        <SendChat conversationId={id} />
      </>
    );
  }

  return (
    <Row className="chat-box">
      <Col className="chat-right-aside">
        <div className="chat">{content}</div>
      </Col>
    </Row>
  );
}
