import React, { Fragment, useState } from "react";
import { HelpCircle } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap";
import { Btn, H5, H6, P } from "../../../AbstractElements";
import { faqList } from "../../FAQ/FaqList";
import "./faq.css";

const MinFaqs = () => {
  let faq = faqList;
  faq = faq.sort((x, y) => {
    return x.sort - y.sort;
  });
  const [isActivity, setIsActivity] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const handleShowLess = (e, value) => {
    e.preventDefault();
    setExpanded(value);
  };
  const handelChange = (e) => {
    let collapseCopy = [...isActivity];
    collapseCopy[e] = !collapseCopy[e];
    setIsActivity(collapseCopy);
  };
  return (
    <Fragment>
      <Container id="faqs">
        <div className="faq-wrap py-0">
          <Row className="" style={{ paddingTop: "80px" }}>
            <h1 className="text-center pb-4">
              Frequently Ask & Questions - FAQs
            </h1>
            <Col lg="12">
              <Row
                className="row default-according style-1 faq-accordion"
                id="accordionoc"
              >
                <Col lg="10" className="offset-md-1">
                  <div className="header-faq py-2 my-0">
                    {/* <h3 className="mb-0">Quick Questions and Answered</h3> */}
                  </div>
                  {faq &&
                    faq.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          <div>
                            {i === 0 && (
                              <div>
                                <div className="faq-title">
                                  <H6>{item.subTitle}</H6>
                                </div>
                                {faq[i].titless.map((item, k) => {
                                  return (
                                    <Card key={item.id}>
                                      <CardHeader>
                                        <H5 attrH5={{ className: "mb-0" }}>
                                          <Btn
                                            attrBtn={{
                                              color: "link ps-0",
                                              onClick: () =>
                                                handelChange(item.id),
                                              datatoggle: "collapse",
                                              datatarget: "#collapseicon14",
                                              ariaexpanded: "true",
                                              ariacontrols: "collapseicon14",
                                            }}
                                          >
                                            <HelpCircle className="" />
                                            <span className="px-4">
                                              {" "}
                                              {item.title}
                                            </span>
                                          </Btn>
                                        </H5>
                                      </CardHeader>
                                      <Collapse isOpen={isActivity[item.id]}>
                                        <CardBody>
                                          <P>{item.answer}</P>
                                        </CardBody>
                                      </Collapse>
                                    </Card>
                                  );
                                })}
                                <div
                                  className={`text-center my-1 ${
                                    expanded ? "d-none" : ""
                                  }`}
                                >
                                  <a
                                    href="#show"
                                    className="text-center f-18 text-underline"
                                    style={{ width: "auto" }}
                                    onClick={(e) =>
                                      handleShowLess(e, !expanded)
                                    }
                                  >
                                    Show More
                                  </a>
                                </div>
                              </div>
                            )}
                            <div className={expanded ? "" : "d-none"}>
                              {i !== 0 && (
                                <div className="faq-title">
                                  <H6>{item.subTitle}</H6>
                                </div>
                              )}
                              {i !== 0 &&
                                faq[i].titless.map((item, k) => {
                                  return (
                                    <Card key={item.id}>
                                      <CardHeader>
                                        <H5 attrH5={{ className: "mb-0" }}>
                                          <Btn
                                            attrBtn={{
                                              color: "link ps-0",
                                              onClick: () =>
                                                handelChange(item.id),
                                              datatoggle: "collapse",
                                              datatarget: "#collapseicon14",
                                              ariaexpanded: "true",
                                              ariacontrols: "collapseicon14",
                                            }}
                                          >
                                            <HelpCircle className="" />
                                            <span className="px-4">
                                              {" "}
                                              {item.title}
                                            </span>
                                          </Btn>
                                        </H5>
                                      </CardHeader>
                                      <Collapse isOpen={isActivity[item.id]}>
                                        <CardBody>
                                          <P>{item.answer}</P>
                                        </CardBody>
                                      </Collapse>
                                    </Card>
                                  );
                                })}
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  <div
                    className={`text-center my-1 ${expanded ? "" : "d-none"}`}
                  >
                    <a
                      href="#show"
                      className="text-center f-18 text-underline"
                      style={{ width: "auto" }}
                      onClick={(e) => handleShowLess(e, !expanded)}
                    >
                      Show Less
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default MinFaqs;
