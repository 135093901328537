import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import { LI } from "../../../AbstractElements";
import { ServerURL } from "../../../api";
import { ChatSvg } from "../../../Data/svgIcons";
import { useGetConversationsQuery } from "../../../redux/features/chat/conversations/conversationsApi";
import { unseenConversations } from "../../../redux/features/chat/conversations/conversationsSlice";
import { toastBottomRight } from "../../../redux/utils/tuteairCommon";
import "./Notif.css";

const ChatIcon = () => {
  const { user: userId } = useSelector((state) => state.auth) || {};
  const { unseensData } = useSelector((state) => state.conversations) || {};
  const dispatch = useDispatch();

  let { data: conversations, isSuccess } = useGetConversationsQuery(userId);

  const [myUnseenConversations, setUnseenConversations] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      var unseens = conversations?.filter(
        (x) => x.isSeen === false && x.updatedBy !== userId
      );
      dispatch(
        unseenConversations({
          type: "ALL",
          unseensData: unseens,
        })
      );
      setUnseenConversations(unseensData);
    }

    return () => {
      setUnseenConversations([]);
    };
  }, [isSuccess]);

  useEffect(() => {
    const socket = io(ServerURL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttemps: 10,
      transports: ["websocket"],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    });

    const realtimeConversations = async () => {
      try {
        socket.on("conversation", (data) => {
          // console.log(data, data.data?.receiverId, userId, conversations);
          if (data.data?.receiverId === userId) {
            var previous = [...conversations];

            if (data.data?.status === "updated") {
              const updatedData = previous.map((x) =>
                x.conversationId === data.data?._id
                  ? {
                      ...x,
                      isSeen: false,
                      message: data.data?.message,
                      updatedBy: data.data?.updatedBy,
                      updatedTime: data.data?.updatedAt,
                      receiverId: data.data?.receiverId,
                    }
                  : x
              );
              var updatedUnseens = updatedData?.filter(
                (x) => x.isSeen === false && x.updatedBy !== userId
              );
              setUnseenConversations(updatedUnseens);
              dispatch(
                unseenConversations({
                  type: "ALL",
                  unseensData: updatedUnseens,
                })
              );
              toastBottomRight("You have one new message", "success");
            } else if (data.data?.status === "created") {
              toastBottomRight("You have one new message", "success");
              var newConversations = [...conversations];
              newConversations.push(data.data);
              var unseensConv = newConversations?.filter(
                (x) => x.isSeen === false && x.updatedBy !== userId
              );
              dispatch(
                unseenConversations({
                  type: "ALL",
                  unseensData: unseensConv,
                })
              );
              setUnseenConversations(unseensConv);
            }
          }
        });
      } catch (err) {
        socket.close();
      } finally {
        if (socket.connected) {
          console.log("chaticon disconnected");
          socket.close();
        }
        // socket.on("connect", function () {
        //   console.log("check 2", socket.connected);
        // });
      }
    };
    if (conversations) {
      realtimeConversations();
    }
  }, [conversations]);

  return (
    <Fragment>
      <LI attrLI={{ className: " " }}>
        <Link to={process.env.PUBLIC_URL + "/inbox/"}>
          <div className="notification-box">
            <ChatSvg />
            <span className="badge rounded-pill badge-warning">
              {unseensData?.length}
            </span>
          </div>
        </Link>
      </LI>
    </Fragment>
  );
};
export default ChatIcon;
