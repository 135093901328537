import { useEffect, useState } from "react";
import { CornerUpLeft } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "reactstrap";
import { ProfilePictureRootURL } from "../../../../api";
import man from "../../../../assets/images/avtar/man.png";
import nouser from "../../../../assets/images/avtar/nouser.png";
import { apiSlice } from "../../../../redux/features/api/apiSlice";
import { useGetConversationsQuery } from "../../../../redux/features/chat/conversations/conversationsApi";
import { chattingInfo } from "../../../../redux/features/chat/conversations/conversationsSlice";
import { axiosRequest } from "../../../../redux/utils/axios-utils";
import TuitionStatusModal from "./TuitionStatusModal";

export default function ChatHead({ conversationId }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [myTuitions, setMyTuitions] = useState([]);
  const [myconfirmedTuitions, setMyConfirmedTuitions] = useState([]);
  const [openedTuitions, setOpenedTuitions] = useState([]);
  const [confirmedTuitions, setconfirmedTuitions] = useState([]);
  const dispatch = useDispatch();
  const { user, photo } = useSelector((state) => state.auth) || {};
  const { tutorTuitionList } = useSelector((state) => state.tuition) || {};
  const { confirmedTutorTuitionList } =
    useSelector((state) => state.confirmedTuition) || {};
  const { selectedUser } = useSelector((state) => state.conversations) || {};

  // const { email } = user || {};
  const { data: conversations } = useGetConversationsQuery(user);
  const selectedConversation = conversations?.find(
    (c) => c.conversationId === conversationId
  );

  useEffect(() => {
    const getSingleUser = async () => {
      var res = await axiosRequest({
        url: "/newMember/settings/singleUser/" + selectedUser.recevierId,
        method: "get",
      });

      if (res) {
        dispatch(
          chattingInfo({
            type: "SELECTED",
            selectedUser: {
              conversationId: selectedUser.conversationId,
              recevierId: selectedUser.recevierId,
              isAccountDeactive: res.data?.isAccountDeactive,
              isAccountDelete: res.data?.isAccountDelete,
            },
          })
        );

        if (selectedUser.conversationId) {
          dispatch(
            apiSlice.util.updateQueryData("getConversations", user, (draft) => {
              const draftConversation = draft?.find(
                (c) => c.conversationId == selectedUser?.conversationId
              );
              if (draftConversation) {
                draftConversation.isAccountDeactive =
                  res.data?.isAccountDeactive;
                draftConversation.isAccountDelete = res.data?.isAccountDelete;
              }
            })
          );
        }
      }
    };
    if (selectedUser) getSingleUser();
    else {
      dispatch(
        chattingInfo({
          type: "TOP",
          topConversation: selectedConversation,
        })
      );
      dispatch(
        chattingInfo({
          type: "SELECTED",
          selectedUser: {
            conversationId: selectedConversation?.conversationId,
            recevierId: selectedConversation?.users?.find((x) => x !== user),
            isAccountDeactive: selectedConversation?.isAccountDeactive,
            isAccountDelete: selectedConversation?.isAccountDelete,
          },
        })
      );
    }
  }, []);

  const picture =
    selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete
      ? nouser
      : selectedConversation?.thumb === "" ||
        selectedConversation?.thumb === " "
      ? man
      : ProfilePictureRootURL + selectedConversation?.thumb;

  useEffect(() => {
    if (tutorTuitionList) {
      var myTuitions = tutorTuitionList.filter(
        (c, i) => c.studentId === selectedConversation?.receiverId
      );
      setMyTuitions(myTuitions);

      var openedTuitions = tutorTuitionList?.filter(
        (c, i) =>
          c.studentId === selectedConversation?.receiverId &&
          c.status === "OPEN"
      );
      setOpenedTuitions(openedTuitions);
    }
    return () => {
      setOpenedTuitions([]);
      setMyTuitions([]);
    };
  }, [tutorTuitionList]);

  useEffect(() => {
    if (confirmedTutorTuitionList) {
      var myConfirmedTuitions = confirmedTutorTuitionList.filter(
        (c, i) =>
          c.tutorId === selectedConversation?.receiverId &&
          c.IsConfirmedByTutor === true
      );
      setMyConfirmedTuitions(myConfirmedTuitions);

      var confirmedTuitions = confirmedTutorTuitionList?.filter(
        (c, i) =>
          c.tutorId === selectedConversation?.receiverId &&
          c.IsConfirmedByTutor === true &&
          c.IsConfirmedByStudent === false
      );
      setconfirmedTuitions(confirmedTuitions);
    }

    return () => {
      setconfirmedTuitions([]);
      setMyConfirmedTuitions([]);
    };
  }, [confirmedTutorTuitionList]);

  const handleTuitionModal = (e) => {
    e.preventDefault();
    toggle();
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);
    setWidth(windowSize.innerWidth);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleBackButton = (e) => {
    dispatch(
      chattingInfo({
        type: "MOBILE",
        showConversation: "",
        showMessage: "d-none",
      })
    );
  };
  return (
    <div className="row align-items-center chat_header_bg">
      <div className="col-md-6">
        <Media className="chat-header clearfix">
          <img src={picture} className="rounded-circle" alt="" />
          <Media body>
            <div className="about">
              <div className="name">
                {selectedUser?.isAccountDeactive ||
                selectedUser?.isAccountDelete
                  ? "TuteAir User"
                  : selectedConversation?.name}
                {/* {selectedUserr && selectedUserr.typing ? (
                <span className="font-primary f-12"> Typing...</span>
              ) : ('')} */}
              </div>
              {/* <div className="status digits">{""}</div> */}
            </div>
          </Media>
        </Media>
      </div>
      <div
        className="col-md-6"
        style={{ textAlign: "right", paddingRight: "25px" }}
      >
        <div class="btn-group" role="group"></div>

        {myTuitions?.length > 0 &&
          !(
            selectedUser?.isAccountDeactive || selectedUser?.isAccountDelete
          ) && (
            <>
              <button
                className="btn btn-primary align-items-center"
                disabled={
                  selectedUser?.isAccountDeactive ||
                  selectedUser?.isAccountDelete
                }
                onClick={(e) => handleTuitionModal(e)}
              >
                <span>Tuitions</span>
                <label className="badge badge-light-dark text-white m-0 mx-1">
                  {openedTuitions?.length === 0 ? 0 : openedTuitions?.length} /{" "}
                  {myTuitions.length}
                </label>
              </button>
              {modal && (
                <TuitionStatusModal
                  title={"Update Tuition Status"}
                  isOpen={modal}
                  toggler={toggle}
                  tuitions={myTuitions}
                  conversationId={conversationId}
                  receiverId={selectedConversation?.receiverId}
                />
              )}
            </>
          )}

        {selectedUser && width <= 500 ? (
          <>
            <button
              className="btn btn-sm btn-default text-primary m-2"
              onClick={handleBackButton}
            >
              <CornerUpLeft />
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <div
        className="col-md-6"
        style={{ textAlign: "right", paddingRight: "25px" }}
      >
        {myconfirmedTuitions?.length > 0 && (
          <div>
            <button
              className="btn btn-primary align-items-center"
              onClick={(e) => handleTuitionModal(e)}
            >
              <span>Tuitions for Confirmation</span>
              <label className="badge badge-light-dark text-white m-0 mx-1">
                {confirmedTuitions?.length === 0
                  ? 0
                  : confirmedTuitions?.length}{" "}
                /{" "}
                {myconfirmedTuitions?.length > 0
                  ? myconfirmedTuitions?.length
                  : 0}
              </label>
            </button>
            {modal && (
              <TuitionStatusModal
                title={"Update Tuition Status"}
                isOpen={modal}
                toggler={toggle}
                tuitions={myconfirmedTuitions}
                IsStudentConfirmation={true}
                conversationId={conversationId}
                receiverId={selectedConversation?.receiverId}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
