import { toast } from "react-toastify";

export function authHeader() {
  // return authorization header with jwt token
  var user = JSON.parse(localStorage.getItem("tuteAirUser"));
  const currentUserToken = user?.accessToken;
  if (currentUserToken) {
    const jwt = `Bearer ${currentUserToken}`;
    return jwt;
    // return { Authorization: `Bearer ${currentUserToken}` };
  } else {
    return {};
  }
}

export function toastNotifiy(msg, type) {
  if (type === "success") {
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "warn") {
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
}

export function parseMyJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}
