import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
  return auth0_profile?.autenticated ? (
    <Outlet />
  ) : (
    <Navigate exact to={`${process.env.PUBLIC_URL}/home`} />
  );
};

export default PrivateRoute;
