import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Bell, X } from "react-feather";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Media } from "reactstrap";
import io from "socket.io-client";
import { format } from "timeago.js";
import { H3, LI, UL } from "../../../AbstractElements";
import { ServerURL } from "../../../api";
import { NotificationUrl, ProfilePictureRootURL } from "../../../api/index";
import man from "../../../assets/images/avtar/man.png";
import { HeaderNotificationSvg } from "../../../Data/svgIcons";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { toastBottomRight } from "../../../redux/utils/tuteairCommon";
import { authHeader } from "../../../Services/AuthVerify";
import "./Notif.css";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [notifCount, setNotifCount] = useState(0);
  const [isCancel, setIsCancel] = useState(false);
  const { user: userId } = useSelector((state) => state.auth) || {};
  const navigate = useNavigate();

  const fetchAllNotifications = async () => {
    const res = await axios.get(NotificationUrl, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (!res.data?.message?.includes("null")) {
      setNotifications(res.data);
    } else {
      console.log(res.data);
    }
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  useEffect(() => {
    const unSeenNotif = notifications.filter((item) => item.isSeen != "true");
    setNotifCount(unSeenNotif.length);
  }, [notifications]);

  useEffect(() => {
    const socket = io(ServerURL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttemps: 10,
      transports: ["websocket"],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false,
    });

    const realtimeNotification = async () => {
      try {
        socket.on("notification", (data) => {
          if (data.data?.receiver === userId) {
            var prevNotifications = [...notifications];
            prevNotifications?.push({
              context: data?.data.context,
              createdAt: data?.data.createdAt,
              description: data?.data.description,
              id: data?.data._id,
              isSeen: data?.data.isSeen,
              sender: data?.data.sender,
              senderName: data?.data.senderName,
              senderPhoto: data?.data.senderPhoto,
              title: data?.data.title,
            });
            // prevNotifications?.push(data?.data);
            prevNotifications.sort(function (a, b) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
            setNotifications(prevNotifications);
            toastBottomRight(
              data.data?.title + " from " + data.data?.senderName,
              "success"
            );
          }
        });
      } catch (err) {
        socket.close();
      } finally {
        if (socket.connected) {
          console.log("notification disconnected");
          socket.close();
        }
      }
    };
    if (notifications) {
      realtimeNotification();
    }
  }, [notifications]);

  const isSeenNotification = async (e, id, url) => {
    e.preventDefault();
    const res = await axiosRequest({
      url: "/notification/updateOne/" + id,
      method: "get",
    });
    if (res?.data?.message) {
      fetchAllNotifications();
    }
    navigate(`${process.env.PUBLIC_URL}${url}`);
  };

  const deleteNotification = async (e, id) => {
    e.preventDefault();
    const res = await axios.delete(NotificationUrl + "/delete/" + id, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res.data == "Deleted") {
      setNotifications(notifications?.filter((item) => item.id !== id));
    }
  };

  const markAllAsRead = async () => {
    const res = await axios.get(NotificationUrl + "/update", {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res.data) {
      fetchAllNotifications();
    }
  };

  return (
    <Fragment>
      <LI attrLI={{ className: "onhover-dropdown " }}>
        <div className="notification-box">
          <HeaderNotificationSvg />
          <span className="badge rounded-pill badge-warning">{notifCount}</span>
        </div>
        <div className={`onhover-show-div notification-dropdown`}>
          <div className="dropdown-title">
            <H3>Notification</H3>
            <a className="f-right" href="#javascript">
              <Bell />
            </a>
          </div>
          <UL attrUL={{ className: "simple-list custom-scrollbar" }}>
            {notifications?.map((item, k) => (
              <li
                key={k}
                className={`list-group-item ${
                  item.isSeen == "true" ? "" : "bgUnseen"
                }`}
              >
                <div
                  className={`header_notification ${
                    item.isSeen == "true" ? "" : "unseen-chat"
                  }`}
                  style={{ display: "flex" }}
                >
                  <div
                    onClick={(e) =>
                      isSeenNotification(e, item.id, item.context)
                    }
                    // to={item.context}
                    className="f-14 m-0"
                  >
                    <Media>
                      <div className={`notification-img bg-light-default`}>
                        <img
                          src={
                            item.senderPhoto === null ||
                            item.senderPhoto === "undefined" ||
                            item.senderPhoto === "" ||
                            item.senderPhoto === " "
                              ? man
                              : `${ProfilePictureRootURL}/${item.senderPhoto}`
                          }
                          className="img-40 img-fluid rounded-circle"
                          alt=""
                        />
                      </div>
                      <Media body>
                        <h6>
                          {item.title} from {item.senderName}
                        </h6>
                        {/* <P>{item.description}</P> */}
                        <span>{format(item.createdAt)} </span>
                      </Media>
                    </Media>
                  </div>
                  <div
                    className="notification-right"
                    onClick={(e) => deleteNotification(e, item.id)}
                  >
                    <X style={{ height: "17px" }} />
                  </div>
                </div>
              </li>
            ))}
            <LI attrLI={{ className: "p-0" }}>
              <a className="btn btn-primary" onClick={() => markAllAsRead()}>
                Mark all as read
              </a>
            </LI>
          </UL>
        </div>
      </LI>
    </Fragment>
  );
};
export default Notification;
