import io from "socket.io-client";
import { ServerURL } from "../../../../api";
import { apiSlice } from "../../api/apiSlice";

export const messagesApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: (id,sender) =>
                `/chat/newMessage/${id}`,
                async onCacheEntryAdded(
                    arg,
                    { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
                ) {
                    // create socket
                    const socket = io(ServerURL, {
                        reconnectionDelay: 1000,
                        reconnection: true,
                        reconnectionAttemps: 10,
                        transports: ["websocket"],
                        agent: false,
                        upgrade: false,
                        rejectUnauthorized: false,
                    });
    
                    try {
                        await cacheDataLoaded;
                        socket.on("message", (data) => {
                            updateCachedData((draft) => {
                               // console.log(current(draft))
                                    if(draft?.conversationId === data?.data.conversationId){
                                        draft.messages.push(data?.data);
                                    }
                               
                             });
                        });
                    } catch (err) {}
    
                    await cacheEntryRemoved;
                   // socket.close();
                },
        }),
        addMessage: builder.mutation({
            query: (data) => ({ 
                url: "/chat/newMessage/save",
                method: "POST", 
                body: data,
            }),
        }),
    }),
});

export const { useGetMessagesQuery, useAddMessageMutation } = messagesApi;
