import React, { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import NewStudentTuitionsContain from "../../../Component/NewMyTuitions/Student";

const NewStudentTuitions = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="MyTuitions" title="" />
      <NewStudentTuitionsContain />
    </Fragment>
  );
};
export default NewStudentTuitions;
