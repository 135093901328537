import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Info, Plus, PlusSquare, ThumbsUp } from "react-feather";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import TutorsContext from "../../../_helper/Tutors";
import { saveNewEducations } from "./fnBecomeTutor";

const BecomeTutorEducation = (props) => {
  const {
    handleSkipOrNext,
    education,
    setEducation,
    educationList,
    setEducationList,
  } = useContext(BecomeTutorContext);

  const { degrees, courses, getDegrees, getCourses } =
    useContext(TutorsContext);
  const [passingYearList, setPassingYears] = useState([]);
  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";

    if (degrees?.length === 0) getDegrees();
    if (courses?.length === 0) getCourses();
    setEducation({
      ...education,
      inProgress: false,
    });
    fnPassingYearList(83, "prev");

    if (educationList?.length > 0) setIsAdded(false); //if education is mandatory then else condition will be true
  }, []);

  const fnPassingYearList = (count, type) => {
    const year = new Date().getFullYear();
    let years = [];
    if (type === "next") {
      years = Array.from(new Array(count), (val, index) => year + index);
    } else {
      years = Array.from(new Array(count), (val, index) => year - index);
    }

    var yearList = [];
    if (years) {
      years?.map((item, index) => {
        yearList.push({
          value: item,
          label: item,
          isDisabled: false,
        });
      });
    }
    setPassingYears(yearList);
  };
  const handleSaveEducation = async (e) => {
    e.preventDefault();
    if (isAdded) {
      var res = await saveNewEducations(education, 5);
      if (res) {
        var obj = {
          ...education,
          marks: education?.grade,
          isRunning: education?.inProgress,
          passingYear: education?.passingYear?.value,
        };
        var newList = [...educationList, obj];
        setEducationList(newList);
        setIsAdded(!isAdded);
      }
    } else {
      alert("disabled");
    }
  };
  const handleAddNew = (e) => {
    e.preventDefault();
    setIsAdded(!isAdded);
  };

  return (
    <>
      <Row className="bt_title">
        <Col md={4}></Col>
        <Col md={4}>
          <h1 className="text-center">Educations</h1>
        </Col>
        <Col md={4} className="self-end">
          <Media className="float-end">
            <div className={` ${isAdded ? "d-none" : ""}`}>
              <a
                href="#education"
                onClick={(e) => handleAddNew(e)}
                className=""
              >
                <PlusSquare height={35} width={35} />
              </a>
            </div>
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  0{props?.step} <br /> Educations
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Fillup all [*] info</h6>
                    <p>
                      <ul>
                        <li>Degree </li>
                        <li>Course </li>
                        <li>Your Institute </li>
                        <li>Result </li>
                        <li>Completed Year </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <hr />

                <div className="media bt_help">
                  <Info />
                  <div className="media-body">
                    <p>
                      You can skip this for the moment, but you must fill it out
                      later to ensure a proper response from the student's side.
                    </p>
                  </div>
                </div>
                <div className="media bt_help align-items-center">
                  <Info />
                  <div className="media-body">
                    <p>
                      By clicking,
                      <span onClick={(e) => handleAddNew(e)} className="icon">
                        <Plus />
                      </span>
                      you may add several educations information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card>
            <CardHeader className="d-none">
              <Media className="float-end">
                <div className={` ${isAdded ? "d-none" : ""}`}>
                  <a
                    href="#education"
                    onClick={(e) => handleAddNew(e)}
                    className=""
                  >
                    <PlusSquare height={30} width={30} />
                  </a>
                </div>
              </Media>
            </CardHeader>
            <CardBody className="megaoptions-border-space-sm">
              {!isAdded && (
                <div className="table-responsive-sm">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr style={{ background: "#dbdbdb2b" }}>
                        <th
                          scope="col"
                          className="px-4"
                          style={{ width: "5%" }}
                        >
                          {"#"}
                        </th>
                        <th scope="col" style={{ width: "25%" }}>
                          {"Institute"}
                        </th>
                        <th scope="col" style={{ width: "25%" }}>
                          {"Degree / Courses"}
                        </th>

                        <th scope="col" style={{ width: "10%" }}>
                          {"Result"}
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          {"Completed Year"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {educationList?.length === 0 && (
                        <tr className="text-center text-red">
                          <td colSpan={5}>No Education Info Found</td>
                        </tr>
                      )}
                      {educationList?.length > 0 &&
                        educationList?.map((item, i) => (
                          <tr key={i}>
                            <td className="px-4">{i + 1}</td>
                            <td className="">
                              <span className="text-left">
                                {item.institute}
                              </span>
                            </td>
                            <td>
                              <p className="mb-0 px-1">{item.degree?.label}</p>
                              <p
                                className="mb-0 px-1"
                                style={{ color: "#488374" }}
                              >
                                <small>{item.course?.label}</small>
                              </p>
                            </td>

                            <td>{item.marks}%</td>
                            <td>{item.passingYear}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {isAdded && (
                <Form className="">
                  <Col sm="12" xl="12 box-col-12">
                    <Card className="mb-0">
                      <CardBody className="megaoptions-border-space-sm">
                        <Row>
                          <Col sm="12" xl="12">
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                Degree
                              </Label>
                              <Col sm="10">
                                <Select
                                  options={degrees}
                                  placeholder="Select Degree"
                                  className="js-example-basic-single col-sm-12 darkMode"
                                  selecte
                                  onChange={(item) =>
                                    setEducation({ ...education, degree: item })
                                  }
                                  value={education?.degree}
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                Course
                              </Label>
                              <Col sm="10">
                                <Select
                                  options={courses}
                                  placeholder="Select Course"
                                  className="js-example-basic-single col-sm-12 darkMode"
                                  onChange={(item) =>
                                    setEducation({ ...education, course: item })
                                  }
                                  value={education?.course}
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label pt-0 asterisk"
                                htmlFor=""
                              >
                                Institute
                              </Label>
                              <Col sm="10">
                                <Input
                                  type="text"
                                  name=""
                                  placeholder="Enter Your Institute"
                                  className="form-control"
                                  value={education?.institute}
                                  onChange={(e) =>
                                    setEducation({
                                      ...education,
                                      institute: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                Result
                              </Label>
                              <Col sm="3">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Enter score in percentage"
                                  value={education?.grade}
                                  // onChange={handleGradeChange}
                                  onChange={(e) => {
                                    e.target.value >= 0 &&
                                      e.target.value <= 100 &&
                                      setEducation({
                                        ...education,
                                        grade: e.target.value,
                                      });
                                  }}
                                />
                                {education?.grade <= 0 &&
                                education?.grade >= 100 ? (
                                  <div className="text-danger">
                                    min: 0% and max:100%
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                Completed Year
                              </Label>
                              <Col sm="3">
                                <Select
                                  options={passingYearList}
                                  placeholder="Select Year"
                                  className="js-example-basic-single col-sm-12 darkMode"
                                  onChange={(item) =>
                                    setEducation({
                                      ...education,
                                      passingYear: item,
                                    })
                                  }
                                  value={education?.passingYear}
                                />
                              </Col>
                              <Col sm="2">
                                <div
                                  className="row"
                                  style={{ placeItems: "center" }}
                                >
                                  <Col sm="2">
                                    <Input
                                      id=""
                                      type="checkbox"
                                      checked={education?.inProgress === true}
                                      onChange={(event) => {
                                        setEducation({
                                          ...education,
                                          inProgress: event.target.checked,
                                        });
                                        event.target.checked === true
                                          ? fnPassingYearList(6, "next")
                                          : fnPassingYearList(83, "prev");
                                      }}
                                    />
                                  </Col>
                                  <Label
                                    className="col-sm-8 col-form-label px-1"
                                    htmlFor="inputEmail3"
                                  >
                                    In Progress
                                  </Label>
                                </div>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Col md={12} className="btn_acc">
                        <button
                          className="btn  btn-sm btn-warning"
                          onClick={(e) => {
                            setIsAdded(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn mx-2 btn-sm btn-success"
                          onClick={(e) => handleSaveEducation(e)}
                        >
                          Save
                        </button>
                      </Col>
                    </div>
                    <div className="row mb-2"></div>
                  </Col>
                </Form>
              )}
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-0"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn btn-lg btn-back btn-info  mx-2"
                onClick={(e) => handleSkipOrNext("back")}
              >
                Back
              </button>

              <button
                className="btn  btn-lg btn-warning btn-skip "
                disabled={isAdded}
                onClick={(e) => handleSkipOrNext("skip")}
              >
                {educationList?.length > 0 ? "Next" : "Skip"}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BecomeTutorEducation;
