import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Info, Plus, PlusSquare, ThumbsUp } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Table,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import { saveNewExperiences } from "./fnBecomeTutor";

const BecomeTutorExperience = () => {
  const {
    handleSkipOrNext,
    experience,
    setExperience,
    experienceList,
    setExperienceList,
  } = useContext(BecomeTutorContext);

  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";
    setExperience({
      ...experience,
      running: false,
      startDate: new Date(),
    });

    if (experienceList?.length > 0) setIsAdded(false); //if education is mandatory then else condition will be true
  }, []);

  const handleSaveExperience = async (e) => {
    e.preventDefault();
    if (isAdded) {
      var res = await saveNewExperiences(experience, 6);
      if (res) {
        var newList = [...experienceList, experience];
        setExperienceList(newList);
        setIsAdded(!isAdded);
      }
    } else {
      alert("disabled");
    }
  };

  const handleAddNew = (e) => {
    e.preventDefault();
    setIsAdded(!isAdded);
  };

  return (
    <>
      <Row className="bt_title">
        <Col md={4}></Col>
        <Col md={4}>
          <h1 className="text-center">Experiences</h1>
        </Col>
        <Col md={4} className="self-end">
          <Media className="float-end">
            <div className={` ${isAdded ? "d-none" : ""}`}>
              <a
                href="#education"
                onClick={(e) => handleAddNew(e)}
                className=""
              >
                <PlusSquare height={35} width={35} />
              </a>
            </div>
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  06 <br /> Experiences
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Fillup all [*] info</h6>
                    <p>
                      <ul className="list-group">
                        <li>
                          <i className="icofont icofont-minus"></i> Your
                          Position
                        </li>
                        <li>
                          <i className="icofont icofont-minus"></i> Organization
                          where you works{" "}
                        </li>
                        <li>
                          <i className="icofont icofont-minus"></i> From when
                          you start & end{" "}
                        </li>
                        <li>
                          <i className="icofont icofont-minus"></i> If you are
                          currently work there click Is Running box{" "}
                        </li>
                        <li>
                          <i className="icofont icofont-minus"></i> Then, if you
                          have any experience, share it.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <hr />

                <div className="media bt_help">
                  <Info />
                  <div className="media-body">
                    <p>
                      You can skip this for the moment, but you must fill it out
                      later to ensure a proper response from the student's side.
                    </p>
                  </div>
                </div>
                <div className="media bt_help align-items-center">
                  <Info />
                  <div className="media-body">
                    <p>
                      By clicking,
                      <span onClick={(e) => handleAddNew(e)} className="icon">
                        <Plus />
                      </span>
                      you may add several educations information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card>
            <CardHeader className="d-none">
              <Media className="float-end">
                <div className={` ${isAdded ? "d-none" : ""}`}>
                  <a
                    href="#education"
                    onClick={(e) => handleAddNew(e)}
                    className=""
                  >
                    <PlusSquare height={30} width={30} />
                  </a>
                </div>
              </Media>
            </CardHeader>
            <CardBody className="megaoptions-border-space-sm">
              {!isAdded && (
                <div className="table-responsive-sm">
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr style={{ background: "#dbdbdb2b" }}>
                        <th scope="col" style={{ width: "5%" }}>
                          {"#"}
                        </th>
                        <th scope="col" style={{ width: "25%" }}>
                          {"Position"}
                        </th>
                        <th scope="col" style={{ width: "40%" }}>
                          {"Organization"}
                        </th>
                        <th scope="col" style={{ width: "25%" }}>
                          {"Duration"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {experienceList?.length === 0 && (
                        <tr className="text-center text-red">
                          <td colSpan={5}>No Experience Info Found</td>
                        </tr>
                      )}
                      {experienceList?.map((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{item.position}</td>
                          <td>
                            <span className=" px-1 py-0">
                              {item.organization}{" "}
                            </span>
                          </td>
                          <td>
                            <code className="text-dark">
                              <span className="badge badge-light-danger">
                                {moment(item.startDate).format("MMM D, yyyy")}
                              </span>
                              <span className="px-2">to</span>

                              {!item.running && (
                                <span className="badge badge-light-danger">
                                  {moment(item.endDate).format("MMM D, yyyy")}
                                </span>
                              )}

                              {item.running && <span>Running</span>}
                            </code>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {isAdded && (
                <Form className="">
                  <Col sm="12" xl="12 box-col-12">
                    <Card className="mb-0">
                      <CardBody className="megaoptions-border-space-sm">
                        <Row>
                          <Col sm="12" xl="12">
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor=""
                              >
                                Position
                              </Label>
                              <Col sm="10">
                                <Input
                                  className="form-control"
                                  type="text"
                                  required
                                  placeholder="Enter Position"
                                  value={experience?.position}
                                  onChange={(e) =>
                                    setExperience({
                                      ...experience,
                                      position: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor=""
                              >
                                Organization
                              </Label>
                              <Col sm="10">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Organization"
                                  value={experience?.organization}
                                  onChange={(e) =>
                                    setExperience({
                                      ...experience,
                                      organization: e.target.value,
                                    })
                                  }
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label asterisk"
                                htmlFor="inputEmail3"
                              >
                                Start Date
                              </Label>
                              <Col sm="4">
                                <DatePicker
                                  className="form-control digits"
                                  selected={experience?.startDate}
                                  maxDate={experience?.endDate}
                                  onChange={(date) =>
                                    setExperience({
                                      ...experience,
                                      startDate: new Date(date),
                                    })
                                  }
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  dateFormat="dd MMMM yyyy"
                                />
                              </Col>
                              {!experience?.running && (
                                <Label
                                  className="col-sm-2 col-form-label asterisk"
                                  htmlFor="inputEmail3"
                                >
                                  End Date
                                </Label>
                              )}
                              <Col sm="4">
                                {!experience?.running && (
                                  <DatePicker
                                    className="form-control digits"
                                    selected={experience?.endDate}
                                    minDate={experience?.startDate}
                                    maxDate={new Date()}
                                    onChange={(date) =>
                                      setExperience({
                                        ...experience,
                                        endDate: new Date(date),
                                      })
                                    }
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd MMMM yyyy"
                                  />
                                )}
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label"
                                htmlFor="inputEmail3"
                              >
                                Is Running
                              </Label>
                              <Col sm="10">
                                <Input
                                  id=""
                                  type="checkbox"
                                  checked={experience?.running === true}
                                  onChange={(event) => {
                                    setExperience({
                                      ...experience,
                                      running: event.target.checked,
                                    });
                                  }}
                                />
                              </Col>
                            </div>
                            <div className="row mb-2">
                              <Label
                                className="col-sm-2 col-form-label"
                                htmlFor="inputEmail3"
                              >
                                About Work Experience
                              </Label>
                              <Col sm="10">
                                <Input
                                  type="textarea"
                                  className="form-control"
                                  rows="3"
                                  value={experience?.aboutWorkExp}
                                  onChange={(event) => {
                                    setExperience({
                                      ...experience,
                                      aboutWorkExp: event.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </div>
                          </Col>
                          <Col sm="12" xl="12">
                            <div className="row mb-2">
                              <Col md={12} className="btn_acc">
                                <button
                                  className="btn  btn-sm btn-warning"
                                  onClick={(e) => {
                                    setIsAdded(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn mx-2 btn-sm btn-success"
                                  onClick={(e) => handleSaveExperience(e)}
                                >
                                  Save
                                </button>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Form>
              )}
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-0"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn btn-lg btn-back btn-info  mx-2"
                onClick={(e) => handleSkipOrNext("back")}
              >
                Back
              </button>

              <button
                className="btn  btn-lg btn-warning btn-skip "
                disabled={isAdded}
                onClick={(e) => handleSkipOrNext("skip")}
              >
                {experienceList?.length > 0 ? "Next" : "Skip"}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BecomeTutorExperience;
