import { apiSlice } from "../api/apiSlice";

export const settingsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        upgradeMemberAccount: builder.mutation({
            query: (data) => ({ 
                url: "/member/settings/update-account",
                method: "PUT", 
                body: data,
            }),
        }),
    }),
});

export const { useUpgradeMemberAccountMutation } = settingsApi;
