import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tutorTuitionList: undefined
};

const tuitionSlice = createSlice({
    name: "tuition",
    initialState,
    reducers: {
        tutorTuitions: (state, action) => {
            state.tutorTuitionList = action.payload.tutorTuitionList;
        },
    },
});

export const {tutorTuitions} = tuitionSlice.actions;
export default tuitionSlice.reducer;
