import React, { Fragment, useContext, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormGroup, Input, InputGroup } from "reactstrap";
import { H4, H5, H6, LI, P, UL } from "../../../../AbstractElements";
import {
  CreateAccount,
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../../../../Constant";
import BecomeTutorContext from "../../../../_helper/BecomeTutor";
import { handleLoginRequest } from "../fnBecomeTutor";
import { SignInGoogle } from "./SignInGoogle";

const LoginNew = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [togglePassword, setTogglePassword] = useState(false);

  const { setIsEmailVerified } = useContext(BecomeTutorContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.warn("Invalid Credentials!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      return;
    }
    setIsLoading(true);
    var response = await handleLoginRequest(
      {
        email,
        password,
      },
      dispatch
    );
    handleLoginResponse(response, "");

    setIsLoading(false);
  };

  const handleLoginResponse = (res, type) => {
    if (res?.isLogged) {
      if (res?.emailVerified && res?.currentStep > 1 && res?.profilePicture) {
        setIsLoading(false);
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        setIsLoading(false);
        if (res?.accountType === "student") {
          navigate(`${process.env.PUBLIC_URL}/become-student`);
        } else {
          navigate(`${process.env.PUBLIC_URL}/become-tutor`);
        }
      }
      if (type === "lwG") setIsEmailVerified(true);
      else setIsEmailVerified(res?.emailVerified);
    } else {
      toast.warn(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <ToastContainer />
        <div className="login-header  text-center">
          <H4>Login</H4>
          <H6>Welcome back! Log in to your account.</H6>
        </div>
        <FormGroup>
          <UL attrUL={{ className: "simple-list login-social flex-row" }}>
            <LI>
              {/* <div id="signInDiv"></div> */}
              <SignInGoogle />
            </LI>
          </UL>
        </FormGroup>
        <div className="login-social-title">
          <H5>Sign in with Email</H5>
        </div>
        <FormGroup>
          <label className="form-label">{EmailAddress}</label>
          <InputGroup>
            <span className="input-group-text">
              <Mail />
            </span>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="user@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-label">{Password}</label>
          <InputGroup>
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              name="login[password]"
              required=""
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="login-btn form-group">
          <div className="row">
            <div className="col-md-6">
              <div className="checkbox d-none">
                <Input id="checkbox1" type="checkbox" />
                <label className="text-muted" htmlFor="checkbox1">
                  {RememberPassword}
                </label>
              </div>
              <div className="" style={{ float: "left" }}>
                <Link
                  to={`${process.env.PUBLIC_URL}/forgot-password`}
                  className="link"
                >
                  {ForgotPassword}?
                </Link>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </FormGroup>
        <FormGroup>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading}
            onClick={(e) => handleLogin(e)}
          >
            {loading ? "LOADING..." : SignIn}
          </button>
        </FormGroup>
        <P>
          Don't have account?
          <Link to={`${process.env.PUBLIC_URL}/register-v2`} className="ms-2">
            {CreateAccount}
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/home`} className="ms-2">
            | Home
          </Link>
        </P>
      </Form>
    </Fragment>
  );
};

export default LoginNew;
