import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Button,
  CardFooter,
} from "reactstrap";
import { UpcomingClassListView } from "../../../../../api";
import axios from "axios";
import { authHeader, toastNotifiy } from "../../../../../Services/AuthVerify";
import { useSelector } from "react-redux";
import { axiosRequest } from "../../../../../redux/utils/axios-utils";

export default function UpComingClass(props) {
  const [allUpcomingClassList, setAllUpcomingClassList] = useState([]);
  const [isPaidList, setIsPaidListR] = useState([]);
  const [data, setAllData] = useState({});
  const { memberType } = useSelector((state) => state.menu) || {};
  const [processedPaid, setProcessedPaid] = useState(true);
  const { user } = useSelector((state) => state.auth) || {};

  const fetchAllUpcomingClass = async () => {
    const res = await axios.get(UpcomingClassListView, {
      headers: {
        Authorization: authHeader(),
      },
    });
    if (res.data) {
      const participant = res.data?.map((item) => {
        item?.participants.map((item2) => {
          if (item2?.studentId === user) {
            res.data?.map((item, index) => {
              item.start = new Date(item.start);
              item.end = new Date(item.end);
              item.subjects = "";
              item.tutorName = "";
              item.confirmedTuitionFee = 0;
              item.currency = "";
              var mySelectedTuition = "";
              const subjects = "";

              const myTuitions = props?.tutorTuitions?.filter(
                (t) => t.tuitionId == item.tuition
              );

              const myTuition = myTuitions[0];
              item.tutorName = myTuition?.tutorName;
              item.confirmedTuitionFee = myTuition?.confirmedTuitionFee;
              item.currency = myTuition?.currency;

              if (myTuition?.tuitionRequest?.subjects) {
                myTuition?.tuitionRequest?.subjects?.map((item2, index2) => {
                  var comma =
                    item2.tuitionRequest?.subjects?.length - 1 === index2
                      ? ""
                      : " - ";
                  item.subjects =
                    subjects + item2.label?.toString().trim() + comma;
                });
              } else {
                myTuition?.interestedSubjects?.subjects?.map(
                  (item2, index2) => {
                    var comma =
                      myTuition.interestedSubjects?.subjects?.length - 1 ===
                      index2
                        ? ""
                        : " - ";
                    item.subjects =
                      item.subjects + item2.name?.toString().trim() + comma;
                  }
                );
              }
            });
            setAllUpcomingClassList(res.data);
          }
        });
      });
    }
  };

  useEffect(() => {
    fetchAllUpcomingClass();
    return () => {
      setAllUpcomingClassList();
    };
  }, [props]);

  const clickHandler = (e, item) => {
    const existItem = isPaidList.map((item) => item._id);
    if (e.target.checked) {
      if (!existItem.includes(item._id)) {
        isPaidList.push(item);
      }
    } else {
    }
  };

  const handleProcessedPaidList = async () => {
    const isPaid = isPaidList;
    const line_items = isPaid.map((item) => {
      return {
        id: item._id,
        tuitionId: item.tuition,
        name: item.title,
        desc: item.subjects,
        price: 50, //item.participants[0]?.payment?.amount,
        images: ["https://i.ibb.co/9T0CHzt/tute-logo-1.png"],
      };
    });
    var res = await axiosRequest({
      url: "/payment/checkout-session",
      method: "post",
      data: {
        classes: line_items,
      },
    });
    if (res.status == 200) {
      window.open(res.data.url, "_self");
    }
  };

  useEffect(() => {}, [isPaidList]);

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <td className="h4" colSpan={6}>
                  Upcoming Classes
                </td>
              </tr>
              <tr style={{ background: "#dbdbdb2b" }}>
                <th style={{ width: "4%" }} scope="col"></th>
                <th style={{ width: "1%" }} scope="col"></th>
                <th>Title</th>
                <th>Subject</th>
                <th>Tutor Name</th>
                <th>Amount</th>
                <th>Time</th>
                <th>Payment Status</th>
              </tr>
            </thead>

            <tbody>
              {allUpcomingClassList?.map((item, index) => (
                <tr className="px-0" key={index}>
                  <td scope="row">{index + 1}</td>
                  <td>
                    <Input
                      type="checkbox"
                      // checked={processedPaid === false}
                      onChange={(e) => clickHandler(e, item)}
                    />
                  </td>

                  <td>{item?.title}</td>
                  <td>{item?.subjects}</td>
                  <td>{item?.tutorName}</td>
                  <td>
                    <span>{item?.confirmedTuitionFee}</span> /{" "}
                    <span>{item?.currency}</span>
                  </td>
                  <td>
                    <span>
                      {new Date(item?.start).toDateString()} :{" "}
                      {new Date(item?.start).toLocaleTimeString()}
                    </span>
                    {" - "}
                    <span>{new Date(item?.end).toLocaleTimeString()}</span>
                  </td>
                  <td>Unpaid</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <CardFooter className={`text-end `}>
          <button
            className="btn btn-sm btn-info w-30"
            onClick={(e) => handleProcessedPaidList(e)}
          >
            Processed to Paid
          </button>
        </CardFooter>
      </Card>
    </div>
  );
}
