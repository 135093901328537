import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { H4, Image } from "../../AbstractElements";
import sidebar from "../../assets/images/side-bar.png";
import { TuteAirSupport } from "../../Constant";
import FeedbackModal from "./FeedbackModal";

const SidebarSection = () => {
  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("Feedback Hub");

  const openFeedbackModal = () => {
    setModal(true)
  }

  return (
    <Fragment>
      <div className="sidebar-img-section">
        <div className="sidebar-img-content">
          <Image
            attrImage={{ className: "img-fluid", src: `${sidebar}`, alt: "" }}
          />
          <H4>Any Feedback?</H4>
          <Link to={"#"} className="txt">
            We appreciate your valuable suggestions or feedback.
          </Link>
          <Button className="btn btn-secondary" onClick={openFeedbackModal}>
            {TuteAirSupport}
          </Button>

          {modal && (
            <FeedbackModal
              title={title}
              isOpen={modal}
              toggler={toggle}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default SidebarSection;
