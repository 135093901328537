import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StepZilla from "react-stepzilla";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import MemberPersonalInfo from "../Tutor/BasicInfo/TutorPersonalInfo";
import MemberEducation from "../Tutor/Education/TutorEduInfo";
import StudentPricing from "./InterestLearn/StudentPricing";

const StudentAccountContain = () => {
  const steps = [
    { name: "Personal Info", component: <MemberPersonalInfo /> },
    { name: "Educational Info", component: <MemberEducation /> },
    { name: "Subject Interest", component: <StudentPricing /> },
  ];

  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/tutor-account`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="pt-1">
                <StepZilla
                  steps={steps}
                  showSteps={true}
                  showNavigation={true}
                  stepsNavigation={true}
                  prevBtnOnLastStep={true}
                  dontValidate={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StudentAccountContain;
