import React, { Fragment, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Card, CardHeader, Col, Form, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";
import { authHeader } from "../../../../Services/AuthVerify";
import EducationModal from "./EducationModal";

import axios from "axios";
import { toast } from "react-toastify";
import {
  DocumentRootURL,
  EducationInfoDeleteApi,

} from "../../../../api";
import MemberInformationContext from "../../../../_helper/MemberInformation";

const TutorEduInfo = () => {
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const [memberEducationsData, setMemberEducationsData] = useState(null);
  const [singleEduData, setSingleEduData] = useState({});
  const dispatch = useDispatch();
  const {
    educations,
    setEducations,
    getMemberInformation
  } = useContext(MemberInformationContext)

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!educations) {
      getMemberInformation();
    }
  }, []);

  // const fetchDropdownData = async () => {
  //   const res = await axios.get(TutorEduGetInitalApi, {
  //     headers: {
  //       Authorization: authHeader(),
  //     },
  //   });
  //   // dispatch(
  //   //   educationData({
  //   //     courseList: res.data.courses,
  //   //     degreeList: res.data.degrees,
  //   //   })
  //   // );
  // };
  // useEffect(() => {
  //   fetchDropdownData();
  //   return () => { };
  // }, []);

  const handleAddNewButton = () => {
    setEdit(false);
    setTitle("Add New Education Info");
    setSingleEduData({
      course: "",
      degree: "",
      institute: "",
      grade: "",
      passingYear: "",
      inProgress: false,
      attachment: [],
    });
    toggle();
  };
  const handleEduInfoEdit = async (item) => {
    setEdit(true);
    const attachURL = DocumentRootURL + item.attachments.id;
    setTitle("Update New Education Info");
    var singleData = {
      id: item.id,
      course: item.course,
      degree: item.degree,
      institute: item.institute,
      grade: item.marks,
      passingYear: item.passingYear,
      attachment: item.attachments.id,
      attachmentURL: attachURL,
      attachmentOriginalName: item.attachments.name,
      inProgress: JSON.parse(item.isRunning)
    };

    setSingleEduData(singleData);
    setModal(true);

  }

  const handleEduInfoDelete = async (id) => {
    if (window.confirm("Are you sure you wish to delete this info?")) {
      const res = await axios.delete(EducationInfoDeleteApi + id, {
        headers: {
          Authorization: authHeader(),
        },
      });
      // console.log(res);
      if (res.status === 201) {
        toast.success("Education Info Deleted successully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        // toastNotify("Deleted successully", "warn");
        getMemberInformation();
      } else {
        alert("something went wrong!!");
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="8">
                  <span>
                    Do you want to add Educational Info to your profile? Please
                    click the “Add New” button.
                  </span>
                </Col>
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: handleAddNewButton,
                    }}
                  >
                    Add New
                  </Btn>

                  {modal && (
                    <EducationModal
                      title={title}
                      isOpen={modal}
                      toggler={toggle}
                      singleEduData={singleEduData}
                      edit={edit}
                    />
                  )}
                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>
            <div className="table-responsive-sm">
              <Table className="table-border-horizontal">
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th scope="col" className="px-4" style={{ width: "5%" }}>
                      {"#"}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {"Institute"}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {"Degree / Courses"}
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                      {"Attachments"}
                    </th>
                    <th scope="col" style={{ width: "10%" }}>
                      {"Result"}
                    </th>
                    <th scope="col" style={{ width: "15%" }}>
                      {"Completed Year"}
                    </th>
                    <th scope="col" style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {educations?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={7}>No Education Info Found</td>
                    </tr>
                  )}
                  {educations?.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4">{index + 1}</td>
                      <td className="">
                        <span className="text-left">{item.institute}</span>
                      </td>
                      <td>
                        <p className="mb-0 px-1">{item.degree.label}</p>
                        <p className="mb-0 px-1" style={{ color: "#488374" }}>
                          <small>{item.course?.label}</small>
                        </p>
                      </td>
                      <td>
                        {item.attachments && (
                          <a
                            style={{ cursor: "pointer" }}
                            className="text-left text-primary"
                            href={DocumentRootURL + "/" + item.attachments.id}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.attachments.name &&
                              item.attachments.name.substring(
                                item.attachments.name.indexOf("_") + 1
                              )}
                          </a>
                        )}
                      </td>
                      <td>{item.marks}%</td>
                      <td>{item.passingYear}</td>
                      <td className="px-1">
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={() => handleEduInfoEdit(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          className="btn px-1 py-0 btn-link text-danger"
                          onClick={() => handleEduInfoDelete(item.id)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>

          <Form className="needs-validation d-none" noValidate>
            <H5>Tutor Education Will be there</H5>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TutorEduInfo;
