import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { Fragment, useEffect, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { H4, H5, H6, LI, P, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL, RegisterApiGoogle } from "../../../api";

import { useNavigate } from "react-router-dom";
import {
  CreateAccount,
  EmailAddress,
  ForgotPassword,
  Login,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import { useLoginMutation } from "../../../redux/features/auth/authApi";
import {
  userAcconuntVisible,
  userLoggedIn,
} from "../../../redux/features/auth/authSlice";
import { memberMenus } from "../../../redux/features/menus/menuSlice";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const dispatch = useDispatch();
  const [login, { data, isLoading, error: responseError }] = useLoginMutation();

  useEffect(() => {
    if (responseError?.data) {
      toast.warn(responseError.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    if (data?.token && data?._id) {
      if (data.isMember) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        if (data.accountType === "student")
          navigate(`${process.env.PUBLIC_URL}/student-account`);
        else navigate(`${process.env.PUBLIC_URL}/tutor-account`);
      }
    }
  }, [data, responseError, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    login({
      email,
      password,
    });
    // navigate(`${process.env.PUBLIC_URL}/dashboard`);
    //window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
  };

  const handleCallBackResponse = async (response) => {
    const userObject = jwt_decode(response.credential);

    const pro_pic_url = userObject.picture;

    const user_object_to_mongodb = {
      firstName: userObject.given_name,
      lastName: userObject.family_name,
      email: userObject.email,
      gClientID: userObject.sub,
      profilePicture: pro_pic_url,
    };

    const login_response = await axios.post(
      RegisterApiGoogle,
      user_object_to_mongodb
    );

    if (login_response.status === 201 || login_response.status === 200) {
      localStorage.setItem(
        "tuteAirUser",
        JSON.stringify({
          name:
            login_response.data.firstName + " " + login_response.data.lastName,
          accessToken: login_response.data.token,
          id: login_response.data._id,
          picture:
            login_response.status === 200
              ? login_response?.data?.profilePicture?.includes("https")
                ? login_response.data.profilePicture
                : ProfilePictureRootURL + login_response.data.profilePicture
              : pro_pic_url,
          email: login_response.data.email,
          autenticated: true,
          memberType: login_response.data.memberType,
          firstName: login_response.data.firstName,
          lastName: login_response.data.lastName,
          accountType: login_response.data.accountType,
        })
      );

      localStorage.setItem(
        "picture2",
        JSON.stringify(
          login_response.status === 200
            ? login_response?.data?.profilePicture?.includes("https")
              ? login_response.data.profilePicture
              : ProfilePictureRootURL + login_response.data.profilePicture
            : pro_pic_url
        )
      );
      localStorage.setItem(
        "isMemberExists",
        JSON.stringify(login_response.data.isMember)
      );

      dispatch(
        userLoggedIn({
          accessToken: login_response.data.token,
          user: login_response.data._id,
          photo:
            login_response.status === 200
              ? login_response?.data?.profilePicture?.includes("https")
                ? login_response.data.profilePicture
                : ProfilePictureRootURL + login_response.data.profilePicture
              : pro_pic_url,
          accountType: login_response.data.accountType,
          firstName: login_response.data.firstName,
          lastName: login_response.data.lastName,
        })
      );
      dispatch(
        userAcconuntVisible({
          isAccountHide: login_response.data.isAccountHide,
        })
      );
      localStorage.setItem("isAccountHide", login_response.data.isAccountHide);

      if (login_response.data.accountType === "both") {
        localStorage.setItem("swMember", "tutor");
        dispatch(memberMenus({ memberType: "tutor" }));
      } else {
        localStorage.setItem("swMember", login_response.data.accountType);
        dispatch(memberMenus({ memberType: login_response.data.accountType }));
      }

      if (login_response.data.isMember) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        if (login_response.data.accountType === "student")
          navigate(`${process.env.PUBLIC_URL}/student-account`);
        else navigate(`${process.env.PUBLIC_URL}/tutor-account`);
      }

      //navigate(`${process.env.PUBLIC_URL}/dashboard`);
      //window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
    } else if (login_response.status === 204) {
      toast.warn(
        "This Email is associated with password. Kindly login with password.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        }
      );
    } else if (login_response.status === 202) {
      toast.warn(login_response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } else {
      toast.warn(login_response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  //456154972130-srk6dkeei4naj0rahiv1fmdlg3hmscjs.apps.googleusercontent.com
  //307876428714-hqdajj5erhi111128fdnb2a7j1it2jb9.apps.googleusercontent.com
  useEffect(() => {
    const google = window.google;
    google.accounts?.id?.initialize({
      client_id:
        //"456154972130-srk6dkeei4naj0rahiv1fmdlg3hmscjs.apps.googleusercontent.com", // localhost
        "8149043166-3d47dr03t2t06t36593mpj67ar7f44dh.apps.googleusercontent.com",
      callback: handleCallBackResponse,
    });

    google.accounts?.id?.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
    //google.accounts.id.prompt();
  }, []);

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <ToastContainer />
        <div className="login-header text-center">
          <H4>{Login}</H4>
          <H6>Welcome back! Log in to your account.</H6>
        </div>
        <FormGroup>
          <UL attrUL={{ className: "simple-list login-social flex-row" }}>
            <LI>
              <div id="signInDiv"></div>
            </LI>
          </UL>
        </FormGroup>
        <div className="login-social-title">
          <H5>Sign in with Email</H5>
        </div>
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <div className="input-group">
            <span className="input-group-text">
              <Mail />
            </span>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="user@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <div className="input-group">
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              name="login[password]"
              required=""
              placeholder="*********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="login-btn form-group">
          <div className="row">
            <div className="col-md-6">
              <div className="checkbox d-none">
                <Input id="checkbox1" type="checkbox" />
                <Label className="text-muted" for="checkbox1">
                  {RememberPassword}
                </Label>
              </div>
              <div className="" style={{ float: "left" }}>
                <Link
                  to={`${process.env.PUBLIC_URL}/forgot-password`}
                  className="link"
                >
                  {ForgotPassword}?
                </Link>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </FormGroup>
        <FormGroup>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading}
            onClick={(e) => handleLogin(e)}
          >
            {isLoading ? "LOADING..." : SignIn}
          </button>
        </FormGroup>
        <P>
          Don't have account?
          <Link to={`${process.env.PUBLIC_URL}/signup`} className="ms-2">
            {CreateAccount}
          </Link>
          <Link to={`${process.env.PUBLIC_URL}/home`} className="ms-2">
            | Home
          </Link>
        </P>
      </Form>
    </Fragment>
  );
};

export default LoginForm;
