import React from "react";
import { Card, CardText, CardTitle } from "reactstrap";

export default function Qualification(props) {
  return (
    <div>
      <Card body>
        <CardTitle tag="h4" className="">
          {props.title}
        </CardTitle>
        {props.data?.map((item, index) => (
          <div key={index} className="py-1 border-top">
            <CardText className="text-primary mb-0">
              <i className="fa fa-graduation-cap"></i>{" "}
              <span className="ms-1">
                {item.degree.label} in {item.course.label}
              </span>
            </CardText>
            <div style={{ paddingLeft: "26px" }}>
              <p className="mb-0"> {item.institute}</p>
              <p className="mb-0"> Year of {item.passingYear}</p>
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
}
