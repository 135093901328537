import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext, useEffect, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import PersonalAddressContext from "../../_helper/PersonalAddress";
import Geocoder from "./Geocoder";
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function SetUsersLocation() {
  var userLocationIcon = false;

  const {
    latitude,
    longitude,
    setAddress1,
    setAddress2,
    setArea,
    setCity,
    setState,
    setPostalCode,
    setCountry,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);

  const setPosition = (event) => {
    setLatitude(event.lngLat.lat);
    setLongitude(event.lngLat.lng);
  };

  useEffect(() => {
    const setInitialLocation = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    };
    setInitialLocation();
  }, []);

  const [Width, setWidth] = useState("37vw");
  useEffect(() => {
    if (window.innerWidth >= 1366 && window.innerWidth <= 1599)
      setWidth("34.5vw");
    else if (window.innerWidth >= 1600 && window.innerWidth <= 1919)
      setWidth("36.5vw");
    else if (window.innerWidth >= 1920) setWidth("39vw");
    else setWidth("33.5vw");
  }, [window.innerWidth]);

  return (
    <div className="geocoder">
      {latitude != 0 && longitude != 0 && (
        <Map
          mapboxAccessToken={
            "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ"
          }
          style={{
            width: { Width },
            height: "37vh",
            borderRadius: "15px",
            border: "1.5px solid black",
          }}
          initialViewState={{
            longitude: longitude,
            latitude: latitude,
            zoom: 4,
          }}
          mapStyle="mapbox://styles/moniruzzaman-18/cl7zwo94p000014qsaoizctns"
        >
          <Marker
            longitude={longitude}
            latitude={latitude}
            draggable
            onDragEnd={(event) => setPosition(event)}
          />

          <NavigationControl position="bottom-right" />
          <Geocoder />
          <FullscreenControl />
          <GeolocateControl
            onGeolocate={(e) => {
              setLongitude(e.coords.longitude);
              setLatitude(e.coords.latitude);

              var config = {
                method: "get",
                url: `https://api.geoapify.com/v1/geocode/reverse?lat=${e.coords.latitude}&lon=${e.coords.longitude}&apiKey=c05c945718444547b952a78b118c1bb2`,
                headers: {},
              };

              axios(config)
                .then(function (response) {
                  const location = response.data.features[0].properties;
                  setAddress1(location.address_line1 || " ");
                  setAddress2(location.address_line2 || " ");
                  setArea(location.street || " ");
                  setCity(location.city || " ");
                  setState(location.state || " ");
                  setPostalCode(location.postcode || " ");
                  setCountry(location.country || " ");
                })
                .catch(function (error) {
                  console.log(error);
                });
            }}
            showAccuracyCircle={userLocationIcon}
          ></GeolocateControl>
        </Map>
      )}
    </div>
  );
}

export default SetUsersLocation;
