import React, { Fragment,useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ContactStudentModal from "../../Chat/NewChat/ContactStudentModal";
import PreferredLanguage from "../Tutor/PreferredLanguage";
import Qualification from "./../Tutor/Qualification";
import About from "./About";
import { default as Feedback } from "./Feedback";
import InterestToLearn from "./InterestToLearn";

const NewStudentProfileContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const [basicInfo, setBasicInfo] = useState({});
  const [educationInfo, setEducationInfo] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [interestToLearnData, setInterestToLearnData] = useState([]);
  const [profile, setProfile] = useState(man);

  const [profile2, setProfile2] = useState("");
  const [isModal, setIsModal] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const initilindex = { index: 0, isOpen: false, toggler: toggle };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getMemberInfo = async () => {
      var res = await axiosRequest({
        url: "/newMember/singleMemberInfo/" + id,
        method: "post",
        data: {
          type: "tutor"
        }
      });
      if (res) {
        if (res.data) {
          setEducationInfo(res.data.educations);
          setWorkExperience(res.data.workExperiences);
          setInterestToLearnData(res.data.interestToLearn);
          var obj = {
            name: res.data.name,
            gender: res.data.gender,
            address: res.data.address,
            email: res.data.email,
            media: res.data.media,
            about: res.data.about,
            languages: res.data.languages,
            contactNo: res.data.contactNo,
            createdAt: res.data.createdAt,
          };
          setBasicInfo(obj);

          if (res.data.name) {
            setFullName(res.data.name.firstName + " " + res.data.name.lastName);
          }

          const pro_pic = localStorage.getItem("picture2");

          var profUrl =
            res.data.media.picture === "" || res.data.media.picture === null
              ? pro_pic.includes("googleusercontent")
                ? JSON.parse(pro_pic)
                : ""
              : res.data.media.picture.includes("googleusercontent")
                ? res.data.media.picture
                : ProfilePictureRootURL + res.data.media.picture;
          setProfile2(profUrl);
        } else {
          const pro_pic = localStorage.getItem("picture2");
          if (pro_pic.includes("googleusercontent")) {
            setProfile2(JSON.parse(pro_pic));
          } else {
            setProfile2("");
          }
        }
      }
    };
    getMemberInfo();
  }, []);

  const handleStartConversationModal = (e) => {
    e.preventDefault();
    setPhotoIndex({
      ...photoIndex,
      isOpen: true,
      userId: id,
      name: fullname,
      tuitions: interestToLearnData,
    });
    setIsModal(true);
  };
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });

  const [fullname, setFullName] = useState("");
  const [address, setAddress] = useState(null);
  const [isContactButton, setIsContactButton] = useState(true);

  const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
  const id = params.id === "me" ? userData.id : params.id;

  useEffect(() => {
    if (params.id === "me") setIsContactButton(false);
  }, [params.id]);

  useEffect(() => {
    if (memberType === "tutor" && params.id === "me") {
      navigate(`${process.env.PUBLIC_URL}/profile/me`);
    }
  }, [memberType, navigate]);

  const studentInstitueName = educationInfo;
  const studentLastInstitueName = studentInstitueName?.slice(-1);

  return (
    <Fragment>
      <div>
        <Card>
          <CardBody className="d-flex align-items-center">
            <img
              alt="student profile"
              style={{ width: "150px", height: "150px" }}
              className="rounded-circle me-4"
              // src={authenticated ? (profile2 ? profile2 : profile) : profile}
              src={profile2 !== "" ? profile2 : profile}
            />

            <div>
              <CardTitle tag="h3" className="cardBox-2">
                {fullname}
              </CardTitle>
              {studentLastInstitueName?.map((item, index) => (
                <CardText className="mb-0" key={index}>
                  Studied at {item.institute}
                </CardText>
              ))}

              <CardText>
                <span className="mb-0">
                  Lives in {basicInfo?.address?.city}
                  {","} {basicInfo?.address?.country}
                </span>
              </CardText>
            </div>
            {isContactButton && (
              <button
                type="submit"
                className="btn btn-warning text-white ms-1 p-2"
                disabled={false}
                onClick={(e) => handleStartConversationModal(e)}
              >
                Contact Student
              </button>
            )}
          </CardBody>
          {isModal && (
                <ContactStudentModal
                  photoIndex={photoIndex}
                  setPhotoIndex={callback}
                />
              )}
        </Card>

        <Row className="d-flex">
          <Col sm="4">
            <About data={basicInfo} fullname={fullname} />
            <PreferredLanguage data={basicInfo?.languages} />
            <Qualification data={educationInfo} title="Education" />
          </Col>
          <Col sm="8">
            <InterestToLearn data={interestToLearnData} />
            <Feedback fullname={fullname} photo={profile2} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default NewStudentProfileContain;
