import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import RequestTuition from "./Student/RequestTuition";

const RequestTuitionContain = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          {/* <div>index</div> */}
          <RequestTuition />
        </Row>
      </Container>
    </Fragment>
  );
};

export default RequestTuitionContain;
