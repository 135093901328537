import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import TutorFormWizardContain from "../../../Component/AccountNew/Tutor/index";

const TutorAccount = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="TutorNewAccount" title="Tutor New Account" />
      {/* <TutorAccountContain /> */}
      <TutorFormWizardContain />
    </Fragment>
  );
};

export default TutorAccount;
