import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Card,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import ReviewModal from "../../Review/ReviewModal";
const NewTutorTuitionsContain = () => {
  const [isAddReview, setIsAddReview] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [singleTuition, setSingleTuition] = useState({});
  const [title, setTitle] = useState("");
  const [tuitionStatus, setTuitionStatus] = useState("ALL");

  const [tutorTuitions, setTutorTuitions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, photo } = useSelector((state) => state.auth) || {};
  const { selectedUser } = useSelector((state) => state.conversations) || {};

  useEffect(() => {
    getTutorTuitions();
    return () => {
      setTutorTuitions([]);
    };
  }, []);

  const getTutorTuitions = async () => {
    setIsLoading(true);
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/tutor",
      method: "get",
    });
    if (res.data) {
      setTutorTuitions(res.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleTuitionsAddReview = (e, tuitionId) => {
    e.preventDefault();
    const singleTuition = tutorTuitions?.find((t) => t.tuitionId === tuitionId);
    setSingleTuition(singleTuition);
    setTitle("Give a review");
    setIsAddReview(true);
    setModal(true);
  };
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "student") {
      navigate(`${process.env.PUBLIC_URL}/student-tuitions`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h6>Tuitions of Tutor</h6>
                <span style={{ fontSize: "14px" }}>
                  {"To give review on specific tuitions status must be closed, click on "}{" "}
                  <strong> {"Review"} </strong>{" "}
                  {"& give opinion about your student"}
                </span>
                {modal && (
                  <ReviewModal
                    title={title}
                    isOpen={modal}
                    toggler={toggle}
                    isAddReview={isAddReview}
                    data={singleTuition}
                    isTutor={true}
                    isStudent={false}
                  />
                )}
              </CardHeader>
              <div className="table-responsive-sm">
                <Table hover className="table-border-horizontal">
                  <thead>
                    <tr>
                      <th style={{ width: "5%" }} scope="col">
                        {"#"}
                      </th>
                      <th
                        style={{ width: "25%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        {"Tuition Subjects"}
                      </th>
                      <th style={{ width: "15%" }} scope="col">
                        {"Student Name"}
                      </th>
                      <th
                        style={{ width: "15%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        {"Tuition Fee"}
                      </th>
                      <th
                        style={{ width: "18%" }}
                        className="text-nowrap"
                        scope="col"
                      >
                        <div className="row align-items-center">
                          <div className="col-md-4 px-0">{"Status"}</div>
                          <div className="col-md-8">
                            <Input
                              style={{ height: "35px", width: "150px" }}
                              type="select"
                              name="select"
                              className="form-control"
                              value={tuitionStatus}
                              onChange={(e) => setTuitionStatus(e.target.value)}
                            >
                              <option value={""} disabled>
                                Select Status
                              </option>
                              <option value={"ALL"}>ALL</option>
                              <option value={"OPEN"}>Open</option>
                              <option value={"CONFIRMED"}>Confirmed</option>
                              <option value={"CANCELED"}>Canceled</option>
                              <option value={"CLOSED"}>Closed</option>
                            </Input>
                          </div>
                        </div>
                      </th>
                      <th style={{ width: "10%" }} scope="col">
                        {""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr className="text-center">
                        <td colSpan={"6"} rowSpan="3">
                          Loading...
                        </td>
                      </tr>
                    )}
                    {tuitionStatus === "ALL" &&
                      tutorTuitions?.map((item, i) => (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>
                          {item.tuitionRequest?.subjects?.length > 0? item.tuitionRequest?.subjects?.map(
                              (item, index) => (
                                <span
                                  className="badge badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item.label}
                                </span>
                              )
                            ) : item.interestedSubjects?.subjects?.map(
                              (item, index) => (
                                <span
                                  className="badge badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item.name}
                                </span>
                              )
                            ) }
                          </td>
                          <td>
                            {item.studentName}
                            {item.isStudentDeactivated
                              ? " (Account Deactivated)"
                              : ""}
                            {item.isStudentDeleted ? " (Account Deleted)" : ""}
                          </td>
                          <td>{item.confirmedTuitionFee}</td>
                          <td className="p-3">
                            <span className="badge badge-light-dark">
                              {item.status}
                            </span>
                          </td>
                          <td className="px-1">
                            {item.status === "CLOSED" &&
                              !(
                                item.isStudentDeactivated ||
                                item.isStudentDeleted
                              ) && (
                                <button
                                  className="btn px-1 py-0 btn-sm btn-warning"
                                  onClick={(e) =>
                                    handleTuitionsAddReview(e, item.tuitionId)
                                  }
                                >
                                  <span>
                                    {" "}
                                    <i className="fa fa-comment"></i> Review
                                  </span>
                                </button>
                              )}
                          </td>
                        </tr>
                      ))}

                    {tutorTuitions?.map((item, i) =>
                      item.status === tuitionStatus ? (
                        <tr key={i}>
                          <th scope="row">{i + 1}</th>
                          <td>
                            {item.tuitionRequest?.subjects?.length > 0? item.tuitionRequest?.subjects?.map(
                              (item, index) => (
                                <span
                                  className="badge badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item.label}
                                </span>
                              )
                            ) : item.interestedSubjects?.subjects?.map(
                              (item, index) => (
                                <span
                                  className="badge badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item.name}
                                </span>
                              )
                            ) }
                          </td>
                          <td>
                            {item.studentName}
                            {item.isStudentDeactivated
                              ? " Account Deactivated"
                              : ""}
                            {item.isStudentDeleted ? " Account Deleted" : ""}
                          </td>
                          <td>{item.confirmedTuitionFee}</td>
                          <td className="p-3">
                            <span className="badge badge-light-dark">
                              {item.status}
                            </span>
                          </td>
                          <td className="px-1">
                            {item.status == "CLOSED" &&
                              !(
                                item.isStudentDeactivated ||
                                item.isStudentDeleted
                              ) && (
                                <button
                                  className="btn px-1 py-0 btn-sm btn-warning"
                                  onClick={(e) =>
                                    handleTuitionsAddReview(e, item.tuitionId)
                                  }
                                >
                                  <span>
                                    {" "}
                                    <i className="fa fa-comment"></i> Review
                                  </span>
                                </button>
                              )}
                          </td>
                        </tr>
                      ) : null
                    )}
                    {tutorTuitions?.length === 0 && !isLoading && (
                      <tr className="text-center">
                        <td colSpan={"6"} rowSpan="3">
                          No Tuitions Found. Change Status Or Accept Request
                          From Chatbox.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewTutorTuitionsContain;
