const faqList2 = [
  {
    id: 1,
    subTitle: "Cost",
    titless: [
      {
        id: 1,
        title: "How much does it cost?",
        answer:
          "This is free now. The promotion is going on until 31 December 2022. After the promotion, the cost is only USD $5 per month.",
      },
      {
        id: 10,
        title: "Can I cancel at any time?",
        answer:
          "Yes, you can cancel at any time. You will remain member until the date of your monthly subscription day. ",
      },
      {
        id: 11,
        title:
          "Do I need to pay extra to open a student account after registered as tutor?",
        answer:
          "No, there is no additional cost to create your student account once you registered. You can simply create your student profile and start learning after finding a tutor.",
      },
      {
        id: 12,
        title:
          "Do I need to pay extra to open a tutor account after registered as student?",
        answer:
          "No, there is no additional cost to create your tutor account once you registered. You can simply create your tutor profile and start teaching.No, there is no additional cost to create your tutor account once you registered. You can simply create your tutor profile and start teaching.",
      },
    ],
    sort: 1,
    abc: true,
  },
  {
    id: 2,
    subTitle: "Search",
    titless: [
      {
        id: 20,
        title: "How will I find tutors?",
        answer:
          "You can use TuteAir smart filtering options to find your preferred tutors. You contact them using TuteAir chat system, set suitable time and commitment. Then you are ready to learn.",
      },
      {
        id: 21,
        title: "How many tutors can I search?",
        answer: "Once you register, you can search unlimited number of tutors.",
      },
      {
        id: 22,
        title: "How many subjects can I search?",
        answer:
          "Once you register, you can search unlimited number of subjects.",
      },
    ],
    sort: 5,
  },
  {
    id: 3,
    subTitle: "Membership",
    titless: [
      {
        id: 30,
        title: "Can I be a student when I am a tutor?",
        answer:
          "Yes, you certainly can. Your membership allows you to be both or either.",
      },
      {
        id: 31,
        title: "Can I be a tutor when I am a student?",
        answer:
          "Yes, you certainly can. Your membership allows you to be both or either.",
      },
    ],
    sort: 3,
  },
  {
    id: 4,
    subTitle: "Location",
    titless: [
      {
        id: 40,
        title: "What is the location of tutors?",
        answer:
          "Our tutors are from all over the world. You can find tutors in your local area using our smart filtering options via map. Then you can start learning face to face or online. You can also find tutors from other part of the world and start learning online.",
      },
    ],
    sort: 4,
  },
  {
    id: 5,
    subTitle: "Tuition Payment",
    titless: [
      {
        id: 50,
        title: "How will I pay tutors?",
        answer:
          "You will pay tutors based on your negotiation with tutors. We recommend using secure payment method.",
      },
      {
        id: 51,
        title: "Can I pay TuteAir to pay my tutors?",
        answer:
          "No, TuteAir doesn’t take payments for tutor-students tuitions. You need to pay tutors directly using your own secured payment method.",
      },
    ],
    sort: 6,
  },
  {
    id: 6,
    subTitle: "Tutoring",
    titless: [
      {
        id: 60,
        title: "Can I teach multiple subjects?",
        answer:
          "Yes, you can teach as many subjects as you want. There is no additional cost to teach multiple subjects.",
      },
      {
        id: 61,
        title: "Can I cancel a tutor?",
        answer:
          "Yes, you can cancel with a tutor at anytime you prefer based on your negotiation with tutors. You can start searching for new tutors at any time.",
      },
      {
        id: 62,
        title: "Can I cancel teaching a student?",
        answer:
          "Yes, you can cancel at anytime based on your negotiation with students.",
      },
      {
        id: 63,
        title: "How will students find tutor?",
        answer:
          "Students can find tutors using “Find Tutors” option. Then students can send message to tutor and start discussion.",
      },
    ],
    sort: 2,
  },
];

export const faqList = faqList2;
