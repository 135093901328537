import { useEffect } from "react";
import { useSelector } from "react-redux";
import Message from "./Message";

export default function Messages({ messages = [] }) {
  const { user } = useSelector((state) => state.auth) || {};
  useEffect(() => {
    scroolToBottom();
  }, [messages]);

  const scroolToBottom = () => {
    var container = document.querySelector(".chat-history");
    if (messages?.length > 0) {
      if (container?.scrollBy) {
        setTimeout(function () {
          container.scrollBy({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  };

  // const { email } = user || {};
  return (
    <div className="chat-history chat-msg-box custom-scrollbar">
      <ul className="simple-list">
        {messages
          .slice()
          .sort((a, b) => a.timestamp - b.timestamp)
          .map((message, i) => {
            const { text: lastMessage, sender, time } = message || {};

            const justify = sender !== user ? "" : "pull-right other-message";
            const justify2 = sender !== user ? "float-start" : "float-end";

            return (
              <Message
                key={i}
                justify={justify}
                imgClass={justify2}
                message={lastMessage}
                time={time}
              />
            );
          })}
      </ul>
    </div>
  );
}
