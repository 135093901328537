import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import CompletedClass from "./CompletedClass/CompletedClass";
import PaidClass from "./PaidClasses/PaidClasses";
import UpComingClass from "./UpcomingClasses/UpcomingClasses";
import { axiosRequest } from "../../../../redux/utils/axios-utils";

const ClassListViewContain = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  const [VerticleTab, setVerticleTab] = useState("1");

  const [tutorTuitions, setTutorTuitions] = useState([]);

  const getTutorTuitions = async () => {
    var res = await axiosRequest({
      url: "/newTuition/getTuitions/student",
      method: "get",
    });
    if (res.data) {
      setTutorTuitions(res.data);
    }
  };

  useEffect(() => {
    getTutorTuitions();
    return () => {
      setTutorTuitions();
    };
  }, []);
  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row>
          <Col sm="12">
            <Card className={` `}>
              <CardBody className="megaoptions-border-space-sm">
                <Nav
                  className="nav nav-pills "
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "1" ? "active" : ""}
                      onClick={() => setVerticleTab("1")}
                    >
                      <i class="fa fa-table-list"></i>
                      {"Upcoming "}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "2" ? "active" : ""}
                      onClick={() => setVerticleTab("2")}
                    >
                      {/* <i className="icofont icofont-ui-password"></i> */}
                      {"Paid"}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "3" ? "active" : ""}
                      onClick={() => setVerticleTab("3")}
                    >
                      {/* <i className="icofont icofont-email"></i> */}
                      {"Completed"}
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" xl="12 box-col-12">
            <TabContent activeTab={VerticleTab}>
              <TabPane tabId="1">
                <UpComingClass tutorTuitions={tutorTuitions} />
              </TabPane>
              <TabPane tabId="2">
                <PaidClass tutorTuitions={tutorTuitions} />
              </TabPane>
              <TabPane tabId="3">
                <CompletedClass tutorTuitions={tutorTuitions} />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ClassListViewContain;
