import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import { useSelector } from "react-redux";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import MapViewContain from "../../FindTutors/MapView";
import TutorCardContain from "./TutorCardHome";
// import EducationFilter from "./EducationFilter";
import { Link, useNavigate } from "react-router-dom";
import { filterBySubject } from "../../../Services/TutorFilter.Service";
import QuickSearchPublic from "./QuickSearch";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";

export default function ListViewHome({ navigation }) {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    scheduleFilter,
    setScheduleFilter,
    educationFilter,
    setEducationFilter,
    workExperiencesFilter,
    setWorkExperiencesFilter,
    priceFilter,
    setPriceFilter,
    // myFilter: filters,
    // setMyFilter: setFilters,
  } = useContext(FilterContext);
  const navigate = useNavigate();

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
    getTopTutors,
    topTutors,
    setTopTutors,
    getCountries,
    countries,
    setCountries,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(true);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchInitialData();
    setLoading(false);

    return () => {
      // setTopTutors(topTutors);
      // setCountries(countries);
    };
  }, []);

  const fetchInitialData = async () => {
    if (topTutors?.length === 0) {
      await getTopTutors();
    } else {
      const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);
      const newData = shuffle(topTutors);
      setTopTutors(newData);
    }

    if (countries?.length === 0) {
      await getCountries();
    }
  };
  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else {
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    // filtering ends here
    setTutorsToShow(filteredTutors);
    setLoading(false);
  };
  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  const updateFilter = (a) => {
    if (a === 1) setIsSearched(!isSearched);
    setIsReset(false);

    const addedFilter = {};

    if (Object.keys(location)?.length != 0) {
      addedFilter.location = location;
    }

    if (subject?.length != 0) {
      addedFilter.subject = subject;
    }

    setFilters(addedFilter);
    navigate(`${process.env.PUBLIC_URL}/search-tutors`);
  };

  const [isListView, setIsListView] = useState(true);
  const [isMapViewView, setIsMapView] = useState(false);

  return (
    <Fragment>
      <Row
        className={`form-inline mx-0  search_tutor_p pt-4`}
        style={{ marginTop: "0.4rem", placeContent: "center" }}
      >
        <QuickSearchPublic isReset={isReset} countries={countries} />
        <div className="col-md-1 d-flex mb-3 search_btn_public">
          <button
            type="reset"
            className="btn btn-outline-info"
            style={{ minHeight: "38px" }}
            onClick={(e) => updateFilter(1)}
          >
            Search
          </button>
        </div>
      </Row>

      <Row className="mb-0 py-2">
        <Col sm="12" className="text-center ta-student">
          <h1>Our Featured Tutors</h1>
        </Col>
      </Row>
      <Row className="mb-2 tutorViewResponsive">
        {isListView && <TutorCardContain tutors={topTutors} />}
        {isMapViewView && <MapViewContain />}
      </Row>
      <Row>
        <Col md={12} style={{ textAlignLast: "center" }}>
          <Link to={`${process.env.PUBLIC_URL}/search-tutors`}>
            <Button color="info" className="mb-2 ">
              See All Tutor
            </Button>
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
}
