//worked till now
import React, { Fragment } from "react";
import { Card, CardBody, Col, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
const RequestTuitionViewContent = (props) => {
  return (
    <Fragment>
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody className={"" + props.bodyClass}>
        <Col sm="12" xl="12 box-col-12">
          <Card className="mb-0">
            <CardBody className="megaoptions-border-space-sm">
              <div className="table-responsive-sm table-bordered">
                <table className="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td className="p-1 py-1">Category</td>
                      <td className="p-1 py-1">
                        <span>{props?.data?.subjects[0]?.tag}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Subjects</td>
                      <td className="p-1 py-1">
                        {props?.data?.subjects?.map((item, index) => (
                          <span
                            className="badge badge-light-dark"
                            style={{ margin: "0px 3px 2px 0px" }}
                            key={index}
                          >
                            {item.name}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Tuition Place</td>
                      <td className="p-1 py-1">
                        {props?.data?.tuitionTypes?.map((item, index) => (
                          <span
                            className="badge badge-light-dark"
                            style={{ margin: "0px 3px 2px 0px" }}
                            key={index}
                          >
                            {item}
                          </span>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Gender</td>
                      <td className="p-1 py-1">
                        <span>{props?.data?.gender}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Is Profile Picture Exist</td>
                      <td className="p-1 py-1">
                        <span>
                          {props?.data?.profilePicExist ? "Yes" : "No"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Language</td>
                      <td className="p-1 py-1">
                        {props?.data?.languages?.map((item, index) => (
                          <span
                            className="badge badge-light-dark"
                            style={{ margin: "0px 3px 2px 0px" }}
                            key={index}
                          >
                            {item}
                          </span>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 py-1">Country</td>
                      <td className="p-1 py-1">
                        <span>
                          {props?.data?.location
                            ? props?.data?.location?.country
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">City</td>
                      <td className="p-1 py-1">
                        <span>
                          {props?.data?.location
                            ? props?.data?.location?.city
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Post Code</td>
                      <td className="p-1 py-1">
                        <span>
                          {props?.data?.location
                            ? props?.data?.location?.postalCode
                            : ""}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Intended Start Date</td>
                      <td className="p-1 py-1">
                        <span className="badge badge-light-dark">
                          {moment(props?.data?.startDate).format("MMM D, yyyy")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Post Expiry Date</td>
                      <td className="p-1 py-1">
                        <span className="badge badge-light-dark">
                          {moment(props?.data?.endDate).format("MMM D, yyyy")}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 py-1">Additional Info in details</td>
                      <td className="p-1 py-1">{props.data.requestMessage}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </ModalBody>
    </Fragment>
  );
};

export default RequestTuitionViewContent;
