import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "reactstrap";

const Breadcrumbs = (props) => {
  const { title = "", parent = "" } = props;
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title py-3">
          <Row>
            <Col xs="12" sm="6">
              <h4 className="m-0">{title}</h4>
            </Col>
            <Col xs="12" sm="6" className="d-none">
              <div className="breadcrumb">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                      <Home />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>{parent}</BreadcrumbItem>
                  <BreadcrumbItem active>{title}</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
