import React, { Fragment, useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  InputGroup,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import {
  ScheduleEditApi,
  ScheduleSaveApi,
} from "../../../../api/index";
import { authHeader } from "../../../../Services/AuthVerify";
import { toast } from "react-toastify";
import "../../../../style/custom-css/PersonalInfo.css";
import MemberInformationContext from "../../../../_helper/MemberInformation";

const ScheduleModal = (props) => {
  const { getMemberInformation } = useContext(MemberInformationContext)

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [from, setfrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  const [mon, setMon] = useState(true);
  const [tue, setTue] = useState(true);
  const [wed, setWed] = useState(true);
  const [thu, setThu] = useState(true);
  const [fri, setFri] = useState(true);
  const [sat, setSat] = useState(true);
  const [sun, setSun] = useState(true);

  useEffect(() => {
    const localTime = new Date().toLocaleString([], { hour12: false }).slice(12, 17)
    setMon(props.singleSchedule.mon);
    setTue(props.singleSchedule.tue);
    setWed(props.singleSchedule.wed);
    setThu(props.singleSchedule.thu);
    setFri(props.singleSchedule.fri);
    setSat(props.singleSchedule.sat);
    setSun(props.singleSchedule.sun);
    props.edit
      ? setfrom(
        parseTime(getLocalTime(props.singleSchedule.from))
      )
      : setfrom(new Date("01-Nov-2022 08:00 AM"));
    props.edit
      ? setTo(parseTime(getLocalTime(props.singleSchedule.to)))
      : setTo(new Date("01-Nov-2022 11:00 AM"));
  }, [props.singleSchedule]);

  const handleFromChange = (from) => {
    setfrom(from);
  };
  const handleToChange = (to) => {
    setTo(to);
  };

  const parseTime = (userInput) => {
    const hours = userInput.slice(0, 2); // userInput => 12:34
    const minutes = userInput.slice(3);

    const date = new Date();
    date.setHours(hours, minutes);

    return date;
  };

  const getLocalTime = (utcDate) => {
    const date = new Date(utcDate);
    const date_string =
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0"); //+ " " + date.toLocaleString().split(',')[1].slice(-2)
    return date_string; //date.toLocaleString().split(',')[1];
  };

  const addNewSchedule = async () => {

    if (!from) {
      toast.warn("Please add a start time", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (!to) {
      toast.warn("Please add an end time", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (!mon && !tue && !wed && !thu && !fri && !sat && !sun) {
      toast.warn("Please add at least one day", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (window.confirm("Are you sure to save this information?")) {
      const res = await axios.post(
        ScheduleSaveApi,
        {
          from: JSON.stringify(from).slice(12, 17),
          to: JSON.stringify(to).slice(12, 17),
          mon,
          tue,
          wed,
          thu,
          fri,
          sat,
          sun,
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (res.data) {
        const msg = "New Schedule Added Succefully";
        toast.success(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        props.toggler();
        getMemberInformation();
      } else {
        alert("something went wrong!");
      }
      //
    }
  };

  const handleUpdateSchedule = async (e) => {
    e.preventDefault();
    if (!from) {
      toast.warn("Please add a start time", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (!to) {
      toast.warn("Please add an end time", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (!mon && !tue && !wed && !thu && !fri && !sat && !sun) {
      toast.warn("Please add at least one day", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      return false;
    }
    if (window.confirm("Are you sure to update this information?")) {
      const res = await axios.put(
        ScheduleEditApi + "/" + props.singleSchedule.id,
        {
          from: JSON.stringify(from).slice(12, 17),
          to: JSON.stringify(to).slice(12, 17),
          mon,
          tue,
          wed,
          thu,
          fri,
          sat,
          sun,
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (res.data) {
        const msg = "Schedule Updated Succefully";
        toast.success(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        props.toggler();
        getMemberInformation();
      } else {
        alert("something went wrong!");
      }
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        From
                      </Label>
                      <Col sm="4">
                        <InputGroup
                          className="date"
                          id="dt-minimum"
                          data-target-input="nearest"
                        >
                          <DatePicker
                            className="form-control datetimepicker-input digits"
                            selected={from}
                            onChange={handleFromChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="From"
                            timeFormat="HH:mm"
                            dateFormat="H:mm"
                          />
                        </InputGroup>
                      </Col>
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        To
                      </Label>
                      <Col sm="4">
                        <InputGroup
                          className="date"
                          id="dt-minimum"
                          data-target-input="nearest"
                        >
                          <DatePicker
                            className="form-control datetimepicker-input digits"
                            selected={to}
                            onChange={handleToChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="To"
                            timeFormat="HH:mm"
                            dateFormat="H:mm"
                          />
                        </InputGroup>
                      </Col>
                    </div>
                    <Col sm="12" xl="12">
                      <div className="row mb-2"></div>
                    </Col>
                    <div className="row mb-2">
                      <div className="table-responsive-sm">
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th>Mon</th>
                              <th>Tue</th>
                              <th>Wed</th>
                              <th>Thu</th>
                              <th>Fri</th>
                              <th>Sat</th>
                              <th>Sun</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={mon}
                                  onChange={() => setMon(!mon)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={tue}
                                  onChange={() => setTue(!tue)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={wed}
                                  onChange={() => setWed(!wed)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={thu}
                                  onChange={() => setThu(!thu)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={fri}
                                  onChange={() => setFri(!fri)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={sat}
                                  onChange={() => setSat(!sat)}
                                />
                              </td>
                              <td>
                                <Input
                                  type="checkbox"
                                  checked={sun}
                                  onChange={() => setSun(!sun)}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              onClick: (e) =>
                props.edit ? handleUpdateSchedule(e) : addNewSchedule(),
            }}
          >
            Save Changes
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ScheduleModal;
