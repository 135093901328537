import moment from "moment";
import { useEffect, useRef } from "react";
import { Image } from "../../../../AbstractElements";

export default function Message({ justify, imgClass, message, time }) {
  const today = new Date();
  const prevDay = new Date(time);
  const diffTime = Math.abs(today - prevDay);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    //scrollToBottom();
  }, [message]);

  return (
    <li className={`clearfix`}>
      <div className={`message my-message ${justify}`}>
        <Image
          attrImage={{
            src: ``,
            className: `rounded-circle ${imgClass} chat-user-img img-30`,
            alt: "",
          }}
        />
        <div className="message-data text-end mb-0">
          {/* <span className="message-data-time">{moment(time).fromNow()}</span> */}
          <span className="message-data-time">
            {diffDays > 7
              ? moment(time).format("LLL")
              : moment(time).calendar()}
          </span>
        </div>
        <span className="">{message}</span>
      </div>
    </li>
  );
}
