import moment from "moment";
import React, { Fragment, useContext, useEffect } from "react";
import { MoreHorizontal } from "react-feather";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { H5, LI, P, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { Today } from "../../../Constant";
import TutorsContext from "../../../_helper/Tutors";

const RecentlyJoinedTutors = (props) => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const { tutorList, GetAllTutors } = useContext(TutorsContext);
  useEffect(() => {
    if (memberType === "student") {
      GetAllTutors();
    }
  }, [memberType]);
  let tutors = [];

  if (tutorList.length > 5) tutors = tutorList.slice(0, 6);
  else tutors = tutorList;

  return (
    <Fragment>
      {memberType === "student" && (
        <Col xl="6" md="6" className={"dash-31 dash-xl-50 box-col-6"}>
          <Card className="news-update">
            <CardHeader className="card-no-border">
              <div className="header-top">
                <H5 attrH5={{ className: "m-0" }}>Recently Joined</H5>
                <div className="icon-box onhover-dropdown">
                  <MoreHorizontal />
                  <div className="icon-box-show onhover-show-div">
                    <UL>
                      <LI>
                        <a href="#javacsript">{Today}</a>
                      </LI>
                      <LI>
                        <a href="#javacsript"> Yesterday</a>
                      </LI>
                    </UL>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="pt-3">
              <div className="table-responsive-sm">
                <Table borderless>
                  <tbody>
                    {tutors?.map((item, k) => {
                      return (
                        <tr key={k}>
                          <td>
                            <div className="profile-media media">
                              <img
                                src={
                                  item.media.picture === ""
                                    ? man
                                    : ProfilePictureRootURL + item.media.picture
                                }
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "20px",
                                }}
                                className=""
                                alt=""
                              />
                              <div className="px-2">
                                <Link
                                  to={`${process.env.PUBLIC_URL}/profile/${item.userId}`}
                                >
                                  <H5>
                                    {item.name?.firstName +
                                      " " +
                                      item.name?.lastName}
                                  </H5>
                                </Link>
                                <P>{item.about?.tutorBio?.length > 165 ? (
                                  <span>
                                    {item.about?.tutorBio
                                      .substring(0, 164)
                                      .trim()}
                                    ...
                                  </span>
                                ) : (
                                  <span>{item.about?.tutorBio}</span>
                                )}</P>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="badge badge-light-theme-light font-theme-light">
                              Joined {moment(item.createdAt).fromNow()}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="text-center py-2" colSpan={2}>
                        <Link to={`${process.env.PUBLIC_URL}/find-tutors`}>
                          Find More
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};
export default RecentlyJoinedTutors;
