import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { chattingInfo } from "../../../redux/features/chat/conversations/conversationsSlice";
import Blank from "./Blank";
import MyChatStatus from "./inbox/ChatStatus";

const Conversation = () => {
  const { user } = useSelector((state) => state.auth) || {};
  const { topConversation } = useSelector((state) => state.conversations) || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (topConversation) {
      dispatch(
        chattingInfo({
          type: "SELECTED",
          selectedUser: {
            conversationId: topConversation?.conversationId,
            recevierId: topConversation?.users?.find((x) => x !== user),
          },
        })
      );
      navigate(
        `${process.env.PUBLIC_URL}/inbox/${topConversation.conversationId}`
      );
    }
  }, []);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="people-list" id="people-list">
                      {/* <SearchChatList /> */}
                      <MyChatStatus /> {/* Sidebar */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                <Blank /> {/*Chat Body*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Conversation;
