import axios from "axios";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Col, Form, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";
import {
  WorkExpDeleteApi,
} from "../../../../api";
import { authHeader } from "../../../../Services/AuthVerify";
import TutorExperienceModal from "./ExperienceModal";
import MemberInformationContext from "../../../../_helper/MemberInformation";

const TutorExperience = () => {
  const [modal, setModal] = useState(false);
  const [expereincSingleData, setSingleExpereinceData] = useState({});
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");

  const {
    workExperiences,
    setWorkExperiences,
    getMemberInformation
  } = useContext(MemberInformationContext)

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (!workExperiences) {
      getMemberInformation();
    }
    return () => {
      setWorkExperiences([]);
    };
  }, []);

  const addNewExperienceInfo = () => {
    setTitle("Add Working Experience");
    setEdit(false);
    setSingleExpereinceData({
      position: "",
      organization: "",
      startDate: new Date(),
      running: false,
      endDate: new Date(),
      aboutWorkExp: "",
    });
    toggle();
  };

  const handleExpInfoEdit = async (item) => {
    setTitle("Edit Working Experience");
    setEdit(true);
    setSingleExpereinceData(item);
    setModal(true);
  };

  const handleExpInfoDelete = async (id) => {
    if (window.confirm("Are you sure you wish to delete this expericence?")) {
      const deletedItem = await axios.delete(WorkExpDeleteApi + "/" + id, {
        headers: {
          Authorization: authHeader(),
        },
      });
      if (deletedItem) {
        toast.warn("Deleted successully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        getMemberInformation();
      }
    }
  };
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="8">
                  <span>
                    Do you want to add Experience Info to your profile? Please
                    click the “Add New” button.
                  </span>
                </Col>
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: addNewExperienceInfo,
                    }}
                  >
                    Add New
                  </Btn>
                  {modal && (
                    <TutorExperienceModal
                      title={title}
                      isOpen={modal}
                      toggler={toggle}
                      expSingleData={expereincSingleData}
                      edit={edit}
                    />
                  )}
                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>
            <div className="table-responsive-sm">
              <Table className="table-border-horizontal">
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th scope="col" style={{ width: "5%" }}>
                      {"#"}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {"Position"}
                    </th>
                    <th scope="col" style={{ width: "40%" }}>
                      {"Organization"}
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      {"Duration"}
                    </th>
                    <th scope="col" style={{ width: "5%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {workExperiences?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={5}>No Experience Info Found</td>
                    </tr>
                  )}
                  {workExperiences?.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.position}</td>
                      <td>
                        <span className=" px-1 py-0">{item.organization} </span>
                      </td>
                      <td>
                        <code className="text-dark">
                          <span className="badge badge-light-danger">
                            {moment(item.startDate).format("MMM D, yyyy")}
                          </span>
                          <span className="px-2">to</span>

                          {!item.running && (
                            <span className="badge badge-light-danger">
                              {moment(item.endDate).format("MMM D, yyyy")}
                            </span>
                          )}

                          {item.running && <span>Running</span>}
                        </code>
                      </td>
                      <td className="px-1">
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={() => handleExpInfoEdit(item)}
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        <button
                          className="btn px-1 py-0 btn-link text-danger"
                          onClick={() => handleExpInfoDelete(item.id)}
                        >
                          <i className="fa fa-trash"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>

          <Form className="needs-validation d-none" noValidate>
            <H5>Tutor Education Will be there</H5>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};
export default TutorExperience;
