import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { ExternalLink, MapPin } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Media, Row,CardImg, CardText, CardTitle } from "reactstrap";
import { Image, LI, UL } from "../../../AbstractElements";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import FilterContext from "../../../_helper/Filter";
import RequestTuitionModal from "../../RequestTuition/Student/RequestTuitionAddEdit";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css"

const TutorCardContain = ({ tutors }) => {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");

  const isValidateForm = () => {
    if (subject?.length === 0) {
      toastNotifiy("Please Fillup the Subject Field!", "warn");
      return false;
    } else {
      return true;
    }
  };
  
  const openRequestTuitionModal = () => {
    if (isValidateForm()) {
    setTitle("Add Request for Tuition");
    setListViewData({
      gender: gender,
      profilePicture: profilePicExist,
      language: languages,
      location : location,
      tutionType : tutionType,
      subject : subject,
      subjectCategory : subject_category_filter,
      subjectSubCategory : subject_subcategory_filter,
      
    });
    toggle();
    setModal(true);
   }
  }
  return (
    <Fragment>
      <Col xl="8" className="">
        <Row className="search-page">
          <div
            className="search-links tab-pane fade show active"
            id="all-links"
            role="tabpanel"
            aria-labelledby="all-link"
          >
            <Row>
              <Col xl="12 box-col-12 search-banner">
                {tutors &&
                  tutors?.map((item, k) => {
                    return (
                      <div
                        className="info-block p-3 mt-0 mb-2 single_tutor"
                        key={k}
                      >
                        <Media>
                          <Link to={`/profile/${item.userId}`}>
                            <Image
                              attrImage={{
                                className: "img-40 img-fluid m-r-20",
                                src: `${
                                  item.media.picture
                                    ? ` ${ProfilePictureRootURL}/${item.media.picture}`
                                    : man
                                }`,
                                alt: "",
                              }}
                            />
                          </Link>
                          <Media body>
                            <h6 className="tutor_name">
                              <Link to={`/profile/${item.userId}`}>
                                {item.name.firstName + " " + item.name.lastName}
                              </Link>
                              <span
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Open in new tab"
                                className="new_tab_icon"
                              >
                                <Link
                                  to={`/profile/${item.userId}`}
                                  target="_blank"
                                >
                                  <ExternalLink height={13} />
                                </Link>
                              </span>
                            </h6>
                            {item.interestAndPricing?.map((item, i) =>
                              [
                                ...new Set(
                                  item.subjects?.map((sub) => sub.tag)
                                ),
                              ]?.map((subject, k) => (
                                <span
                                  key={k}
                                  className="badge badge-light-primary py-1"
                                >
                                  {subject}
                                </span>
                              ))
                            )}
                            <a href="#javascript">{}</a>
                            <p className="" style={{ textAlign: "justify" }}>
                              {item.about?.tutorBio?.length > 165 ? (
                                <span>
                                  {item.about?.tutorBio
                                    .substring(0, 164)
                                    .trim()}
                                  ...
                                </span>
                              ) : (
                                <span>{item.about?.tutorBio}</span>
                              )}
                            </p>
                            <div className="star-ratings">
                              <UL
                                attrUL={{
                                  className: "simple-list search-info flex-row",
                                }}
                              >
                                <LI>
                                  <span>
                                    <MapPin height={"12px"} />
                                  </span>
                                  <span>
                                    {item.address?.postalCode}{" "}
                                    {item.address?.city} {item.address?.country}
                                  </span>
                                </LI>
                                <LI>
                                  Joined {moment(item.createdAt).fromNow()}
                                </LI>
                                {/* <LI>{"item.vote"}</LI> */}
                                {/* <LI>
                                  {item.interestAndPricing?.map((item, i) =>
                                    [
                                      ...new Set(
                                        item.subjects?.map((sub) => sub.tag)
                                      ),
                                    ]?.map((subject, k) => (
                                      <span
                                        key={k}
                                        className="badge badge-light-primary py-1"
                                      >
                                        {subject}
                                      </span>
                                    ))
                                  )}
                                </LI> */}
                              </UL>
                            </div>
                          </Media>
                        </Media>
                      </div>
                    );
                  })}
              </Col>
              {tutors?.length == 0 && (
                <Col xl="12 box-col-12 search-banner">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <CardTitle className="h4">
                      Tired of getting desired tutor ?
                    </CardTitle>
                    <CardText className="h5">
                      Post your Tutor requirement Details
                    </CardText>
                    <div onClick={openRequestTuitionModal}>
                      <CardText className="h4 mb-2 text-primary clickHere">Click Here</CardText>
                    </div>
                    {modal && (
                      <RequestTuitionModal
                        title={title}
                        isOpen={modal}
                        toggler={toggle}
                        //edit={edit}
                        //view={view}
                        data={listViewData}
                      />
                    )}
                    <CardImg
                      style={{
                        width: "100%",
                        height: "55vh",
                      }}
                      alt="Card image cap"
                      src={NoTutorImage}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </Row>
      </Col>
    </Fragment>
  );
};

export default TutorCardContain;
