import React from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import tuteair from "../assets/img/tuteair-v2.png";
import "./become-tutor.css";
import LoginNew from "./ChildComponent/LoginNew";
import SlickSlider from "./ChildComponent/SlickSlider";

const BecomeTutorLogin = () => {
  return (
    <>
      <div className="" style={{ background: "#737a8a00" }}>
        <Navbar
          collapseOnSelect
          expand="lg"
          className=" mb-5"
          variant="light"
          fixed="top"
          // style={{ background: "#faebd7" }}
        >
          <Container>
            <Link to={`${process.env.PUBLIC_URL}/home`}>
              <Navbar.Brand className="tuteairBrand px-3">
                <img src={tuteair} height="35" className="" alt="TuteAir" />
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav "
              className="tuteair_navbar"
            >
              <Nav className="me-auto"></Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container className="">
          <Row className="m-0">
            <Col
              sm="12"
              lg="6"
              xl="6"
              md="12"
              className="p-0 carousal-page"
              style={{ placeSelf: "center" }}
            >
              <SlickSlider
                slides={1}
                title="Responsive Example"
                rtl={false}
                lazyLoad={true}
              />
            </Col>
            <Col sm="12" lg="6" xl="6" md="12" className="p-0">
              <div className="login-card1 become_tutor_card1">
                <LoginNew />
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </>
  );
};

export default BecomeTutorLogin;
