import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: undefined,
  user: undefined,
  photo: undefined,
  accountType: undefined,
  firstName: undefined,
  lastName: undefined,
  isAccountHide: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      state.photo = action.payload.photo;
      state.accountType = action.payload.accountType;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.isAccountHide = action.payload.isAccountHide;
    },
    userAcconuntVisible: (state, action) => {
      state.isAccountHide = action.payload.isAccountHide;
    },
    userLoggedOut: (state) => {
      state.accessToken = undefined;
      state.user = undefined;
      state.photo = undefined;
      state.accountType = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.isAccountHide = undefined;
    },
  },
});

export const { userLoggedIn, userLoggedOut, userAcconuntVisible } =
  authSlice.actions;
export default authSlice.reducer;
