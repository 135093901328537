import React from "react";
import { MapPin } from "react-feather";
import { ProfilePictureRootURL } from "../../api";
import man from "../../assets/images/avtar/man.png";
import "../../style/custom-css/ProfilePopup.css";

function ProfilePopup(props) {
  const tutorInfo = props.tutorInfo;

  return (
    <div className="container">
      <div>
        <div className="" style={{ margin: "0 0 0 0" }}>
          <div className="px-2 card-photo">
            <img
              src={
                tutorInfo.media.picture
                  ? ` ${ProfilePictureRootURL + tutorInfo.media.picture}`
                  : man
              }
              alt="Profile Picture"
              width={"auto"}
              height={100}
            />
          </div>
          <div className="px-2 py-0 pt-1">
            <p className="mb-0 text-dark">
              {tutorInfo.name.firstName} {tutorInfo.name.lastName}
            </p>
            <div className="text-dark">
              <p className="mb-0" style={{ fontSize: "10px" }}>
                <MapPin style={{ height: "10px" }} />
                {tutorInfo.address?.city}, {tutorInfo.address?.country}
              </p>
            </div>
            <div className="text-dark">
              <span>
                <span>Teaches: </span>
                {tutorInfo.interestAndPricing?.map((item, i) => (
                  [...new Set(item.subjects?.map(sub => sub.tag))]?.map((subject, k) => (
                    <span
                      key={k}
                      className="badge badge-light-primary"
                    >
                      {subject}
                    </span>
                  ))
                ))}
              </span>
            </div>
            <div className="py-1">
              <button
                className="btn btn-sm btn-primary py-0 px-2"
                onClick={() =>
                  window.open(`/profile/${tutorInfo.userId}`, "_blank")
                }
              >
                View Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilePopup;
