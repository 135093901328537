import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardSubtitle, CardText, CardTitle } from "reactstrap";
import { ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import { axiosRequest } from "../../../redux/utils/axios-utils";

export default function Feedback(props) {
  const [myReviews, setMyReviews] = useState([]);
  const [count, setCount] = useState(0);
  const studentPhoto = props.photo === "" ? man : props.photo;

  const { user: userId } = useSelector((state) => state.auth);
  const params = useParams();
  const id = params.id === "me" ? userId : params.id;
  useEffect(() => {
    const getReviews = async () => {
      var res = await axiosRequest({
        url: "/newTuition/getAllMemberReviews",
        method: "post",
        data: {
          memberType: "student",
          userId: id,
        },
      });
      if (res && res.data) {
        var c = 0;
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].reviews.length; j++) {
            if (res.data[i].studentId === res.data[i].reviews[j].toUserId) {
              c++;
              break;
            }
          }
        }
        setCount(c);
        setMyReviews(res.data);
      }
    };

    getReviews();

    return () => {};
  }, []);

  return (
    <div>
      <Card body>
        <div>
          <CardTitle tag="h4">
            Tutor Response for {props.fullname} ({count})
          </CardTitle>
          <CardSubtitle className="py-1">
            We have total {count} feedback from tutor for {props.fullname}
          </CardSubtitle>
          {myReviews?.map((item, i) => (
            <div className="" key={i}>
              {item.reviews?.map((item2, k) => (
                <div key={k}>
                  {item2.toUserId === item.studentId &&
                    item2.parentReviewId === null && (
                      <div className="row border-top py-2">
                        <div className="col-md-1">
                          <img
                            src={
                              item.tutorThumb === "" || item.tutorThumb === " "
                                ? man
                                : ProfilePictureRootURL + item.tutorThumb
                            }
                            className="img-fluid rounded-circle img-60"
                            alt=""
                          />
                        </div>
                        <div className="col-md-11">
                          <CardText className="text-primary mb-0">
                            {item.tutorName}
                          </CardText>
                          <CardText className="mb-0">
                            {/* <span className="mb-0 text-primary">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </span> */}
                            <small className="px-0">
                              {moment(item2.postingTime).format(
                                "Do MMMM YYYY, h:mm a"
                              )}
                            </small>
                          </CardText>
                          <CardText>{item2.reviewText}</CardText>
                        </div>
                      </div>
                    )}

                  {item2.fromUserId === item.studentId &&
                    item2.parentReviewId !== null && (
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-11">
                          <div className="row py-2 border-top">
                            <div className="col-md-1">
                              <img
                                src={studentPhoto}
                                className="img-fluid rounded-circle img-60"
                                alt=""
                              />
                            </div>
                            <div className="col-md-11">
                              <span className="text-primary mb-0">
                                Student's Response
                              </span>
                              <CardText>{item2.reviewText}</CardText>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}
