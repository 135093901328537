import { toast } from "react-toastify";

export function toastMessage(message, type) {
  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  }
}
export function toastBottomRight(message, type) {
  const customId = "custom-id-yes";

  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      toastId: customId
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  } else if (type === "danger") {
    toast.warn(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
  }
}



function parseJwt(token) {
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }