import axios from "axios";
import { NotificationUrl } from "../api/index";
import { authHeader } from "./AuthVerify";

export const AddNotification = async (notifyObject) => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  const picture2 = localStorage.getItem("picture2");
  var senderPhoto = "";
  if (picture2) {
    senderPhoto = picture2?.includes("googleusercontent")
      ? picture2
      : picture2?.split("/")?.pop;
  }

  const { receiver, title, description, context, isPublic } = notifyObject;
  if (!receiver || !title || !description || !context) {
    return "Missing Fields";
  }

  const res = await axios.post(
    NotificationUrl + "/save",
    {
      receiver,
      title,
      description,
      context,
      isPublic,
      senderPhoto: senderPhoto,
      senderName: _tuteair?.name,
    },
    {
      headers: {
        Authorization: authHeader(),
      },
    }
  );

  if (res.data) {
    return true;
  } else {
    return false;
  }
};
