import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BecomeTutorContext from "../../../../_helper/BecomeTutor";
import { handleGoogleLoginRequest } from "../fnBecomeTutor";

export const SignInGoogle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setIsEmailVerified } = useContext(BecomeTutorContext);
  const handleCallBackResponse = async (response) => {
    var loginResponse = await handleGoogleLoginRequest(
      response,
      dispatch,
      navigate
    );

    handleLoginResponse(loginResponse, "lwG");
  };
  const handleLoginResponse = (res, type) => {
    if (res?.isLogged) {
      if (res?.emailVerified && res?.currentStep > 1 && res?.profilePicture) {
        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        navigate(`${process.env.PUBLIC_URL}/become-tutor`);
      }
      if (type === "lwG") setIsEmailVerified(true);
      else setIsEmailVerified(res?.emailVerified);
    } else {
      toast.warn(res?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  useEffect(() => {
    const google = window.google;
    google.accounts?.id?.initialize({
      client_id:
        // "456154972130-srk6dkeei4naj0rahiv1fmdlg3hmscjs.apps.googleusercontent.com", // localhost
        "8149043166-3d47dr03t2t06t36593mpj67ar7f44dh.apps.googleusercontent.com",
      callback: handleCallBackResponse,
    });

    google.accounts?.id?.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);
  return <div id="signInDiv"></div>;
};
