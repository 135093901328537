import { createSlice } from "@reduxjs/toolkit";
import categories from "../../../api/categories.json";
import subjects from "../../../api/subjects.json";

const initialState = {
    subjectCategoryList: undefined,
    subjectSubCategoryList: undefined,
    subjectList: undefined,
    tutorInterestPricing: undefined,
    type: undefined,
};

const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {
        interestSubjects: (state, action) => {
            switch (action.payload.type) {
                case "CATEGORY":
                    let categoryArrList = [];
                    let subCategoryArrList = [];
                    categories?.map((item, i) => {
                        if (item.split("/").length > 0) {
                            categoryArrList.push(item.split("/")[0]);
                        }
                        if (item.split("/").length > 1) {
                            subCategoryArrList.push(item);
                        }
                    });
                    var uniqueCategory = [...new Set(categoryArrList?.map((item) => item))];
                    var categoryObjList = [];
                    if (uniqueCategory) {
                        uniqueCategory?.map((item, index) => {
                            if (item.split("/").length > 0) {
                            categoryObjList.push({
                                value: item.split("/")[0],
                                label: item.split("/")[0],
                                isDisabled: false,
                            });
                            }
                        });
                    }
                    
                    state.subjectCategoryList = categoryObjList;
                    state.subjectSubCategoryList = subCategoryArrList;
                    break;
                case "SUBJECT":
                    let subjectList1 = [];
                    subjects?.map((item, i) => {
                        subjectList1.push({
                            value: item.tag,
                            label: item.name,
                            isDisabled: false,
                        });
                    });
                    state.subjectList = subjectList1;
                    break
                default:
                    break;
            }
           
        },
        tutorProfile: (state, action) => {
            switch (action.payload.type) {
                case "InterestPricing":
                    state.tutorInterestPricing = action.payload.tutorInterestPricing;
                    break;
                default:
                    break;
            }
        }
    },
});

export const {interestSubjects, tutorProfile} = memberSlice.actions;
export default memberSlice.reducer;
