import React, { Fragment, useEffect, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import MemberInformationContext from "../../../_helper/MemberInformation";
import ProfileView from "./ProfileView";
import RecentActivitys from "./RecentActivitys";
import RecentlyJoinedTutors from "./RecentlyJoinedTutors";
import StudenRquestPost from "./StudenRquestPost";

const DefaultContain = () => {
  var userInfo = JSON.parse(localStorage.getItem("tuteAirUser"));
  const {
    getMemberInformation
  } = useContext(MemberInformationContext)

  useEffect(() => {
    getMemberInformation();
  }, [])

  return (
    <Fragment>
      <Container fluid={true} className="default-dash pt-4">
        <Row>
          <Col xs="12">
            <div
              className="knowledgebase-bg"
              style={{
                backgroundImage: `url(${require("../../../assets/images/knowledgebase/bg_1.jpg")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "block",
              }}
            >
              <div className="knowledgebase-search">
                <div>
                  <H3>Hello {`${userInfo?.name}`}</H3>
                  <h4>
                    Welcome to tuteair{" "}
                    {/* {`${accountType.toLocaleUpperCase()} Dashboard`}{" "} */}
                  </h4>
                </div>
              </div>
            </div>
          </Col>
          {/* {ArticalsData.map((item) => {
            return (
              <Col xl="4" lg="6" sm="6" key={item.is} className=" xl-50">
                <Card className="bg-primary">
                  <CardBody>
                    <Media className="faq-widgets">
                      <Media body>
                        <a href="#javascript">
                          <H5>{item.title}</H5>
                        </a>
                        <P>{item.para}</P>
                      </Media>
                      {item.icon}
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            );
          })} */}
        </Row>
        <Row>
          <RecentlyJoinedTutors />
          <StudenRquestPost val={[]} />
          <RecentActivitys />
          <ProfileView />
        </Row>
      </Container>
    </Fragment>
  );
};
export default DefaultContain;
