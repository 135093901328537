import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import imgg from "../../../assets/img/sign/signin-1.png";
import imgg from "../../../assets/4.jpg";
import LoginForm from "./LoginForm";

//renamed LoginOne to SignIn
const SignIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
    if (auth0_profile !== null && auth0_profile.autenticated) {
      navigate(`${process.env.PUBLIC_URL}/dashboard`);
    }
    //loadDataOnlyOnce(); // this will fire only on first render
  }, []);
  //const loadDataOnlyOnce = () => {};

  return (
    <Fragment>
      <section>
        <Container fluid={true} className="p-0">
          <Row className="m-0">
            <Col
              xl="5"
              style={{
                backgroundImage: `url(${imgg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "block",
              }}
            ></Col>
            <Col xl="7 p-0">
              <div className="login-card1">
                <LoginForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default SignIn;
