
export default function Blank() {
    return (
        <div className="chat-box row">
            {/* <img
                src={""}
                alt="No messages"
                className="w-10 hidden md:block"
            /> */}
            <div className="chat-right-aside col">
                <div className="chat text-center py-4">
                    Please select a chat.
                </div>
            </div>
        </div>
    );
}
