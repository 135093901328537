//worked till now
import axios from "axios";
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Select, { createFilter } from "react-select";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Multiselect from "multiselect-react-dropdown";
import { FixedSizeList as List } from "react-window";
import { Btn } from "../../../../AbstractElements";
import { TutorInterestAndPricingGetApi } from "../../../../api/index";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import "../../../../style/custom-css/PersonalInfo.css";

import MemberInformationContext from "../../../../_helper/MemberInformation";
import "./styles.css";
import {
  editInterestPricing,
  getSetEditInterestSubjectData,
  getSubCategoryList1,
  getSubCategoryList2,
  getSubjectList,
  saveInterestPricing,
  validateInterestPricing,
} from "./subjectInterestCommon";

let { options, optionsOld, height } = getSubjectList();

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options?.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const InterestAddEditContent = (props) => {
  const { getMemberInformation } = useContext(MemberInformationContext);

  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currencyList, setCurrencyList] = useState(null);
  const [classList, setClassList] = useState(null);
  const [tutionTypeList, setTutionTypeList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectSubCategory, setSelectSubCategory] = useState([]); // new
  const [selectedOptions, setSubjectSelectedOptions] = useState([]);

  const [selectedTutionTypeOptions, setTutionTypeSelectedOptions] = useState(
    []
  );
  const [isNegotiable, setIsNegotiable] = useState(true);

  //validate amount
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(false);
  const amountErrorMessage = "amount cannot be negative";
  const { subjectCategoryList, subjectSubCategoryList } =
    useSelector((state) => state.member) || {};

  const fnIsValidAmount = (Number) => {
    if (Number >= 0) return true;
    else return false;
  };

  // post request values
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [tutionTypes, setTutionTypes] = useState([]);

  useEffect(() => {
    if (!fnIsValidAmount(amount)) {
      setIsValidAmount(false);
      setShowErrorMessage(true);
    } else {
      setIsValidAmount(false);
      setShowErrorMessage(false);
    }
  }, [amount]);

  const fnSetInitialValueAdd = () => {
    setSubjectSelectedOptions([]);
    setSubCategoryList([]);
    setSubjects([]);
    setClasses("");
    setSpecialization([]);
    setCurrency("");
    setAmount("");
    setTutionTypes([]);
    setIsNegotiable(true);
    options = optionsOld;
  };
  // get all inital values
  const fetchInitialValue = async () => {
    const res = await axios.get(TutorInterestAndPricingGetApi, {
      headers: {
        Authorization: authHeader(),
      },
    });
    var catList = subjectCategoryList
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));
    setCategoryList(catList);
    var clasList1 = [];
    if (res.data.classes) {
      res.data.classes.map((item, index) => {
        clasList1.push({
          value: item._id,
          label: item.name,
        });
      });
    }

    setClassList(clasList1);

    var currList = [];
    if (res.data.currencies) {
      res.data.currencies.map((item, index) => {
        currList.push({
          value: item._id,
          label: item.code + " - " + item.name,
          code: item.code,
          name: item.name,
          countryId: item.countryId,
        });
      });
    }
    currList = currList.slice().sort((a, b) => a.label.localeCompare(b.label));
    setCurrencyList(currList);

    setTutionTypeList([
      "Online",
      "Teachers Place",
      "Students Place",
      "Public Place",
    ]);
  };

  useEffect(() => {
    fetchInitialValue();
    return () => {
      fnSetInitialValueAdd(); // when unmount the component
    };
  }, []);

  useEffect(() => {
    if (props.edit) {
      fnSetInitialEditData();
    } else {
      fnSetInitialValueAdd();
    }
  }, [props.data]);

  const fnSetInitialEditData = () => {
    const subjects = props.data.subjects[0];
    setSelectCategory({
      value: subjects?.tag.includes("/")
        ? subjects?.tag.split("/")[0]
        : subjects?.tag,
      label: subjects?.tag.includes("/")
        ? subjects?.tag.split("/")[0]
        : subjects?.tag,
    });
    fnSetEditedData(
      props.data.subjects[0]?.tag,
      props.data.subjects,
      props.data.specialization
    );
    setClasses({
      value: props.data.classes.id,
      label: props.data.classes.name,
    });
    const currency1 = props.data.currency;
    setCurrency({
      value: currency1.id,
      label: currency1.code + " - " + currency1.name,
      code: currency1.code,
      name: currency1.name,
      countryId: currency1.countryId,
    });

    setAmount(props.data.amount.initialPrice);
    setTutionTypeSelectedOptions(props.data.tutionTypes);
    setTutionTypes(props.data.tutionTypes);
    setIsNegotiable(props.data.amount.isNegotiable);
  };

  const fnSetEditedData = async (tag, subjects2, spSubjects) => {
    const subCatList = subjectSubCategoryList?.filter(
      (x) => x.split("/")[0] === tag.split("/")[0]
    );

    if (subCatList) {
      const { subCategoryObjList2, selectedSubjects } =
        await getSetEditInterestSubjectData(
          subjectSubCategoryList,
          tag,
          subjects2,
          spSubjects,
          options,
          optionsOld
        );

      // let subCategoryObjList = [];
      // subCatList?.map((item, index) => {
      //   subCategoryObjList.push({
      //     value: item.split("/")[0] + "/" + item.split("/")[1],
      //     label: item.split("/")[1],
      //     isDisabled: false,
      //   });
      // });
      setSubCategoryList(subCategoryObjList2);

      // options = optionsOld?.filter((x) => x.tag === tag);
      // var subj3 = [];
      // var spSubjects1 = [];

      // subjects2?.map((item, index) => {
      //   subj3.push({
      //     value: item.name,
      //     label: "[ " + item.tag + " ] " + item.name,
      //     tag: item.tag,
      //     id: item.id,
      //   });
      // });
      setSubjectSelectedOptions(selectedSubjects);

      // spSubjects?.map((item, index) => {
      //   spSubjects1.push({
      //     value: item.name,
      //     label: "[ " + item.tag + " ] " + item.name,
      //     tag: item.tag,
      //     id: item.id,
      //   });
      // });
      // setSubjectSpecialSelectedOptions(selectedSpecializeSubjects);
      // setSpecializationList(selectedSubjects);
      if (tag.includes("/")) {
        setSelectSubCategory({
          value: tag.includes("/") ? tag : "",
          label: tag.includes("/") ? tag.split("/")[1] : "",
        });
      }
    } else {
      alert(22);
    }
  };

  const handleSubjects = async (sub) => {
    if (!sub || sub.length === 0) {
      setSubjects([]);
      setSubjectSelectedOptions([]);
      return;
    }
    setSubjectSelectedOptions(sub);

    options = options?.filter((x) => x.tag === sub[0]?.tag);

    if (sub[0]?.tag.includes("/")) {
      setSelectCategory({
        value: sub[0]?.tag.split("/")[0],
        label: sub[0]?.tag.split("/")[0],
      });

      var subCatList = await getSubCategoryList2(
        sub[0],
        subjectSubCategoryList
      );

      setSubCategoryList(subCatList);
      setSelectSubCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag.split("/")[1],
      });
    } else {
      if (!selectedOptions) {
        alert(1);
      }
      setSelectCategory({
        value: sub[0]?.tag,
        label: sub[0]?.tag,
      });
    }
  };

  const handleCategories = async (item) => {
    setSubjectSelectedOptions([]);
    setSelectCategory(item);
    setSelectSubCategory([]);
    if (!item) {
      setSubCategoryList([]);
      setSubjectSelectedOptions([]);
      options = optionsOld;
      return;
    }
    options = optionsOld?.filter((x) => x.tag.split("/")[0] === item?.value);

    var subCatList = await getSubCategoryList1(item, subjectSubCategoryList);
    if (subCatList) setSubCategoryList(subCatList);
  };

  const handleSubCategory = async (item) => {
    setSubjectSelectedOptions([]);
    setSelectSubCategory(item);
    options = optionsOld.filter((x) => x.tag === item.value);
  };
  // set subjects & specializations for send backend
  useEffect(() => {
    let arr1 = [];
    let arr2 = [];
    if (
      selectedOptions !== null &&
      selectedOptions !== undefined &&
      selectedOptions.length > 0
    ) {
      selectedOptions?.map((item) => {
        arr1.push({
          id: item.id,
          tag: item.tag,
          name: item.value,
        });
      });

      setSubjects(arr1);
      setSpecialization(arr2);
    }
  }, [selectedOptions]);

  const handleSaveChangesInterestAndPricing = async (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
      subjects,
      specialization,
      classes,
      currency,
      amount,
      isNegotiable,
      tutionTypes,
      id: props.data.interestId,
    };

    if (validateInterestPricing(data)) {
      const res = await saveInterestPricing(data);
      if (res) {
        if (res.status === 201) {
          const msg = "Successfully Added Your Interest to learn Information!";
          toastNotifiy(msg, "success");
          props.toggler();
          getMemberInformation();
          setLoading(false);
        } else {
          toastNotifiy("Failed to insert!!", "warn");
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const handleUpdateInterestInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    var data = {
      subjects,
      specialization,
      classes,
      currency,
      amount,
      isNegotiable,
      tutionTypes,
      id: props.data.interestId,
    };

    if (validateInterestPricing(data)) {
      const res = await editInterestPricing(data);
      if (res) {
        if (res.status === 201) {
          const msg =
            "Successfully Updated Your Interest to learn Information!";
          toastNotifiy(msg, "success");
          props.toggler();
          getMemberInformation();

          setLoading(false);
        } else {
          toastNotifiy("Failed to insert!!", "warn");
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <div>
                      <Row>
                        <Col md={6}>
                          <Label className="col-form-label asterisk" htmlFor="">
                            Subject Category
                          </Label>
                          <Select
                            className="darkMode"
                            options={categoryList}
                            placeholder="Select Category"
                            onChange={(item) => handleCategories(item)}
                            value={selectCategory}
                            isClearable={true}
                          />
                        </Col>
                        <Col
                          md={6}
                          className={subCategoryList.length > 0 ? "" : "d-none"}
                        >
                          <Label className="col-form-label asterisk" htmlFor="">
                            Sub Category
                          </Label>
                          <Select
                            className="darkMode"
                            options={subCategoryList}
                            placeholder="Select Sub Category"
                            onChange={(item) => handleSubCategory(item)}
                            value={selectSubCategory}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col md={12}>
                        <Label
                          className="col-form-label asterisk"
                          htmlFor="inputEmail3"
                        >
                          Subject
                        </Label>
                        <Select
                          isMulti
                          components={{ MenuList }}
                          options={options}
                          className="darkMode"
                          isClearable={true}
                          placeholder="Select Subjects"
                          isSearchable={true}
                          isDisabled={false}
                          isLoading={false}
                          closeMenuOnSelect={false}
                          filterOption={createFilter({ ignoreAccents: false })}
                          onChange={(item) => handleSubjects(item)}
                          value={selectedOptions}
                        />
                      </Col>
                      <Col md={6}>
                        <Label
                          className="col-form-label asterisk"
                          htmlFor="inputEmail3"
                        >
                          Degree
                        </Label>
                        <Select
                          options={classList}
                          placeholder="Select Degree"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setClasses(item)}
                          value={classes}
                        />
                      </Col>
                      <Col md={6}>
                        <Label className="col-form-label" htmlFor="">
                          Tuition Type
                        </Label>
                        <Multiselect
                          className="darkMode"
                          isObject={false}
                          options={tutionTypeList}
                          showCheckbox
                          onRemove={(e) => {
                            setTutionTypes(e);
                          }}
                          onSelect={(e) => {
                            setTutionTypes(e);
                          }}
                          selectedValues={selectedTutionTypeOptions}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Label className="col-form-label asterisk" htmlFor="">
                          Currency
                        </Label>
                        <Select
                          options={currencyList}
                          placeholder="Select Currency"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setCurrency(item)}
                          value={currency}
                        />
                        {/* <Input
                          type="select"
                          name="select"
                          className="form-control"
                          value={currency}
                          onChange={(e) => setCurrency(e.target.value)}
                        >
                          <option value="0">Select Currency</option>
                          {currencyList?.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </Input> */}
                      </Col>

                      <Col md={6}>
                        <Label className="col-form-label asterisk" htmlFor="">
                          Amount
                        </Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className="form-control inputFieldStyle"
                            type="number"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) =>
                              setAmount(e.target.value.slice(0, 6))
                            }
                          />

                          <span className="ms-2">/Hour</span>
                        </div>
                        {showErrorMessage && !isValidAmount ? (
                          <div className="text-danger">
                            {amountErrorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={4}
                        className="mt-2 ms-2 d-flex align-items-center"
                      >
                        <Input
                          //id=""
                          className="form-control"
                          type="checkbox"
                          checked={isNegotiable === true}
                          onChange={(event) => {
                            setIsNegotiable(event.target.checked);
                          }}
                        />
                        <Label className="col-form-label" htmlFor="">
                          &nbsp;&nbsp;Is Negotiable
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) =>
                props.edit
                  ? handleUpdateInterestInfo(e)
                  : handleSaveChangesInterestAndPricing(e),
            }}
          >
            {/* {loading ? "LOADING..." : "Save changes"} */}
            {loading
              ? "LOADING..."
              : props.edit
              ? "Update Changes"
              : "Save Changes"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default InterestAddEditContent;
