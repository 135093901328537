import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import StudentProfileContain from "../../Component/StudentProfile";

const StudentProfile = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="StudentProfile" title="StudentProfile" />
      <StudentProfileContain />
    </Fragment>
  );
};

export default StudentProfile;
