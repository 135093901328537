import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { axiosRequest } from "../../../redux/utils/axios-utils";
import { toastNotifiy } from "../../../Services/AuthVerify";

export default function EmailNotification() {
  const [isEdit, setIsEdit] = useState(false);
  const [firstName, setFirstName] = useState(fName);
  const [memberType, setMemberType] = useState();
  const [newsAndUpdate, setNewsAndUpdate] = useState(true);
  const [valuableOpinions, setValuableOpinions] = useState(true);
  const [studentContact, setStudentContact] = useState(true);
  const [tutorContact, setTutorContact] = useState(true);
  const [studentMessage, setStudentMessage] = useState(true);
  const [tutorMessage, setTutorMessage] = useState(true);

  const {
    firstName: fName,
    lastName: lName,
    user: userId,
  } = useSelector((state) => state.auth) || {};

  const handleEditIcon = async () => {
    setIsEdit(!isEdit);
    var response = await axiosRequest({
      url: "/notification/get-setting-notification",
      method: "get",
    });
    if (response.status == 200) {
      console.log(response.data);
      setNewsAndUpdate(response.data.newsAndUpdate);
      setValuableOpinions(response.data.setValuableOpinions);
      setStudentContact(response.data.setStudentContact);
      setTutorContact(response.data.tutorContact);
      setStudentMessage(response.data.studentMessage);
      setTutorMessage(response.data.tutorMessage);
    } else {
      // do nothing
    }
  };

  useEffect(() => {
    var memberType = localStorage.getItem("swMember");
    setMemberType(memberType);
  }, [memberType]);

  const handleUpdateEmailNotification = async (e) => {
    e.preventDefault();
    var response = await axiosRequest({
      url: "/notification/setting-notification",
      method: "post",
      data: {
        newsAndUpdate,
        valuableOpinions,
        studentContact,
        tutorContact,
        studentMessage,
        tutorMessage,
      },
    });

    if (response.status == 200) {
      SweetAlert.fire({
        title: "Successfully saved your email preferences!",
        icon: "success",
      });
    } else {
      toastNotifiy("Something went wrong!", "warn");
    }
  };
  return (
    <Fragment>
      <Card className="mb-0">
        <CardBody className="megaoptions-border-space-sm">
          <Row>
            <Col sm="9">
              <h3 className="tab_title">Email Notifications</h3>
            </Col>
            <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
          </Row>
          <div className={`row mb-1 pt-3 ${isEdit ? "d-none" : ""}`}>
            <Label className="col-sm-3 col-form-label pt-0" htmlFor="">
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={handleEditIcon}
              >
                Email Notification Preference
              </button>
            </Label>
            <Col sm="7">
              <p>What notifications you receive from TuteAir.</p>
            </Col>
            <Col sm="2" style={{ textAlign: "-webkit-right" }}>
              <button
                className="btn btn-default btn-sm text-primary"
                onClick={handleEditIcon}
              >
                View
              </button>
            </Col>
          </div>

          <Row className={`${!isEdit ? "d-none" : ""}`}>
            <Col sm="9">
              <h4 className="tab_title">General</h4>
            </Col>

            <FormGroup className="form-group">
              <Row>
                <Col md={10}>
                  <div
                    className="row mt-2 ms-2 d-flex align-items-center"
                    style={{ placeItems: "center" }}
                  >
                    <Input
                      id=""
                      type="checkbox"
                      checked={newsAndUpdate === true}
                      onChange={(event) => {
                        setNewsAndUpdate(event.target.checked);
                      }}
                    />

                    <Label
                      className="col-sm-10 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      News and Updates.
                    </Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <div
                    className="row mt-2 ms-2 d-flex align-items-center"
                    style={{ placeItems: "center" }}
                  >
                    <Input
                      id=""
                      type="checkbox"
                      checked={valuableOpinions === true}
                      onChange={(event) => {
                        setValuableOpinions(event.target.checked);
                      }}
                    />

                    <Label
                      className="col-sm-10 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Share your valuable opinions by particiapting in TuteAir
                      surveys.
                    </Label>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <div className="row mb-1 pt-3">
                <Col sm="6">
                  <h4 className="tab_title">
                    {fName} {lName} <span>({memberType?.toUpperCase()})</span>
                  </h4>
                </Col>
                <Col sm="3" style={{ textAlign: "-webkit-right" }}></Col>
              </div>

              {memberType === "tutor" && (
                <div>
                  <Row>
                    <Col md={10}>
                      <div
                        className="row mt-2 ms-2 d-flex align-items-center"
                        style={{ placeItems: "center" }}
                      >
                        <Input
                          id=""
                          type="checkbox"
                          checked={studentContact === true}
                          onChange={(event) => {
                            setStudentContact(event.target.checked);
                          }}
                        />

                        <Label
                          className="col-sm-10 col-form-label"
                          htmlFor="inputEmail3"
                        >
                          A new student contacted you.
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10}>
                      <div
                        className="row mt-2 ms-2 d-flex align-items-center"
                        style={{ placeItems: "center" }}
                      >
                        <Input
                          id=""
                          type="checkbox"
                          checked={studentMessage === true}
                          onChange={(event) => {
                            setStudentMessage(event.target.checked);
                          }}
                        />

                        <Label
                          className="col-sm-10 col-form-label"
                          htmlFor="inputEmail3"
                        >
                          New message from existing chat with student.
                        </Label>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {memberType === "student" && (
                <div>
                  <Row>
                    <Col md={10}>
                      <div
                        className="row mt-2 ms-2 d-flex align-items-center"
                        style={{ placeItems: "center" }}
                      >
                        <Input
                          id=""
                          type="checkbox"
                          checked={tutorContact === true}
                          onChange={(event) => {
                            setTutorContact(event.target.checked);
                          }}
                        />

                        <Label
                          className="col-sm-10 col-form-label"
                          htmlFor="inputEmail3"
                        >
                          A new tutor contacted you
                        </Label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10}>
                      <div
                        className="row mt-2 ms-2 d-flex align-items-center"
                        style={{ placeItems: "center" }}
                      >
                        <Input
                          id=""
                          type="checkbox"
                          checked={tutorMessage === true}
                          onChange={(event) => {
                            setTutorMessage(event.target.checked);
                          }}
                        />

                        <Label
                          className="col-sm-10 col-form-label"
                          htmlFor="inputEmail3"
                        >
                          New message from existing chat with tutor
                        </Label>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </FormGroup>
            <CardFooter>
              <button
                className="btn btn-sm btn-warning mx-2 w-30"
                onClick={(e) => setIsEdit(!isEdit)}
              >
                Cancel
              </button>
              <button
                className="btn btn-sm btn-info w-30"
                onClick={(e) => handleUpdateEmailNotification(e)}
              >
                Update Changes
              </button>
            </CardFooter>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
}
