import React, { Fragment, useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { useContext } from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import tuteair from "../assets/img/tuteair-v2.png";
// import icon from "../../../assets/img/tuteair-logo-icon.png";
import { userLoggedOut } from "../../../redux/features/auth/authSlice";
import { clearMemberMenus } from "../../../redux/features/menus/menuSlice";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import "./header.css";

const Header = () => {
  const { clearBecomeTutorContext } = useContext(BecomeTutorContext);
  const { clearBecomeStudentContext } = useContext(BecomeStudentContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");

  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));

  const {
    setAddress1,
    setAddress2,
    setArea,
    setCity,
    setState,
    setPostalCode,
    setCountry,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);

  const clearProvider = () => {
    setAddress1("");
    setAddress2("");
    setArea("");
    setCity("");
    setState("");
    setPostalCode("");
    setCountry("");
    setLatitude("");
    setLongitude("");
  };

  useEffect(() => {
    if (_tuteair?._id && _tuteair?.accessToken) {
      setVisible(true);
      setName(_tuteair.name);
    } else {
      setVisible(false);
    }
  }, []);

  const Logout = () => {
    dispatch(userLoggedOut());
    dispatch(clearMemberMenus());
    clearProvider();
    localStorage.clear();
    navigate(`${process.env.PUBLIC_URL}/login-account`);
  };

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <Fragment>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg_navbar mb-5"
        variant="light"
        fixed="top"
        // style={{ background: "#faebd7" }}
      >
        <Container>
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <Navbar.Brand className="tuteairBrand px-3">
              <img src={tuteair} height="35" className="" alt="TuteAir" />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="tuteair_navbar d-none"
          >
            <Nav className="me-auto">
              {_tuteair?.accountType !== "student" && (
                <Nav className="px-0 nav-link">
                  <Link
                    to={`${process.env.PUBLIC_URL}/become-tutor`}
                    className={url === "/become-tutor" ? "nav_active" : ""}
                  >
                    <span className="be_tutor">Become a Tutor</span>
                  </Link>
                </Nav>
              )}

              <Nav className="px-0 nav-link">
                <Link
                  to={`${process.env.PUBLIC_URL}/search-tutors`}
                  className={url === "/search-tutors" ? "nav_active" : ""}
                >
                  <span className="be_tutor">Find Tutors</span>
                </Link>
              </Nav>
            </Nav>
            <Nav>
              <Nav className="px-0 nav-link">
                <Link
                  to={`${process.env.PUBLIC_URL}/get-help`}
                  className={url === "/get-help" ? "nav_active" : ""}
                >
                  <span className="be_tutor">Help</span>
                </Link>
              </Nav>
              {visible && _tuteair?.emailVerified ? (
                <Nav className="px-0 nav-link">
                  <Link
                    to={`${process.env.PUBLIC_URL}/dashboard`}
                    className={url === "/dashboard" ? "nav_active" : ""}
                  >
                    <span className="be_tutor">Dashboard</span>
                  </Link>
                </Nav>
              ) : (
                <Nav className="px-0 nav-link">
                  <Link
                    to={`${process.env.PUBLIC_URL}/register-v2`}
                    className={url === "/register-v2" ? "nav_active" : ""}
                  >
                    <span className="be_tutor">Register</span>
                  </Link>
                </Nav>
              )}

              <Nav className="px-0 nav-link">
                {visible ? (
                  <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                    <span className="be_tutor" onClick={Logout}>
                      Logout
                    </span>
                  </Link>
                ) : (
                  <Link to={`${process.env.PUBLIC_URL}/login-account`}>
                    <span className="be_tutor">Login</span>
                  </Link>
                )}
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default Header;
