import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./AccountSettings.css";
import ChangePassword from "./ChangePassword";
import EmailNotification from "./EmailNotification";
import GeneralProfile from "./GeneralProfile";
import HideAccount from "./HideAccount";
import MyProfile from "./myProfile";
import SecurityAndPrivacySettings from "./SecurityAndPrivacySettings";

const AccountSettingContain = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  const [VerticleTab, setVerticleTab] = useState("1");

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row className="pb-0 mb-0 d-none">
          <Col sm="12">
            <MyProfile />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <Card style={{ height: "65vh" }}>
              <CardTitle>
                <h3 className="p-4 pb-0">Settings</h3>
              </CardTitle>
              <CardBody className="megaoptions-border-space-sm">
                <Nav className="nav flex-column nav-pills">
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "1" ? "active" : ""}
                      onClick={() => setVerticleTab("1")}
                    >
                      <i className="icofont icofont-teacher"></i>
                      {fName + " " + lName}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "2" ? "active" : ""}
                      onClick={() => setVerticleTab("2")}
                    >
                      <i className="icofont icofont-ui-password"></i>
                      {"Change Password"}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "3" ? "active" : ""}
                      onClick={() => setVerticleTab("3")}
                    >
                      <i className="icofont icofont-email"></i>
                      {"Email Notification"}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "4" ? "active" : ""}
                      onClick={() => setVerticleTab("4")}
                    >
                      <i className="icofont icofont-ssl-security"></i>
                      {"Security & Privacy"}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={VerticleTab === "5" ? "active" : ""}
                      onClick={() => setVerticleTab("5")}
                    >
                      <i className="icofont icofont-zipped"></i>
                      {"Hide & Visualize"}
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>

          <Col sm="8" md="8" xl="8 box-col-8">
            <TabContent activeTab={VerticleTab}>
              <TabPane className="fade show" tabId="1">
                <GeneralProfile />
              </TabPane>
              <TabPane tabId="2">
                <ChangePassword />
              </TabPane>
              <TabPane tabId="3">
                <EmailNotification />
              </TabPane>
              <TabPane tabId="4">
                <SecurityAndPrivacySettings />
              </TabPane>
              <TabPane tabId="5">
                <HideAccount />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default AccountSettingContain;
