import React, { Fragment } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import TutorProfileContain from "../../Component/TutorProfile";

const TutorProfile = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="TutorProfile" title="TutorProfile" />
      <TutorProfileContain />
    </Fragment>
  );
};

export default TutorProfile;
