import axios from "axios";
import { toast } from "react-toastify";
import React, {
  Component,
  Fragment,
  useEffect,
  useState,
  useContext,
} from "react";
import DatePicker from "react-datepicker";

import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
  FormGroup,
  FormText,
  Button,
} from "reactstrap";

import { Btn } from "../../../../AbstractElements";
import {
  ClassSchedulePostApi,
  ClassScheduleUpdateApi,
  ClassScheduleDeleteApi,
} from "../../../../api/index";

import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import { toastMessage } from "../../../../redux/utils/tuteairCommon";
import "../../../AccountNew/Student/InterestLearn/styles.css";
import Select, { createFilter } from "react-select";
import { AddNotification } from "../../../../Services/Notification.Service";

const PopUp = (props) => {
  const [start, setStartDate] = useState(new Date());
  const [end, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [myTuitions, setMyTuitions] = useState([]);
  const [subjects, setsubjects] = useState("");
  const [title, setTitleName] = useState("");
  const [selectedTuition, setSelectedTuition] = useState("");
  const [allDay, setAllDay] = useState(false);

  const intitalFetch = () => {
    var arrList = [];

    if (props.tutorTuitions?.map((item) => item.status == "CONFIRMED")) {
      props.tutorTuitions?.map((item, index) => {
        var subjects = "";
        if (item.tuitionRequest?.subjects && item.status == "CONFIRMED") {
          item.tuitionRequest?.subjects?.map((item2, index2) => {
            var comma =
              item.tuitionRequest?.subjects?.length - 1 === index2 ? "" : " - ";
            subjects = subjects + item2.label?.toString().trim() + comma;
          });
        } else if (item.status == "CONFIRMED") {
          item.interestedSubjects?.subjects?.map((item2, index2) => {
            var comma =
              item.interestedSubjects?.subjects?.length - 1 === index2
                ? ""
                : " - ";
            subjects = subjects + item2.name?.toString().trim() + comma;
          });
        }
        if (subjects) {
          subjects = subjects + " (" + item.studentName + ")";
        }

        arrList.push({
          value: item.tuitionId,
          label: subjects,
          isDisabled: false,
        });
      });

      setMyTuitions(arrList);
    }
  };

  useEffect(() => {
    intitalFetch();
  }, []);

  useEffect(() => {
    setStartDate(new Date(props?.data?.start));
    setTitleName(props?.data?.title);

    setEndDate(new Date(props?.data?.end));
    const myTuition = props.tutorTuitions?.filter(
      (t) => t.tuitionId === props?.data?.tuition
    );
    var mySelectedTuition = "";
    var subjects = "";
    myTuition?.map((item, index) => {
      if (
        item.tuitionRequest?.subjects &&
        item.tuitionId == props?.data?.tuition
      ) {
        item.tuitionRequest?.subjects?.map((item2, index2) => {
          var comma =
            item.tuitionRequest?.subjects?.length - 1 === index2 ? "" : " - ";
          subjects = subjects + item2.label?.toString().trim() + comma;
        });
      } else if (item.tuitionId == props?.data?.tuition) {
        item.interestedSubjects?.subjects?.map((item2, index2) => {
          var comma =
            item.interestedSubjects?.subjects?.length - 1 === index2
              ? ""
              : " - ";
          subjects = subjects + item2.name?.toString().trim() + comma;
        });
      }
    });
    if (myTuition) {
      mySelectedTuition = {
        value: myTuition ? props?.data?.tuition : "",
        label: subjects,
        isDisabled: false,
      };
      setSelectedTuition(mySelectedTuition);
    }
  }, []);

  const handleStartDateChange = (date) => {
    setStartDate(new Date(date));
  };
  const handleEndDateChange = (date) => {
    setEndDate(new Date(date));
  };
  const checkboxHandler = async (isChk) => {
    setAllDay(isChk);
  };

  const validateSchedule = () => {
    if (!isNaN(title)) {
      toastMessage("Enter valid Event Name!", "warn");
      return false;
    } else {
      return true;
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    const daysBetween = new Date(end).getDate() - new Date(start).getDate();
    const myTuition = props.tutorTuitions?.filter(
      (t) => t.tuitionId === selectedTuition.value
    )[0];
    const participants = [];
    const participant = {
      studentId: myTuition.studentId,
      payment: {
        ispaid: false,
        amount: 0,
        currency: myTuition.currency,
        TransactionNo: "",
      },
    };
    participants.push(participant);
    var status = false;
    if (validateSchedule()) {
      if (window.confirm("Are you sure to save this information?")) {
        for (let i = 0; i < daysBetween + 1; i++) {
          start.setDate(i > 0 ? start.getDate() + 1 : start.getDate() + 0);
          end.setDate(start.getDate() + 0);
          let request = {
            tuition: selectedTuition.value,
            participants,
            title,
            start,
            end,
          };
          const res = await axios.post(ClassSchedulePostApi, request, {
            headers: {
              Authorization: authHeader(),
            },
          });

          if (res.status === 200) {
            status = true;
          }
        }
        if (status) {
          const msg = "Successfully Added Event Information!";
          toastNotifiy(msg, "success");
          props.toggle();
          setLoading(false);
          const notifyObj = {
            receiver: myTuition.studentId,
            title: title + " class Created",
            description: "New Class created by Tutor",
            context: process.env.PUBLIC_URL + "/schedule",
            isPublic: false,
          };
          AddNotification(notifyObj);
        } else {
          toastNotifiy("Failed to insert!!", "warn");
          setLoading(false);
        }
        return;
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const myTuition = props.tutorTuitions?.filter(
      (t) => t.tuitionId === selectedTuition.value
    )[0];

    if (validateSchedule()) {
      if (window.confirm("Are you sure to update this information?")) {
        setLoading(true);
        var req = {
          tuition: selectedTuition.value,
          //participants,
          title,
          start,
          end,
        };
        const res = await axios.put(
          ClassScheduleUpdateApi + "/" + props.data._id,
          req,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        if (res.status === 200) {
          if (res.data && res.data) {
            const msg = "Schedule Updated Succefully";
            toast.success(msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            props.toggle();
            const notifyObj = {
              receiver: myTuition.studentId,
              title: title + " class has been rescheduled",
              description: "Class rescheduled by Tutor",
              context: process.env.PUBLIC_URL + "/schedule",
              isPublic: false,
            };
            AddNotification(notifyObj);
          } else {
            toast.warn("Failed to add", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
          setLoading(false);
        } else {
          toastNotifiy("Failed to insert!!", "warn");
          setLoading(false);
        }
        return;
      }
    }
  };

  const handleDeleteEvent = async (e) => {
    if (window.confirm("Are you sure you wish to delete this event?")) {
      const deletedItem = await axios.delete(
        ClassScheduleDeleteApi + "/" + props.data._id,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );
      if (deletedItem) {
        toast.warn("Deleted successully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        const notifyObj = {
          receiver: props.data.participants[0].studentId,
          title: props.data?.title + " class has been deleted",
          description: "Class deleted by Tutor",
          context: process.env.PUBLIC_URL + "/schedule",
          isPublic: false,
        };
        AddNotification(notifyObj);
      }
      props.toggle();
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered
        // className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">Event Name</Label>
                        <Input
                          type="text"
                          placeholder="Event Name"
                          value={title}
                          onChange={(e) => setTitleName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleEmail">Subject</Label>
                        <Select
                          options={myTuitions}
                          placeholder="Select Subject"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setSelectedTuition(item)}
                          value={selectedTuition}
                        />
                      </FormGroup>
                    </Form>
                  </Col>
                  <div className="row mb-2">
                    {!allDay && (
                      <Col sm="6">
                        <DatePicker
                          className="form-control digits"
                          selected={start}
                          // maxDate={end}
                          onChange={handleStartDateChange}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          // dateFormat="dd MMMM yyyy"

                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy h:mm aa"
                          timeInputLabel="Time:"
                          showTimeInput
                        />
                      </Col>
                    )}

                    {allDay && (
                      <Col sm="6">
                        <DatePicker
                          className="form-control digits"
                          selected={start}
                          // maxDate={end}
                          onChange={handleStartDateChange}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy"
                          // timeInputLabel="Time:"
                          // showTimeInput
                        />
                      </Col>
                    )}
                    {!allDay && (
                      <Col sm="6">
                        <DatePicker
                          className="form-control digits"
                          selected={end}
                          minDate={start}
                          // maxDate={new Date()}
                          onChange={handleEndDateChange}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy h:mm aa"
                          timeInputLabel="Time:"
                          showTimeInput
                        />
                      </Col>
                    )}
                    {allDay && (
                      <Col sm="6">
                        <DatePicker
                          className="form-control digits"
                          selected={end}
                          minDate={start}
                          // maxDate={new Date()}
                          onChange={handleEndDateChange}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd MMMM yyyy"
                          // timeInputLabel="Time:"
                          // showTimeInput
                        />
                      </Col>
                    )}
                  </div>
                  {/* <div
                    className="row  ms-1 d-flex align-items-center"
                    style={{ placeItems: "center" }}
                  >
                    <Input
                      id=""
                      type="checkbox"
                      checked={allDay === true}
                      onChange={(event) => {
                        checkboxHandler(event.target.checked);
                      }}
                    />

                    <Label
                      className="col-sm-10 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      All Day
                    </Label>
                  </div> */}
                  {/* <div className="mt-2 ">
                    <FormGroup>
                      <Label for="exampleSelect">Class</Label>
                      <Input id="exampleSelect" name="select" type="select">
                        <option>Paid</option>
                        <option>Unpaid</option>
                      </Input>
                    </FormGroup>
                  </div> */}

                  <Col sm="12" xl="12">
                    <div className="row mb-2"></div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggle }}>Close</Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) =>
                props.edit ? handleUpdate(e) : handleSaveChanges(e),
            }}
          >
            {/* {loading ? "LOADING..." : "Save changes"} */}
            {loading
              ? "LOADING..."
              : props.edit
              ? "Update Changes"
              : "Save Changes"}
          </Btn>
          {props.isdelete && (
            <button
              className="btn px-1 py-0 btn-link text-danger"
              onClick={(e) => handleDeleteEvent(e)}
            >
              <i className="fa fa-trash"></i>{" "}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default PopUp;
