import React, { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, Col, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";
import { interestSubjects } from "../../../../redux/features/member/memberSlice";
import { toastNotifiy } from "../../../../Services/AuthVerify";
import MemberInformationContext from "../../../../_helper/MemberInformation";
import InterestModal from "./InterestModal";
import { deleteSingleInterestPricing } from "./subjectInterestCommon";

const InterestPricing = () => {
  const [modal, setModal] = useState(false);
  // const [interestPricingData, setinterestPricingData] = useState([]);
  const [interestedSingleData, setInterestedSingleData] = useState({});
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [title, setTitle] = useState("");
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { subjectCategoryList, subjectList } =
    useSelector((state) => state.member) || {};

  const { getMemberInformation, interestAndPricing } = useContext(
    MemberInformationContext
  );

  useEffect(() => {
    if (!interestAndPricing) {
      getMemberInformation();
    }
  }, [interestAndPricing]);

  useEffect(() => {
    if (!subjectCategoryList) {
      dispatch(
        interestSubjects({
          type: "CATEGORY",
        })
      );
    }
  }, [subjectCategoryList, dispatch]);

  const handleAddNewButton = () => {
    setTitle("Add Interest & Pricing ");
    setView(false);
    setEdit(false);
    setInterestedSingleData({});
    toggle();
  };

  const handleViewInterestPricingInfo = async (id) => {
    setTitle("View Interest & Pricing");
    var data = interestAndPricing.filter((x) => x.id === id);
    if (data) {
      setInterestedSingleData(data[0]);
      setView(true);
      setEdit(false);
      toggle();
    }
  };

  const handleInterestPricingInfoEdit = async (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure to edit this information?")) {
      setTitle("Edit Interest & Pricing");
      var data = interestAndPricing.filter((x) => x.id === id);
      if (data) {
        data = data[0];
        setView(false);
        setEdit(true);
        let selectedTutionTypes = [];

        for (var i = 0; i < data.tutionTypes?.length; i++) {
          selectedTutionTypes.push({
            value: data.tutionTypes[i],
            label: data.tutionTypes[i],
            isDisabled: false,
          });
        }
        setInterestedSingleData({
          interestId: data.id,
          amount: data.amount,
          currency: data.currency,
          classes: data.classes,
          specialization: data.specializeSubjects,
          subjects: data.subjects,
          tutionTypes: selectedTutionTypes?.map(({ value }) => value),
          selectedTutionTypes: selectedTutionTypes,
        });
        setModal(true);
      }
    }
  };

  const handleInterestPricingInfoDelete = async (e, id) => {
    e.preventDefault();
    if (
      window.confirm(
        "Are you sure you wish to delete these interest and pricing information?"
      )
    ) {
      const res = await deleteSingleInterestPricing(id);
      if (res.status === 201) {
        toastNotifiy("Deleted successully", "warn");
        // fetchInterestedSubjectData();
        getMemberInformation();
      } else {
        toastNotifiy("Something went wrong! Please try again", "warn");
        console.warn(res.data);
      }
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="8">
                  <span>
                    Do you want to add Interest and Pricing Info to your
                    profile? Please click the “Add New” button.
                  </span>
                </Col>
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: handleAddNewButton,
                    }}
                  >
                    Add New
                  </Btn>
                  {modal && (
                    <InterestModal
                      title={title}
                      isOpen={modal}
                      toggler={toggle}
                      edit={edit}
                      view={view}
                      data={interestedSingleData}
                    />
                  )}
                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>
            <div className="table-responsive-sm">
              <div>{}</div>
              <Table className="table-border-horizontal">
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th style={{ width: "5%" }} scope="col">
                      {"#"}
                    </th>
                    <th style={{ width: "35%" }} scope="col">
                      {"Subjects"}
                    </th>
                    <th style={{ width: "25%" }} scope="col">
                      {"Degree"}
                    </th>
                    <th style={{ width: "20%" }} scope="col">
                      {"Tuition Type"}
                    </th>
                    <th style={{ width: "10%" }} scope="col">
                      {"Amount"}
                    </th>
                    <th style={{ width: "5%" }} scope="col">
                      {""}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {interestAndPricing?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={6}>No Interest & Pricing Info Found</td>
                    </tr>
                  )}
                  {interestAndPricing?.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row">{index + 1}</th>
                      <td className="">
                        <span
                          className="text-left h6"
                          style={{ cursor: "pointer" }}
                          title="For Details Click here"
                          onClick={() => handleViewInterestPricingInfo(item.id)}
                        >
                          {item.subjects?.map((item, index) => (
                            <span
                              className="badge-light-primary"
                              style={{ margin: "0px 3px 2px 0px" }}
                              key={index}
                            >
                              {item?.name}
                            </span>
                          ))}
                        </span>
                      </td>
                      <td>
                        <span> {item.classes?.name} </span>
                      </td>
                      <td className="">
                        <span
                          className="text-left h6"
                          style={{ cursor: "pointer" }}
                          title="For Details Click here"
                          onClick={() => handleViewInterestPricingInfo(item.id)}
                        >
                          {item.tutionTypes?.map((item, index) => (
                            <span
                              className="badge-light-primary"
                              style={{ margin: "0px 3px 2px 0px" }}
                              key={index}
                            >
                              {item}
                            </span>
                          ))}
                        </span>
                      </td>
                      <td>
                        {item.amount?.initialPrice} {item.currency.name}
                      </td>
                      <td className="px-1">
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={(e) =>
                            handleInterestPricingInfoEdit(e, item.id)
                          }
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </button>
                        <button
                          className="btn px-1 py-0 btn-link text-danger"
                          onClick={(e) =>
                            handleInterestPricingInfoDelete(e, item.id)
                          }
                        >
                          <i className="fa fa-trash"></i>{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default InterestPricing;
