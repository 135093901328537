import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import TutorClassScheduleContain from "../../../Component/Scheduler/Tutor";

const TutorClassSchedule = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="Class Schedule" title="Class Schedule" />
      <TutorClassScheduleContain />
    </Fragment>
  );
};

export default TutorClassSchedule;
