import React, { Fragment } from "react";
import Conversation from "../../../Component/Chat/NewChat/Conversation";

const ConversationContain = () => {
  return (
    <Fragment>
      {/* <Breadcrumbs parent="Chat" title=" Chat App" /> */}
      <div className="pt-2">
        <Conversation />
      </div>
    </Fragment>
  );
};
export default ConversationContain;
