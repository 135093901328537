import React, { Fragment, useState } from "react";
import { HelpCircle } from "react-feather";
import { Card, CardBody, CardHeader, Col, Collapse, Row } from "reactstrap";
import { Btn, H5, H6, P } from "../../AbstractElements";
import { faqList } from "./FaqList";

const Questionss = () => {
  let faq = faqList;
  faq = faq.sort((x, y) => {
    return x.sort - y.sort;
  });
  const [isActivity, setIsActivity] = useState([]);

  const handelChange = (e) => {
    let collapseCopy = [...isActivity];
    collapseCopy[e] = !collapseCopy[e];
    setIsActivity(collapseCopy);
  };
  return (
    <Fragment>
      <Col lg="12">
        <div className="header-faq py-2 my-0">
          <h3 className="mb-0">Quick Questions are answered</h3>
        </div>
        <Row
          className="row default-according style-1 faq-accordion"
          id="accordionoc"
        >
          {faq &&
            faq.map((item, i) => {
              return (
                <Fragment key={i}>
                  <Col xl="6" lg="6" md="6" className="box-col-6">
                    <div className="faq-title">
                      <H6>{item.subTitle}</H6>
                    </div>
                    {faq[i].titless.map((item, k) => {
                      return (
                        <Card key={item.id}>
                          <CardHeader>
                            <H5 attrH5={{ className: "mb-0" }}>
                              <Btn
                                attrBtn={{
                                  color: "link ps-0",
                                  onClick: () => handelChange(item.id),
                                  datatoggle: "collapse",
                                  datatarget: "#collapseicon14",
                                  ariaexpanded: "true",
                                  ariacontrols: "collapseicon14",
                                }}
                              >
                                <HelpCircle />
                                {item.title}
                              </Btn>
                            </H5>
                          </CardHeader>
                          <Collapse isOpen={isActivity[item.id]}>
                            <CardBody>
                              <P>{item.answer}</P>
                            </CardBody>
                          </Collapse>
                        </Card>
                      );
                    })}
                  </Col>
                </Fragment>
              );
            })}
        </Row>
      </Col>
    </Fragment>
  );
};
export default Questionss;
