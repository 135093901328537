import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListView from "./ListView/ListView";

const BookmarksContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "tutor") {
      navigate(`${process.env.PUBLIC_URL}/find-students`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <ListView />
    </Fragment>
  );
};
export default BookmarksContain;
