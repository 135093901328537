import React from "react";
import { Card, Input } from "reactstrap";

export default function Schedule(props) {
  const getLocalTime = (utcDate) => {
    const date = new Date(utcDate);
    const date_string =
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0"); //+ " " + date.toLocaleString().split(',')[1].slice(-2)
    return date_string; //date.toLocaleString().split(',')[1];
  };

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table className="table table-borderless">
            <thead>
              <tr>
                <td className="h4" colSpan={8}>
                  Schedule
                </td>
              </tr>
              <tr>
                <th>Time</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
                <th>Sun</th>
              </tr>
            </thead>
            <tbody>
              {props.data?.length === 0 && (
                <tr className="text-center text-red">
                  <td colSpan={8}>No Schedule Info Found</td>
                </tr>
              )}
              {props.data?.map((item, index) => (
                <tr key={index}>
                  <th scope="row">
                    <span className="d-flex scheduleTime">
                      {getLocalTime(item.from)} -{getLocalTime(item.to)}{" "}
                    </span>
                  </th>
                  <td>
                    <Input type="checkbox" checked={item.mon} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.tue} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.wed} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.thu} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.fri} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.sat} readOnly />
                  </td>
                  <td>
                    <Input type="checkbox" checked={item.sun} readOnly />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
