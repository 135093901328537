import React, { useContext, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Info, ThumbsUp } from "react-feather";
import { Card, CardBody, Col, Form, Input, Label } from "reactstrap";
import { H5 } from "../../../AbstractElements";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import SetUsersLocation from "../../FindTutors/SetUsersLocation";
import { saveNewAddresses } from "./fnBecomeTutor";

const BecomeTutorAddress = (props) => {
  const {
    step,
    setStep,
    handleSkipOrNext,
    addresses,
    setAddresses,
    saveCurrentStepToSession,
  } = useContext(BecomeTutorContext);
  const {
    address1,
    setAddress1,
    address2,
    setAddress2,
    area,
    setArea,
    city,
    setCity,
    state,
    setState,
    postalCode,
    setPostalCode,
    country,
    setCountry,
    latitude,
    longitude,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);
  useEffect(() => {
    document.title = "Become a Tutor | TuteAir - Learn & Teach Gloabally";

    if (addresses) {
      setAddress1(addresses?.address1);
      setAddress2(addresses?.address2);
      setArea(addresses?.area);
      setPostalCode(addresses?.area);
      setCountry(addresses?.area);
      setAddress1(addresses?.area);
      setCity(addresses?.city);
      setState(addresses?.state);
      // setLatitude(addresses?.latitude);
      // setLongitude(addresses?.longitude);
    }
    window.scrollTo({
      top: 90,
      left: 0,
      behavior: "smooth",
    });
    setStep(4);
  }, []);

  const handleAddressSave = async () => {
    var obj = {
      address1: address1,
      address2: address2,
      area: area,
      city: city,
      postalCode: postalCode,
      state: state,
      country: country,
      latitude: latitude,
      longitude: longitude,
    };
    var res = await saveNewAddresses(obj, 4);
    if (res) {
      saveCurrentStepToSession(5);
    }
    setAddresses(obj);
  };
  return (
    <>
      <Row className="bt_title">
        <Col md={12}>
          <h1 className="text-center">Addresses</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  0{props?.step}
                  <br /> Addresses
                </H5>
              </div>
              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Fillup all [*] info</h6>
                    <p>
                      <ul>
                        <li>Your Address Line 1 </li>
                        <li>City</li>
                        <li>Post Code </li>
                        <li>Country </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">
                      You would like to be located on a map
                    </h6>
                    <p>
                      To locate you on a map, type in your address and choose a
                      location so that students can find you there. This will
                      make it easier for students to locate you in your
                      preferred areas.
                    </p>
                  </div>
                </div>

                <hr />
                <div className="media bt_help">
                  <Info />
                  <div className="media-body">
                    <p>
                      You can skip this for the moment, but you must fill it out
                      later to ensure a proper response from the student's side.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card>
            <CardBody className="contact-form">
              <Form className="">
                <Row>
                  <Col sm="12" xl="12">
                    <div className="row mb-1">
                      <Label
                        style={{ color: "red" }}
                        className="col-sm-12 col-form-label"
                        htmlFor=""
                      >
                        <b>
                          {" "}
                          {1 == 0 && 1 == 0 ? (
                            <i>
                              Please allow your location from browser settings
                              to visualize the map here and set your location on
                              map.
                            </i>
                          ) : (
                            <i>
                              Search your location on map to auto-complete
                              address fields.
                            </i>
                          )}
                        </b>
                      </Label>
                    </div>

                    <div className="row mb-1">
                      <SetUsersLocation />
                    </div>

                    <div className="row mb-1">
                      <Label
                        className="col-sm-2 col-form-label asterisk "
                        htmlFor=""
                      >
                        Address Line 1
                      </Label>

                      <Col sm="10">
                        <Input
                          type="text"
                          name="select"
                          value={address1 === null || undefined ? "" : address1}
                          className="form-control"
                          onChange={(e) => setAddress1(e.target.value)}
                          placeholder=""
                        ></Input>
                      </Col>
                    </div>

                    <div className="row mb-1">
                      <Label className="col-sm-2 col-form-label" htmlFor="">
                        Address Line 2
                      </Label>

                      <Col sm="10">
                        <Input
                          type="text"
                          name="select"
                          value={address2 === null || undefined ? "" : address2}
                          className="form-control"
                          onChange={(e) => setAddress2(e.target.value)}
                          placeholder=""
                        ></Input>
                      </Col>
                    </div>

                    <div className="row mb-1">
                      <Label className="col-sm-2 col-form-label" htmlFor="">
                        Area
                      </Label>

                      <Col sm="10">
                        <Input
                          type="text"
                          name="select"
                          value={area === null || undefined ? "" : area}
                          className="form-control"
                          onChange={(e) => setArea(e.target.value)}
                          placeholder="[Optional]"
                        ></Input>
                      </Col>
                    </div>

                    <div className="row mb-1">
                      <Label
                        className="col-sm-2 col-form-label asterisk "
                        htmlFor=""
                      >
                        City
                      </Label>
                      <Col sm="4">
                        <Input
                          type="text"
                          name="select"
                          className="form-control"
                          value={city === null || undefined ? "" : city}
                          onChange={(e) => setCity(e.target.value)}
                        ></Input>
                      </Col>
                      <Label
                        className="col-sm-2 col-form-label asterisk "
                        htmlFor=""
                      >
                        Post Code
                      </Label>
                      <Col sm="4">
                        <Input
                          className="form-control"
                          type="text"
                          placeholder=""
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </Col>
                    </div>
                    <div className="row mb-1">
                      <Label className="col-sm-2 col-form-label" htmlFor="">
                        State
                      </Label>

                      <Col sm="4">
                        <Input
                          type="text"
                          name="select"
                          value={state === null || undefined ? "" : state}
                          className="form-control"
                          onChange={(e) => setState(e.target.value)}
                        ></Input>
                      </Col>

                      <Label
                        className="col-sm-2 col-form-label asterisk "
                        htmlFor=""
                      >
                        Country
                      </Label>

                      <Col sm="4">
                        <Input
                          type="text"
                          name="select"
                          value={country === null || undefined ? "" : country}
                          className="form-control"
                          onChange={(e) => setCountry(e.target.value)}
                        ></Input>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          <Row
            style={{
              float: "right",
            }}
            className="py-0"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn btn-lg btn-back btn-info  mx-2"
                onClick={(e) => handleSkipOrNext("back")}
              >
                Back
              </button>

              <button
                className="btn  btn-lg btn-warning btn-skip "
                // disabled={!isSkip}
                onClick={(e) => handleSkipOrNext("skip")}
              >
                Skip
              </button>

              <button
                className="btn  btn-lg btn-primary  mx-2"
                disabled={false}
                onClick={(e) => handleAddressSave()}
              >
                Save & Continue
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BecomeTutorAddress;
