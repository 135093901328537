import React from "react";
import { Col, Row } from "react-bootstrap";
import "./become-tutor.css";
import SlickSlider from "./ChildComponent/SlickSlider";
import TutorRegister from "./ChildComponent/TutorRegister";

const BecomeTutorCreateAccount = () => {
  return (
    <>
      <Row className="m-0">
        <Col
          sm="12"
          lg="6"
          xl="6"
          md="12"
          className="p-0 carousal-page"
          style={{ placeSelf: "center" }}
        >
          <SlickSlider
            slides={1}
            title="Responsive Example"
            rtl={false}
            lazyLoad={true}
          />
        </Col>
        <Col sm="12" lg="6" xl="6" md="12" className="p-0">
          <div className="login-card1 become_tutor_card1">
            <TutorRegister />
          </div>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};

export default BecomeTutorCreateAccount;


