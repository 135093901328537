import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { ProfilePictureRootURL } from "../../../api";
import {
  userAcconuntVisible,
  userLoggedIn,
  userLoggedOut,
} from "../../../redux/features/auth/authSlice";
import {
  clearMemberMenus,
  memberMenus,
} from "../../../redux/features/menus/menuSlice";
import { axiosRequest } from "../../../redux/utils/axios-utils";

export const saveInterestPricingNew = async (
  {
    subjects,
    specialization,
    degree,
    currency,
    amount,
    isNegotiable,
    tutionTypes,
  },
  step
) => {
  if (!subjects || !degree || !currency || !tutionTypes || !amount) {
    alert("Please fillup all required [*] field!");
    return;
  }
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 1) {
    var data = {
      subjects: subjects,
      classes: {
        id: degree.value,
        name: degree.label,
      },
      specializeSubjects: specialization,
      currency: {
        code: currency.code,
        id: currency.value,
        countryId: currency.countryId,
        name: currency.name,
      },
      tutionTypes: tutionTypes,
      amount: {
        initialPrice: parseFloat(amount),
        confirmedPrice: 0,
        isNegotiable: isNegotiable,
      },
      userId: _tuteair._id,
      accountType: _tuteair.accountType,
    };
    if (window.confirm("Are you sure to save this information?")) {
      var res = await axiosRequest({
        url: "/public/tutor-interest-pricing-save",
        method: "post",
        data: data,
      });
      return res;
    }
  }
};
export const saveInteresLearnNew = async (
  { subjects, degree, currency, amount, tutionTypes },
  step
) => {
  if (!subjects || !degree || !currency || !tutionTypes || !amount) {
    alert("Please fillup all required [*] field!");
    return;
  }

  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 1) {
    var data = {
      subjects: subjects,
      classes: {
        id: degree.value,
        name: degree.label,
      },
      currency: {
        code: currency.code,
        id: currency.value,
        countryId: currency.countryId,
        name: currency.name,
      },
      tutionTypes: tutionTypes,
      amount: {
        initialPrice: parseFloat(amount),
        confirmedPrice: 0,
      },
      userId: _tuteair._id,
      accountType: _tuteair.accountType,
    };
    if (window.confirm("Are you sure to save this information?")) {
      var res = await axiosRequest({
        url: "/public/student-interest-learn-save",
        method: "post",
        data: data,
      });
      console.log(res);
      return res;
    }
  }
};

export const saveNewBasicInfo = async (
  { gender, doB, contactNo, language, about },
  step
) => {
  if (
    gender === "not" ||
    !doB ||
    !contactNo?.number ||
    language?.length === 0 ||
    !about
  ) {
    alert("Please fillup all required [*] field!");
    return false;
  }

  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 3) {
    if (window.confirm("Are you sure to save this information?")) {
      var data = {
        doB,
        gender,
        contactNo,
        language,
        about,
        userId: _tuteair._id,
      };
      console.log(data);
      var res = await axiosRequest({
        url: "/public/tutor-basicInfo-save",
        method: "post",
        data: data,
      });

      if (res.data) {
        const msg = "Basic Info Update Succefully";
        toast.success(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        return true;
      } else {
        alert("something went wrong!");
        return false;
      }
    }
  }
};

export const saveNewAddresses = async (obj, step) => {
  var address = obj;
  console.log(address);
  if (
    address?.address1.trim() === "" ||
    address?.city.trim() === "" ||
    address?.postalCode.trim() === "" ||
    address?.country.trim() === ""
  ) {
    toast.warn("Please fillup required field", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    return false;
  }
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 4) {
    var res = await axiosRequest({
      url: "/public/tutor-address-save",
      method: "post",
      data: {
        address,
        userId: _tuteair._id,
      },
    });

    if (res.data) {
      const msg = "Address Info Update Succefully";
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      return true;
    } else {
      alert("something went wrong!");
      return false;
    }
  }
};

export const saveNewSchedule = async (
  { from, to, mon, tue, wed, thu, fri, sat, sun },
  step
) => {
  if (!from) {
    toast.warn("Please add a start time", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    return false;
  }
  if (!to) {
    toast.warn("Please add an end time", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    return false;
  }
  if (!mon && !tue && !wed && !thu && !fri && !sat && !sun) {
    toast.warn("Please add at least one day", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
    return false;
  }
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 2) {
    if (window.confirm("Are you sure to save this information?")) {
      var res = await axiosRequest({
        url: "/public/tutor-schedule-save",
        method: "post",
        data: {
          from: JSON.stringify(from).slice(12, 17),
          to: JSON.stringify(to).slice(12, 17),
          mon,
          tue,
          wed,
          thu,
          fri,
          sat,
          sun,
          userId: _tuteair._id,
        },
      });

      if (res.data) {
        const msg = "New Schedule Added Succefully";
        toast.success(msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        return true;
      } else {
        alert("something went wrong!");
        return false;
      }
    }
  }
};

export const saveNewEducations = async (
  { passingYear, degree, course, grade, inProgress, department, institute },
  step
) => {
  if (!passingYear || !degree || !course || !grade || !institute) {
    alert("[*] all field required");
    return false;
  }
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 5) {
    var res = await axiosRequest({
      url: "/public/tutor-education-save",
      method: "post",
      data: {
        passingYear: passingYear?.value,
        degree,
        course,
        marks: grade,
        isRunning: inProgress,
        department,
        institute,
        userId: _tuteair._id,
      },
    });

    if (res.data) {
      const msg = "Education Info Update Succefully";
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      return true;
    } else {
      alert("something went wrong!");
      return false;
    }
  }
};

export const saveNewExperiences = async (
  { position, organization, startDate, endDate, running, aboutWorkExp },
  step
) => {
  if (!position || !organization || !startDate) {
    alert("[*] all field required");
    return false;
  }

  if (!running && !endDate) {
    alert("End date field required");
    return false;
  }
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id && step === 6) {
    var res = await axiosRequest({
      url: "/public/tutor-experience-save",
      method: "post",
      data: {
        position,
        organization,
        startDate,
        endDate: running ? "" : endDate,
        running,
        aboutWorkExp,
        userId: _tuteair._id,
      },
    });

    if (res.data) {
      const msg = "Experience Info Update Succefully";
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      return true;
    } else {
      alert("something went wrong!");
      return false;
    }
  }
};

export const getEmailVerifiedOrNot = async () => {
  const _tuteair = JSON.parse(localStorage.getItem("_tuteair_newTutor"));
  if (_tuteair?._id) {
    var res = await axiosRequest({
      url: "/public/check-email-verification/" + _tuteair?._id,
      method: "get",
    });
    console.log(res);
    return res.data;
  } else {
    console.warn(_tuteair);
  }
};

export const createNewAccount2 = async ({
  firstName,
  lastName,
  password,
  accountType,
  email,
}) => {
  if (!firstName || !password || !email || !accountType) {
    return;
  }
  var data = {
    firstName,
    lastName: lastName ? lastName : "",
    password,
    accountType,
    email,
  };
  if (window.confirm("Are you sure to save this information?")) {
    var res = await axiosRequest({
      url: "/public/create-account",
      method: "post",
      data: data,
    });
    localStorage.clear();
    console.log(res);
    if (res && res.status === 201) {
      localStorage.setItem(
        "_tuteair_newTutor",
        JSON.stringify({
          currentStep: accountType === "student" ? 0 : 1,
          currentStepStudent: accountType === "student" ? 1 : 0,
          name: res.data?.firstName + " " + res.data?.lastName,
          accountType: res.data.accountType,
          email: res.data.email,
          accessToken: res.data.token,
          _id: res.data._id,
        })
      );

      return {
        isRegistered: true,
        message: "",
      };
    } else {
      return {
        isRegistered: false,
        message: res?.response?.data?.message,
      };
    }
  }
};
export const createNewAccount = async ({
  firstName,
  lastName,
  password,
  accountType,
  email,
}) => {
  if (!firstName || !password || !email || !accountType) {
    return;
  }
  var data = {
    firstName,
    lastName: lastName ? lastName : "",
    password,
    accountType,
    email,
  };
  if (window.confirm("Are you sure to save this information?")) {
    var res = await axiosRequest({
      url: "/public/create-account",
      method: "post",
      data: data,
    });
    localStorage.clear();

    if (res && res.status === 201) {
      localStorage.setItem(
        "_tuteair_newTutor",
        JSON.stringify({
          currentStep: accountType === "student" ? 0 : 1,
          currentStepStudent: accountType === "student" ? 1 : 0,
          name: res.data?.firstName + " " + res.data?.lastName,
          accountType: res.data.accountType,
          email: res.data.email,
          _id: res.data._id,
          accessToken: res.data.token,
        })
      );
    }

    return res;
  }
};

export const handleLoginRequest = async (request, dispatch) => {
  const data = {
    email: request.email,
    password: request.password,
  };

  var res = await axiosRequest({
    url: "/public/login-v2",
    method: "post",
    data: data,
  });

  if (res?.status === 200 && res?.data?._id) {
    await allLoginProcess(res.data, dispatch);
    return {
      isLogged: true,
      message: "",
      currentStep: res?.data?.currentStep,
      emailVerified: res?.data?.emailVerified,
      profilePicture: res?.data?.profilePicture,
      accountType: res?.data?.accountType,
    };
  } else {
    return { isLogged: false, message: res?.response?.data?.message };
  }
};

export const handleGoogleLoginRequest = async (
  response,
  dispatch,
  navigate
) => {
  const userObject = jwt_decode(response.credential);
  const pro_pic_url = userObject.picture;

  const data = {
    firstName: userObject.given_name,
    lastName: userObject.family_name,
    email: userObject.email,
    gClientID: userObject.sub,
    profilePicture: pro_pic_url,
  };

  var res = await axiosRequest({
    url: "/public/register-google",
    method: "post",
    data: data,
  });
  console.log(res);

  if (res.status === 201 || res.status === 200) {
    await allLoginProcess(res.data, dispatch);

    return {
      isLogged: true,
      message: "",
      currentStep: res?.data?.currentStep,
      emailVerified: res?.data?.emailVerified,
      profilePicture: res?.data?.profilePicture,
    };
  } else if (res.status === 204) {
    return {
      isLogged: false,
      message:
        "This Email is associated with password. Please try with password",
    };
  } else {
    console.warn(res);
    return { isLogged: false, message: "Something went wrong!!" };
  }
};

export const allLoginProcess = async (data, dispatch) => {
  // var img64 = "";
  // if (data?.profilePicture?.includes("googleusercontent")) {
  //   img64 = data?.profilePicture;
  // } else {
  //   if (data?.profilePicture && data?.profilePicture !== " ") {
  //     img64 = await getBase64FromUrl(
  //       ProfilePictureRootURL + data?.profilePicture
  //     );
  //   }
  // }

  localStorage.setItem(
    "tuteAirUser",
    JSON.stringify({
      name: data.firstName + " " + data.lastName,
      accessToken: data.token,
      id: data._id,
      email: data.email,
      autenticated: true,
      firstName: data.firstName,
      lastName: data.lastName,
      accountType: data.accountType,
    })
  );

  dispatch(
    userLoggedIn({
      accessToken: data.token,
      user: data._id,
      accountType: data.accountType,
      firstName: data.firstName,
      lastName: data.lastName,
    })
  );

  dispatch(
    userAcconuntVisible({
      isAccountHide: data.isAccountHide,
    })
  );

  localStorage.setItem("isAccountHide", data.isAccountHide);

  const pro_pic_url = data?.profilePicture?.includes("googleusercontent")
    ? data?.profilePicture
    : ProfilePictureRootURL + data?.profilePicture;

  localStorage.setItem("picture2", pro_pic_url);

  if (data.accountType === "both") {
    localStorage.setItem("swMember", "tutor");
    dispatch(memberMenus({ memberType: "tutor" }));
  } else {
    localStorage.setItem("swMember", data.accountType);
    dispatch(memberMenus({ memberType: data.accountType }));
  }

  localStorage.setItem(
    "_tuteair_newTutor",
    JSON.stringify({
      currentStep: data?.currentStep,
      name: data?.firstName + " " + data?.lastName,
      accountType: data.accountType,
      accessToken: data.token,
      email: data.email,
      _id: data._id,
      emailVerified: data?.emailVerified,
      prev_photo: data?.profilePicture?.includes("googleusercontent")
        ? ""
        : data?.profilePicture,
    })
  );
};

export const clearAllLoginStore = async (dispatch) => {
  dispatch(userLoggedOut());
  dispatch(clearMemberMenus());
  localStorage.clear();
};

export const getResendEmailResponse = async (userId) => {
  if (userId) {
    var res = await axiosRequest({
      url: "/public/resend-email/" + userId,
      method: "get",
    });
    return res.data;
  } else {
    console.warn(userId);
  }
};
