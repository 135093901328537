import io from "socket.io-client";
import { ServerURL } from "../../../../api";
import { apiSlice } from "../../api/apiSlice";
import { messagesApi } from "../messages/messagesApi";

export const conversationsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getConversations: builder.query({
            query: (sender) =>
                `/chat/newConversation/getConversations/${sender}`, 
                async onCacheEntryAdded(
                    arg,
                    { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
                ) {
                    // create socket
                    const socket = io(ServerURL, {
                        reconnectionDelay: 1000,
                        reconnection: true,
                        reconnectionAttemps: 10,
                        transports: ["websocket"],
                        agent: false,
                        upgrade: false,
                        rejectUnauthorized: false,
                    });
    
                    try {
                        await cacheDataLoaded;
                        socket.on("conversation", (data) => {
                            updateCachedData((draft) => {
                                const conversation = draft.find(
                                    (c) => c.conversationId == data?.data?._id
                                );
                                const auth0_profile = JSON.parse(localStorage.getItem("tuteAirUser"));
                               // console.log(conversation)
                                if (conversation?.conversationId) {
                                    conversation.message = data?.data?.message;
                                    conversation.updatedTime = data?.data?.updatedAt;
                                   // console.log(current(draftConversation))
                                   conversation.updatedBy = data?.data?.updatedBy;
                                   conversation.isSeen = false;
                                } else {
                                    // do nothing
                                    const checkConversationId = obj => obj.conversationId === data?.data.conversationId;

                                    const isExists = draft.some(checkConversationId);
                                    if(!isExists && data?.data.receiverId === auth0_profile.id) {
                                        // if(data?.data.receiverId === auth0_profile.id)
                                        dispatch(
                                            apiSlice.util.updateQueryData(
                                                "getConversations",data?.data.receiverId,
                                                (draft) => {
                                                // console.log(current(draft))
                                                    draft.push({
                                                        conversationId: data?.data?.conversationId, 
                                                        name: data?.data?.senderName,
                                                        thumb: data?.data?.senderThumb,
                                                        online: data?.data?.online,
                                                        updatedTime: data?.data?.updatedTime,
                                                        updatedBy: data?.data?.updatedBy,
                                                        message: data?.data?.message,
                                                        isSeen: false,
                                                        reply: data?.data?.reply,
                                                        email: data?.data?.email,
                                                        receiverId: data?.data?.receiverId
                                                   })
                                                }
                                            )
                                        );
                                        }
                                }
                                
                            });
                        });
                    } catch (err) {}
    
                    await cacheEntryRemoved;
                   // socket.close();
                },
        }),
       
        getConversation: builder.query({
            query: ({ recevierId }) =>
                `/chat/newConversation/getConversation/${recevierId}`, 
        }),
        addConversation: builder.mutation({
            query: (data,sender) => ({
                url: "/chat/newConversation/save",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
               
                try {
                    const conversation = await queryFulfilled;
                  //  console.log(conversation)
                    if (conversation?.data?.conversationId) {
                        // silent entry to message table
                        const res = await dispatch(
                            messagesApi.endpoints.addMessage.initiate({
                                conversationId: conversation.data.conversationId,
                                text: arg.data.message,
                            })
                        ).unwrap();
                     
                        dispatch(
                            apiSlice.util.updateQueryData(
                                "getMessages", conversation.data.conversationId,arg.sender,
                                (draft) => {
                                    draft.messages.push(res); //console.log(current(draft))

                                }
                            )
                        );
                        // update messages cache pessimistically end

                        dispatch(
                            apiSlice.util.updateQueryData(
                                "getConversations",res.sender,
                                (draft) => {
                                   // console.log(current(draft))
                                    draft.push(conversation.data)
                                }
                            )
                        );
                        
                    }
                } catch (err) {
                   // pathResult.undo();
                }
            },
        }),
        editConversation: builder.mutation({
            query: ({ id, data, sender }) => ({
                url: `/chat/newConversation/${id}`,
                method: "PATCH",
                body: {data},
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                // optimistic cache update start
                dispatch(
                    apiSlice.util.updateQueryData(
                        "getConversations",arg.sender,
                        (draft) => {
                            const draftConversation = draft.find(
                                (c) => c.conversationId == arg.id
                            );
                            draftConversation.message = arg.data.message;
                            draftConversation.isSeen = false;
                            draftConversation.updatedTime = new Date().getTime();
                            draftConversation.updatedBy = arg.sender;
                        }
                    )
                );
                // optimistic cache update end
                try {
                    const conversation = await queryFulfilled;

                    if (conversation?.data?._id) {
                        // silent entry to message table
                        const res = await dispatch(
                            messagesApi.endpoints.addMessage.initiate({
                                conversationId: conversation?.data?._id,
                                text: arg.data.message,
                            })
                        ).unwrap();
                        dispatch(
                            apiSlice.util.updateQueryData(
                                "getMessages", conversation?.data?._id,arg.sender,
                                (draft) => {
                                    draft.messages.push(res); //console.log(current(draft))

                                }
                            )
                        );
                    }
                } catch (err) {
                   // pathResult.undo();
                }
            },
        }),
        getMemberTuitionInfo: builder.query({
            query: ({ tutorId }) =>
                `/chat/getOfferedTuitions/${tutorId}`, 
        }),
        addTuition: builder.mutation({
            query: (data) => ({
                url: "/chat/saveTuition/",
                method: "POST",
                body: data.data,
            }),
        })
    }),
});

export const {
    useGetConversationsQuery,
    useGetConversationQuery,
    useAddConversationMutation,
    useEditConversationMutation,
    useGetMemberTuitionInfoQuery, 
    useAddTuitionMutation
} = conversationsApi;