import axios from "axios";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NoTutorImage from "../../../assets/images/login/2.jpg";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  CardTitle,
  CardBody,
  CardImg,
  CardText,
  Col,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import { FilteredTutorsApi, ProfilePictureRootURL } from "../../../api";
import man from "../../../assets/images/avtar/man.png";
import allowLocation from "../../../assets/images/avtar/allowLocation.png";
import { authHeader, toastNotifiy } from "../../../Services/AuthVerify";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import PersonalAddressContext from "../../../_helper/PersonalAddress";
import FindTutorsGeocoder from "../FindTutorsGeocoder";
import ProfilePopup from "../ProfilePopup";
import PulseLoader from "react-spinners/PulseLoader";
import { P } from "../../../AbstractElements";
import TuitionRequestContext from "../../../_helper/TuitionRequest";

import FilterOptionsContain from "../ListView/FilterOptions";

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

function ShowStudentsOnMap() {
  const {
    gender,
    languages,
    profilePicExist,
    location,
    tutionType,
    subject,
    subject_category_filter,
    subject_subcategory_filter,
  } = useContext(FilterContext);

  const {
    tuitionRequestList: tutors,
    setTuitionRequestList,
    GetAllTuitionRequest,
    tuitionRequestToShow,
    setTuitionRequestToShow
  } = useContext(TuitionRequestContext);

  const { user: userId } = useSelector((state) => state.auth) || {};

  const { latitude, longitude, setLatitude, setLongitude } = useContext(
    PersonalAddressContext
  );

  const [filters, setFilters] = useState({});
  //const [tutors, setTutors] = useState([]);
  const [loading, setLoading] = useState(false);

  //const [tuitionRequestList, setTuitionRequestList] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [isReset, setIsReset] = useState(false);


  useEffect(() => {
    const setInitialLocation = () => {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    };
    setInitialLocation();
  }, []);

 
  const filterByLanguage = async (tutors, languageArray) => {
    let temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      for (var j = 0; j < languageArray.length; j++) {
        if (
          tutors[i].languages.includes(languageArray[j])
        ) {
            temp_tutors.push(tutors[i]);
            break;
        }
      }
    }
    return temp_tutors;
  };

  const filterByTuitionType = async (tutors, tuitionTypeArray) => {
    let temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      for (var j = 0; j < tuitionTypeArray.length; j++) {
        if (
          tutors[i].tuitionTypes.includes(tuitionTypeArray[j])
        ) {
            temp_tutors.push(tutors[i]);
            break;
        }
      }
    }
    return temp_tutors;
  };

  const filterBySubject = async (tutors, subjectsArray) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].subjects.length; j++) {
        for (var k = 0; k < subjectsArray.length; k++) {
            if (
              tutors[i].subjects[j].id ==
              subjectsArray[k].id
            ) {
              tutor_taken = true;
              temp_tutors.push(tutors[i]);
              break;
            }
          
          if (tutor_taken) break;
        }
        
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const filterBySubjectCategory = async (tutors, categoryObject) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].subjects.length; j++)
       {
          if (
            tutors[i].subjects[j].tag.indexOf(
              categoryObject.value
            ) !== -1
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender?.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.student?.media?.picture?.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.location?.country?.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.location?.country.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.location?.city.toLowerCase() ==
                filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.location?.country?.toLowerCase() ==
                filters.location.country.toLowerCase() &&
              tutor.location?.postalCode?.toLowerCase() ==
                filters.location.postalCode.toLowerCase()
          );
        }
      }
    }
    // filtering ends here

    setTuitionRequestToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  const updateFilter = () => {
    setIsReset(false);

    const addedFilter = {};

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }

    setFilters(addedFilter);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTuitionRequest();
  };

  return (
    <Fragment>
      <Col sm="12" xl="12 box-col-12">
        <Row className="mt-4 tutorViewResponsive">
          <Col md={8}>
            {loading ? null : (
              <CardTitle className="mb-3 mt-2 text-primary h4 ps-3">
                {
                  tuitionRequestToShow?.filter(
                    (x) =>
                      x.studentId &&
                      x.studentId !== userId &&
                      x.memberType !== "tutor" &&
                      !x.student.isAccountDeactive &&
                      !x.student.isAccountDelete &&
                      !x.student.isAccountHide
                  ).length
                }{" "}
                matched tuition request based on your search criteria
              </CardTitle>
            )}

            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <PulseLoader
                  color="blue"
                  loading={loading}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <span>
                {latitude != 0 && longitude != 0 && (
                  <Map
                    mapboxAccessToken={
                      "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ"
                    }
                    style={{
                      width: "100%",
                      height: "80vh",
                      borderRadius: "15px",
                      border: "2px solid red",
                    }}
                    initialViewState={{
                      longitude: longitude,
                      latitude: latitude,
                      zoom: 7,
                    }}
                    mapStyle="mapbox://styles/moniruzzaman-18/cl7zwo94p000014qsaoizctns"
                  >
                    {tuitionRequestToShow
                      ?.filter(
                        (x) =>
                          x.studentId &&
                          x.studentId !== userId &&
                          x.memberType !== "tutor" &&
                          !x.student.isAccountDeactive &&
                          !x.student.isAccountDelete &&
                          !x.student.isAccountHide
                      )
                      .map((item, key) =>
                        item.student?.address?.latitude &&
                        item.student?.address?.longitude ? (
                          <Marker
                            key={key}
                            longitude={item.student?.address?.longitude}
                            latitude={item.student?.address?.latitude}
                          >
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedTutor(item.student);
                              }}
                            >
                              {" "}
                              <img
                                src={
                                  item.student?.media?.picture
                                    ? ` ${
                                        ProfilePictureRootURL +
                                        item.student?.media?.picture
                                      }`
                                    : man
                                }
                                height={32}
                                width={32}
                                alt={"tuteair-member"}
                                style={{
                                  borderRadius: "50%",
                                  border: "1.5px solid red",
                                }}
                              />
                            </span>
                          </Marker>
                        ) : null
                      )}

                    {selectedTutor ? (
                      <Popup
                        latitude={selectedTutor.address.latitude}
                        longitude={selectedTutor.address.longitude}
                        onClose={() => {
                          setSelectedTutor(null);
                        }}
                      >
                        <ProfilePopup tutorInfo={selectedTutor} />
                      </Popup>
                    ) : null}

                    <NavigationControl position="bottom-right" />
                    <FindTutorsGeocoder />
                    <FullscreenControl />
                    <GeolocateControl />
                  </Map>
                )}
                {latitude == 0 && longitude == 0 && (
                  <>
                    <P>
                      Please allow your location to see student's location on map
                      view.
                    </P>
                    <P>
                      You can allow location by clicking on location icon in
                      your browser's address bar. <br></br>
                      <img src={allowLocation}></img>
                    </P>
                  </>
                )}
              </span>
            )}
          </Col>
          <Col md={4} className="filterListResponsive">
            <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
              <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>
                <Button
                  color="primary"
                  className="mb-2"
                  onClick={updateFilter}
                >
                  Apply Filter
                </Button>
              </div>
          </Col>
        </Row>
      </Col>
    </Fragment>
  );
}

export default ShowStudentsOnMap;
