import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import {
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { authHeader, toastNotifiy } from "../../Services/AuthVerify";
// import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import { Btn } from "../../AbstractElements";
import { FeedbackSubmitUrl } from "../../api";


const FeedbackModal = (props) => {

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isGetback, setIsGetback] = useState(true)

    const submitFeedback = async (e) => {
        if (!message || message.replace(" ", "").length == 0) {
            toast.warn("Please add a message!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
            return
        }

        setLoading(true)
        const res = await axios.post(
            FeedbackSubmitUrl,
            {
                message: message,
                isGetback: isGetback
            },
            {
                headers: {
                    Authorization: authHeader(),
                },
            }
        );
        if (res.status == 201) {
            toast.success("Feedback submitted successfully. Thanks for your time.", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            setTimeout(() => {
                props.toggler();
            }, 4000)
        } else {
            toast.warn("Something went wrong! Please try again after sometimes. ", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
            });
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <Modal
                isOpen={props.isOpen}
                toggle={props.toggler}
                centered
                className="modal-lg"
                backdrop="static"
            >
                <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
                <ModalBody className={"pb-0 " + props.bodyClass}>
                    <Col sm="12" xl="12 box-col-12">
                        <Card className="mb-0">
                            <CardBody className="megaoptions-border-space-sm">
                                <Row>
                                    <Col sm="12" xl="12">
                                        <div className="row mb-1">
                                            <Label className="col-sm-3 col-form-label asterisk">
                                                Message
                                            </Label>
                                            <Col sm="9">
                                                <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    rows="12"
                                                    maxLength={1000}
                                                />
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col sm="12" xl="12">
                                        <div className="row mb-1">
                                            <Label className="col-sm-3 col-form-label">

                                            </Label>
                                            <Col sm="9">
                                                <Input
                                                    id="inline-1"
                                                    type="checkbox"
                                                    checked={isGetback}
                                                    onChange={(event) => {
                                                        setIsGetback(event.target.checked);
                                                    }}
                                                />
                                                <label
                                                    htmlFor="inline-1"
                                                    style={{ position: "absolute", FontSize: "13px" }}
                                                    className="mb-0 px-2"
                                                > I won't mind if Tuteair get back to me!</label>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
                        Close
                    </Btn>
                    <Btn
                        attrBtn={{
                            color: "success",
                            disabled: loading ? loading : loading,
                            onClick: (e) =>
                                submitFeedback(e)
                        }}
                    >
                        {loading
                            ? "Submitting..." : "Submit"}
                    </Btn>
                </ModalFooter>
            </Modal>
        </Fragment >
    );
};

export default FeedbackModal;
