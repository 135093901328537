import React, { Fragment } from 'react';
import './App.css';
import useAuthCheck from "./redux/hooks/useAuthCheck";
import Routers from './Routes';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import BecomeStudentProvider from './_helper/BecomeStudent/BecomeStudentProvider';
import BecomeTutorProvider from './_helper/BecomeTutor/BecomeTutorProvider';
import ChatProvider from './_helper/chat-app/ChatProvider';
import { default as CustomizerProvider, default as CustomProvider } from './_helper/customizer/CustomizerProvider';
import CartProvider from './_helper/ecommerce/cart/CartProvider';
import ProductProvider from './_helper/ecommerce/product/ProductProvider';
import WishListProvider from './_helper/ecommerce/wishlist/WishProvider';
import FilterProvider from './_helper/Filter/FilterProvider';
import MemberInformationProvider from './_helper/MemberInformation/MemberInformationProvider';
import PersonalAddressProvider from './_helper/PersonalAddress/PersonalAddressProvider';
import ProjectProvider from './_helper/project-app/ProjectProvider';
import TuitionRequestProvider from './_helper/TuitionRequest/TuitionRequestProvider';
import TutorsProvider from './_helper/Tutors/TutorsProvider';

function App() {
      const authChecked = useAuthCheck();
      return !authChecked ? (
            <div>
                  {/* Checking authentication.... */}
            </div>
      ) : (
            <Fragment>
                  <CustomizerProvider>
                        <CustomProvider>
                              <BecomeTutorProvider>
                                    <BecomeStudentProvider>
                                          <WishListProvider>
                                                <FilterProvider>
                                                      <CartProvider>
                                                            <MemberInformationProvider>
                                                                  <PersonalAddressProvider>
                                                                  <TuitionRequestProvider>
                                                                        <TutorsProvider>
                                                                              <ChatProvider>
                                                                                    <ProductProvider>
                                                                                          <ProjectProvider>
                                                                                                <AnimationThemeProvider>
                                                                                                      <FilterProvider>
                                                                                                      </FilterProvider>                                                                                                                  <Routers />
                                                                                                </AnimationThemeProvider>                                                                                                  </ProjectProvider>
                                                                                    </ProductProvider>
                                                                              </ChatProvider>
                                                                        </TutorsProvider>
                                                                        </TuitionRequestProvider>
                                                                  </PersonalAddressProvider>
                                                            </MemberInformationProvider>
                                                      </CartProvider>
                                                </FilterProvider>
                                          </WishListProvider>
                                    </BecomeStudentProvider>
                              </BecomeTutorProvider>
                        </CustomProvider>
                  </CustomizerProvider>
            </Fragment >
      );
}
export default App;
