import React, { Fragment } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import StudentFormWizardContain from "../../../Component/AccountNew/Student/index";

const StudentAccount = () => {
  return (
    <Fragment>
      <Breadcrumbs parent="StudentNewAccount" title="Student New Account" />
      {/* <TutorAccountContain /> */}
      <StudentFormWizardContain />
    </Fragment>
  );
};

export default StudentAccount;
