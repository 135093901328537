import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Button,
} from "reactstrap";
import { PaidClassListView } from "../../../../../api";
import axios from "axios";
import { authHeader, toastNotifiy } from "../../../../../Services/AuthVerify";
import { useSelector } from "react-redux";
import PopUp from "./PopUp";

export default function PaidClass(props) {
  const [allPaidClassList, setAllPaidClassList] = useState([]);
  const [isJoin, setIsJoin] = useState(false);
  const { user } = useSelector((state) => state.auth) || {};
  // const [isConfirm, setIsConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});
  const [isConfirm, setIsConfirm] = useState(false);
  const viewConfirmToggle = () => setIsConfirm(!isConfirm);

  function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }

  function isToday(date) {
    const today = new Date();
    if (today.toDateString() === date.toDateString()) {
      return true;
    }
    return false;
  }

  function getIsJoin(start, end) {
    setIsJoin(false);
    if (new Date() < end && isToday(end)) {
      const times = getMinutesBetweenDates(new Date(), start);
      if (times < 0) {
        const duration = getMinutesBetweenDates(start, new Date());
        if (duration > 0) {
          setIsJoin(true);
        }
      }
      if (times > 0 && times < 16) {
        setIsJoin(true);
      }
    } else {
      setIsJoin(false);
    }
    return isJoin;
  }

  const fetchAllPaidClass = async () => {
    const res = await axios.get(PaidClassListView, {
      headers: {
        Authorization: authHeader(),
      },
    });

    if (res.data) {
      const participant = res.data?.map((item) => {
        item?.participants.map((item2) => {
          if (item2?.studentId != user) {
            res.data?.map((item, index) => {
              item.start = new Date(item.start);
              item.end = new Date(item.end);
              item.subjects = "";
              item.isJoin = false;
              item.studentName = "";
              item.confirmedTuitionFee = 0;
              item.currency = "";
              if (new Date() < item.end && isToday(item.end)) {
                const times = getMinutesBetweenDates(new Date(), item.start);
                if (times < 0) {
                  const duration = getMinutesBetweenDates(
                    item.start,
                    new Date()
                  );
                  if (duration > 0) {
                    item.isJoin = true;
                    //setIsJoin(true);
                  }
                }
                if (times > 0 && times < 16) {
                  item.isJoin = true;
                  //setIsJoin(true);
                }
              } else {
                item.isJoin = false;
                // setIsJoin(false);
              }
              const subjects = "";
              const myTuitions = props?.tutorTuitions?.filter(
                (t) => t.tuitionId == item.tuition
              );
              const myTuition = myTuitions[0];
              item.studentName = myTuition?.studentName;
              item.confirmedTuitionFee = myTuition?.confirmedTuitionFee;
              item.currency = myTuition?.currency;

              if (myTuition?.tuitionRequest?.subjects) {
                myTuition?.tuitionRequest?.subjects?.map((item2, index2) => {
                  var comma =
                    item2.tuitionRequest?.subjects?.length - 1 === index2
                      ? ""
                      : " - ";
                  item.subjects =
                    subjects + item2.label?.toString().trim() + comma;
                });
              } else {
                myTuition?.interestedSubjects?.subjects?.map(
                  (item2, index2) => {
                    var comma =
                      myTuition.interestedSubjects?.subjects?.length - 1 ===
                      index2
                        ? ""
                        : " - ";
                    item.subjects =
                      item.subjects + item2.name?.toString().trim() + comma;
                  }
                );
              }
            });
            setAllPaidClassList(res.data);
          }
        });
      });
    }
  };

  useEffect(() => {
    fetchAllPaidClass();
    return () => {
      setAllPaidClassList();
    };
  }, [props]);

  const handleConfirmButton = (item) => {
    setTitle("Is Class Completed ?");
    setIsConfirm(true);
    setData(item);
  };

  return (
    <div>
      <Card body>
        <div className="table-responsive-sm">
          <table className="table">
            <thead>
              <tr>
                <td className="h4" colSpan={6}>
                  Paid Classes
                </td>
              </tr>
              <tr style={{ background: "#dbdbdb2b" }}>
                <th style={{ width: "5%" }} scope="col"></th>
                <th>Title</th>
                <th>Subject</th>
                <th>Student Name</th>
                <th>Amount</th>
                <th>Time</th>
                <th style={{ width: "5%" }} scope="col"></th>
                <th style={{ width: "10%" }} scope="col">
                  Class Status
                </th>
              </tr>
            </thead>
            <tbody>
              {allPaidClassList?.map((item, index) => (
                <tr className="px-0" key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item?.title}</td>
                  <td>{item?.subjects}</td>
                  <td>{item?.studentName}</td>
                  <td>
                    <span>{item?.confirmedTuitionFee}</span> /{" "}
                    <span>{item?.currency}</span>
                  </td>
                  <td>
                    <span>
                      {new Date(item?.start).toDateString()} :{" "}
                      {new Date(item?.start).toLocaleTimeString()}
                    </span>
                    {" - "}
                    <span>{new Date(item?.end).toLocaleTimeString()}</span>
                  </td>
                  <td>
                    <span>
                      {item.isJoin ? (
                        <Button
                          color="success"
                          onClick={() =>
                            window.open(`https://meet.jit.si/${item?.tuition}`)
                          }
                        >
                          Join
                        </Button>
                      ) : (
                        ""
                      )}
                    </span>
                  </td>
                  <td>
                    {item.start < new Date() && !item.isClassCompleteByTutor && (
                      <Button
                        type="button"
                        color="success"
                        onClick={() => handleConfirmButton(item)}
                      >
                        Complete
                      </Button>
                    )}
                    {item?.isClassCompleteByTutor && <span>Completed</span>}
                  </td>
                </tr>
              ))}
              <span>
                {isConfirm && (
                  <PopUp
                    title={title}
                    isOpen={isConfirm}
                    isConfirm={isConfirm}
                    toggle={viewConfirmToggle}
                    data={data}
                  />
                )}
              </span>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
}
