import React from "react";
import { Card } from "reactstrap";

export default function SubjectAndPricing(props) {
  return (
    <Card body>
      <div className="table-responsive-sm">
        <table className="table">
          <thead>
            <tr>
              <td className="h4" colSpan={6}>
                Tuition Details
              </td>
            </tr>
            <tr style={{ background: "#dbdbdb2b" }}>
              <th>Subjects</th>
              {/* <th>Class</th> */}
              <th>Tuition Type</th>
              <th>Amount</th>
              <th>Negotiable</th>
            </tr>
          </thead>
          <tbody>
            {props?.data?.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  No Info Found
                </td>
              </tr>
            )}
            {props?.data?.map((item, index) => (
              <tr key={index} className="px-0">
                <td>
                  <span>
                    {item.subjects?.map((item, index) => (
                      <span
                        className="badge badge-light-primary"
                        style={{ margin: "0px 3px 2px 0px" }}
                        key={index}
                      >
                        {item.name}
                      </span>
                    ))}
                  </span>
                  <span className="mb-0 px-1" style={{ color: "#488374" }}>
                    <small>{item.classes?.name}</small>
                  </span>
                </td>
                {/* <td>{item.classes?.name}</td> */}
                <td className="">
                  <span>
                    {item.tutionTypes?.map((item, index) => (
                      <span
                        className="badge badge-light-primary"
                        style={{ margin: "0px 3px 2px 0px" }}
                        key={index}
                      >
                        {item}
                      </span>
                    ))}
                  </span>
                </td>
                <td style={{ fontSize: "13px" }}>
                  {item.amount?.initialPrice} {item.currency?.name} / Hourly
                </td>
                <td style={{ fontSize: "13px" }}>
                  {!item.amount.isNegotiable && <span>No</span>}
                  {item.amount.isNegotiable && <span>Yes</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
