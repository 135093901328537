import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import NewStudentProfileContain from "../Profile/Student";

const StudentProfileContain = () => {
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (memberType === "tutor" && params.id === "me") {
      navigate(`${process.env.PUBLIC_URL}/profile/me`);
    }
  }, [memberType, navigate]);
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <NewStudentProfileContain />
          {/* <StudentProfile /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default StudentProfileContain;
