import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Container,
  Row,
  UncontrolledAccordion,
} from "reactstrap";

import { Filter, List, MapPin, X } from "react-feather";
import { useSelector } from "react-redux";
import "../../../style/custom-css/FindTutor.css";
import FilterContext from "../../../_helper/Filter";
import TutorsContext from "../../../_helper/Tutors";
import MapViewContain from "../MapView";
import BasicFilter from "./BasicFilter";
import FilterOptionsContain from "./FilterOptions";
import QuickSearch from "./QuickSearch";
import SubjectsFilter from "./SubjectsFilter";
import TutorCardContain from "./TutorCard";
import TutorLocationFilter from "./TutorLocationFilter";
import ReactPaginate from "react-paginate";
import moment from 'moment';
// import EducationFilter from "./EducationFilter";
import ScheduleFilter from "./ScheduleFilter";
// import TuitionFeesFilter from "./TuitionFeesFilter";
// import WorkExperienceFilter from "./WorkExperienceFilter";

export default function ListView() {
  const {
    gender,
    setGender,
    languages,
    setLanguages,
    profilePicExist,
    setProfilePicExist,
    location,
    setLocation,
    tutionType,
    setTutionType,
    subject,
    setSubject,
    subject_category_filter,
    subject_subcategory_filter,
    scheduleFilter,
    setScheduleFilter,
    educationFilter, setEducationFilter,
    workExperiencesFilter, setWorkExperiencesFilter,
    priceFilter, setPriceFilter
  } = useContext(FilterContext);

  const {
    tutorList: tutors,
    setTutorList,
    GetAllTutors,
    tutorsToShow,
    setTutorsToShow,
  } = useContext(TutorsContext);

  const { user: userId } = useSelector((state) => state.auth) || {};
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [listViewData, setListViewData] = useState({});
  const [title, setTitle] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const allFilteredTutors = tutorsToShow?.filter(
    (x) =>
      x.userId &&
      x.userId !== userId &&
      x.memberType !== "student" &&
      !x.isAccountDeactive &&
      !x.isAccountDelete &&
      !x.isAccountHide
  );
  const showTutors = allFilteredTutors.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  const pageCount = Math.ceil(allFilteredTutors.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const filterByLanguage = async (tutors, languageArray) => {
    let temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      if (languageArray.every((lan) => tutors[i].languages?.includes(lan))) {
        temp_tutors.push(tutors[i]);
      }
    }
    return temp_tutors;
  };

  const filterByTuitionType = async (tutors, tutionType) => {
    return tutors.filter((tutor) =>
      tutionType.some((item) =>
        tutor.interestAndPricing.filter(
          (i) => i.tutionTypes?.indexOf(item) >= 0
        ).length
          ? true
          : false
      )
    );
  };

  const filterBySubject = async (tutors, subjectsArray) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
        for (var k = 0; k < subjectsArray.length; k++) {
          for (
            var l = 0;
            l < tutors[i].interestAndPricing[j].subjects.length;
            l++
          ) {
            if (
              tutors[i].interestAndPricing[j].subjects[l].id ==
              subjectsArray[k].id
            ) {
              tutor_taken = true;
              temp_tutors.push(tutors[i]);
              break;
            }
          }
          if (tutor_taken) break;
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const filterBySubjectCategory = async (tutors, categoryObject) => {
    var temp_tutors = [];
    for (var i = 0; i < tutors.length; i++) {
      var tutor_taken = false;
      for (var j = 0; j < tutors[i].interestAndPricing.length; j++) {
        for (
          var l = 0;
          l < tutors[i].interestAndPricing[j].subjects.length;
          l++
        ) {
          if (
            tutors[i].interestAndPricing[j].subjects[l].tag.indexOf(
              categoryObject.value
            ) !== -1
          ) {
            tutor_taken = true;
            temp_tutors.push(tutors[i]);
            break;
          }
        }
        if (tutor_taken) break;
      }
    }
    return temp_tutors;
  };

  const checkInsideLimitRange = (userFrom1, filterFrom1, userTo1, filterTo1) => {
    var format = 'hh:mm:ss'
    const userFrom = moment(userFrom1.toString().slice(11, 19), format)
    const filterFrom = moment(filterFrom1.toString().slice(16, 24), format)
    const userTo = moment(userTo1.toString().slice(11, 19), format)
    const filterTo = moment(filterTo1.toString().slice(16, 24), format)

    if (filterFrom1.toString().slice(16, 24) == "00:00:00" && filterTo1.toString().slice(16, 24) == "23:59:00") {
      return true
    }
    else {
      //var userFromH = parseInt(userFrom1.toString().slice(11, 13)) * 60 + parseInt(userFrom1.toString().slice(14, 16))
      //console.log(parseInt(userFrom1.toString().slice(11, 13)) * 60, parseInt(userFrom1.toString().slice(14, 16)))
      //console.log(filterFrom.isBetween(userFrom, userTo))
      if (filterFrom.isBetween(userFrom, userTo) || filterTo.isBetween(userFrom, userTo)) {
        return true
      } else {
        return false
      }
    }
  }

  const filterBySchedule = async (tutors, scheduleFilter) => {
    var temp_tutors = [];

    for (var i = 0; i < tutors.length; i++) {
      for (var j = 0; j < tutors[i].schedules.length; j++) {
        const scheduleObject = tutors[i].schedules[j];
        if (((scheduleObject.sat == true) && scheduleFilter.sat == true)
          || ((scheduleObject.sun == true) && scheduleFilter.sun == true)
          || ((scheduleObject.mon == true) && scheduleFilter.mon == true)
          || ((scheduleObject.tue == true) && scheduleFilter.tue == true)
          || ((scheduleObject.wed == true) && scheduleFilter.wed == true)
          || ((scheduleObject.thu == true) && scheduleFilter.thu == true)
          || ((scheduleObject.fri == true) && scheduleFilter.fri == true)
        ) {
          if (checkInsideLimitRange(scheduleObject.from, scheduleFilter.from, scheduleObject.to, scheduleFilter.to)) {
            temp_tutors.push(tutors[i]);
            break;
          }
        }
      }
    }

    return temp_tutors
  }

  const filterTutorByCourse = async (filteredTutors, course) => {
    var temp_tutors = [];
    for (var i = 0; i < filteredTutors.length; i++) {
      const userString = JSON.stringify(filteredTutors[i])
      if (userString.indexOf(course) >= 0) {
        temp_tutors.push(filteredTutors[i])
      }
    }

    return temp_tutors
  }

  const filterTutorByDegree = (filteredTutors, degree) => {
    var temp_tutors = [];
    for (var i = 0; i < filteredTutors.length; i++) {
      const userString = JSON.stringify(filteredTutors[i])
      if (userString.indexOf(degree) >= 0) {
        temp_tutors.push(filteredTutors[i])
      }
    }

    return temp_tutors
  }

  const filterByInstitute = (filteredTutors, institute) => {
    var temp_tutors = [];
    for (var i = 0; i < filteredTutors.length; i++) {
      const userString = JSON.stringify(filteredTutors[i])
      if (userString.toLowerCase().indexOf(institute.toLowerCase()) >= 0) {
        temp_tutors.push(filteredTutors[i])
      }
    }

    return temp_tutors
  }

  const filterByGrade = (filteredTutors, grade) => {
    var temp_tutors = [];

    for (var i = 0; i < filteredTutors.length; i++) {
      for (var j = 0; j < filteredTutors[i].educations.length; j++) {
        if (parseInt(filteredTutors[i].educations[j].marks) >= grade[0] && parseInt(filteredTutors[i].educations[j].marks) <= grade[1]) {
          temp_tutors.push(filteredTutors[i])
          break
        }
      }
    }

    return temp_tutors
  }

  const filterByEducation = async (filteredTutors, educationFilter) => {

    if (educationFilter.course) {
      filteredTutors = await filterTutorByCourse(filteredTutors, educationFilter.course)
    }
    if (educationFilter.degree) {
      filteredTutors = await filterTutorByDegree(filteredTutors, educationFilter.degree)
    }
    if (educationFilter.institute) {
      filteredTutors = await filterByInstitute(filteredTutors, educationFilter.institute)
    }
    if (educationFilter.grade) {
      filteredTutors = await filterByGrade(filteredTutors, educationFilter.grade)
    }
    if (educationFilter.passingYear) {
      filteredTutors = filteredTutors.filter(tutor => Object.values(tutor).indexOf(educationFilter.passingYear))
    }

    return filteredTutors
  }

  const filterByWorkExperience = (filteredTutors, wexpFilter) => {
    var temp_tutors = []

    for (var i = 0; i < filteredTutors.length; i++) {
      var expYearSum = 0;
      for (var j = 0; j < filteredTutors[i].workExperiences.length; j++) {
        if (wexpFilter.org) {
          if (filteredTutors[i].workExperiences[j].organization?.toLowerCase().includes(wexpFilter.org.toLowerCase()) > 0) {
            var currentExp = filteredTutors[i].workExperiences[j]
            const diffTime = Math.abs(new Date() - new Date(currentExp.startDate));
            var expDur = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
            if (wexpFilter.expYear == "4+" && expDur >= 4) {
              temp_tutors.push(filteredTutors[i])
              break;
            }
            else if (wexpFilter.expYear == "2-4" && expDur >= 2) {
              temp_tutors.push(filteredTutors[i])
              break;
            }
            else if (wexpFilter.expYear == "1-2" && expDur >= 1) {
              temp_tutors.push(filteredTutors[i])
              break;
            }
            else if (wexpFilter.expYear == "<1") {
              temp_tutors.push(filteredTutors[i])
              break;
            }
          }
        }
        else {
          var currentExp = filteredTutors[i].workExperiences[j]
          var diffTime = 0;
          if (currentExp.running) {
            Math.abs(new Date() - new Date(currentExp.startDate));
          } else {
            diffTime = Math.abs(new Date(currentExp.endDate) - new Date(currentExp.startDate));
          }
          var expDur = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 365));
          expYearSum += expDur
        }
      }

      if (!wexpFilter.org) {
        if (wexpFilter.expYear == "4+" && expYearSum >= 4) {
          temp_tutors.push(filteredTutors[i])
        }
        else if (wexpFilter.expYear == "2-4" && expYearSum >= 2) {
          temp_tutors.push(filteredTutors[i])
        }
        else if (wexpFilter.expYear == "1-2" && expYearSum >= 1) {
          temp_tutors.push(filteredTutors[i])
        }
        else if (wexpFilter.expYear == "<1") {
          temp_tutors.push(filteredTutors[i])
        }
      }
    }

    return temp_tutors
  }

  const filterByPrice = (filteredTutors, priceFilter) => {
    var temp_tutors = []
    for (var i = 0; i < filteredTutors.length; i++) {
      for (var j = 0; j < filteredTutors[i].interestAndPricing.length; j++) {
        if (filteredTutors[i].interestAndPricing[j].currency?.name == priceFilter.currency?.name) {
          if (priceFilter.max && priceFilter.max) {
            if (filteredTutors[i].interestAndPricing[j].amount.initialPrice >= priceFilter.min
              && filteredTutors[i].interestAndPricing[j].amount.initialPrice <= priceFilter.max) {
              temp_tutors.push(filteredTutors[i])
              break
            }
          } else {
            temp_tutors.push(filteredTutors[i])
            break
          }
        }
      }
    }

    return temp_tutors
  }

  const fetchFilteredTutors = async () => {
    setLoading(true);
    var filteredTutors = [...tutors];

    // filtering starts here
    if (filters.gender) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.gender.toLowerCase() == filters.gender.toLowerCase()
      );
    }

    if (filters.profilePicture) {
      filteredTutors = filteredTutors.filter(
        (tutor) => tutor.media.picture.length > 0
      );
    }

    if (filters.language) {
      filteredTutors = await filterByLanguage(filteredTutors, filters.language);
    }

    if (filters.tutionType) {
      filteredTutors = await filterByTuitionType(
        filteredTutors,
        filters.tutionType
      );
    }

    if (filters.subject) {
      filteredTutors = await filterBySubject(filteredTutors, filters.subject);
    } else if (filters.subjectSubCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectSubCategory
      );
    } else if (filters.subjectCategory) {
      filteredTutors = await filterBySubjectCategory(
        filteredTutors,
        filters.subjectCategory
      );
    } else {
      filteredTutors = filteredTutors;
    }

    if (filters.location) {
      if (
        (!filters.location.city && !filters.location.postalCode) ||
        (filters.location.city == "" && filters.location.postalCode == "")
      ) {
        filteredTutors = filteredTutors.filter(
          (tutor) =>
            tutor.address.country.toLowerCase() ==
            filters.location.country.toLowerCase()
        );
      } else {
        if (filters.location.city && filters.location.city != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
              filters.location.country.toLowerCase() &&
              tutor.address.city.toLowerCase() ==
              filters.location.city.toLowerCase()
          );
        }
        if (filters.location.postalCode && filters.location.postalCode != "") {
          filteredTutors = filteredTutors.filter(
            (tutor) =>
              tutor.address.country.toLowerCase() ==
              filters.location.country.toLowerCase() &&
              tutor.address.postalCode.toLowerCase() ==
              filters.location.postalCode.toLowerCase()
          );
        }
      }
    }

    if (filters.scheduleFilter) {
      filteredTutors = await filterBySchedule(filteredTutors, filters.scheduleFilter)
    }
    if (filters.educationFilter) {
      filteredTutors = await filterByEducation(filteredTutors, filters.educationFilter)
    }
    if (filters.workExperiencesFilter) {
      filteredTutors = await filterByWorkExperience(filteredTutors, filters.workExperiencesFilter)
    }
    if (filters.priceFilter) {
      filteredTutors = await filterByPrice(filteredTutors, filters.priceFilter)
    }

    // filtering ends here
    setTutorsToShow(filteredTutors);
    setLoading(false);
  };

  useEffect(() => {
    fetchFilteredTutors();
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    GetAllTutors();
    setLoading(false);
  }, []);

  const updateFilter = (a) => {
    if (a === 1) setIsSearched(!isSearched);
    setIsReset(false);

    const addedFilter = {};

    if (gender != "0") {
      addedFilter.gender = gender;
    }
    if (profilePicExist) {
      addedFilter.profilePicture = profilePicExist;
    }
    if (languages.length != 0) {
      addedFilter.language = languages;
    }
    if (Object.keys(location).length != 0) {
      addedFilter.location = location;
    }
    if (tutionType.length != 0) {
      addedFilter.tutionType = tutionType;
    }
    if (subject.length != 0) {
      addedFilter.subject = subject;
    }
    if (Object.keys(subject_category_filter).length) {
      addedFilter.subjectCategory = subject_category_filter;
    }
    if (Object.keys(subject_subcategory_filter).length) {
      addedFilter.subjectSubCategory = subject_subcategory_filter;
    }
    if (Object.keys(scheduleFilter).length) {
      addedFilter.scheduleFilter = scheduleFilter;
    }
    if (Object.keys(educationFilter).length) {
      addedFilter.educationFilter = educationFilter;
    }
    if (Object.keys(workExperiencesFilter).length) {
      addedFilter.workExperiencesFilter = workExperiencesFilter;
    }
    if (Object.keys(priceFilter).length) {
      addedFilter.priceFilter = priceFilter
    }

    //console.log(addedFilter);

    setFilters(addedFilter);
  };

  const handleSearch = () => {
    setIsSearched(!isSearched);
  };

  const [isListView, setIsListView] = useState(true);
  const [isMapViewView, setIsMapView] = useState(false);

  const handleListView = (e) => {
    e.preventDefault();
    setIsListView(true);
    setIsMapView(false);
  };
  const handleMapView = (e) => {
    e.preventDefault();
    setIsMapView(true);
    setIsListView(false);
  };

  const resetFilter = (e) => {
    setIsReset(true);
    GetAllTutors();
  };

  //console.log(tutors)

  return (
    <Fragment>
      <Container fluid={true} className="pt-2">
        <Row>
          <Col md="12 search_title">
            <h4>Your Search Results</h4>
          </Col>
        </Row>
        <div
          className={`row form-inline my-3 mx-0 search_tutor pt-4 ${isSearched ? "d-none" : ""
            }`}
        >
          <QuickSearch isReset={isReset} />
          <div className="col-xxl-1 d-flex mb-3">
            <button
              type="reset"
              className="btn btn-outline-info"
              onClick={(e) => updateFilter(1)}
            >
              Search
            </button>
            <button
              className="btn btn-sm btn-outline-warning px-2 d-none"
              style={{
                marginLeft: "50px",
                width: "120px",
                textAlign: "-webkit-match-parent",
                fontSize: "14px",
              }}
            >
              <Filter height={17} />
              <span style={{ position: "absolute", marginTop: "0px" }}>
                Filter More
              </span>
            </button>
          </div>
        </div>

        <Row className="mb-0 py-2">
          <Col sm="8">
            <p className="pb-1">
              About {allFilteredTutors?.length} tutors found (0.60 seconds)
            </p>
          </Col>

          <Col sm="4" className="px-3" style={{ textAlign: "-webkit-right" }}>
            {/* style={{ textAlign: "-webkit-right" }} */}
            <div
              className={`square-product-setting d-inline-block ${!isSearched ? "d-none" : ""
                }`}
              style={{ paddingRight: "20px" }}
            >
              <button
                className="btn btn-sm btn-outline-warning"
                onClick={handleSearch}
              >
                Show Quick Search
              </button>
            </div>
            <div className={`square-product-setting d-inline-block`}>
              <a
                className={`icon-grid m-0 list-layout-view  ${isListView ? "icon_visited" : ""
                  }`}
                onClick={(e) => handleListView(e)}
                href="#javascript"
                data-toggle="tooltip"
                data-placement="bottom"
                title="List View"
              >
                <List />
              </a>
            </div>
            <div className={`mx-2 square-product-setting d-inline-block`}>
              <a
                className={`icon-grid grid-layout-view  ${isMapViewView ? "icon_visited" : ""
                  }`}
                onClick={(e) => handleMapView(e)}
                href="#javascript"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Map View"
              >
                <MapPin />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="d-none">
          <Col sm="12">
            Filtered By:{" "}
            <span className="px-2">
              <span className="text-primary">
                Bangladesh
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Dhaka
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">
                Physics
                <X height={13} />
              </span>
            </span>
            <span className="px-2">
              <span className="text-primary">Clear All</span>
            </span>
          </Col>
        </Row>
        <Row className="mb-5 tutorViewResponsive">
          {isListView && <TutorCardContain tutors={showTutors} />}
          {isMapViewView && <MapViewContain />}

          <Col xl="4" className="filterListResponsive mt-0">
            <FilterOptionsContain setFilters={setFilters} isReset={isReset} />
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button
                color="warning"
                className={`mb-2 mx-2 ${isReset ? "d-none" : ""}`}
                onClick={(e) => resetFilter(e)}
              >
                Reset Filter
              </Button>

              <Button
                color="primary"
                className="mb-2 "
                onClick={(e) => updateFilter(0)}
              >
                Apply Filter
              </Button>
            </div>
          </Col>
          <div className=" d-none">
            <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
              <AccordionItem>
                <AccordionHeader targetId="1">Basic</AccordionHeader>
                <AccordionBody accordionId="1">
                  <BasicFilter />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">Subjects</AccordionHeader>
                <AccordionBody accordionId="2">
                  <SubjectsFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">Tutor's Location</AccordionHeader>
                <AccordionBody accordionId="3">
                  <TutorLocationFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="7">Schedule</AccordionHeader>
                <AccordionBody accordionId="7">
                  <ScheduleFilter setFilters={setFilters} />
                </AccordionBody>
              </AccordionItem>

              {/* <AccordionItem>
                  <AccordionHeader targetId="4">Tuition Fees</AccordionHeader>
                  <AccordionBody accordionId="4">
                    <TuitionFeesFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="5">Education</AccordionHeader>
                  <AccordionBody accordionId="5">
                    <EducationFilter />
                  </AccordionBody>
                </AccordionItem>

                <AccordionItem>
                  <AccordionHeader targetId="6">
                    Work Experience
                  </AccordionHeader>
                  <AccordionBody accordionId="6">
                    <WorkExperienceFilter />
                  </AccordionBody>
                </AccordionItem> */}
            </UncontrolledAccordion>
          </div>
        </Row>
        {isListView && allFilteredTutors?.length !== 0 && (
          <div className="mt-5 mb-5 pagination">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              //disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </Container>
    </Fragment>
  );
}
