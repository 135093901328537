import MapBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { useControl } from "react-map-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useContext } from "react";
import PersonalAddressContext from "../../_helper/PersonalAddress";
import axios from "axios";
import "../../style/custom-css/PersonalInfo.css";

const Geocoder = () => {
  const {
    setAddress1,
    setAddress2,
    setArea,
    setCity,
    setState,
    setPostalCode,
    setCountry,
    setLatitude,
    setLongitude,
  } = useContext(PersonalAddressContext);

  const ctrl = new MapBoxGeocoder({
    accessToken:
      "pk.eyJ1IjoibW9uaXJ1enphbWFuLTE4IiwiYSI6ImNsODJtNzNwYjAycHkzdmtwd3hmbmRtdHcifQ.UyNVkQ3MchruzvNqMrVaGQ",
    marker: false,
    collapsed: false,
    placeholder: "Search Location",
  });

  useControl(() => ctrl);
  ctrl.on("result", (e) => {
    const coords = e.result.geometry.coordinates;
    setLatitude(coords[1]);
    setLongitude(coords[0]);

    var config = {
      method: "get",
      url: `https://api.geoapify.com/v1/geocode/reverse?lat=${coords[1]}&lon=${coords[0]}&apiKey=c05c945718444547b952a78b118c1bb2`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        const location = response.data.features[0].properties;
        setAddress1(location.address_line1 || " ");
        setAddress2(location.address_line2 || " ");
        setArea(location.street || " ");
        setCity(location.city || " ");
        setState(location.state || " ");
        setPostalCode(location.postcode || " ");
        setCountry(location.country || " ");
      })
      .catch(function (error) {
        console.log(error);
      });
  });
  return null;
};

export default Geocoder;
