import React, { Fragment, useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
  Button,
} from "reactstrap";
// import { Btn } from "../../../../AbstractElements";
import { Btn } from "../../../../../AbstractElements";
import { useSelector } from "react-redux";
import axios from "axios";
import { authHeader, toastNotifiy } from "../../../../../Services/AuthVerify";
import { AddNotification } from "../../../../../Services/Notification.Service";
import { ClassScheduleUpdateApi } from "../../../../../api/index";
import { toast } from "react-toastify";

const PopUps = (props) => {
  const [selectedTuition, setSelected] = useState("");
  const { user } = useSelector((state) => state.auth) || {};
  const [text, setText] = useState("");
  const [receiver, setReceiver] = useState(null);
  const [isConfirm, setIsConfirm] = useState(props.isConfirm);
  const [link, setLink] = useState("");
  const [status, setStatus] = useState(props?.data?.status);
  const [isClassCompleteByTutor, setIsClassCompleteByTutor] = useState(
    props?.data?.isClassCompleteByTutor
  );

  const [isClassCompleteByStudent, setIsClassCompleteByStudent] = useState(
    props?.data?.isClassCompleteByStudent
  );

  useEffect(() => {
    if (props?.data?.eventCreator == user) {
      setText("Tutor");

      setReceiver(props?.data?.participants[0].studentId);

      setLink("student-class-schedule");

      setIsConfirm(
        props?.data?.isClassCompleteByTutor ? false : props.isConfirm
      );

      setIsClassCompleteByTutor(true);
    } else if (props?.data?.participants[0].studentId === user) {
      setText("Student");

      setReceiver(props?.data?.eventCreator);

      setLink("tutor-class-schedule");

      setIsConfirm(
        props?.data?.isClassCompleteByStudent ? false : props.isConfirm
      );

      setIsClassCompleteByStudent(true);
    }

    if (
      props?.data?.isClassCompleteByTutor &&
      props?.data?.isClassCompleteByStudent
    ) {
      setStatus("Complete");
    }
  }, [props]);

  const handleConfirmedButton = async (e) => {
    e.preventDefault();

    if (window.confirm("Are you sure to completed this class?")) {
      if (props?.data?.eventCreator === user) {
        setIsClassCompleteByTutor(true);
      }

      if (props?.data?.participants[0].studentId === user) {
        setIsClassCompleteByStudent(true);
      }

      var req = {
        isClassCompleteByTutor:
          props?.data?.eventCreator === user
            ? true
            : props?.data?.isClassCompleteByTutor,

        isClassCompleteByStudent:
          props?.data?.participants[0].studentId === user
            ? true
            : props?.data?.isClassCompleteByStudent,

        status: status,
      };

      const res = await axios.put(
        ClassScheduleUpdateApi + "/" + props?.data?._id,

        req,

        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      if (res.status === 200) {
        if (res.data) {
          const msg = "Class Completed Successfully";

          toast.success(msg, {
            position: toast.POSITION.TOP_CENTER,

            autoClose: 3000,
          });
          props.toggle();
          const notifyObj = {
            receiver: receiver,

            title: props?.data?.title + " class completed confirmed by " + text,

            description: "Class Completed Confirmation",

            context: process.env.PUBLIC_URL + "/" + link,

            isPublic: false,
          };

          AddNotification(notifyObj);

          //props.toggle();
        } else {
          toast.warn("Failed to add", {
            position: toast.POSITION.TOP_CENTER,

            autoClose: 3000,
          });
        }

        //setLoading(false);
      } else {
        toastNotifiy("Failed to insert!!", "warn");

        // setLoading(false);
      }
    }

    return;
  };

  useEffect(() => {
    if (isClassCompleteByTutor && isClassCompleteByStudent) {
      setStatus("Complete");
    }
  }, [isClassCompleteByTutor]);

  useEffect(() => {
    if (isClassCompleteByTutor && isClassCompleteByStudent) {
      setStatus("Complete");
    }
  }, [isClassCompleteByStudent]);

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered
        // className="modal-lg"
        backdrop={"static"}
      >
        <ModalHeader toggle={props.toggle}>
          {props.title}
          <span className="me-2">
            <Button
              type="button"
              color="success"
              onClick={handleConfirmedButton}
            >
              Completed
            </Button>
          </span>
        </ModalHeader>
        <ModalBody className={"" + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <div className="table-responsive-sm table-bordered">
                  <table className="table table-striped table-hover">
                    <tbody>
                      <tr>
                        <td className="p-1 py-1">Event Name</td>
                        <td className="p-1 py-1">
                          <span>{props?.data?.title}</span>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-1 py-1">Subjects</td>
                        <td className="p-1 py-1">
                          <span>{props?.data?.subjects}</span>
                        </td>
                      </tr>

                      <tr>
                        <td className="p-1 py-1">Start Date</td>

                        <td className="p-1 py-1">
                          <span>
                            {new Date(props?.data?.start).toDateString()} -{" "}
                            {new Date(props?.data?.start).toLocaleTimeString()}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1 py-1">End Date</td>

                        <td className="p-1 py-1">
                          <span>
                            {new Date(props?.data?.end).toDateString()} -{" "}
                            {new Date(props?.data?.end).toLocaleTimeString()}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-1 py-1">Class Duration</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default PopUps;
