import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { SendMessageApi } from "../../api";
import { authHeader, parseMyJwt } from "../../Services/AuthVerify";
import Context from "./index";

const ChatProvider = (props) => {
  const [allMemberss, setAllMembers] = useState([]);
  const [memberss, setMembers] = useState();
  const [chatss, setChats] = useState([]);
  const [currentUserr, setCurrentUser] = useState();
  const [selectedUserr, setSelectedUser] = useState();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const socket = useRef();

  // const getChatConversations = async () => {
  //   try {
  //     var response = await axios.get(GetConversationsApi, {
  //       headers: {
  //         Authorization: authHeader(),
  //       },
  //     });
  //     setAllMembers(response.data);
  //     setSelectedUser(response.data[0]);

  //     let convId = response.data[0].conversationId;
  //     var resp2 = await axios.get(GetMsgByConversationIdApi + convId, {
  //       headers: {
  //         Authorization: authHeader(),
  //       },
  //     });
  //     var selectedTopChat = [];
  //     selectedTopChat[0] = resp2.data;
  //     setChats(selectedTopChat);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  useEffect(() => {
    //getChatConversations();
  }, []);

  useEffect(() => {
    // socket.current = io("ws://localhost:5000");
    // socket.current = io(SocketApi, {
    //   reconnectionDelay: 1000,
    //   reconnection: true,
    //   reconnectionAttemps: 10,
    //   transports: ["websocket"],
    //   agent: false,
    //   upgrade: false,
    //   rejectUnauthorized: false,
    // });

    // const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
    // if (userData) {
    //   var jwtDecode = parseMyJwt(userData?.accessToken);
    //   socket.current.emit("addUser", jwtDecode?.id);
    // }

    // socket.current.on("getMessage", (data) => {
    //   setArrivalMessage(data);
    // });
  }, []);

  const getMembersSuccess = (members) => {
    setCurrentUser(members[0]);
    setMembers(members);
  };

  useEffect(() => {
    if (arrivalMessage) {
      if (chatss.length > 0) {
        let chat = chatss[0];
        chat.messages.push({
          sender: arrivalMessage.senderId,
          text: arrivalMessage.text,
          time: new Date().toISOString(),
        });
        var chatList = [];
        chatList[0] = chat;
        setChats(chatList);
      }
    }
  }, [arrivalMessage, chatss]);

  const fetchChatMemberAsyn = () => {
    if (allMemberss.length > 0) getMembersSuccess(allMemberss);
  };

  const getChatsSuccess = (chats, conversationId, online) => {
    if (allMemberss.length > 0) {
      setChats(chats);
      setSelectedUser(
        allMemberss.find((x) => x.conversationId === conversationId)
      );
    }
  };

  const updateSelectedUser = (conversationId, online) => {
    if (allMemberss.length > 0)
      return allMemberss.find((x) => x.conversationId === conversationId);
  };

  const fetchChatAsyn = () => {
    if (chatss?.length > 0) {
      // const currentUserId = currentUserr.id; //"63149602c2b23ea2b95d1597"; // "63149ae82bb871881e29d9fa";
      const online = true;
      //  const chat = chatss[0];
      const conversationId = chatss[0].conversationId;

      getChatsSuccess(chatss, conversationId, online);
      updateSelectedUser(conversationId, online);
    }
  };

  const sendMessageToChat = async (conversationId, text, chats) => {
    try {
      let body = { conversationId, text };
      let resp = await axios.post(SendMessageApi, body, {
        headers: {
          Authorization: authHeader(),
        },
      });

      // let chat = chats.find((x) => x.conversationId.includes(conversationId));
      if (resp.data) {
        // chat.messages.push(resp.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  ///

  const sendMessageAsyn = (conversationId, text, chats) => {
    const userData = JSON.parse(localStorage.getItem("tuteAirUser"));
    var jwtDecode = parseMyJwt(userData.accessToken);
    var myId = jwtDecode.id; //"63149ae82bb871881e29d9fa";
    let chat = chats[0];
    chat.messages.push({
      sender: myId,
      time: new Date().toISOString(),
      text: text,
    });
    var chatList = [];
    chatList[0] = chat;
    setChats(chatList);

    const receiverId = selectedUserr.receiverId;

    socket.current.emit("sendMessage", {
      senderId: myId,
      receiverId: receiverId,
      text: text,
    });

    sendMessageToChat(conversationId, text, chats);

    setTimeout(() => {
      // sendMessageToChat2(conversationId, text);
    }, 1000);
  };

  const replyByUserAsyn = (
    currentUserId,
    selectedUserId,
    replyMessage,
    chats,
    online
  ) => {
    let chat = chats.find(
      (x) => x.users.includes(currentUserId) && x.users.includes(selectedUserId)
    );
    const now = new Date();
    const time = now.getHours() + ":" + now.getMinutes();
    const status = online;
    if (chat) {
      chat.messages.push({
        sender: selectedUserId,
        time: time,
        text: replyMessage,
        status: true,
      });
      chat.lastMessageTime = time;
      chat.online = status;
      let chats_data = chats.filter((x) => x.id !== chat.id);
      chats_data.splice(0, 0, chat);

      getChatsSuccess(chats_data, selectedUserId, online);
    }

    sendMessageToChat(currentUserId, chats);
  };

  const createNewChatAsyn = (currentUserId, selectedUserId, chats) => {
    let conversation = {
      id: chats.conversationId,
      users: [currentUserId, selectedUserId],
      lastMessageTime: "-",
      messages: [],
    };
    // chats.splice(0, 0, conversation);
    getChatsSuccess(chats, selectedUserId);
  };

  const changeChat = (convId, currentChat) => {
    var member = allMemberss.find((x) => x.conversationId === convId);
    let currentChatArr = [];
    currentChatArr[0] = currentChat;
    setChats(currentChatArr);
    setSelectedUser(member);
  };

  const searchMember = (keywords) => {
    if (keywords === "") {
      setMembers(allMemberss);
    } else {
      const keyword = keywords.toLowerCase();
      const searchedMembers = allMemberss.filter(
        (member) => member.name.toLowerCase().indexOf(keyword) > -1
      );
      setMembers(searchedMembers);
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        allMemberss,
        chatss,
        selectedUserr,
        currentUserr,
        memberss,
        sidebarToggle,
        setSidebarToggle: setSidebarToggle,
        getChatsSuccess: getChatsSuccess,
        updateSelectedUserr: updateSelectedUser,
        fetchChatAsyn: fetchChatAsyn,
        fetchChatMemberAsyn: fetchChatMemberAsyn,
        sendMessageAsyn: sendMessageAsyn,
        replyByUserAsyn: replyByUserAsyn,
        createNewChatAsyn: createNewChatAsyn,
        changeChat: changeChat,
        searchMember: searchMember,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ChatProvider;
