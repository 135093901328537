import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import CalenderContain from "./Calender/Index";
import ClassListViewContain from "./ClassListView/index";
import { useNavigate } from "react-router-dom";

// import CompletedClass from "./CompletedClass/CompletedClass";
// import PaidClass from "./PaidClasses/PaidClasses";
// import UpComingClass from "./UpcomingClasses/UpcomingClasses";
// import { axiosRequest } from "../../../redux/utils/axios-utils";

const TutorClassScheduleContain = () => {
  const {
    firstName: fName,
    lastName: lName,
    user: userId,
    accountType,
  } = useSelector((state) => state.auth) || {};

  const [VerticleTab, setVerticleTab] = useState("1");
  const { memberType } = useSelector((state) => state.menu) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (memberType === "student") {
      navigate(`${process.env.PUBLIC_URL}/student-class-schedule`);
    }
  }, [memberType, navigate]);

  return (
    <Fragment>
      <Container fluid={true} className="pt-4">
        <Row>
          <Col sm="8">
            <Card>
              <CardBody className="megaoptions-border-space-sm">
                <Nav className="nav  nav-pills">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "1" ? "active" : ""}
                      onClick={() => setVerticleTab("1")}
                    >
                      <i className="icofont icofont-ui-calendar"></i>
                      {"Calendar"}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={VerticleTab === "2" ? "active" : ""}
                      onClick={() => setVerticleTab("2")}
                    >
                      <i className="icofont icofont-listing-number"></i>
                      {"Class List View"}
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12" xl="12 box-col-12">
            <TabContent activeTab={VerticleTab}>
              <TabPane tabId="1">
                <CalenderContain />
              </TabPane>
              <TabPane tabId="2">
                <ClassListViewContain />{" "}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default TutorClassScheduleContain;
