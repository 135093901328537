import React from "react";
import { Col, Row } from "reactstrap";
// import SupportImg from "../../../assets/images/dashboard-2/support-img.png";
import "../assets/home.scss";
import imgdd from "../assets/img/footer_plant_3d@2x.png";
import banner from "../assets/img/teaching-.png";
import "./hero-section.css";
import ListViewHome from "./ListViewHome";

const HeroSection = () => {
  return (
    <section className="container-fluid px-0">
      <div id="home">
        <div
          className="container"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "block",
          }}
        >
          <Row className="">
            <Col lg="12" md="12" className="col_hero">
              <div className="hero__content">
                <img src={imgdd} className="bg_img_1" alt="" />
                <h1 className="mb-4 hero__title ta-student">
                  Find Tuition | Tutor in One Platform
                </h1>
                <p className="mb-5">
                  Be a tutor and teach students locally or from anywhere in
                  world. <br /> Parents or students can find preferred tutors
                  from anywhere too. <br /> <br /> All in one platform with one
                  registration.
                </p>
                <img src={banner} className="bg_img_2" alt="" />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              {/* <div className="search">
              <input type="text" placeholder="Search" />
              <button className="btn">Search</button>
            </div> */}
            </Col>
          </Row>
        </div>

        <Row className="mt-5 d-none">
          <Col md="12">
            <div className="text-center bg-warning py-4 free_reg">
              <span>Registration free on until 31 December 2022</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="container py-0 my-0">
        <h2 className="text-left ta-student search_t1 my-0 mt-3">
          Search Your Perfect Tutor
        </h2>
        <ListViewHome />
      </div>
    </section>
  );
};

export default HeroSection;
