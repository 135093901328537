import React, { Fragment, useContext, useEffect, useState } from "react";
import { Lock, Mail, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Form, FormGroup, Input, InputGroup } from "reactstrap";

import { Btn, H4, H5, H6, LI, P, UL } from "../../../../AbstractElements";
import man from "../../../../assets/images/dashboard/1.png";
import {
  CreateAccount,
  EmailAddress,
  Password,
  PrivacyPolicy,
  SignIn,
  YourName,
} from "../../../../Constant";
import { GoogleSVG } from "../../../../Data/svgIcons";
import BecomeTutorContext from "../../../../_helper/BecomeTutor";
import { createNewAccount } from "../fnBecomeTutor";

const TutorRegister = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const [emailVerify, setEmailVerify] = useState(false);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [pwdErrorMessage, setPwdErrorMessage] = useState(
    "minimum 8 characters, at least 1 letter, 1 number and 1 special character"
  );
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const [resendEnabled, setResendEnabled] = useState(false);
  const [emailObject, setEmailObject] = useState({});
  const { step, setStep, setIsEmailVerified } = useContext(BecomeTutorContext);

  const fnIsValidPassword = (string) =>
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/.test(
      string
    );
  //^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,20}$/.test(string);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  useEffect(() => {
    if (isCPasswordDirty) {
      if (!fnIsValidPassword(password)) {
        setIsValidPassword(false);
      } else {
        setShowErrorMessage(true);
        if (agreeCheckbox) {
          setButtonDisabled(true);
          setShowErrorMessage(fnIsValidPassword(password));
        }
      }
    }
  }, [password]);

  const handlePasswordKeyUp = (e) => {
    var isValidPwd = fnIsValidPassword(password);
    setIsValidPassword(isValidPwd);
    setShowErrorMessage(!isValidPwd);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const checkboxHandler = (isChk) => {
    setAgreeCheckbox(isChk);
    if (isCPasswordDirty === false && isChk === true) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };
  useEffect(() => {
    setStep(0);
  }, []);
  const handleRegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let result = await createNewAccount({
        firstName,
        lastName,
        email,
        password,
        accountType: "tutor",
      });
      if (result?.data?._id) {
        setLoading(false);
        setButtonDisabled(true);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setStep(1);
        setIsEmailVerified(false);
      } else {
        if (
          result?.response?.status !== 201 ||
          result?.response?.status === 400
        ) {
          setTimeout(() => {
            toast.error(result.response?.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 300);
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        toast.error("Oppss.. Something went wrong!!.");
      }, 200);
    }
  };

  return (
    <Fragment>
      <Form className="theme-form login-form">
        <ToastContainer />
        <div className="login-header  text-center">
          <H4>Create your account</H4>
          <H6>Enter your personal details to create account</H6>
        </div>
        <FormGroup>
          <UL attrUL={{ className: "simple-list login-social flex-row" }}>
            <LI>
              <a href="https://www.google.com/">
                <GoogleSVG />
              </a>
            </LI>
            {/* 
            <LI>
              <a href="https://www.facebook.com/">
                <FaceBookSVG />
              </a>
            </LI> */}
          </UL>
        </FormGroup>
        <div className="login-social-title">
          <H5>Sign in with Email</H5>
        </div>
        <FormGroup className="form-group">
          <label className="form-label">{YourName}</label>
          <div className="small-group">
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <span className="input-group-text">
                <User />
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </InputGroup>
          </div>
        </FormGroup>
        <FormGroup>
          <label className="form-label">{EmailAddress}</label>
          <InputGroup>
            <span className="input-group-text">
              <Mail />
            </span>
            <Input
              className="form-control"
              type="email"
              required=""
              placeholder="user@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <label className="form-label">{Password}</label>
          <InputGroup>
            <span className="input-group-text">
              <Lock />
            </span>
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              required=""
              placeholder="*********"
              value={password}
              onChange={handlePassword}
              onKeyUp={handlePasswordKeyUp}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
          {showErrorMessage && !isValidPassword ? (
            <div className="text-danger">{pwdErrorMessage}</div>
          ) : (
            ""
          )}
        </FormGroup>

        <FormGroup className="form-group">
          <div
            className="m-t-15 m-checkbox-inline custom-radio-ml"
            style={{ fontSize: "13px" }}
          >
            <div className="checkbox checkbox-dark">
              <Input
                id="inline-1"
                type="checkbox"
                onChange={(event) => {
                  checkboxHandler(event.target.checked);
                }}
              />
              <label
                htmlFor="inline-1"
                style={{ position: "absolute", FontSize: "13px" }}
                className="mb-0"
              >
                <div>
                  {Option}
                  <span className="digits">
                    Agree with{" "}
                    <Link
                      to={`${process.env.PUBLIC_URL}/privacy`}
                      target="_blank"
                    >
                      {PrivacyPolicy}
                    </Link>
                    <span className="px-1">and</span>
                    <Link
                      to={`${process.env.PUBLIC_URL}/terms-condition`}
                      target="_blank"
                    >
                      Terms and Condition
                    </Link>
                  </span>
                </div>
              </label>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="topSpace">
          <Btn
            attrBtn={{
              className: "btn-block",
              color: "primary",
              type: "submit",
              disabled: isButtonDisabled, //loading ? loading : loading,
              onClick: (e) => handleRegisterUser(e),
            }}
          >
            {loading
              ? "LOADING..."
              : resendEnabled
              ? "Resend Email"
              : emailVerify
              ? "An Email has been sent to your account. Please verify"
              : CreateAccount}
          </Btn>
        </FormGroup>
        <P>
          Already have an account?
          <Link to={`${process.env.PUBLIC_URL}/login-account`} className="ms-2">
            {SignIn}
          </Link>
        </P>
      </Form>
    </Fragment>
  );
};

export default TutorRegister;
