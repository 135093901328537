import axios from "axios";
import React, { Fragment, useEffect, useState, useContext } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { toast } from "react-toastify";
import { Card, CardHeader, Col, Input, Row, Table } from "reactstrap";
import { Btn, H5 } from "../../../../AbstractElements";
import { ScheduleDeleteApi } from "../../../../api";
import { authHeader } from "../../../../Services/AuthVerify";
import ScheduleModal from "./ScheduleModal";
import MemberInformationContext from "../../../../_helper/MemberInformation";

const TutorSchedule = () => {
  const [modal, setModal] = useState(false);
  const [singleSchedule, setSingleSchedule] = useState({});
  const [edit, setEdit] = useState(false);

  const toggle = () => setModal(!modal);
  const { schedules, setSchedules, getMemberInformation } = useContext(MemberInformationContext)

  useEffect(() => {
    if (!schedules) {
      getMemberInformation();
    }
  }, []);

  const addNewSchedule = () => {
    setEdit(false);
    setSingleSchedule({
      from: new Date(),
      to: new Date(),
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    });
    toggle();
  };

  const handleScheduleEdit = async (item) => {
    setEdit(true);
    setSingleSchedule(item);
    setModal(true);
  };

  const handleScheduleDelete = async (id) => {
    if (window.confirm("Are you sure you wish to delete this Schedule?")) {
      const deletedItem = await axios.delete(ScheduleDeleteApi + "/" + id, {
        headers: {
          Authorization: authHeader(),
        },
      });
      if (deletedItem) {
        toast.warn("Deleted successully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        getMemberInformation();
      }
    }
  };

  const getLocalTime = (utcDate) => {
    const date = new Date(utcDate);
    const date_string =
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0"); //+ " " + date.toLocaleString().split(',')[1].slice(-2)
    return date_string; //date.toLocaleString().split(',')[1];
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col md="8">
                  <span>
                    Do you want to add new Schedule Info to your profile? Please
                    click the “Add New” button.
                  </span>
                </Col>
                <Col md="4">
                  <Btn
                    attrBtn={{
                      color: "success",
                      className: "pull-right",
                      onClick: addNewSchedule,
                    }}
                  >
                    Add New
                  </Btn>
                  {
                    modal && (
                      <ScheduleModal
                        title={"Add Schedule"}
                        isOpen={modal}
                        toggler={toggle}
                        singleSchedule={singleSchedule}
                        edit={edit}
                      />
                    )
                  }

                </Col>
              </Row>
              <H5 className="d-none"></H5>
            </CardHeader>

            <div className="table-responsive-sm">
              <Table className="table-border-horizontal">
                <thead>
                  <tr style={{ background: "#dbdbdb2b" }}>
                    <th>Time</th>
                    <th>Mon</th>
                    <th>Tue</th>
                    <th>Wed</th>
                    <th>Thu</th>
                    <th>Fri</th>
                    <th>Sat</th>
                    <th>Sun</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>

                <tbody>
                  {schedules?.length === 0 && (
                    <tr className="text-center text-red">
                      <td colSpan={9}>No Schedule Info Found</td>
                    </tr>
                  )}
                  {schedules?.map((item) => (
                    <tr key={item.id}>
                      <th scope="row">
                        {/* <span className="d-flex scheduleTime">
                          {JSON.stringify(item.from).slice(12, 17)}-
                          {JSON.stringify(item.to).slice(12, 17)}{" "}
                        </span> */}
                        <span className="d-flex scheduleTime">
                          {getLocalTime(item.from)} -{getLocalTime(item.to)}{" "}
                        </span>
                      </th>
                      <td>
                        <Input type="checkbox" checked={item.mon} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.tue} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.wed} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.thu} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.fri} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.sat} readOnly />
                      </td>
                      <td>
                        <Input type="checkbox" checked={item.sun} readOnly />
                      </td>
                      <td>
                        <button
                          className="btn px-1 py-0 btn-link"
                          onClick={() => handleScheduleEdit(item)}
                        >
                          <i className="fa fa-edit"></i>
                        </button>{" "}
                        <button
                          className="btn px-1 py-0 btn-link text-danger"
                          onClick={() => handleScheduleDelete(item.id)}
                        >
                          {" "}
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
export default TutorSchedule;
