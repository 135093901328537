import React, { Fragment } from "react";
import BasicFilter from "./BasicFilter";
import SubjectsFilter from "./SubjectsFilter";
import TutorLocation from "./TutorLocationFilter";
import ScheduleFilter from "./ScheduleFilter";
import TuitionFeesFilter from "./TuitionFeesFilter";
import WorkExperienceFilter from "./WorkExperienceFilter";
import EducationFilter from "./EducationFilter";

const FilterOptionsContain = ({ setFilters, isReset }) => {
  return (
    <Fragment>
      <SubjectsFilter setFilters={setFilters} isReset={isReset} />
      <BasicFilter setFilters={setFilters} isReset={isReset} />
      <TutorLocation setFilters={setFilters} isReset={isReset} />
      <ScheduleFilter setFilters={setFilters} isReset={isReset} />
      <TuitionFeesFilter setFilters={setFilters} isReset={isReset} />
      <WorkExperienceFilter setFilters={setFilters} isReset={isReset} />
      <EducationFilter setFilters={setFilters} isReset={isReset} />
    </Fragment>
  );
};

export default FilterOptionsContain;
