import React, { Fragment } from "react";
import DefaultContain from "../../../Component/DashBoard/Default/index";

const Default = (e) => {
  return (
    <Fragment>
      {/* <Breadcrumbs
        parent="Dashboards"
        title={`${swMember.toUpperCase()} Dashboard`}
      /> */}
      <DefaultContain />
    </Fragment>
  );
};
export default Default;
