const api = `${process.env.PUBLIC_URL}/api`;
export const TaskApi = ``;
export const BookmarkApi = ``;
export const ChartistApi = ``;
export const ChartjsApi = ``;
export const ChatMemberApi = `${api}/chatMember.json`;
export const ChatApi = `${api}/chats.json`;
export const ContactApi = ``;
export const ProductsApi = ``;
export const EmailApi = ``;
export const ImageLightApi = ``;
export const BigImageApi = ``;
export const MasonryApi = ``;
export const GooglechartApi = ``;
export const AllProjectApi = ``;
export const SearchAllTabsApi = ``;
export const TableDataApi = ``;
export const TodoApi = ``;
export const JobSearchApi = ``;
export const LearningApi = ``;
export const KnowledgebaseApi = ``;
export const FaqApi = ``;
export const FileApi = ``;
export const UserCardApi = ``;
export const StickyApi = ``;
export const TypeaheadDataApi = ``;
export const FeatherDataApi = ``;
export const FlagIconDataApi = ``;
export const FontawsomeDataApi = ``;
export const IcoDataApi = ``;
export const ThemifyDataApi = ``;
// our custom API
// dev-live api

// dev-live-api
const api_url = `https://dev-tuteair-backend-app-service.azurewebsites.net/api`;
export const SocketApi = `wss://dev-tuteair-backend-app-service.azurewebsites.net`;
export const ServerURL = `wss://dev-tuteair-backend-app-service.azurewebsites.net`;
export const API_URL_V1 = `https://dev-tuteair-backend-app-service.azurewebsites.net/api`;

// dev-local-api
// const api_url = `http://localhost:5000/api`;
// export const API_URL_V1 = `http://localhost:5000/api`;
// export const ServerURL = `http://localhost:5000`;
// export const SocketApi = `http://localhost:5000`;

export const RegisterApi = `${api_url}/users`;

export const RegisterApiGoogle = `${api_url}/users/register-google`;
export const LoginApi = `${api_url}/users/login`;
export const CreatePasswordURL = `${api_url}/users/reset-password`;
export const ForgotPasswordURL = `${api_url}/users/forgot-password`;
export const FeedbackSubmitUrl = `${api_url}/users/feedback`;

// Tutor Account Setup Api
export const TutorWorkExpPostApi = `${api_url}/member/work-exp/save`;
export const TutorWorkExpGetApi = `${api_url}/member/work-exp`;
export const TutorWorkExpGetByIdApi = `${api_url}/member/work-exp/`; //+id
export const TutorWorkExpUpdateByIdApi = `${api_url}/member/work-exp/`; //+id
export const TutorWorkExpDeleteByIdApi = `${api_url}/member/work-exp/`;
//
export const CountriesApi = `${api_url}/member/personal-info/countries/`;
export const StateByCountryIdApi = `${api_url}/member/personal-info/getStatesByCountry/`;
export const CityByStateIdApi = `${api_url}/member/personal-info/getLocationsByState/`;
export const LanguagesApi = `${api_url}/member/personal-info/languages`;

// Tutor Account Setup Api
export const TutorPersonalInfoGetApi = `${api_url}/member/personal-info/`;
export const TutorPersonalInfoPostApi = `${api_url}/member/personal-info/save`; //`http://localhost:5000/api/member/personal-info/save`;
// Tutor experience setup api
export const TutorSchedulePostApi = `${api_url}/member/schedule/save`;
export const TutorScheduleGetAllApi = `${api_url}/member/schedule`;
export const TutorScheduleActionApi = `${api_url}/member/schedule/`;

export const TutorInterestPricingGetAllApi = `${api_url}/member/interest-pricing/`;
export const TutorInterestPricingPostApi = `${api_url}/member/interest-pricing/save`;
export const TutorInterestAndPricingGetApi = `${api_url}/member/interest-pricing/initialValue`;
export const TutorInterestAndPricingDeleteApi = `${api_url}/member/interest-pricing/`;
export const TutorInterestAndPricingGetByIdApi = `${api_url}/member/interest-pricing/`;
//
export const GetSubCategoryByIdApi = `${api_url}/member/interest-pricing/subCategory/`; // + id
export const GetTutorInterestedSubjectsByIdApi = `${api_url}/member/interest-pricing/subjects/`; // +id

// Tutor Education Info
export const TutorEduGetInitalApi = `${api_url}/member/educational-info/getAllDegreeCourseAndInstitutes`;
export const TutorEduGetApi = `${api_url}/member/educational-info/`;
export const TutorEduPostApi = `${api_url}/member/educational-info/save`; //`http://localhost:5000/api/member/educational-info/save`; //
export const TutorEduGetByIdApi = `${api_url}/member/educational-info/`; // +id
export const TutorEduUpdateByIdApi = `${api_url}/member/educational-info/`; //`http://localhost:5000/api/member/educational-info/`; // // +id
export const TutorEduDeleteByIdApi = `${api_url}/member/educational-info/`; // +id

// Chat Api -
export const ConversationPostApi = `${api_url}/chat/conversation/save`;
export const SendMessageApi = `${api_url}/chat/message/save`;
export const GetMsgByConversationIdApi = `${api_url}/chat/message/`; // 631da642c448f32d347791df
export const GetConversationsApi = `${api_url}/chat/conversation/`; //
export const GetOfferedTuitionByTutor = `${api_url}/chat/getOfferedTuitions/`; // tutorID
export const GetConversationApi = `${api_url}/chat/newConversation/getConversation/`; // recevierId

//Student Interest To Learn API
export const StudentInterestToLearnGetAllApi = `${api_url}/member/interest-learn/`;
export const StudentInterestToLearnPostApi = `${api_url}/member/interest-learn/save`;
export const StudentInterestToLearnDeleteApi = `${api_url}/member/interest-learn/`;
export const StudentInterestToLearnGetByIdApi = `${api_url}/member/interest-learn/`;

//Profile pic url
export const ProfilePictureRootURL = `https://dev-tuteair-backend-app-service.azurewebsites.net/document/image/`;
export const DocumentRootURL = `https://dev-tuteair-backend-app-service.azurewebsites.net/document/document/`;

// Filtered tutors
export const FilteredTutorsApi = `${api_url}/tutors/filtered`;
export const GetAllTutors = `${api_url}/tutors`;
export const GetAllTutorsNew = `${api_url}/tutors/alltutors`;

//Member profile
export const MemberProfile_BasicInfoApi = `${api_url}/member/profile/basic_infos`;
export const MemberProfile_PersonalInfoApi = `${api_url}/member/profile/personal_infos`;
export const MemberProfile_EducationInfoApi = `${api_url}/member/profile/educational_infos`;
export const MemberProfile_WorkingExperienceApi = `${api_url}/member/profile/working_experiences`;
export const MemberProfile_SchedulesApi = `${api_url}/member/profile/schedules`;
export const MemberProfile_InterestPricingApi = `${api_url}/member/profile/interest_pricings`;
export const MemberProfile_InterestLearnApi = `${api_url}/member/profile/interest_learns`;

export const NotificationUrl = `${api_url}/notification`;
export const ResendEmailUrl = `${api_url}/users/resend-email`;
export const SubscribeEmailPostApi = `https://tuteair-backend-app-service.azurewebsites.net/subscriber`;

// New Account URLs
export const MemberAllInfosGetApi = `${api_url}/newMember/membersInfo`;

// basic info
export const MemberBasicInfoSave = `${api_url}/newMember/saveBasicInfo`;

// schedule
export const ScheduleEditApi = `${api_url}/newMember/updateSchedule`;
export const ScheduleSaveApi = `${api_url}/newMember/saveSchedule`;
export const ScheduleDeleteApi = `${api_url}/newMember/deleteSchedule`;

// work experience
export const WorkExpSaveApi = `${api_url}/newMember/saveWorkExperience`;
export const WorkExpEditApi = `${api_url}/newMember/updateWorkExperience`;
export const WorkExpDeleteApi = `${api_url}/newMember/deleteWorkExperience`;

// education info
export const EducationInfoSaveApi = `${api_url}/newMember/saveEduInfo`;
export const EducationInfoUpdateApi = `${api_url}/newMember/updateEduInfo/`;
export const EducationInfoDeleteApi = `${api_url}/newMember/deleteEduInfo/`;

//Request For Tutor API
export const TuitionRequestGetAllApi = `${api_url}/tuitionRequest/getAllRequest/`;
export const TuitionRequestPostApi = `${api_url}/tuitionRequest/save`;
export const TuitionRequestUpdateApi = `${api_url}/tuitionRequest/update/`;
export const TuitionRequestDeleteApi = `${api_url}/tuitionRequest/delete/`;
export const TuitionRequestGetByIdApi = `${api_url}/tuitionRequest/getRequest/`;
export const AllTuitionRequestGetApi = `${api_url}/tuitionRequest/getAlls/`;

//Account Deactivation from old member route
export const UserPassGetApi = `${api_url}/newMember/settings/update-account`;
//get is google account have null password?
export const PasswordNullCheckApi = `${api_url}/newMember/settings/password-null-check`;

//Setting Email Notification
export const EmailNotificationPostApi = `${api_url}/notification/setting-notification`;

//class-schedule-calendar
export const ClassSchedulePostApi = `${api_url}/classSchedule/save`;
export const ClassScheduleGetAllApi = `${api_url}/classSchedule/getMyEvents`;
export const ClassScheduleUpdateApi = `${api_url}/classSchedule/update`;
export const ClassScheduleDeleteApi = `${api_url}/classSchedule/delete`;

//class-schedule-list-view
export const CompletedClassListView = `${api_url}/classSchedule/getCompleteEvents`;
export const PaidClassListView = `${api_url}/classSchedule/getPaidEvents`;
export const UpcomingClassListView = `${api_url}/classSchedule/getUpcomingEvents`;
