import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unseenConversationCount: 0,
    unseensData: undefined,
    selectedUser: undefined,
    topConversation: undefined,
    type: undefined,
    mobileSelected: undefined,
    showConversation: undefined,
    showMessage: undefined,
};

const conversationsSlice = createSlice({
    name: "conversations",
    initialState,
    reducers: {
        unseenConversations: (state, action) => {
            switch (action.payload.type) {
                case "UNSEEN":
                  //  console.log(action.payload.unseenConversationCount)
                    state.unseenConversationCount = action.payload.unseenConversationCount;
                    break;
                case "ALL":
                //  console.log(action.payload.unseenConversationCount)
                  state.unseensData = action.payload.unseensData;
                break;
                default:
                    break;
            }
        },
        chattingInfo: (state, action) => {
            switch (action.payload.type) {
                case "SELECTED":
                    state.selectedUser = action.payload.selectedUser;
                    break;
                case "TOP":
                    state.topConversation = action.payload.topConversation;
                    break;
                case "MOBILE":
                    state.showConversation = action.payload.showConversation;
                    state.showMessage = action.payload.showMessage;
                    break;
                default:
                    break;
            }
        }
    },
});

export const {unseenConversations, chattingInfo} = conversationsSlice.actions;
export default conversationsSlice.reducer;
