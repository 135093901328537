import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userLoggedIn, userLoggedOut } from "../../redux/features/auth/authSlice";
import { clearMemberMenus, memberMenus } from "../../redux/features/menus/menuSlice";

export default function useAuthCheck() {
    const dispatch = useDispatch();
    const [authChecked, setAuthChecked] = useState(false);
    
    useEffect(() => {
        const localAuth = localStorage?.getItem("tuteAirUser");
        const layoutVersion = localStorage?.getItem("layout_version");
        const swMember = localStorage?.getItem("swMember");
        if(layoutVersion && layoutVersion === "dark-only") 
        {
            document.body.classList.remove('light');
            document.body.classList.remove('dark-sidebar');
            document.body.classList.add('dark-only');
        }

        if(swMember === undefined) {
            dispatch(userLoggedOut());
            dispatch(clearMemberMenus());
            localStorage.clear();
        }
        if (localAuth) {
            const auth = JSON.parse(localAuth);
            if (auth?.accessToken && auth?.id) {
                dispatch(
                    userLoggedIn({
                        accessToken: auth.accessToken,
                        user: auth.id,
                        accountType: auth.accountType,
                        firstName: auth.firstName,
                        lastName: auth.lastName,
                    })
                );

                dispatch(
                    memberMenus({
                        memberType: swMember,
                    })
                );
            }
        }
        //  setTimeout(() => {}, 3000);
        setAuthChecked(true);

    }, [dispatch, setAuthChecked]);

    return authChecked;
}
