import React, { Fragment } from "react";
import FindTutorsContain from "../../Component/FindTutors";

const FindTutors = () => {
  return (
    <Fragment>
      <FindTutorsContain />
    </Fragment>
  );
};
export default FindTutors;
