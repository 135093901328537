import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { authHeader, toastNotifiy } from "../../../../Services/AuthVerify";
import "../../../../style/custom-css/PersonalInfo.css";
// import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  EducationInfoSaveApi,
  EducationInfoUpdateApi,
  TutorEduGetInitalApi
} from "../../../../api";
import { toastMessage } from "../../../../redux/utils/tuteairCommon";
import MemberInformationContext from "../../../../_helper/MemberInformation";

const TutorEducationModal = (props) => {
  const [courseList, setCourseList] = useState([]);
  const [degreeList, setDegreeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const {
    getMemberInformation,
    courseList: courseList1,
    degreeList: degreeList1,
    getDegreeAndCoursesList,
  } = useContext(MemberInformationContext)

  // post request
  const [institute, setInstitute] = useState("");
  const [grade, setGrade] = useState("");
  const [passingYear, setPassingYear] = useState({});
  const [attachment, setAttachment] = useState([]);
  const [attachmentURL, setAttachmentURL] = useState("");
  const [attachmentOriginalName, setAttachmentOriginalName] = useState("");
  const [documentID, setDocumentID] = useState("");
  const [passingYearList, setPassingYears] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  // new
  const [selectedDegree, setSelectedDegree] = useState({});
  const [selectedCourse, setSelectedCourse] = useState({});

  //validate result percentage
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValidGrade, setIsValidGrade] = useState(true);
  const [gradeErrorMessage, setGradeErrorMessage] = useState(
    " min: 0% and max:100%"
  );

  const fnIsValidGrade = (Number) => {
    if (Number >= 0 && Number <= 100) return true;
    else return false;
  };

  useEffect(() => {
    if (!fnIsValidGrade(grade)) {
      setIsValidGrade(false);
      setShowErrorMessage(true);
    } else {
      setIsValidGrade(true);
      setShowErrorMessage(false);
    }
  }, [grade]);

  useEffect(() => {

    if (!degreeList || !courseList) {
      getDegreeAndCoursesList()
    }

    fnModifiedDegreeList(degreeList1);
    fnModifiedCourseList(courseList1);

    if (props.singleEduData.inProgress) {
      fnPassingYearList(6, "next");
    } else {
      fnPassingYearList(83, "prev");
    }
  }, []);

  useEffect(() => {
    setInstitute(props.singleEduData.institute);
    setInProgress(props.singleEduData.inProgress);
    setGrade(props.singleEduData.grade);
    if (props.edit) {
      setAttachmentURL(props.singleEduData.attachmentURL);
      setAttachment(props.singleEduData.attachment);
      setAttachmentOriginalName(props.singleEduData.attachmentOriginalName);
      setDocumentID(props.singleEduData.attachment);
      if (props.singleEduData.inProgress) {
        fnPassingYearList2(6, "next");
      } else {
        fnPassingYearList2(83, "prev");
      }
    } else {
      setPassingYear("");
      setSelectedCourse("");
      setSelectedDegree("");
    }
  }, [props.singleEduData]);

  const fnModifiedDegreeList = (result) => {
    result = result.slice().sort((x, y) => {
      return x.sorting - y.sorting;
    });
    var degreeList2 = [];
    if (result) {
      result.map((item, index) => {
        degreeList2.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    setDegreeList(degreeList2);
    setSelectedDegree(
      degreeList2?.filter(
        ({ value }) => value === props.singleEduData.degree.value
      )[0]
    );
  };
  const fnModifiedCourseList = (result) => {
    result = result.slice().sort((a, b) => a.name.localeCompare(b.name));
    var courseList2 = [];
    if (result) {
      result.map((item, index) => {
        courseList2.push({
          value: item._id,
          label: item.name,
          isDisabled: false,
        });
      });
    }
    setCourseList(courseList2);
    setSelectedCourse(
      courseList2?.filter(
        ({ value }) => value === props.singleEduData.course.value
      )[0]
    );
  };
  const fnPassingYearList = (count, type) => {
    const year = new Date().getFullYear();
    let years = [];
    if (type === "next") {
      years = Array.from(new Array(count), (val, index) => year + index);
    } else {
      years = Array.from(new Array(count), (val, index) => year - index);
    }

    var yearList = [];
    if (years) {
      years?.map((item, index) => {
        yearList.push({
          value: item,
          label: item,
          isDisabled: false,
        });
      });
    }
    setPassingYears(yearList);
  };
  const fnPassingYearList2 = (count, type) => {
    const year = new Date().getFullYear();
    let years = [];
    if (type === "next") {
      years = Array.from(new Array(count), (val, index) => year + index);
    } else {
      years = Array.from(new Array(count), (val, index) => year - index);
    }

    var yearList = [];
    if (years) {
      years?.map((item, index) => {
        yearList.push({
          value: item,
          label: item,
          isDisabled: false,
        });
      });
    }
    setPassingYears(yearList);
    setPassingYear(
      yearList?.filter(
        ({ value }) => value == props.singleEduData.passingYear
      )[0]
    );
  };

  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "pdf"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  const onFilesChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    if (isValidFileUploaded(e.target.files[0])) {
      setAttachment(e.target.files[0]);
      setAttachmentURL(window.URL.createObjectURL(e.target.files[0]));
    } else {
      toastNotifiy("File not valid. [Only pdf,png,jpeg,jpg accpeted]", "warn");
      e.target.value = null;
      ref.current.value = "";
    }
  };

  const validatedField = () => {
    if (
      !selectedDegree ||
      !selectedCourse ||
      !institute ||
      !grade ||
      !passingYear
    ) {
      toastMessage("Fillup Required Field!", "warn");
      return false;
    } else if (!isValidGrade) {
      toastMessage("Result validation failed!", "warn");
      return false;
    } else if (!isNaN(institute)) {
      toastMessage("Enter Valid Institute Name!", "warn");
      return false;
    } else {
      return true;
    }
  };
  const handleUpdateEduInfo = async (e) => {
    e.preventDefault();
    if (validatedField()) {
      if (window.confirm("Are you sure to update this information?")) {
        setLoading(true);
        const formData = new FormData();
        const selectedCourseObject = {
          label: selectedCourse.label,
          value: selectedCourse.value
        }
        const selectedDegreeObject = {
          label: selectedDegree.label,
          value: selectedDegree.value
        }

        formData.append("course", JSON.stringify(selectedCourseObject));
        formData.append("degree", JSON.stringify(selectedDegreeObject));
        formData.append("institute", institute);
        formData.append("marks", grade);
        formData.append("passingYear", passingYear.value);
        formData.append("document", attachment);
        formData.append("documentID", documentID);
        formData.append("isRunning", inProgress);

        const res = await axios.put(
          EducationInfoUpdateApi + props.singleEduData.id,
          formData,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        if (res.status === 201) {
          if (res.data) {
            toast.success("Education Info Updated successully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            props.toggler();
            getMemberInformation()
          } else {
            toast.warn("Failed to add", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          toast.warn("something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }
  };

  function deleteFile() {
    if (window.confirm("Are you sure to delete this file?")) {
      setAttachment([]);
      setDocumentID("");
      setAttachmentOriginalName("");
      ref.current.value = "";
      document.getElementById("fileControl").value = "";
    }
  }

  const handleAddEduInfo = async (e) => {
    e.preventDefault();
    if (validatedField()) {
      if (window.confirm("Are you sure to save this information?")) {
        setLoading(true);

        const formData = new FormData();

        const selectedCourseObject = {
          label: selectedCourse.label,
          value: selectedCourse.value
        }
        const selectedDegreeObject = {
          label: selectedDegree.label,
          value: selectedDegree.value
        }

        formData.append("course", JSON.stringify(selectedCourseObject));
        formData.append("degree", JSON.stringify(selectedDegreeObject));
        formData.append("institute", institute);
        formData.append("marks", grade);
        formData.append("passingYear", passingYear.value);
        formData.append("document", attachment);
        formData.append("documentID", "");
        formData.append("isRunning", inProgress);

        const res = await axios.post(EducationInfoSaveApi, formData, {
          headers: {
            Authorization: authHeader(),
          },
        });

        if (res.status === 201) {
          if (res.data) {
            toast.success("Education Info Added successully", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            props.toggler();
            getMemberInformation();
          } else {
            toast.warn("Failed to add", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          console.log(res.data);
          toast.warn("something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
    }
  };

  const checkboxHandler = async (isChk) => {
    setPassingYear("");
    setInProgress(isChk);
    if (isChk) {
      fnPassingYearList(6, "next");
    } else {
      fnPassingYearList(83, "prev");
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggler}
        centered
        className="modal-lg"
        backdrop="static"
      >
        <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
        <ModalBody className={"pb-0 " + props.bodyClass}>
          <Col sm="12" xl="12 box-col-12">
            <Card className="mb-0">
              <CardBody className="megaoptions-border-space-sm">
                <Row>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        Degree
                      </Label>
                      <Col sm="10">
                        <Select
                          options={degreeList}
                          placeholder="Select Degree"
                          className="js-example-basic-single col-sm-12 darkMode"
                          selecte
                          onChange={(item) => setSelectedDegree(item)}
                          value={selectedDegree}
                        />
                      </Col>
                      {/*<Col sm="2">
                        <Btn
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                        </Col>*/}
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        Course
                      </Label>
                      <Col sm="10">
                        <Select
                          options={courseList}
                          placeholder="Select Course"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setSelectedCourse(item)}
                          value={selectedCourse}
                        />
                      </Col>{" "}
                      {/*<Col sm="2">
                        <Btn
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                      </Col>*/}
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label pt-0 asterisk"
                        htmlFor=""
                      >
                        Institute
                      </Label>
                      <Col sm="10">
                        <Input
                          type="text"
                          name=""
                          placeholder="Enter Your Institute"
                          className="form-control"
                          value={institute}
                          onChange={(e) => setInstitute(e.target.value)}
                        />
                        {/* <Input
                          type="text"
                          name="select"
                          className="form-control"
                          defaultValue={institute}
                          onChange={(e) => setInstitute(e.target.value)}
                        >
                          <option value="" disabled>
                            Select Institute
                          </option>
                          {instituteList?.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </Input> */}
                      </Col>{" "}
                      {/*<Col sm="2">
                        <Btn
                          className="btn-sm"
                          attrBtn={{
                            color: "success",
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </Btn>
                      </Col>*/}
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        Result
                      </Label>
                      <Col sm="4">
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Enter score in percentage"
                          value={grade}
                          onChange={(e) => setGrade(e.target.value)}
                        //onChange={handleGradeChange}
                        />
                        {showErrorMessage && !isValidGrade ? (
                          <div className="text-danger">{gradeErrorMessage}</div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Label
                        className="col-sm-2 col-form-label asterisk"
                        htmlFor="inputEmail3"
                      >
                        Completed Year
                      </Label>
                      <Col sm="2">
                        <Select
                          options={passingYearList}
                          placeholder="Select Year"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) => setPassingYear(item)}
                          value={passingYear}
                        />
                      </Col>
                      <Col sm="2">
                        <div className="row" style={{ placeItems: "center" }}>
                          <Col sm="2">
                            <Input
                              id=""
                              type="checkbox"
                              checked={inProgress === true}
                              onChange={(event) => {
                                checkboxHandler(event.target.checked);
                              }}
                            />
                          </Col>
                          <Label
                            className="col-sm-8 col-form-label"
                            htmlFor="inputEmail3"
                          >
                            In Progress
                          </Label>
                        </div>
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        Attachments
                      </Label>
                      <Col sm="7">
                        <Input
                          className="form-control"
                          type="file"
                          id="fileControl"
                          onChange={onFilesChange}
                          ref={ref}
                          title="Choose a Attachment "
                        />
                      </Col>
                      <Col
                        sm="3"
                        className={
                          attachment?.length > 0 || attachment.size > 0
                            ? ""
                            : "d-none"
                        }
                      >
                        <Btn
                          attrBtn={{
                            className: "btn",
                            color: "danger",
                            type: "button",
                            onClick: (e) => deleteFile(e),
                          }}
                        >
                          Delete
                        </Btn>
                      </Col>
                    </div>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Label
                        className="col-sm-2 col-form-label"
                        htmlFor="inputEmail3"
                      ></Label>
                      <Col sm="10">
                        {props.edit && (
                          <u>
                            <a
                              className=""
                              href={attachmentURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {props.edit &&
                                attachmentOriginalName.substring(
                                  attachmentOriginalName.indexOf("_") + 1
                                )}
                            </a>
                          </u>
                        )}
                      </Col>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn attrBtn={{ color: "warning", onClick: props.toggler }}>
            Close
          </Btn>
          <Btn
            attrBtn={{
              color: "success",
              disabled: loading ? loading : loading,
              onClick: (e) =>
                props.edit ? handleUpdateEduInfo(e) : handleAddEduInfo(e),
            }}
          >
            {loading
              ? "LOADING..."
              : props.edit
                ? "Update Changes"
                : "Save changes"}
          </Btn>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default TutorEducationModal;
