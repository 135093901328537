import React, { useCallback, useState } from "react";
import { Container, Row } from "reactstrap";
import { useGetUsersQuery } from "../../../redux/features/users/usersApi";
import ContactMemberModal from "./ContactMemberModal";
import ListOfImageDesc from "./ListOfImageDesc";

const AllUsers = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const initilindex = { index: 0, isOpen: false, toggler: toggle };
  const [photoIndex, setPhotoIndex] = useState(initilindex);

  const { data: users, isLoading, isError, error } = useGetUsersQuery("tutor");
  const callback = useCallback((photo) => {
    setPhotoIndex(photo);
  });
  return (
    <div>
      <Container fluid={true}>
        <Row>
          <ListOfImageDesc
            users={users === undefined ? [] : users}
            setPhotoIndex={callback}
            photoIndex={photoIndex}
            withDesc={true}
          />
        </Row>
      </Container>
      {photoIndex.isOpen && (
        <ContactMemberModal photoIndex={photoIndex} setPhotoIndex={callback} />
      )}
    </div>
  );
};

export default AllUsers;
