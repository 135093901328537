import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import Header from "../Header/Header";

const HelpContain = () => {
  useEffect(() => {
    document.title = "Get Help | TuteAir - Learn & Teach Gloabally";
  }, []);
  return (
    <>
      <div className="" style={{ background: "#737a8a00" }}>
        {/* #f174171f */}
        <Header />
        <section className="searchPage container px-0">
          <Row className="px-0 search_heading">
            <Col md={8} className="offset-2">
              <h1 className="text-center my-0 mb-2 ta-student">Get Help</h1>
              <p className="text-center"></p>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};

export default HelpContain;
