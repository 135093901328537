//worked till now
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import Select, { createFilter } from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";
import { H5 } from "../../../AbstractElements";

import Multiselect from "multiselect-react-dropdown";
import { FixedSizeList as List } from "react-window";
import "../../../style/custom-css/PersonalInfo.css";

import { Table } from "react-bootstrap";
import { Info, Plus, PlusSquare, ThumbsUp } from "react-feather";
import BecomeStudentContext from "../../../_helper/BecomeStudent";
import BecomeTutorContext from "../../../_helper/BecomeTutor";
import TutorsContext from "../../../_helper/Tutors";
import "../../AccountNew/Tutor/InterestPricing/styles.css";
import {
  getSubCategoryList1,
  getSubCategoryList2,
  getSubjectList,
} from "../../AccountNew/Tutor/InterestPricing/subjectInterestCommon";
import { saveInteresLearnNew } from "../BecomeTutor/fnBecomeTutor";

let { options, optionsOld, height } = getSubjectList();

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options?.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

const StudentInterestToLearn = (props) => {
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tutionTypeList, setTutionTypeList] = useState([]);
  const [selectedOptions, setSubjectSelectedOptions] = useState([]);
  const [selectedTutionTypeOptions, setTutionTypeSelectedOptions] = useState(
    []
  );

  //validate amount
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [isValidAmount, setIsValidAmount] = useState(false);
  const amountErrorMessage = "amount cannot be negative";

  const fnIsValidAmount = (Number) => {
    if (Number >= 0) return true;
    else return false;
  };

  const {
    getCurrencies,
    currencies,
    getDegrees,
    degrees,
    getCategories,
    allCategories,
    allSubCategories,
  } = useContext(TutorsContext);

  const {} = useContext(BecomeTutorContext);

  const {
    handleSkipOrNext,
    interestLearn,
    setInterestLearn,
    interestLearnList,
    setInterestLearnList,
  } = useContext(BecomeStudentContext);

  useEffect(() => {
    if (currencies.length === 0) getCurrencies();
    if (degrees.length === 0) getDegrees();
    getCategories();
    if (!interestLearn) {
      options = optionsOld;
      setInterestLearn({
        ...interestLearn,
        amount: 0,
      });
    }
    // if (courses.length === 0) getCourses();
    setTutionTypeList([
      "Online",
      "Teachers Place",
      "Students Place",
      "Public Place",
    ]);

    if (interestLearnList?.length === 0) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }
  }, []);

  useEffect(() => {
    if (!fnIsValidAmount(interestLearn?.amount)) {
      setIsValidAmount(false);
      setShowErrorMessage(true);
    } else {
      setIsValidAmount(false);
      setShowErrorMessage(false);
    }
  }, [interestLearn?.amount]);

  const handleSubjects = async (sub) => {
    if (!sub || sub.length === 0) {
      setSubjectSelectedOptions([]);
      setInterestLearn({
        ...interestLearn,
        subjects: [],
      });
      return;
    }
    setSubjectSelectedOptions(sub);

    var arr1 = [];

    sub?.map((item) => {
      arr1.push({
        id: item.id,
        tag: item.tag,
        name: item.value,
      });
    });

    options = options?.filter((x) => x.tag === sub[0]?.tag);

    if (sub[0]?.tag.includes("/")) {
      var subCatList = await getSubCategoryList2(sub[0], allSubCategories);

      setSubCategoryList(subCatList);

      setInterestLearn({
        ...interestLearn,
        subjects: arr1,
        category: {
          value: sub[0]?.tag.split("/")[0],
          label: sub[0]?.tag.split("/")[0],
        },
        subCategory: {
          value: sub[0]?.tag,
          label: sub[0]?.tag.split("/")[1],
        },
      });
    } else {
      if (!selectedOptions) {
        alert(1);
      }
      setInterestLearn({
        ...interestLearn,
        subjects: arr1,
        category: {
          value: sub[0]?.tag,
          label: sub[0]?.tag,
        },
      });
    }
  };

  const handleCategories = async (item) => {
    if (!item) {
      setSubCategoryList([]);
      setSubjectSelectedOptions([]);
      options = optionsOld;
      setInterestLearn({
        ...interestLearn,
        category: "",
        subjects: [],
      });
      return;
    }
    setInterestLearn({
      ...interestLearn,
      category: item,
    });
    setInterestLearn({
      ...interestLearn,
      category: item,
      subCategory: [],
    });

    options = optionsOld?.filter((x) => x.tag.split("/")[0] === item?.value);

    var subCatList = await getSubCategoryList1(item, allSubCategories);
    if (subCatList) setSubCategoryList(subCatList);
  };

  const handleSubCategory = async (item) => {
    setSubjectSelectedOptions([]);
    setInterestLearn({ ...interestLearn, subCategory: item });
    options = optionsOld.filter((x) => x.tag === item.value);
  };

  useEffect(() => {
    if (
      selectedTutionTypeOptions !== null &&
      selectedTutionTypeOptions !== undefined &&
      selectedTutionTypeOptions.length > 0
    ) {
      setInterestLearn({
        ...interestLearn,
        tutionTypes: selectedTutionTypeOptions,
      });
    } else {
      setInterestLearn({
        ...interestLearn,
        tutionTypes: [],
      });
    }
  }, [selectedTutionTypeOptions]);

  const handleSave = async (e) => {
    e.preventDefault();
    if (isAdded) {
      console.log(interestLearn);
      var result = await saveInteresLearnNew(interestLearn, 1);
      if (result?.data) {
        var newObj = {
          ...interestLearn,
          amount: {
            initialPrice: interestLearn.amount,
          },
          classes: {
            name: interestLearn?.degree?.label,
          },
        };
        var data = [...interestLearnList, newObj];
        setInterestLearnList(data);
        setIsAdded(!isAdded);
      }
    } else {
      alert("disabled");
    }
  };

  const handleAddNew = (e) => {
    e.preventDefault();
    setIsAdded(!isAdded);
  };

  return (
    <Fragment>
      <Row className="bt_title">
        <Col md={4}></Col>
        <Col md={4}>
          <h1 className="text-center">Tuitions</h1>
        </Col>
        <Col md={4} className="self-end">
          <Media className="float-end">
            <div className={` ${isAdded ? "d-none" : ""}`}>
              <a
                href="#education"
                onClick={(e) => handleAddNew(e)}
                className=""
              >
                <PlusSquare height={35} width={35} />
              </a>
            </div>
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="4" xl="4" md="12" className="bt_left">
          <Card className="height-equal">
            <div className="calender-widget">
              <div className="cal-img d-none"></div>
              <div className="cal-date">
                <H5>
                  01
                  <br /> Subjects
                </H5>
              </div>

              <div className="card-body">
                <h3 className="py-3">How works?</h3>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Subjects</h6>
                    <p>
                      Pick the right category for your subjects, and you can add
                      four subjects for one tuition.
                    </p>
                  </div>
                </div>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Degrees</h6>
                    <p>
                      Choose the best course or degree that will fit this
                      tuition.
                    </p>
                  </div>
                </div>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Tuition Type</h6>
                    <p>
                      In what location would you like to teach? Online, at your
                      place, a student's place, or a public place.
                    </p>
                  </div>
                </div>
                <div className="media bt_help">
                  <ThumbsUp />
                  <div className="media-body">
                    <h6 className="f-w-600">Currency & Amount</h6>
                    <p>
                      Choose your currencies and enter your tuition's hourly
                      rate.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="media bt_help align-items-center">
                  <Info />
                  <div className="media-body">
                    <p>
                      By clicking,
                      <span onClick={(e) => handleAddNew(e)} className="icon">
                        <Plus />
                      </span>
                      you may add several educations information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm="12" lg="8" xl="8" md="12" className="bt_right">
          <Card className="mb-0">
            <CardHeader className="d-none">
              <Media className="float-end">
                <div className={` ${isAdded ? "d-none" : ""}`}>
                  <a
                    href="#education"
                    onClick={(e) => handleAddNew(e)}
                    className=""
                  >
                    <PlusSquare height={30} width={30} />
                  </a>
                </div>
              </Media>
            </CardHeader>
            <CardBody className="megaoptions-border-space-sm">
              {!isAdded && (
                <div className="table-responsive-sm">
                  <div>{}</div>
                  <Table className="table-border-horizontal">
                    <thead>
                      <tr style={{ background: "#dbdbdb2b" }}>
                        <th style={{ width: "5%" }} scope="col">
                          {"#"}
                        </th>
                        <th style={{ width: "35%" }} scope="col">
                          {"Subjects"}
                        </th>
                        <th style={{ width: "25%" }} scope="col">
                          {"Degree"}
                        </th>
                        <th style={{ width: "20%" }} scope="col">
                          {"Tuition Type"}
                        </th>
                        <th style={{ width: "10%" }} scope="col">
                          {"Amount"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {interestLearnList?.length === 0 && (
                        <tr className="text-center text-red">
                          <td colSpan={6}>No Interest & Pricing Info Found</td>
                        </tr>
                      )}
                      {interestLearnList?.map((item, k) => (
                        <tr key={k}>
                          <th scope="row">{k + 1}</th>
                          <td className="">
                            <span className="text-left h6">
                              {item.subjects?.map((item, index) => (
                                <span
                                  className="badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item?.name}
                                </span>
                              ))}
                            </span>
                          </td>
                          <td>
                            <span> {item.classes?.name} </span>
                          </td>
                          <td className="">
                            <span className="text-left h6">
                              {item.tutionTypes?.map((item, index) => (
                                <span
                                  className="badge-light-primary"
                                  style={{ margin: "0px 3px 2px 0px" }}
                                  key={index}
                                >
                                  {item}
                                </span>
                              ))}
                            </span>
                          </td>
                          <td>
                            {item.amount?.initialPrice} {item.currency?.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
              {isAdded && (
                <Row>
                  <Col sm="12" xl="12">
                    <div>
                      <Row>
                        <Col md={6}>
                          <Label className="col-form-label asterisk" htmlFor="">
                            Subject Category
                          </Label>
                          <Select
                            className="darkMode"
                            options={allCategories}
                            placeholder="Select Category"
                            onChange={(item) => handleCategories(item)}
                            value={interestLearn?.category}
                            isClearable={true}
                          />
                        </Col>
                        <Col
                          md={6}
                          className={subCategoryList.length > 0 ? "" : "d-none"}
                        >
                          <Label className="col-form-label asterisk" htmlFor="">
                            Sub Category
                          </Label>
                          <Select
                            className="darkMode"
                            options={subCategoryList}
                            placeholder="Select Sub Category"
                            onChange={(item) => handleSubCategory(item)}
                            value={interestLearn?.subCategory}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col md={12}>
                        <Label
                          className="col-form-label asterisk"
                          htmlFor="inputEmail3"
                        >
                          Subject
                        </Label>
                        <Select
                          isMulti
                          components={{ MenuList }}
                          options={options}
                          className="darkMode"
                          isClearable={true}
                          placeholder="Select Subjects"
                          isSearchable={true}
                          isDisabled={false}
                          isLoading={false}
                          isOptionDisabled={() => selectedOptions.length >= 4}
                          closeMenuOnSelect={false}
                          filterOption={createFilter({ ignoreAccents: false })}
                          onChange={(item) => handleSubjects(item)}
                          value={selectedOptions}
                        />
                      </Col>
                      <Col md={6}>
                        <Label
                          className="col-form-label asterisk"
                          htmlFor="inputEmail3"
                        >
                          Degree
                        </Label>
                        <Select
                          options={degrees}
                          placeholder="Select Degree"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) =>
                            setInterestLearn({
                              ...interestLearn,
                              degree: item,
                            })
                          }
                          value={interestLearn?.degree}
                        />
                      </Col>
                      <Col md={6}>
                        <Label className="col-form-label" htmlFor="">
                          Tuition Type
                        </Label>
                        <Multiselect
                          className="darkMode"
                          isObject={false}
                          options={tutionTypeList}
                          showCheckbox
                          onRemove={(e) => {
                            setTutionTypeSelectedOptions(e);
                          }}
                          onSelect={(e) => {
                            setTutionTypeSelectedOptions(e);
                          }}
                          selectedValues={selectedTutionTypeOptions}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Label className="col-form-label asterisk" htmlFor="">
                          Currency
                        </Label>
                        <Select
                          options={currencies}
                          placeholder="Select Currency"
                          className="js-example-basic-single col-sm-12 darkMode"
                          onChange={(item) =>
                            setInterestLearn({
                              ...interestLearn,
                              currency: item,
                            })
                          }
                          value={interestLearn?.currency}
                        />
                      </Col>

                      <Col md={6}>
                        <Label className="col-form-label asterisk" htmlFor="">
                          Amount
                        </Label>
                        <div className="d-flex align-items-center">
                          <Input
                            className="form-control"
                            type="number"
                            placeholder="Enter Amount"
                            value={interestLearn?.amount}
                            onChange={(e) =>
                              setInterestLearn({
                                ...interestLearn,
                                amount: e.target.value.slice(0, 6),
                              })
                            }
                          />

                          <span className="ms-2">/Hour</span>
                        </div>
                        {showErrorMessage && !isValidAmount ? (
                          <div className="text-danger">
                            {amountErrorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" xl="12">
                    <div className="row mb-2">
                      <Col md={12} className="btn_acc">
                        <button
                          className="btn  btn-sm btn-warning"
                          onClick={(e) => {
                            setIsAdded(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn mx-2 btn-sm btn-success"
                          onClick={(e) => handleSave(e)}
                        >
                          Save
                        </button>
                      </Col>
                    </div>
                    <div className="row mb-2"></div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>

          <Row
            style={{
              float: "right",
            }}
            className="py-4"
          >
            <Col md={12} className="btn_acc">
              <button
                className="btn  btn-lg btn-primary  mx-2"
                disabled={isAdded || interestLearnList?.length === 0}
                onClick={(e) => handleSkipOrNext("next")}
              >
                Next
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StudentInterestToLearn;
